import InspectionList from './InspectionList';
import InspectionCreate from './InspectionCreate';
import InspectionEdit from './InspectionEdit';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

export default {
  list: InspectionList,
  create: InspectionCreate,
  edit: InspectionEdit,
  icon: PlaylistAddCheckIcon
};
