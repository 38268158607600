import React, { useState } from "react";
import ReactExport from "react-export-excel";
import { Button, LinearProgress } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { dateParseWithoutTime } from '../../utils/functions';

const VehiclesExport = ({ dataExport }) => {
    const [exportLoading, setExportLoading] = useState(false);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const data = dataExport.map((item) => {
        return {
            patent: item.patent,
            customer: item.customer.name,
            brand: item.brand.name,
            model: item.model,
            chassis: item.chassis,
            busLine: item.busLine.name,
            internal: item.internal,
            color: item.color,
            year: item.year,
            busTemplate: item.busTemplateObject.name,
        }
    });

    const handleExportClick = () => {
        setExportLoading(true);
        setTimeout(() => {
            setExportLoading(false);
        }, 1000);
    };

    const filename = `vehiculos-${dateParseWithoutTime(Date.now())}`
    return (
        <ExcelFile
            filename={filename}
            element={
                <Button
                    label="Exportar a Excel"
                    size="small"
                    variant="contained"
                    onClick={handleExportClick}
                    disabled={exportLoading}
                >
                    {exportLoading ? <LinearProgress /> : <DownloadIcon />}
                </Button>
            }>
            <ExcelSheet data={data} name="VEHICULOS">
                <ExcelColumn label="Patente" value="patent" />
                <ExcelColumn label="Cliente" value="customer" />
                <ExcelColumn label="Marca" value="brand" />
                <ExcelColumn label="Modelo" value="model" />
                <ExcelColumn label="Chasis" value="chassis" />
                <ExcelColumn label="Linea" value="busLine" />
                <ExcelColumn label="Interno" value="internal" />
                <ExcelColumn label="Color" value="color" />
                <ExcelColumn label="Año" value="year" />
                <ExcelColumn label="Plantilla" value="busTemplate" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default VehiclesExport;
