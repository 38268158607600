import * as React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    useNotify
} from 'react-admin';
import { calculateTotalPaymentsPurchases, calculateTotalReceipts } from '../utils/functions';
import PaymentEditToolbar from './PaymentEditToolbar';
import PaymentForm from './PaymentForm';
import SelectInputMethod from './SelectInputMethod';

const PaymentCreate = (props) => {
    const notify = useNotify();
    const redirect = `/payments`;

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const validate = (values) => {
        const methods = JSON.parse(localStorage.getItem("methods"));
        const purchasesPayments = JSON.parse(localStorage.getItem("purchasesPayments"));
        const total = (methods === null) ? 0 : calculateTotalReceipts(methods);
        const totalPurchases = (purchasesPayments === null) ? 0 : calculateTotalPaymentsPurchases(purchasesPayments);
        const errors = {};

        if (values.supplierId !== undefined) {
            if (total !== totalPurchases || totalPurchases === 0 || total === 0) {
                errors.total = 'Total Error';
            }
        } else {
            if (total === 0) {
                errors.total = 'Total Error';
            }
        }
        return errors;
    };

    return (
        <Create
            {...props}
            onFailure={onFailure}
            title={'Orden de Pago'}
        >
            <TabbedForm
                toolbar={<PaymentEditToolbar />}
                redirect={redirect}
                validate={validate}
            >
                <FormTab label="Cabecera" >
                    <PaymentForm />
                </FormTab>
                <FormTab label="Medios de Pago" path="method">
                    <SelectInputMethod {...props} />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default PaymentCreate;
