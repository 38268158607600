import React from 'react';
import { Link } from 'react-admin';

const ShiftLinkField = props => (
    <Link to={`/shifts/${props.record.id}`}>  
        {props.record.description}
    </Link>
);

ShiftLinkField.defaultProps = {
    source: 'description',
    addLabel: true,
};

export default ShiftLinkField;
