import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        width: 'initial',
        minWidth: 'initial',
        maxWidth: '42em',
        maxHeight: '15em',
    },
};

const useStyles = makeStyles(styles);

const ImageQR = ({ record }) => {
    const classes = useStyles();
    let urlImageQR = `https://api.marasa.com.ar/tracking-api/api/tracking/qr/${record.id}`;
    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <img src={urlImageQR} alt={record.documentNumber} title={record.documentNumber} className={classes.img} />
            </CardContent>
        </Card>
    );
}
export default ImageQR;
