import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button
} from 'react-admin';

import IconContentAdd from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    extraHourButton: {
        alignItems: "flex-start",
        justify: "flex-end",
        direction: "row",
        marginTop: '1em',
        float: 'right'
    }
};

const AddExtraHourButton = ({ classes, record }) => (
    <Button
        component={Link}
        variant="contained"
        to={{
            pathname: "/extra-hour/create",
            search: `?id=${record.id}`,
        }}
        label="Crear"
        className={classes.extraHourButton}
    >
        <IconContentAdd />
    </Button>
);

export default withStyles(styles)(AddExtraHourButton);
