import * as React from 'react';
import {
    Edit,
    SimpleForm,
    useNotify,
    useRefresh,
    useRedirect
} from 'react-admin';
import {
    absenceDateValidation
} from '../validators/validators';
import CustomToolbar from '../components/CustomToolbar';
import AbsenceForm from './AbsenceForm';

const EmployeeTitle = ({ record }) => {
    return <span>{record ? `${record.fullName}` : ''}</span>;
};

const AbsenceEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/employees/${data.employeeId}/absences`);
        refresh();
    };

    return (
        <Edit
            {...props}
            title={<EmployeeTitle />}
            onSuccess={onSuccess}
            undoable={false}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                validate={absenceDateValidation}
            >
                <AbsenceForm />
            </SimpleForm>
        </Edit>
    );
};


export default AbsenceEdit;
