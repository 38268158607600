import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    usePermissions,
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import EmployeeLinkField from './EmployeeLinkField';
import EmployeeMobileGrid from './EmployeeMobileGrid';
import FullNameField from './FullNameField';
import { getRolesRRHH } from '../appRolesComponents';
import DateFieldFormatted from '../components/DateFieldFormatted';

const ListFilters = (props) => (
    <Filter {...props}>
        <SearchInput
            placeholder="Buscar por nombre"
            source="q"
            alwaysOn
            autoFocus
            resettable
            parse={(value) => value.toUpperCase()}
        />
        <SelectInput
            label="Estado"
            source="status"
            choices={[
                { id: 'true', name: 'ACTIVO' },
                { id: 'false', name: 'INACTIVO' },
            ]}
            alwaysOn
        />
    </Filter>
);

const EmployeeList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isRRHH = getRolesRRHH(permissions);

    if (!isRRHH) return null;

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            //filters={<InputFilter />}
            filters={<ListFilters />}
            sort={{ field: 'fullName', order: 'ASC' }}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <EmployeeMobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                >
                    <EmployeeLinkField
                        sortable={false}
                    />
                    <FullNameField sortable={false}
                    />
                    <ChipField
                        label="Puesto"
                        source="role"
                        size="small"
                        variant="outlined"
                        sortable={false}
                    />
                    <TextField
                        label="resources.employees.fields.cellphone"
                        source="cellphone"
                        sortable={false}
                    />
                    <ReferenceArrayField
                        label="resources.employees.fields.shift"
                        source="shiftId"
                        reference="shifts"
                        link={false}
                        sortable={false}

                    >
                        <SingleFieldList>
                            <ChipField
                                source="description"
                                size="small"
                            />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <DateFieldFormatted
                        label="resources.employees.fields.end_date"
                        source="endDate"
                        sortable={false}
                        showTime={false}
                    />
                    <DateFieldFormatted
                        label="resources.employees.fields.end_date"
                        source="endDate"
                        sortable={false}
                        showTime={false}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default EmployeeList;
