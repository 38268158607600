import moment from "moment";

//CREATE A UNIQUE ID
export const uid = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);

    return head + tail;
};

export const toChoices = items => items.map(item => ({ id: item, name: item }));

export const voucherFormat = (voucher, length) => {
    if (!voucher) {
        return '';
    }

    return voucher.toString().padStart(length, '0');
}

export const currencyFormatter = v => {
    return v.toLocaleString(
        'es-AR',
        {
            style: 'currency',
            currency: 'ARS'
        }
    );
};

export const toUpperCase = v => {
    return v.toUpperCase();
};

export const toLowerCase = v => {
    return v.toLowerCase();
};

export const dateParse = v => {
    return moment(v).format('YYYY-MM-DDTHH:mm:ss');
};

export const dateParseDateTime = v => {
    return moment(v).format('YYYY-MM-DD HH:mm:ss');
};

export const dateParseWithoutTime = v => {
    return moment(v).format('YYYY-MM-DD');
};

export const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return dateParseWithoutTime(result);
};

export const getFirstDay = () => {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay.getTime();
};

export const getLastDay = () => {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay.getTime();
};

export const isImageExist = (url) => {
    /*var img = new Image();
    img.src = url;   
    return img.height !== 0;*/
    var http = new XMLHttpRequest();
    http.open('HEAD', url);
    http.send();
    return http.status !== 404;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const calculateTotal = (items) => {
    return items.map(({ price, itemQuantity }) => price * itemQuantity).reduce((sum, i) => sum + i, 0);
}

export const calculateQuantity = (items) => {
    return items.map(({ itemQuantity }) => itemQuantity).reduce((sum, i) => sum + i, 0);
}

// Función para redondear a 2 decimales
export const roundToTwoDecimals = (value) => {
    return Math.round(value * 100) / 100;
}

//---INVOICES
export const calculateTotalExempt = (items) => {
    const itemsExempt = items.filter(record => record.itemTax === 1); // Filtra los exentos

    const totalExempt = itemsExempt.map(({ price, itemQuantity, itemBonus }) =>
        roundToTwoDecimals(
            (itemQuantity * price) - ((itemQuantity * price) * (itemBonus / 100))
        )
    ).reduce((sum, i) => roundToTwoDecimals(sum + i), 0);

    return totalExempt;
}

export const calculateSubtotalTax = (items) => {
    const itemsNotExempt = items.filter(record => record.itemTax !== 1); // Filtra los no exentos

    const subtotalTax = itemsNotExempt.map(({ price, itemQuantity, itemBonus, itemTax }) =>
        roundToTwoDecimals(
            ((itemQuantity * price) - ((itemQuantity * price) * (itemBonus / 100))) * itemTax
        )
    ).reduce((sum, i) => roundToTwoDecimals(sum + i), 0);

    return subtotalTax;
}

export const calculateSubtotal = (items) => {
    const subtotal = items.map(({ price, itemQuantity, itemBonus }) =>
        roundToTwoDecimals(
            (itemQuantity * price) - ((itemQuantity * price) * (itemBonus / 100))
        )
    ).reduce((sum, i) => roundToTwoDecimals(sum + i), 0);

    return subtotal;
}


export const calculateTotalTaxBase = (items) => {
    const itemsNotExempt = (items !== null) ? items.filter(record => record.itemTax !== 1) : []; // Filtra los no exentos

    const totalTaxBase = itemsNotExempt.map(({ price, itemQuantity, itemBonus }) =>
        roundToTwoDecimals(
            (itemQuantity * price) - ((itemQuantity * price) * (itemBonus / 100))
        )
    ).reduce((sum, i) => roundToTwoDecimals(sum + i), 0);

    return totalTaxBase;
}


// Función para calcular el total sin IVA con redondeo
export const calculateTotalWithoutTax = (product) => {
    const subtotal = product.itemQuantity * product.price;
    const discount = subtotal * (product.itemBonus / 100);
    return roundToTwoDecimals(subtotal - discount);
}


//CALCULA EL TOTAL DE LOS RECIBOS DE COBRO
export const calculateTotalReceipts = (items) => {
    return items.map(({ total }) => total).reduce((sum, i) => sum + i, 0);
}


//CALCULA EL TOTAL DE LOS RECIBOS DE COBRO QUE PAGO
export const calculateTotalReceiptsInvoices = (items) => {
    return items.map(({ totalReceipt }) => totalReceipt).reduce((sum, i) => sum + i, 0);
}

//CALCULA EL TOTAL PAGOS PARCIALES
export const calculatePartialTotal = (items) => {
    return items.map(({ partialTotal }) => partialTotal).reduce((sum, i) => sum + i, 0);
}


//CALCULA EL TOTAL DE ORDENES DE PAGO QUE PAGO
export const calculateTotalPaymentsPurchases = (items) => {
    return items.map(({ totalPurchase }) => totalPurchase).reduce((sum, i) => sum + i, 0);
}

export const getOperationTypeFormat = (record) => {
    const numberVoucher = voucherFormat(record.id, 8);
    let textVoucher = "";
    let result = "";
    switch (record.operationType) {
        case 'SELL':
            textVoucher = `FACTURA`;
            break;
        case 'RECIBO':
            textVoucher = `RECIBO`;
            break;
        case 'PURCHASE':
            textVoucher = `COMPROBANTE DE COMPRA`;
            break;
        default:
            textVoucher = `ORDEN`;
            break;
    }

    result = (record.name === 'Saldo Anterior') ? record.name : `${textVoucher}-${numberVoucher}`;
    return result;
}

export const getUrlResourceByOperationType = (record) => {
    let resource = "";
    switch (record.operationType) {
        case 'SELL':
            resource = `invoices`;
            break;
        case 'RECIBO':
            resource = `receipts`;
            break;
        case 'PURCHASE':
            resource = `purchases-invoices`;
            break;
        default:
            resource = `payments`;
            break;
    }

    return `/${resource}/${record.id}`;
}

export const getTypeAbsence = (record) => {
    let textVoucher = "";
    switch (record) {
        case 'justified':
            textVoucher = `JUSTIFICADA`;
            break;
        case 'vacation':
            textVoucher = `VACACIONES`;
            break;
        case 'art':
            textVoucher = `ART`;
            break;
        case 'sanction':
            textVoucher = `SANCIÓN`;
            break;
        default:
            textVoucher = `NO EXISTE`;
            break;
    }
    return textVoucher;
}