import * as React from 'react';
import {
    List
} from 'react-admin';
import CategoryGrid from './CategoryGrid';
import ActionsTopToolbar from '../components/ActionsTopToolbar';

const CategoryList = (props) => (
    <List
        {...props}
        actions={<ActionsTopToolbar />}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={25}
        pagination={false}
        component="div"
        bulkActionButtons={false}
    >
        <CategoryGrid />
    </List>
);

export default CategoryList;
