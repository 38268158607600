import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import { createTheme } from '@material-ui/core/styles';
import createPalette from "@material-ui/core/styles/createPalette";
import defaultMuiTheme from "@material-ui/core/styles/defaultTheme";

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      main: "#ff0000",
    },
    secondary: {
      main: "#ff0000",
    },
  })
);

const typography = {
  fontFamilySecondary: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Arial',
    'sans-serif',
  ].join(','),
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Arial',
    'sans-serif',
  ].join(','),

};

const typographyBase = {
  fontFamily: typography.fontFamily,
  //fontSize: typography.fontSize, //ERA MUY GRANDE EL TAMAÑO DE LETRA
  fontStyle: typography.fontStyle,
  color: typography.color,
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: typography.fontWeightBold,
  fontFamily: typography.fontFamilySecondary,
};

const typographyBody = {
  ...typographyBase,
  fontWeight: typography.fontWeightRegular,
  fontFamily: typography.fontFamily,
};

const rawTheme = {
  palette,

  typography: {
    ...typographyBase,
    h1: {
      ...typographyHeader,
      textTransform: "uppercase",
    //  fontSize: "4rem",
    },
    h2: {
      ...typographyHeader,
      textTransform: "uppercase",
    //  fontSize: "3rem",
    },
    h3: {
      ...typographyHeader,
    //  fontSize: "2.75rem",
    },
    h4: {
      ...typographyHeader,
    //  fontSize: "2rem",
    },
    h5: {
      ...typographyHeader,
      fontWeight: typography.fontWeightMedium,
    //  fontSize: "1.5rem",
    },
    h6: {
      ...typographyHeader,
      fontWeight: typography.fontWeightMedium,
    //  fontSize: "1.25rem",
    },
    body1: {
      ...typographyBody,
    //  fontSize: "1rem",
    },
    body2: {
      ...typographyBody,
    //  fontSize: "1rem",
    },
    button: {
      ...typographyBody,
    //  fontSize: "1rem",
    },
    caption: {
      ...typographyBody,
    //  fontSize: "0.875rem",
    },
  },

  shape: {
    borderRadius: 10,
  },

  overrides: {
    // React-Admin
    RaAppBar: {
      title: {
        textTransform: "capitalize",
      },
    },

    // Material-UI

    MuiAppBar: {
      root: {
        background: `linear-gradient(127deg, #ff0000, #ba0000);`,
      },
    },
    MuiCard: {
      root: {
        border: "none",
      },
    },
    MuiButton: {
      root: {
        //   color: palette.secondary.main,
        paddingTop: defaultMuiTheme.spacing(1),
        paddingRight: defaultMuiTheme.spacing(4),
        paddingBottom: defaultMuiTheme.spacing(1),
        paddingLeft: defaultMuiTheme.spacing(4),
      },
      sizeSmall: {
        paddingTop: defaultMuiTheme.spacing(0),
        paddingRight: defaultMuiTheme.spacing(2),
        paddingBottom: defaultMuiTheme.spacing(0),
        paddingLeft: defaultMuiTheme.spacing(2),
      },
      sizeLarge: {
        paddingTop: defaultMuiTheme.spacing(2),
        paddingRight: defaultMuiTheme.spacing(6),
        paddingBottom: defaultMuiTheme.spacing(2),
        paddingLeft: defaultMuiTheme.spacing(6),
      },
      contained: {},
      containedPrimary: {
        color: palette.common.white,
        backgroundColor: palette.secondary.main,
      },
      containedSecondary: {
        color: palette.common.white,
      },
      outlined: {},
      outlinedPrimary: {
        color: palette.primary.main,
        borderColor: palette.primary.main,
      },
      outlinedSecondary: {
        color: palette.common.white,
        borderColor: palette.common.white,
      },
      text: {},
      textPrimary: {
        color: palette.primary.main,
      },
      textSecondary: {
        color: palette.secondary.main,
      },
      label: {},
    },

    // React-Admin

    RaSidebar: {
      drawerPaper: {
        backgroundColor: palette.common.white,
        color: palette.primary.main,
        height: "100%",
        boxShadow:
          "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
      },
    },
    RaMenuItemLink: {
      active: {
        borderLeftStyle: "none",
        borderRightColor: palette.secondary.main,
        borderRightWidth: defaultMuiTheme.spacing(0.5),
        borderRightStyle: "solid",
        backgroundColor: palette.action.selected,
        color: palette.primary.main,
      },
      icon: {
        color: "inherit",
      },
    },
    RaLayout: {
      content: {
        height: "auto",
        backgroundColor: palette.background.default,
        paddingTop: defaultMuiTheme.spacing(0),
        paddingRight: defaultMuiTheme.spacing(0),
        paddingBottom: defaultMuiTheme.spacing(0),
        paddingLeft: defaultMuiTheme.spacing(0),
        display: "flex",
        flexDirection: "column",
        [defaultMuiTheme.breakpoints.up("xs")]: {
          paddingTop: defaultMuiTheme.spacing(0),
          paddingRight: defaultMuiTheme.spacing(0),
          paddingBottom: defaultMuiTheme.spacing(0),
          paddingLeft: defaultMuiTheme.spacing(0),
        },
        "& > div, & > h2": {
          paddingTop: defaultMuiTheme.spacing(1),
          paddingRight: defaultMuiTheme.spacing(2),
          paddingBottom: defaultMuiTheme.spacing(2),
          paddingLeft: defaultMuiTheme.spacing(2),
          [defaultMuiTheme.breakpoints.up("xs")]: {
            paddingLeft: defaultMuiTheme.spacing(2),
          },
        },
      },
    },

    RaTabbedShowLayout: {
      content: {
        marginTop: defaultMuiTheme.spacing(4),
        backgroundColor: palette.common.white,
        boxShadow: defaultMuiTheme.shadows[3],
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    RaShow: {
      main: {
        marginTop: defaultMuiTheme.spacing(2),
      },
      noActions: {
        marginTop: defaultMuiTheme.spacing(2),
      },
    },
    // RaFilter: {
    //   form: {},
    //   button: {
    //     "& button": {
    //       borderStyle: "solid",
    //       borderWidth: "2px",
    //       borderColor: palette.grey[300],
    //       textTransform: "uppercase",
    //       fontWeight: typography.fontWeightBold,
    //       color: palette.primary.main,
    //       paddingLeft: defaultMuiTheme.spacing(2),
    //       paddingRight: defaultMuiTheme.spacing(2),
    //       paddingTop: defaultMuiTheme.spacing(1),
    //       paddingBottom: defaultMuiTheme.spacing(1),
    //     },
    //   },
    // },
    RaListToolbar: {
      toolbar: {
        paddingBottom: defaultMuiTheme.spacing(1),
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: palette.grey[300],
        marginBottom: defaultMuiTheme.spacing(2),
      },
      actions: {
        marginRight: "0px",
      },
    },

    RaFormInput: {
      width: 544
    }
  },
};

const theme = createTheme(merge({}, defaultTheme, rawTheme));

export default theme;
