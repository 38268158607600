import React from 'react';
import {
    TopToolbar,
    LinearProgress,
} from 'react-admin';
import PDFButtonPayment from './PDFButtonPayment';

const EditActionsPayment = ({ data }) => {
    return (
        <TopToolbar>
            {(data !== undefined) ?
                <PDFButtonPayment {...data} />
                :
                <LinearProgress />
            }
        </TopToolbar>
    )
};

export default EditActionsPayment;