import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormatter } from '../utils/functions';
import InvoiceFootDetail from './InvoiceFootDetail';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const ListItemsMobile = ({ formData }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {formData.operationInfo.map((item, i) => (
                <Card key={item.id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h4>{item.name} - {item.itemComment}</h4>
                            </div>
                        }
                        subheader={
                            <div className={classes.card}>
                                <div>
                                    Cantidad: {item.itemQuantity}
                                </div>
                                <div>
                                    Precio S/IVA: {currencyFormatter(item.price)}
                                </div>
                                <div>
                                    IVA: {(item.itemTax === 1) ? 'EXENTO' : `${item.itemTax * 100}%`}
                                </div>
                            </div>
                        }

                    />
                    <CardContent className={classes.cardContent}>
                        <div className={classes.card}>
                            <div>
                                Total Sin IVA: {currencyFormatter(item.itemQuantity * item.price)}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ))}

            <InvoiceFootDetail products={formData.operationInfo} />

        </div>
    );
};

export default ListItemsMobile;
