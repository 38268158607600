import ItemList from './ItemList';
import ItemCreate from './ItemCreate';
import ItemEdit from './ItemEdit';
import BallotIcon from '@material-ui/icons/Ballot';

export default {
  list: ItemList,
  create: ItemCreate,
  edit: ItemEdit,
  icon: BallotIcon
};
