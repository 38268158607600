import React from 'react';
import { Link } from 'react-admin';

const InspectionField = props => (
    <Link to={`/inspections/${props.record.inspectionId}`}>  
        IN-{props.record.inspectionId}
    </Link>
);

InspectionField.defaultProps = {
    source: 'inspectionId',
    label: 'Inspección',
};

export default InspectionField;