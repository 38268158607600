import React, { useState, useEffect } from 'react';
import {
    useDataProvider,
    LinearProgress,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import EmployeeField from '../components/EmployeeField';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const ListItemsMobileDisplay = (props) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [products, setProducts] = useState([]);

    useEffect(() => {
        dataProvider.getOne('orders', { id: props.id })
            .then(response => {
                let itemsOrder = response.data.items;
                dataProvider.getMany('items', {
                    ids: itemsOrder.map((item) => item.id),
                })
                    .then(response => {
                        let items = response.data;
                        //Merge objects by id
                        const result = itemsOrder.map(t1 => ({ ...t1, ...items.find(t2 => t2.id === t1.id) }));
                        setProducts(result);
                        setLoading(false);
                    });
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!products) return null;

    return (
        <div className={classes.root}>
            {(products.length === 0) ?
                <div>
                    <Card key={0}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sin articulos cargados!</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Card>
                </div>
                : products.map((product) => (
                    <Card key={product.id} className={classes.card}>
                        <CardHeader
                            title={
                                <div className={classes.cardTitleContent}>
                                    <h3>{`${product.name} - (${product.code})`}</h3>
                                </div>
                            }
                            subheader={
                                <div>
                                    <div>
                                        {'Cant. Asignada'}
                                        :&nbsp;
                                        {product.itemQuantity}
                                    </div>
                                    <div>
                                        {'Cant. Pañol'}
                                        &nbsp;:&nbsp;
                                        {product.quantity}
                                    </div>
                                </div>
                            }

                        />
                        <CardContent className={classes.cardContent}>
                            {//MATERIALES
                                (product.employeeId !== null) ?
                                    <EmployeeField
                                        id={product.employeeId}
                                    />
                                    :
                                    null
                            }
                        </CardContent>
                    </Card>
                ))}
        </div>
    );
};

export default ListItemsMobileDisplay;
