import * as React from 'react';
import {
    EditButton,
    useTranslate,
    NumberField,
    usePermissions,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import WorkOrderStatusColorChip from '../components/WorkOrderStatusColorChip';
import { getRolesOnlyDriverExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';
import DateFieldFormatted from '../components/DateFieldFormatted';
import ActionButtonsField from './components/ActionButtonsField';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const OrderListMobile = ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { permissions } = usePermissions();

    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isWorkshopOnly = getRolesWorkshopOnly(permissions);

    let dataFilter = [];

    if (ids.length > 0) {
        dataFilter = (isDriver) ?
            Object.keys(data)
                .filter(id => data[id].workOrderStatus === 'FINALIZADA_ENTREGAR' && data[id].deliveryDate === null && data[id].invoiced === false)
                .map(id => data[id])
                .sort((a, b) => (a.id < b.id) ? 1 : -1)
            :
            Object.keys(data)
                .map(id => data[id])
                .sort((a, b) => (a.id < b.id) ? 1 : -1);
    }

    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {dataFilter.map(order => (
                <Card key={order} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h2>{`OT-${order.id}`}</h2>
                                {(!isDriver) && (
                                    <EditButton
                                        resource="orders"
                                        basePath={basePath}
                                        record={order}
                                    />
                                )}
                            </div>
                        }
                        subheader={
                            <div>
                                {`IN-${order.inspectionId}`}
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                            {translate('resources.orders.fields.startDate')}
                            :&nbsp;
                            <DateFieldFormatted
                                record={order}
                                source="start"
                            />
                        </div>
                        <div>
                            {translate('resources.orders.fields.endDate')}
                            :&nbsp;
                            <DateFieldFormatted
                                record={order}
                                source="end"
                            />
                        </div>
                        <div>
                            {translate('resources.orders.fields.deliveryDate')}
                            :&nbsp;
                            <DateFieldFormatted
                                record={order}
                                source="deliveryDate"
                            />
                        </div>
                        {(isWorkshopOnly) && (
                            <div>
                                {translate('resources.orders.fields.total')}
                                :&nbsp;
                                <NumberField
                                    record={order}
                                    source="total"
                                    locales="es-AR"
                                    options={{ style: 'currency', currency: 'ARS' }}
                                />
                            </div>
                        )}
                        <div>
                            {translate('resources.orders.fields.workOrderStatus')}
                            :&nbsp;
                            <WorkOrderStatusColorChip record={order} />
                        </div>
                        {isWorkshopOnly && <ActionButtonsField record={order} />}
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default OrderListMobile;
