import * as React from 'react';
import {
    Filter,
    ReferenceInput,
    SearchInput,
    SelectInput,
    NumberInput
} from 'react-admin';

const ItemFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <ReferenceInput
            label="Categoria"
            source="category"
            reference="categories"
            sort={{ field: 'id', order: 'ASC' }}
        >
            <SelectInput source="name" />
        </ReferenceInput>
        <NumberInput 
            label="Stock"
            source="quantity" 
        />
    </Filter>
);

export default ItemFilter;