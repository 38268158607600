import * as React from 'react';
import {
    TextField,
    EditButton,
    useTranslate,
    ReferenceField,
    NumberField,
    ChipField
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { voucherFormat } from '../utils/functions';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const InspectionMobileGrid = ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div className={classes.name}>
                                    <h6>{data[id].invoiceType} {data[id].pointOfSale}-{voucherFormat(data[id].id)}</h6>
                                </div>
                                <EditButton
                                    resource="invoices"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        subheader={
                            <div>
                                <div>
                                    {translate('resources.invoices.fields.date')}
                                    :&nbsp;
                                    <DateFieldFormatted
                                        record={data[id]}
                                        source="date"
                                        showTime={false}
                                    />
                                </div>
                                <div>
                                    {'Pago'}
                                    :&nbsp;
                                    <ChipField
                                        record={data[id]}
                                        size="small"
                                        source="invoicePaymentStatus"
                                    />
                                </div>
                            </div>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <div>
                            {translate('resources.invoices.fields.customer')}:&nbsp;
                            <ReferenceField
                                record={data[id]}
                                source="customerId"
                                reference="customers"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </div>
                        <div>
                            {translate('resources.invoices.fields.start_date')}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="startDate"
                                showTime={false}
                            />
                        </div>
                        <div>
                            {translate('resources.invoices.fields.end_date')}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="endDate"
                                showTime={false}
                            />
                        </div>
                        <div>
                            {translate('resources.invoices.fields.due_date')}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="dueDate"
                                showTime={false}
                            />
                        </div>
                        <br />
                        <span className={classes.cardContentRow}>
                            {translate('resources.invoices.fields.total')}
                            :&nbsp;
                            <NumberField
                                record={data[id]}
                                source="total"
                                locales="es-AR"
                                options={{ style: 'currency', currency: 'ARS' }}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.invoices.fields.status')}
                            :&nbsp;
                            <ChipField
                                record={data[id]}
                                size="small"
                                source="invoiceStatus"
                                variant="outlined"
                            />
                        </span>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};


export default InspectionMobileGrid;
