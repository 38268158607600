import * as React from 'react';
import {
    Create,
    SimpleForm,
    useNotify,
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import ShiftForm from './ShiftForm';

const ShiftCreate = (props) => {
    const redirect = `/shifts`;
    const notify = useNotify();

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    return (
        <Create
            {...props}
            onFailure={onFailure}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <ShiftForm />
            </SimpleForm>
        </Create>
    );
};

export default ShiftCreate;
