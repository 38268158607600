import * as React from 'react';
import { Fragment } from 'react';
import {
    SaveButton,
    Toolbar
} from 'react-admin';
import { dateParseDateTime } from '../utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import ThumbDown from '@material-ui/icons/ThumbDown';
import authProvider from '../authProvider';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const ToolCustomToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    record,
    saving,
}) => {
    const classes = useStyles();
    const user = authProvider.getIdentity();

    console.log(user);
    if (!record) return null;
    return (
        <Toolbar className={classes.root}>
            <Fragment>
                {(record.start === undefined) &&
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        submitOnEnter={true}
                        label="Retirar Herramienta del Pañol"
                        transform={data => (
                            {
                                ...data,
                                status: true,
                                quantity: 1, // Si es 1, resta del stock
                                start: dateParseDateTime(Date.now()),
                                userId: user.id,
                                userName: user.username,
                            }
                        )}
                    />
                }
                {(record.start !== undefined && record.end === null) &&
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        redirect="tools"
                        submitOnEnter={true}
                        label="Devolver Herramienta al Pañol"
                        transform={data => (
                            {
                                ...data,
                                status: true,
                                quantity: 1, // Aca suma al stock
                                end: dateParseDateTime(Date.now()),
                                userId: user.id,
                                userName: user.username,
                            }
                        )}
                    />
                }
                {(record.start !== undefined && record.end === null) &&
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        redirect="tools"
                        icon={<ThumbDown
                                color="primary"
                                style={{ color: 'red' }}
                            />}
                        submitOnEnter={false}
                        variant="outlined"
                        label="Dar de Baja Herramienta"
                        transform={data => (
                            {
                                ...data,
                                quantity: 0, // Si es 0, no se resta del stock
                                status: false,
                                end: dateParseDateTime(Date.now()),
                                userId: user.id,
                                userName: user.username,
                            }
                        )}
                    />
                }                
            </Fragment>
        </Toolbar>
    );
};

export default ToolCustomToolbar;
