import * as React from 'react';
import {
    List,
    Filter,
    DateInput,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InvoiceMobileGrid from './InvoiceMobileGrid';
import InvoiceListDesktop from './InvoiceListDesktop';

const ListFilters = (props) => (
    <Filter {...props}>
        <DateInput
            label="resources.reports_rrhh.filters.startDate"
            source="start"
            alwaysOn
        />
        <DateInput
            label="resources.reports_rrhh.filters.endDate"
            source="end"
            alwaysOn
        />
    </Filter>
);

const InvoiceList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <List
            {...props}
            filters={<ListFilters />}
            actions={<ActionsTopToolbar />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <InvoiceMobileGrid />
            ) : (
                <InvoiceListDesktop />
            )}
        </List>
    );
};

export default InvoiceList;
