import * as React from 'react';
import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useDataProvider,
    Loading
} from 'react-admin';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import GridEmployees from './GridEmployees';
import CropFreeIcon from '@material-ui/icons/CropFree';

const ButtonTrackingAutomatic = () => (
    <Button
        variant="contained"
        color="primary"
        component={Link}
        to={{
            pathname: '/tracking/create'
        }}
    >
        <CropFreeIcon />&nbsp;&nbsp;Fichar Empleados
    </Button>
);

const GridEmployeeList = ({ classes, ...props }) => {
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const dataProvider = useDataProvider();

    const fetchEmployees = useCallback(async () => {
        dataProvider
            .getList('employees', {
                filter: { active: 'true' },
                sort: { field: 'fullName', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
            })
            .then(response => {
                let employees = response.data.filter(emp => emp.active === true);

                dataProvider.getMany('shifts', {
                    ids: employees.map((employee) => employee.shiftId).flat(),
                })
                    .then(response => {
                        let shifts = response.data;
                        const primaryShifts = shifts.filter(record => record.primaryShift === true)

                        let employee = {};
                        let result = [];
                        employees.map((record) => {
                            employee = {
                                id: record.id,
                                fullName: record.fullName,
                                role: record.role,
                                shifts: primaryShifts.filter(item => record.shiftId.includes(item.id))
                            }
                            result.push(employee);
                            return result
                        });
                        setState(state => ({ ...state, result }));
                        setLoading(false);
                    });

            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });

    }, [dataProvider]);

    useEffect(() => {
        fetchEmployees();
    }, [fetchEmployees]);

    const {
        result,
    } = state;

    if (loading) return <Loading />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!result) return null;

    return (
        <div>
            <h3>{''}</h3>
            <div>
                <ButtonTrackingAutomatic />
            </div>
            <h3>{''}</h3>

            <div>
                <GridEmployees
                    employees={result}
                />
            </div>
        </div>
    );
};

export default GridEmployeeList;
