import * as React from 'react';
import { useState } from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    useRefresh,
    useRedirect,
    useNotify
} from 'react-admin';
import DocumentFileInput from './document/DocumentFileInput';
import DocumentFileView from './document/DocumentFileView';
import AutocompleteItemsSearch from './AutocompleteItemsSearch';
import ListItemsDisplay from './ListItemsDisplay';
import PurchaseForm from './PurchaseForm';
import PurchaseCustomToolbar from './PurchaseCustomToolbar';
import EditActionsPurchase from './EditActionsPurchase';

const PurchaseEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [loading, setLoading] = useState(true);

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/purchases`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const Title = ({ record }) => {
        return <span>{record ? `OC-${record.id}` : ''}</span>;
    };

    const handleLoading = (isLoading) => {
        setLoading(isLoading);
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<Title />}
            actions={<EditActionsPurchase />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                toolbar={<PurchaseCustomToolbar loading={loading} />}
                redirect={redirect}
            >
                <FormTab label="General">
                    <PurchaseForm {...props} />
                </FormTab>
                <FormTab
                    label="Articulos"
                    path="items"
                >
                    <FormDataConsumer>
                        {({ formData }) => (formData.purchaseStatus !== 'EN_DEPOSITO') ?
                            <div>
                                <AutocompleteItemsSearch {...props} formData={formData} handleLoading={handleLoading} />
                            </div>
                            :
                            <div>
                                <ListItemsDisplay {...props} />
                            </div>
                        }
                    </FormDataConsumer>

                </FormTab>
                <FormTab
                    label="Documentación"
                    path="documents"
                >
                    <DocumentFileInput />
                    <DocumentFileView />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default PurchaseEdit;
