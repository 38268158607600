import * as React from 'react';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
} from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoneyIcon from '@material-ui/icons/Money';
import BarChartIcon from '@material-ui/icons/BarChart';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const CheckListAside = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <FilterLiveSearch />

                <FilterList
                    label="Tipo de Cheque"
                    icon={<MoneyIcon />}
                >
                    <FilterListItem
                        label="De Terceros"
                        value={{
                            myCheck: false,
                        }}
                    />
                    <FilterListItem
                        label="Propios"
                        value={{
                            myCheck: true,
                        }}
                    />
                </FilterList>


                <FilterList
                    label="Tipo de Reporte"
                    icon={<BarChartIcon />}
                >
                    <FilterListItem
                        label="En Cartera"
                        value={{
                            checkVision: 'CARTERA',
                        }}
                    />
                    <FilterListItem
                        label="Dados de Baja"
                        value={{
                            checkVision: 'BAJA',
                        }}
                    />
                    <FilterListItem
                        label="A Depositar"
                        value={{
                            checkVision: 'DEPOSITAR',
                        }}
                    />
                    <FilterListItem
                        label="A Cubrir"
                        value={{
                            checkVision: 'CUBRIR',
                        }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default CheckListAside;
