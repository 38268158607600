import * as React from 'react';
import { useTranslate } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { dateParseDateTime } from '../utils/functions';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles({
    container: { minWidth: '35em', marginLeft: '1em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
    chip: { margin: '4px' }
});

const RptEmployeesHoursLines = ({ record }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Paper className={classes.container} elevation={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {translate('resources.reports_rrhh.fields.first')}
                        </TableCell>
                        <TableCell>
                            {translate('resources.reports_rrhh.fields.latest')}
                        </TableCell>
                        <TableCell>
                            {translate('resources.reports_rrhh.fields.shift')}
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {translate('resources.reports_rrhh.fields.hour')}
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {translate('resources.reports_rrhh.fields.leftHour')}
                        </TableCell>
                        <TableCell className={classes.rightAlignedCell}>
                            {translate('resources.reports_rrhh.fields.extraHour')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.tracking.map((item, i) => (
                        <TableRow key={i}>
                            <TableCell>
                                {dateParseDateTime(item.start)}
                            </TableCell>
                            <TableCell>
                                {dateParseDateTime(item.end)}
                            </TableCell>
                            <TableCell>
                                {item.shift.map((shift) => (
                                    <Chip
                                        label={shift}
                                        size="small"
                                        className={classes.chip}
                                    />
                                ))}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {item.duration.toLocaleString('ar-AR', { maximumFractionDigits: 2 })}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {item.leftHours.toLocaleString('ar-AR', { maximumFractionDigits: 2 })}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {item.extraHours.toLocaleString('ar-AR', { maximumFractionDigits: 2 })}
                            </TableCell>
                        </TableRow>
                    )
                    )}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default RptEmployeesHoursLines;
