import React from 'react';
import {
    TextInput,
    NumberInput,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import { requiredValidateValueWithoutZero } from '../validators/validators';
import authProvider from "../authProvider";

const WarehouseForm = (props) => {
    const user = authProvider.getIdentity();
    
    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="¿Que cantidad ingresa al Pañol?" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                autoFocus
                                label="Cantidad a ingresar"
                                source="addQuantity"
                                min="1"
                                max={props.record.warehouseQuantity}
                                defaultValue={0}
                                validate={requiredValidateValueWithoutZero}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Depósito"
                                source="warehouseQuantity"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <SectionTitle label="Cantidad del Pañol" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Pañol"
                                source="quantity"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Minima"
                                source="minQuantity"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Maxima"
                                source="maxQuantity"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    flex={2}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Articulo del Pañol" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Código"
                                source="code"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                autoFocus
                                label="Nombre"
                                source="name"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}>
                <TextInput
                    source="userId"
                    defaultValue={user.id}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="userName"
                    defaultValue={user.username}
                    disabled={true}
                    fullWidth
                />
            </div>
        </CardContent>
    );
};

export default WarehouseForm;
