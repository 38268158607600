//import jwtDecoder from 'jwt-decode'
export default {
    login: ({ username, password }) => {
        const request = new Request('https://api.marasa.com.ar/authentication-api/api/auth/signin', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('accessToken', auth.accessToken);
                localStorage.setItem('id', auth.id);
                localStorage.setItem('username', auth.username);
                localStorage.setItem('permissions', JSON.stringify(auth.roles));
            });

        // if (username === 'gsettino' && password === '123456789') {
        //     localStorage.removeItem('not_authenticated');
        //     localStorage.removeItem('role');
        //     return Promise.resolve();
        // }

        //localStorage.setItem('not_authenticated', true);
        //return Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('id');
        localStorage.removeItem('username');
        localStorage.removeItem('permissions');

        //OTROS
        localStorage.removeItem('invoiceCart');
        localStorage.removeItem('purchasesInvoiceCart');
        localStorage.removeItem('purchaseCart');
        localStorage.removeItem('busTemplate');
        localStorage.removeItem('savedDrawing');
        localStorage.removeItem('methods');
        localStorage.removeItem('invoicesReceipts');
        localStorage.removeItem('purchasesPayments');

        return Promise.resolve();
    },
    checkError: ({ status }) => {
        return status === 401 || status === 403
            ? Promise.reject()
            : Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('accessToken')
            ? Promise.resolve()
            : Promise.reject();
        // return localStorage.getItem('not_authenticated')
        // ? Promise.reject()
        // : Promise.resolve();
    },
    getPermissions: () => {
        const roles = JSON.parse(localStorage.getItem('permissions'));
        return Promise.resolve(roles);
    },
    getIdentity: () => {
        const user = {
            id: localStorage.getItem('id'),
            username: localStorage.getItem('username')
        }
        return user;
    }
};
