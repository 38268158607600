import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { currencyFormatter, voucherFormat } from '../../utils/functions';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        //borderBottomWidth: 1,
        alignItems: 'center',
        //height: 10,
        fontSize: 10,
        fontStyle: 'bold',
    },
    date: {
        width: '20%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    voucher: {
        width: '40%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    total: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
    blank: {
        width: '100%',
    },
    method: {
        width: '60%',
        textAlign: 'left',
        paddingLeft: 8,
    }


});

const PaymentTableRow = ({ pdfDocument }) => {

    const rowsPurcharses = pdfDocument.purchases.map(item =>
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.date}>{item.date}</Text>
            <Text style={styles.voucher}>
                {item.invoiceType} {item.pointOfSale}-{voucherFormat(item.id)}
            </Text>
            <Text style={styles.total}>{currencyFormatter(item.totalInvoice)}</Text>
            <Text style={styles.amount}>{currencyFormatter(item.totalPurchase)}</Text>
        </View>
    )

    const rowsMethods = pdfDocument.methods.map(item =>
        <View style={styles.row} key={item.name}>
            {item.method === 'CHEQUE' ?
                <Text style={styles.method}>
                    {item.method}{item.myCheck ? `PROPIO` : `DE TERCEROS`} #{voucherFormat(item.numberCheck)} - {item.bank}{item.startDate} - {item.dueDate}
                </Text>
                :
                <Text style={styles.method}>
                    {item.method}
                </Text>
            }
            <Text style={styles.total}>-</Text>
            <Text style={styles.amount}>{currencyFormatter(item.total)}</Text>
        </View>
    )
    return (
        <Fragment>

            {rowsPurcharses}

            <View style={styles.row}>
                <Text style={styles.blank}>-</Text>
            </View>

            {rowsMethods}

        </Fragment>
    )
};

export default PaymentTableRow;