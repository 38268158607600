import React from 'react';
import { Box, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const InfoMessage = ({ message }) => {
    return (
        <Box display="flex" alignItems="center" bgcolor="#e8f4fd" p={1} borderRadius={4}>
            <InfoIcon style={{ color: '#1976d2', marginRight: '8px' }} />
            <Typography variant="body2" style={{ color: '#1976d2' }}>
                {message}
            </Typography>
        </Box>
    );
};

export default InfoMessage;
