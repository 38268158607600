
import React from 'react';
import { useRecordContext } from 'react-admin';


const NumberFieldWithIVA = ({ record, source, locales = 'es-AR', options = { style: 'currency', currency: 'ARS' } }) => {
    const contextRecord = useRecordContext();
    const currentRecord = record || contextRecord;
    if (!currentRecord) return null;

    const value = currentRecord[source];
    const valueWithVAT = value * 1.21;

    const formattedValue = new Intl.NumberFormat(locales, options).format(valueWithVAT);

    return <span>{formattedValue}</span>;
};

export default NumberFieldWithIVA;