import * as React from 'react';
import {
    TextField,
    LinearProgress,
    useGetOne,
    BooleanField,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import EmployeeField from '../components/EmployeeField';
import VehicleField from '../components/VehicleField';
import InternalField from '../components/InternalField';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        flexDirection: 'row',
        alignItems: 'left',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

const InspectionMobileDetailsField = ({ id }) => {
    const classes = useStyles();
    const { data, loaded, error } = useGetOne('inspections', id);
    if (!loaded) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!data) return null;
    return (
        <div className={classes.root}>
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div>
                            <h2>{`IN-${data.id}`}</h2>
                        </div>
                    }
                    subheader={
                        <div>
                            {'Fecha Insp.'}
                            :&nbsp;
                            <TextField
                                record={data}
                                source="start"
                            />
                            <br />
                            {'Inspector'}
                            :&nbsp;
                            <EmployeeField id={data.employeeId} />
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <div>
                        {'Patente'}
                        :&nbsp;
                        <VehicleField id={data.vehicleId} />
                    </div>
                    <div>
                        {'Interno'}
                        :&nbsp;
                        <InternalField id={data.vehicleId} />
                    </div>
                    <div>
                        {'Chocado'}
                        :&nbsp;
                        <BooleanField
                            record={data}
                            source="crashed"
                        />
                    </div>
                    <div>
                        {'Pintura Completa'}
                        :&nbsp;
                        <BooleanField
                            record={data}
                            source="completePaint"
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default InspectionMobileDetailsField;
