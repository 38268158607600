import * as React from 'react';
import {
    List,
} from 'react-admin';

import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import PurchaseListMobile from './PurchaseListMobile';
import PurchaseListDesktop from './PurchaseListDesktop';
import { useMediaQuery } from '@material-ui/core';

const PurchaseList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <PurchaseListMobile />
            ) : (
                <PurchaseListDesktop />
            )
            }
        </List>
    );
};

export default PurchaseList;
