import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FormDataConsumer,
    useDataProvider,
    Button,
    RadioButtonGroupInput
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Card, Box, CardContent, CardActions, makeStyles } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { secondaryAccountsData } from '../components/data/secondaryAccountsData';
import primaryAccountsData from '../components/data/primaryAccountsData';
import { dateParseWithoutTime, getFirstDay, getLastDay, toChoices } from '../utils/functions';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReportExport from '../components/pdf-reports/ReportExport';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const ReportInvoice = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [error, setError] = useState();

    const handleExport = async (result) => {
        const doc = <ReportExport pdfDocument={result} />;
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `Reporte-Facturas.pdf`);
    }

    const handleSubmit = (values) => {

        if (Object.keys(values).length > 0) {

            const resourceOperationType = (values.primaryAccount === 'CLIENTES') ? 'invoices' : 'purchases-invoices';
            const resource = (values.primaryAccount === 'CLIENTES') ? 'customers' : 'suppliers';

            if (document.getElementById('customerId') === null) { delete values.customerId; }
            if (document.getElementById('supplierId') === null) { delete values.supplierId; }

            dataProvider
                .getList(resourceOperationType, {
                    pagination: { page: 1, perPage: 99999 },
                    sort: { field: 'id', order: 'DESC' },
                    filter: { status: true },
                })
                .then(response => {

                    const invoices = values.primaryAccount === 'CLIENTES' ?
                        response.data.filter(
                            item => (item.customerId === values.customerId || values.customerId === undefined) && (item.invoiceStatus === 'FACTURADA') && (item.date >= values.start && item.date <= values.end)
                        )
                        :
                        response.data.filter(
                            item => (item.supplierId === values.supplierId || values.supplierId === undefined) && (item.invoiceStatus === 'FACTURADA') && (item.date >= values.start && item.date <= values.end)
                        );

                    dataProvider.getList(resource, {
                        sort: { field: 'id', order: 'ASC' },
                        pagination: { page: 1, perPage: 900 },
                        filter: {},
                    })
                        .then(response => {

                            // SE BUSCA EL CLIENTE O PROVEEDOR POR ID Y LE PONGO EL NOMBRE Y CUIT
                            let objectAux = {};
                            const person = values.primaryAccount === 'CLIENTES' ?
                                invoices.map(invoice => {
                                    objectAux = {
                                        ...invoice,
                                        customer: `${response.data.find(customer => customer.id === invoice.customerId).name} (${response.data.find(customer => customer.id === invoice.customerId).cuit})`
                                    }
                                    return objectAux
                                })
                                :
                                invoices.map(invoice => {
                                    objectAux = {
                                        ...invoice,
                                        supplier: `${response.data.find(supplier => supplier.id === invoice.supplierId).name} (${response.data.find(supplier => supplier.id === invoice.supplierId).cuit})`
                                    }
                                    return objectAux
                                });

                            //--- AGRUPACION POR CLIENTE/PROVEEDOR
                            const groups = values.primaryAccount === 'CLIENTES' ?
                                person.reduce((groups, item) => {
                                    const group = (groups[item.customer] || []);
                                    group.push(item);
                                    groups[item.customer] = group;
                                    return groups;
                                }, {})
                                :
                                person.reduce((groups, item) => {
                                    const group = (groups[item.supplier] || []);
                                    group.push(item);
                                    groups[item.supplier] = group;
                                    return groups;
                                }, {});
                            //--- AGRUPACION POR CLIENTE/PROVEEDOR

                            const result = (values.groupBy === 'proveedor' || values.groupBy === 'cliente') ? {
                                ...values,
                                invoices: groups
                            } : (values.groupBy === 'comprobante') ? {
                                ...values,
                                invoices: invoices
                            } : {
                                ...values,
                                invoices: invoices // TODO --- AGRUPAR POR RUBRO
                            }

                            handleExport(result);

                        })
                        .catch((error) => {
                            setError(error);
                        });
                })
                .catch((error) => {
                    setError(error);
                });
        }
    };

    const choicesPrimaryAccounts = primaryAccountsData.filter(i => i.id === 'CLIENTES' || i.id === 'PROVEEDORES');

    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Reporte Facturas/Comprobantes'} />
                            <CardContent>
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="resources.reports_rrhh.filters.startDate"
                                                    source="start"
                                                    defaultValue={dateParseWithoutTime(getFirstDay())}
                                                    validate={requiredValidate}
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="resources.reports_rrhh.filters.endDate"
                                                    source="end"
                                                    defaultValue={dateParseWithoutTime(getLastDay())}
                                                    validate={requiredValidate}
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <SelectInput
                                                    label="Cuenta Principal"
                                                    source="primaryAccount"
                                                    choices={choicesPrimaryAccounts}
                                                    validate={requiredValidate}
                                                    resettable
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => (formData.primaryAccount === 'PROVEEDORES' && formData.primaryAccount !== undefined) ?
                                                        <ReferenceInput
                                                            label="Busque a su Proveedor"
                                                            source="supplierId"
                                                            reference="suppliers"
                                                            sort={{ field: 'name', order: 'ASC' }}
                                                            perPage={99999} //TODO NO TRAE MAS Q 99999
                                                            fullWidth
                                                            resettable
                                                        >
                                                            <AutocompleteInput
                                                                optionText="name"
                                                            />
                                                        </ReferenceInput>
                                                        :
                                                        (formData.primaryAccount === 'CLIENTES' && formData.primaryAccount !== undefined) ?
                                                            <ReferenceInput
                                                                label="Busque a su Cliente"
                                                                source="customerId"
                                                                reference="customers"
                                                                sort={{ field: 'name', order: 'ASC' }}
                                                                fullWidth
                                                                resettable
                                                            >
                                                                <AutocompleteInput
                                                                    optionText="name"
                                                                />
                                                            </ReferenceInput>
                                                            :
                                                            (formData.primaryAccount !== undefined) &&
                                                            <SelectInput
                                                                label="Cuenta Secundaria"
                                                                source="secondaryAccount"
                                                                choices={
                                                                    formData.primaryAccount
                                                                        ? toChoices(secondaryAccountsData[formData.primaryAccount])
                                                                        : []
                                                                }
                                                                validate={requiredValidate}
                                                                fullWidth
                                                                resettable
                                                                {...rest}
                                                            />
                                                    }
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>

                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData }) => (formData.primaryAccount === 'PROVEEDORES' && formData.primaryAccount !== undefined) ?
                                                        <RadioButtonGroupInput
                                                            label="Agrupar por"
                                                            source="groupBy"
                                                            choices={[
                                                                { id: 'proveedor', name: 'Proveedor' },
                                                                { id: 'comprobante', name: 'Comprobante' },
                                                                { id: 'rubro', name: 'Rubro' },
                                                            ]}
                                                            row={false}
                                                            defaultValue={'proveedor'}
                                                        />
                                                        :
                                                        (formData.primaryAccount === 'CLIENTES') &&
                                                        <RadioButtonGroupInput
                                                            label="Agrupar por"
                                                            source="groupBy"
                                                            choices={[
                                                                { id: 'cliente', name: 'Cliente' },
                                                                { id: 'comprobante', name: 'Comprobante' },
                                                            ]}
                                                            row={false}
                                                            defaultValue={'cliente'}
                                                        />
                                                    }
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>

                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        label="Descargar"
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        size="medium"
                                                    >
                                                        <PictureAsPdfIcon fontSize="small" />
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>

    );
};

export default ReportInvoice;
