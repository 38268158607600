import React from 'react';
// import { useState } from 'react';
import {
    TextInput,
    CheckboxGroupInput,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { toUpperCase } from '../utils/functions';
import SectionTitle from '../components/SectionTitle';
import inspectionData from '../components/data/inspectionData';
// import CustomCheckboxGroupInput from './CustomCheckboxGroupInput';

const InspectionFormDetail = () => {
    // const [selectedOptions, setSelectedOptions] = useState([]);

    // const handleOptionChange = newSelectedOptions => {
    //     setSelectedOptions(newSelectedOptions);
    // };

    const inspectionDataSort = inspectionData.map(item => item).sort((a, b) => (a.name > b.name) ? 1 : -1);

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Detalle de Inspección" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        {/* <CustomCheckboxGroupInput
                            label="Select Options"
                            choices={inspectionDataSort}
                            value={selectedOptions}
                            onChange={handleOptionChange}
                        /> */}
                           
                         <CheckboxGroupInput
                            label={''}
                            source="inspectionDetails"
                            choices={inspectionDataSort}
                        />
                       
                    </Box>

                    <SectionTitle label="Otros detalles" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Goma de Piso"
                                source="rubberFloor"
                                parse={toUpperCase}
                                fullWidth
                                multiline
                                resettable
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Mamparas"
                                source="bulkhead"
                                parse={toUpperCase}
                                fullWidth
                                multiline
                                resettable
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Pantallas"
                                source="screens"
                                parse={toUpperCase}
                                fullWidth
                                multiline
                                resettable
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Modelo de Tapizado"
                                source="upholsteryModel"
                                parse={toUpperCase}
                                fullWidth
                                multiline
                                resettable
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Color de Tapizado"
                                source="upholsteryColor"
                                parse={toUpperCase}
                                fullWidth
                                multiline
                                resettable
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default InspectionFormDetail;
