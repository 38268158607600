import React, { useState } from 'react';
import { Box, Typography, IconButton, Collapse } from '@material-ui/core';
import { Info as InfoIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const InfoMessageWithBreaks = () => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    return (
        <Box display="flex" flexDirection="column" bgcolor="#e8f4fd" p={2} borderRadius={4} mb={2}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Box display="flex" alignItems="center">
                    <InfoIcon style={{ color: '#1976d2', marginRight: '8px' }} />
                    <Typography variant="body2" style={{ color: '#1976d2' }}>
                        Información de cantidades
                    </Typography>
                </Box>
                <IconButton onClick={handleToggle} size="small">
                    <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s' }} />
                </IconButton>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
                    <b>'Total Cantidad (Usado)'</b>: Cantidad total usado en la OT (Es decir es la cantidad usado, que restaron stock por NO ser del tipo consumible + la cantidad de los consumibles que se asigno a las OT, que no resto stock).
                </Typography>
                <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
                    <b>'Total Monto (Usado)'</b>: Monto total usado en la OT (Es decir es el monto total usado, que restaron stock por NO ser del tipo consumible + el monto total de los consumibles que se asigno a las OT, que no resto stock).
                </Typography>
                <br />
                <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
                    <b>'Total Cantidad'</b>: Cantidad total usado en la OT que restaron Stock (Es decir es la cantidad usado + la cantidad de los consumibles que el usuario confirmó o finalizó).
                </Typography>
                <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
                    <b>'Total Monto'</b>: Monto total usado en la OT que restaron Stock (Es decir es el monto total usado + el monto total de los consumibles que el usuario confirmó o finalizó).
                </Typography>
                <br />
                <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
                    <b>'Cantidad (Usado)'</b>: Cantidad de cada material y/o repuesto usado en la OT.
                </Typography>
                <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
                    <b>'Cantidad (Confirmado)'</b>: Confirmado o finalizado en la OT (Es decir en el flujo de consumibles es la cantidad que el usuario confirmó para restar Stock, y tener en cuenta que solo son para los artículos que tienen activado el campo 'Consumible' en el Pañol de artículos).
                </Typography>
                <br />
                <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
                    <b>*Nota</b>: Tener en cuenta que para los artículos que son del tipo 'consumible', se le calcula el Subtotal en base a la cantidad confirmada por el usuario.
                </Typography>
            </Collapse>
        </Box>
    );
};

export default InfoMessageWithBreaks;
