import React from 'react';
import { Link } from 'react-admin';

const EmployeeLinkField = props => (
    <Link to={`/employees/${props.record.id}`}>
        {props.record.documentNumber}
    </Link>
);

EmployeeLinkField.defaultProps = {
    source: 'documentNumber',
    label: 'resources.employees.fields.documentNumber',
};

export default EmployeeLinkField;
