import * as React from "react";
import { FileInput, FileField } from 'react-admin';

const DocumentFileInput = () => (

    <FileInput
        label="Documentación del empleado:"
        source="files"
        accept="image/png, image/jpeg, application/pdf"
        maxSize={1000000}
        placeholder={
            <div>
                <p>Arrastre el documento para adjuntar a la ficha del empleado.</p>
                <em>
                    (Solo se pueden subir 1MB como máximo, solamente admite archivos ".jpeg", ".png" y ".pdf")
                </em>
            </div>
        }
    >
        <FileField source="files" title="Documento" />
    </FileInput>

);

export default DocumentFileInput;