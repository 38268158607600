
import * as React from 'react';
import { Typography } from '@material-ui/core';
 
const SectionTitle = ({ label }) => {
    return (
        <Typography variant="subtitle1" gutterBottom>
            {label}
        </Typography>
    );
};

export default SectionTitle;