import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Tooltip } from '@material-ui/core';

const OrderInvoicedButton = ({ record, isXsmall }) => {
    if (record && record.workOrderStatus === 'FINALIZADA_ENTREGAR' && record.deliveryDate === null) {

        return (
            <Tooltip title="A Facturar" arrow>
            {isXsmall ? (
                <Button
                    size="large"
                    label="A FACTURAR"
                    component={Link}
                    variant="outlined"
                    to={{ pathname: `/orders/${record.id}/budget` }}
                    style={{ 
                        color: '#ff9800', 
                        borderColor: '#ff9800'
                    }}
                >
                    <ReceiptIcon style={{ color: '#ff9800'}} />
                </Button>
            ) : (
                <Button
                    size="small"
                    label="A FACTURAR"
                    component={Link}
                    variant="outlined"
                    to={{ pathname: `/orders/${record.id}/budget` }}
                    style={{ 
                        color: '#ff9800', 
                        borderColor: '#ff9800', 
                        minWidth: 20, 
                        padding: '2px 3px', 
                        fontSize: '10px',
                        lineHeight: '12px'
                    }}
                >
                    <ReceiptIcon style={{ color: '#ff9800', fontSize: '14px' }} />
                </Button>
            )}
            </Tooltip>
        );
    } else {
        return null;
    }
};

export default OrderInvoicedButton;
