import PaymentList from './PaymentList';
import PaymentCreate from './PaymentCreate';
import PaymentEdit from './PaymentEdit';
import PaymentIcon from '@material-ui/icons/Payment';

export default {
  list: PaymentList,
  create: PaymentCreate,
  edit: PaymentEdit,
  icon: PaymentIcon
};
