import React from 'react';
import { Link } from 'react-admin';
import { getOperationTypeFormat, getUrlResourceByOperationType } from '../utils/functions';

const AccountLinkField = (props) => {
    const result = getOperationTypeFormat(props.record);
    const urlTo = getUrlResourceByOperationType(props.record);

    if (props.record.id === null) return (<span style={{ color: 'purple' }}>{props.record.name}</span>);

    return (
        <Link
            to={urlTo}
            onClick={
                () => {
                    localStorage.removeItem('invoiceCart');
                    localStorage.removeItem('purchasesInvoiceCart');
                    localStorage.removeItem('purchaseCart');
                    localStorage.removeItem('methods');
                    localStorage.removeItem('invoicesReceipts');
                    localStorage.removeItem('purchasesPayments');
                }
            }
        >
            {result}
        </Link>
    )
};

AccountLinkField.defaultProps = {
    source: 'id',
    label: 'Comprobante',
};

export default AccountLinkField;
