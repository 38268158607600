import React from 'react';
import {
    LinearProgress,
    useGetOne,
    TextField,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CustomerField from '../components/CustomerField';
import LineField from '../components/LineField';
import BrandField from '../components/BrandField';
import BusTemplateInput from '../components/BusTemplateInput';
import SectionTitle from '../components/SectionTitle';

const VehicleDetailsField = ({ id }) => {
    const { data, loaded, error } = useGetOne('vehicles', id);
    if (!loaded) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }

    return (
        <div>
            <BusTemplateInput id={data.busTemplate} />
            <br /><br />
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'Cliente'}
                            </TableCell>
                            <TableCell>
                                {'Linea'}
                            </TableCell>
                            <TableCell>
                                {'Interno'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <CustomerField id={data.customerId} />
                            </TableCell>
                            <TableCell>
                                <LineField id={data.lineId} />
                            </TableCell>
                            <TableCell>
                                <TextField record={data} source="internal" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
            <br />
            <SectionTitle label="Detalles" />
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'Marca'}
                            </TableCell>
                            <TableCell>
                                {'Carrocería'}
                            </TableCell>
                            <TableCell>
                                {'Año'}
                            </TableCell>
                            <TableCell>
                                {'Modelo'}
                            </TableCell>
                            <TableCell>
                                {'Color'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <BrandField id={data.brandId} />
                            </TableCell>
                            <TableCell>
                                <TextField record={data} source="chassis" />
                            </TableCell>
                            <TableCell>
                                <TextField record={data} source="year" />
                            </TableCell>
                            <TableCell>
                                <TextField record={data} source="model" />
                            </TableCell>
                            <TableCell>
                                <TextField record={data} source="color" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        </div>
    );
}
export default VehicleDetailsField;