import React from 'react';
import {
    Datagrid,
    TextField,
    List,
    NumberField,
    Filter,
    DateInput,
    SelectInput,
    SearchInput,
    TopToolbar,
   // LinearProgress,
    downloadCSV,
    ExportButton
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import RptEmployeesHoursLines from './RptEmployeesHoursLines';
import { dateParseWithoutTime, getFirstDay, getLastDay } from '../utils/functions';
//import EmployeesExport from './EmployeesExport';
import jsonExport from 'jsonexport/dist';

const styles = {
    totalHours: { color: 'purple' },
    totalLeftHours: { color: 'red' }
};

const ListFilters = (props) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput
            label="resources.reports_rrhh.filters.startDate"
            source="start"
            alwaysOn
        />
        <DateInput
            label="resources.reports_rrhh.filters.endDate"
            source="end"
            alwaysOn
        />
        <SelectInput
            label="Estado"
            source="status"
            choices={[
                { id: 'true', name: 'ACTIVO' },
                { id: 'false', name: 'INACTIVO' },
            ]}
            alwaysOn
        />
    </Filter>
);

// const ActionsExport = (props) => {
//     return (
//         <TopToolbar>
//             {
//                 (Object.keys(props.data).length !== 0) ?
//                     <EmployeesExport dataExport={props.data} />
//                     :
//                     <LinearProgress />
//             }
//         </TopToolbar>
//     )
// };

const ActionsExport = (props) => {
    return (
        <TopToolbar>
            <ExportButton 
                label="Exportar a Excel"
                size="small"
                variant="contained" 
            />
        </TopToolbar>
    )
};


const exporter = employees => {
    const data = employees.map(record => ({
        Documento: record.documentNumber,
        Empleado: record.fullName,
        Funcion: record.role,
        Total_Horas: record.totalHours.toLocaleString('ar-AR', { maximumFractionDigits: 2 }),
        Total_Ausencias: record.totalAbsence,
        Total_Horas_Faltantes: record.totalLeftHours.toLocaleString('ar-AR', { maximumFractionDigits: 2 }),
        Total_Horas_Extras: record.totalExtraHours.toLocaleString('ar-AR', { maximumFractionDigits: 2 }),
        Fichaje: record.tracking.map(i => ({
            Entrada: i.start,
            Salida: i.end,
            Turno: i.shift,
            Cumplidas: i.duration.toLocaleString('ar-AR', { maximumFractionDigits: 2 }),
            Faltantes: i.leftHours.toLocaleString('ar-AR', { maximumFractionDigits: 2 }),
            Extras: i.extraHours.toLocaleString('ar-AR', { maximumFractionDigits: 2 })
        }))
    }));

    //EXPORTACION 
    jsonExport(data, {
        headers: ['Documento', 'Empleado', 'Funcion', 'Total_Ausencias', 'Total_Horas', 'Total_Horas_Faltantes', 'Total_Horas_Extras']
    }, (err, csv) => {
        downloadCSV(csv, `empleados-${dateParseWithoutTime(Date.now())}`);
    });
};

const RptEmployees = ({ classes, ...props }) => {
    return (
        <List
            {...props}
            title={"Reporte de Empleados"}
            actions={<ActionsExport />}
            exporter={exporter}
            filters={<ListFilters />}
            sort={{ field: 'fullName', order: 'ASC' }}
            perPage={25}
            bulkActionButtons={false}
            filterDefaultValues={{
                start: dateParseWithoutTime(getFirstDay()),
                end: dateParseWithoutTime(getLastDay()),
                status: true
            }}
        >
            <Datagrid expand={<RptEmployeesHoursLines />}>
                <TextField
                    label="resources.reports_rrhh.fields.documentNumber"
                    source="documentNumber"
                />
                <TextField
                    label="resources.reports_rrhh.fields.name"
                    source="fullName"
                />
                <NumberField
                    label="resources.reports_rrhh.fields.totalAbsence"
                    source="totalAbsence"
                    locales="ar-AR"
                    options={{ maximumFractionDigits: 0 }}
                />
                <NumberField
                    label="resources.reports_rrhh.fields.totalHours"
                    source="totalHours"
                    locales="ar-AR"
                    options={{ maximumFractionDigits: 2 }}
                    className={classes.totalHours}
                />
                <NumberField
                    label="resources.reports_rrhh.fields.leftHours"
                    source="totalLeftHours"
                    locales="ar-AR"
                    options={{ maximumFractionDigits: 2 }}
                    className={classes.totalLeftHours}
                />
                <NumberField
                    label="resources.reports_rrhh.fields.extraHours"
                    source="totalExtraHours"
                    locales="ar-AR"
                    options={{ maximumFractionDigits: 2 }}
                    className={classes.totalHours}
                />
            </Datagrid>
        </List>
    )
};

export default withStyles(styles)(RptEmployees);
