import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    useGetList,
} from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: '15em',
            marginRight: '1em',
            overflow: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const AsideItems = () => {
    const { data, ids } = useGetList(
        'categories',
        { page: 1, perPage: 30 },
        { field: 'name', order: 'ASC' },
        { filter: '' }
    );
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <FilterLiveSearch
                    label="Buscar (nombre o código)"
                    parse={(value) => value.toUpperCase()}
                    source="q"
                 />

                <FilterList
                    label="Stock"
                    icon={<BarChartIcon />}
                >
                    <FilterListItem
                        label="Sin stock"
                        value={{
                            quantity: 0,
                        }}
                    />
                </FilterList>

                <FilterList
                    label="Categorias"
                    icon={<LocalOfferIcon />}
                >
                    {ids.map((id) => (
                        <FilterListItem
                            label={inflection.humanize(data[id].name)}
                            key={data[id].id}
                            value={{ category: data[id].id }}
                        />
                    ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default AsideItems;
