import React from 'react';
import { Link } from 'react-admin';

const OrderLinkField = props => (
    <Link to={`/orders/${props.record.id}`} onClick={() => localStorage.removeItem('cart')}>  
        OT-{props.record.id}
    </Link>
);

OrderLinkField.defaultProps = {
    source: 'id',
    label: 'Orden',
};

export default OrderLinkField;