import * as React from 'react';
import {
    Toolbar,
    SaveButton,
    usePermissions
} from 'react-admin';
import { useLocation } from 'react-router';
import { getRolesOnlyStorekeeperExceptAdmin, getRolesOnlySupervisorExceptAdmin } from '../appRolesComponents';

const CustomToolbar = (props) => {

    const location = useLocation();
    const { permissions } = usePermissions();

    const isStorekeeperAndSupervisor = getRolesOnlyStorekeeperExceptAdmin(permissions) || getRolesOnlySupervisorExceptAdmin(permissions);

    let pathname = location.pathname.split('/');
    let lastPathname = pathname[pathname.length - 1];
    let secondLastPathname = pathname[pathname.length - 2];

    let isDisabledButton = (lastPathname === 'trackingHistory' || lastPathname === 'absences' || lastPathname === 'extra-hour' || secondLastPathname === 'tracking') && isStorekeeperAndSupervisor;

    return (
        <Toolbar {...props} >
            <SaveButton disabled={isDisabledButton} />
        </Toolbar>
    )
};

export default CustomToolbar;