const choices = [
    {
        method: 'CHEQUE',
    },
    {
        method: 'EFECTIVO',
    },
    {
        method: 'TRANSFERENCIA_BANCARIA',
    },
    {
        method: 'RETENCIONES',
    },

];

export default choices
