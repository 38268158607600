import React from 'react';
import { useRecordContext } from 'react-admin';
import { format } from 'date-fns';

const DateFieldFormatted = ({ record, source, showTime = true }) => {
    const contextRecord = useRecordContext();
    const currentRecord = record || contextRecord;
    if (!currentRecord) return null;

    const date = currentRecord[source];
    const dateFormat = showTime ? 'dd-MM-yyyy HH:mm' : 'dd-MM-yyyy';
    const formattedDate = date ? format(new Date(date), dateFormat) : '';

    return <span>{formattedDate}</span>;
};

export default DateFieldFormatted;