import GroupIcon from '@material-ui/icons/Group';
import EmployeeList from './EmployeeList';
import EmployeeCreate from './EmployeeCreate';
import EmployeeEdit from './EmployeeEdit';

export default {
  list: EmployeeList,
  create: EmployeeCreate,
  edit: EmployeeEdit,
  icon: GroupIcon
};
