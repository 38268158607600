import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PaymentTableHeader from './PaymentTableHeader';
import PaymentTableRow from './PaymentTableRow';
import PaymentTableBlankSpace from './PaymentTableBlankSpace';
import TableFooter from './TableFooter';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    borderWidth: 1,
  },
});

const tableRowsCount = 25;

const PaymentItemsTable = ({ pdfDocument }) => {
  return (
    <View style={styles.tableContainer}>
      <PaymentTableHeader />
      <PaymentTableRow pdfDocument={pdfDocument} />
      <PaymentTableBlankSpace rowsCount={tableRowsCount - pdfDocument.purchases.length - pdfDocument.methods.length} />
      <TableFooter pdfDocument={pdfDocument} />
    </View>
  )
};

export default PaymentItemsTable;