import * as React from 'react';
import {
    Pagination,
    Title,
    useListContext,
    TopToolbar,
    SortButton,
    CreateButton
} from 'react-admin';
import { Box } from '@material-ui/core';
import GridList from './GridList';
import AsideItems from './AsideItems';
import ItemFilter from './ItemFilter';

const ListActions = ({ isSmall }) => (
    <TopToolbar>
        {isSmall && <ItemFilter context="button" />}
        <SortButton 
            fields={['name', 'price', 'quantity']} 
        />
        <CreateButton 
            variant="contained" 
            basePath="/items" 
        />
    </TopToolbar>
);

const ItemListView = ({ isSmall }) => {
    const { defaultTitle } = useListContext();
    return (
        <div>
            <Title defaultTitle={defaultTitle} />
            <ListActions isSmall={isSmall} />
            {isSmall && (
                <Box m={1}>
                    <ItemFilter context="form" />
                </Box>
            )}
            <Box display="flex">
                <AsideItems />
                <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                    <GridList />
                    <Pagination rowsPerPageOptions={[10, 20, 40]} />
                </Box>
            </Box>
        </div>
    );
};

export default ItemListView;
