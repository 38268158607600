import * as React from 'react';
import {
    linkToRecord,
    useListContext
} from 'react-admin';
import { Link } from 'react-router-dom';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { currencyFormatter } from '../utils/functions';
import imageDefault from './imagenItem/image-default.svg';

const useStyles = makeStyles(theme => ({
    gridList: {
        margin: 0,
    },
    tileNameBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 90%, rgba(0,0,0,0) 100%)',
    },
    tileBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 90%,rgba(0,0,0,0) 100%)',
    },
    placeholder: {
        backgroundColor: theme.palette.grey[500],
        height: '100%',
    },
}));

const getColsForWidth = (width) => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    return 6;
};

const times = (nbChildren, fn) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = ({
    width,
    nbItems = 20,
}) => {
    const classes = useStyles();
    return (
        <MuiGridList
            cellHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {' '}
            {times(nbItems, key => (
                <GridListTile key={key}>
                    <div className={classes.placeholder} />
                </GridListTile>
            ))}
        </MuiGridList>
    );
};

const LoadedGridList = ({ width }) => {
    const { ids, data, basePath } = useListContext();
    const classes = useStyles();

    const urlImageBuilder = (id) => {
        return `https://api.marasa.com.ar/workshop-api/api/items/${id}/download`;
    }

    if (!ids || !data) return null;
    return (
        <MuiGridList
            cellHeight={180}
            cols={getColsForWidth(width)}
            className={classes.gridList}
        >
            {ids.map((id) => (
                data[id] && ( // Verifica si data[id] es definido
                    <GridListTile
                        component={Link}
                        key={id}
                        to={linkToRecord(basePath, data[id].id)}
                    >

                        <img
                            src={urlImageBuilder(data[id].id)}
                            alt={data[id].name}
                            title={data[id].name}
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = imageDefault
                            }}
                        />
                        <GridListTileBar
                            className={classes.tileNameBar}
                            title={data[id].name}
                            titlePosition="top"
                            subtitle={
                                <span>
                                    {`(${data[id].code})`}
                                </span>
                            }
                        />
                        <GridListTileBar
                            className={classes.tileBar}
                            title={`Cantidad: ${data[id].quantity}`}
                            subtitle={
                                <span>
                                    {`Precio Unit.: ${currencyFormatter(data[id].price)}`}
                                </span>
                            }
                        />
                    </GridListTile>
                )))}
        </MuiGridList>
    );
};

const GridList = ({ width }) => {
    const { loaded } = useListContext();
    return loaded ? (
        <LoadedGridList width={width} />
    ) : (
        <LoadingGridList width={width} />
    );
};

export default withWidth()(GridList);
