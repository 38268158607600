import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { currencyFormatter, toUpperCase } from '../../utils/functions';
import writtenNumber from 'written-number';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        //borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        fontStyle: 'bold',
    },
    description: {
        width: '65%',
        textAlign: 'right',
        fontSize: 15,
        paddingRight: 8,
    },
    total: {
        width: '45%',
        textAlign: 'right',
        fontSize: 15,
        paddingRight: 8,
    },
    letter: {
        textAlign: 'right',
        paddingRight: 8,
        fontSize: 8,
    },
});

const TableFooter = ({ pdfDocument }) => {
    writtenNumber.defaults.lang = 'es';

    return (
        <div>
            <View style={styles.row}>
                <Text style={styles.description}>TOTAL</Text>
                <Text style={styles.total}>{currencyFormatter(pdfDocument.total)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.letter}>{toUpperCase(writtenNumber(pdfDocument.total))}</Text>
            </View>
        </div>
    )
};

export default TableFooter;