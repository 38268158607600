import React, { useState } from 'react';
import keyBy from 'lodash/keyBy';
import {
    useQuery,
    ListContextProvider,
    Datagrid,
    Pagination,
    Loading,
    NumberField
} from 'react-admin';
import AccountLinkField from './AccountLinkField';
import { makeStyles } from '@material-ui/core/styles';
import AccountExport from './AccountExport';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles({
    root: {
        marginBottom: '1em',
        float: "right"

    }
});

const AccountDatagrid = ({ value }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'id', order: 'DESC' })
    const classes = useStyles();

    const { data, total, loading, error } = useQuery({
        type: 'getList',
        resource: 'accounts',
        payload: {
            pagination: { page, perPage },
            sort,
            filter: {
                start: value.start,
                end: value.end,
                primaryAccount: value.primaryAccount,
                secondaryAccount: value.secondaryAccount,
                customerId: value.customerId,
                supplierId: value.supplierId
            }
        }
    });

    if (loading) { return <Loading /> }
    if (error) { return <p>ERROR: {error}</p> }

    return (
        <ListContextProvider
            value={{
                basePath: '/accounts',
                data: keyBy(data, 'id'),
                ids: data.map(({ id }) => id),
                currentSort: { field: 'id', order: 'ASC' },
                selectedIds: [],
            }}
        >
            <div className={classes.root}>
                <AccountExport dataExport={data} />
            </div>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({ id }) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({ field, order })}
            >
                <DateFieldFormatted
                    label="Fecha"
                    source="date"
                    showTime={true}
                    sortable={false}
                />
                <AccountLinkField
                    sortable={false}
                />

                <NumberField
                    label="Debe"
                    source="debit"
                    locales="es-AR"
                    options={{ style: 'currency', currency: 'ARS' }}
                    sortable={false}
                />

                <NumberField
                    label="Haber"
                    source="assets"
                    locales="es-AR"
                    options={{ style: 'currency', currency: 'ARS' }}
                    sortable={false}
                />
                <NumberField
                    label="Saldo"
                    source="balance"
                    locales="es-AR"
                    options={{ style: 'currency', currency: 'ARS' }}
                    sortable={false}
                />
            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />
        </ListContextProvider>
    );
}

export default AccountDatagrid;