import * as React from 'react';
import {
    Edit,
    TabbedForm,
    TextInput,
    FormTab,
    useNotify,
    useRefresh,
    useRedirect
} from 'react-admin';
import {
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase
} from '../utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import CustomToolbar from '../components/CustomToolbar';
import ImageCategoryInput from './imageCategory/ImageCategoryInput';
import ImageCategoryView from './imageCategory/ImageCategoryView';
import categoryStyle from './categoryStyle';

const useStyles = makeStyles(categoryStyle);

const CategoryTitle = ({ record }) => {
    return <span>{record ? `${record.name}` : ''}</span>;
};

const CategoryEdit = (props) => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/categories`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    return (

        <Edit
            {...props}
            undoable={false}
            title={<CategoryTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <FormTab
                    label="General"
                >
                    <TextInput
                        autoFocus
                        label="Nombre"
                        source="name"
                        validate={requiredValidate}
                        parse={toUpperCase}
                        formClassName={classes.name}
                    />
                    <TextInput
                        label="Descripción"
                        source="description"
                        parse={toUpperCase}
                        formClassName={classes.description}
                        rows={5}
                        multiline
                        resettable
                    />
                </FormTab>
                <FormTab
                    label="Foto Categoria"
                    path="image"
                    contentClassName={classes.image}
                >
                    <ImageCategoryInput />
                    <ImageCategoryView />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default CategoryEdit;
