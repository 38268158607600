import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    SimpleForm,
    Create,
    useDataProvider
} from 'react-admin';
import { parse } from 'query-string';
import ExtraHourForm from './ExtraHourForm';

const ExtraHourCreate = (props) => {
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();

    const { id: employee_id_string } = parse(props.location.search);
    const customResource = employee_id_string ? `/rrhh-api/api/extra-hour/${employee_id_string}` : '';
    const id = employee_id_string ? employee_id_string : '';
    const redirect = `/employees/${id}/extra-hour`;

    const fetchEmployee = useCallback(async () => {
        dataProvider.getOne('employees', { id: id })
            .then(response => {
                let fullName = response.data.fullName;
                setState(state => ({ ...state, fullName }));
            });
    }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchEmployee();
    }, [fetchEmployee]);

    const {
        fullName,
    } = state;

    return (
        <Create
            {...props}
            title={`${fullName}`}
            resource={customResource}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                redirect={redirect}
            >
                <ExtraHourForm />
            </SimpleForm>
        </Create>
    );
};

export default ExtraHourCreate;
