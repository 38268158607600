import React from 'react';
import { useState } from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    useRedirect,
    useNotify,
    usePermissions,
    TabbedFormTabs,
} from 'react-admin';
import DocumentFileInput from './document/DocumentFileInput';
import DocumentFileView from './document/DocumentFileView';
import OrderEditToolbar from './OrderEditToolbar';
import OrderFormItemsSearch from './OrderFormItemsSearch';
import ListItemsDisplay from './ListItemsDisplay';
import OrderForm from './OrderForm';
import OrderFormBuget from './OrderFormBuget';
import BasketSelectInput from '../baskets/BasketSelectInput';
import EditActionsOrder from './EditActionsOrder';
import { useMediaQuery } from '@material-ui/core';
import ListItemsMobileDisplay from './ListItemsMobileDisplay';
import {
    getRolesOnlyInspectorExceptAdmin,
    getRolesOnlyStorekeeperExceptAdmin,
    getRolesOnlySupervisorExceptAdmin,
    getRolesWorkshopOnly
} from '../appRolesComponents';

const OrderEdit = (props) => {
    const { permissions } = usePermissions();
    const notify = useNotify();
    const redirect = useRedirect();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [loading, setLoading] = useState(true);

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);

        if (!isWorkshopOnly) {
            redirect(`/baskets`);
        } else {
            redirect(`/orders`);
        }
        //  refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const OrderTitle = ({ record }) => {
        return <span>{record ? `OT-${record.id}` : ''}</span>;
    };

    const isStorekeeper = getRolesOnlyStorekeeperExceptAdmin(permissions)
    const isSupervisor = getRolesOnlySupervisorExceptAdmin(permissions);

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);


    if (!isWorkshopOnly && !isStorekeeper && !isSupervisor && !isInspector) return null;

    const handleLoading = (isLoading) => {
        setLoading(isLoading);
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<OrderTitle />}
            actions={isWorkshopOnly ? <EditActionsOrder /> : null}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                toolbar={<OrderEditToolbar loading={loading} />}
                redirect={redirect}
                tabs={<TabbedFormTabs scrollButtons="auto" />}
            >
                {(isWorkshopOnly || isInspector) && (
                    <FormTab label="General">
                        <OrderForm {...props} />
                    </FormTab>
                )}


                <FormTab
                    label="Pañol"
                    path="items"
                >
                    <FormDataConsumer>
                        {({ formData }) =>
                            (formData.workOrderStatus === 'PENDIENTE' || formData.workOrderStatus === 'EN_PROGRESO') ?
                                <OrderFormItemsSearch
                                    {...props}
                                    formData={formData}
                                    handleLoading={handleLoading}
                                />
                                : ((isXsmall) ?
                                    (<ListItemsMobileDisplay {...props} />)
                                    :
                                    (<ListItemsDisplay {...props} />)
                                )
                        }
                    </FormDataConsumer>
                    {(isWorkshopOnly || isStorekeeper || isSupervisor) && (
                        <BasketSelectInput />
                    )}
                </FormTab>


                {isWorkshopOnly && (
                    <FormTab
                        label="Detalle total"
                        path="budget"
                    >
                        <OrderFormBuget />
                    </FormTab>
                )}

                {isWorkshopOnly && (
                    <FormTab
                        label="Documentación"
                        path="documents"
                    >
                        <DocumentFileInput />
                        <DocumentFileView />
                    </FormTab>
                )}

            </TabbedForm>
        </Edit>
    );
};

export default OrderEdit;
