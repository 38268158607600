import * as React from 'react';
import {
    Create,
    SimpleForm,
    useNotify,
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import { patentValidation } from '../validators/validators';
import VehicleForm from './VehicleForm';

const VehicleCreate = (props) => {
    const redirect = `/vehicles`;
    const notify = useNotify();

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    return (
        <Create
            {...props}
            onFailure={onFailure}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
                validate={patentValidation}
            >
                   <VehicleForm />
            </SimpleForm>
        </Create>
    );
};


export default VehicleCreate;
