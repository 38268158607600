import React from 'react';
import { Link } from 'react-admin';

const ConsumableLinkField = ({ record }) => (
    <Link to={`/consumables/${record.id}`}>
        {record.name} ({record.code})
    </Link>
);

ConsumableLinkField.defaultProps = {
    source: 'id',
    label: 'Consumible',
};

export default ConsumableLinkField;
