import React, { useState, useEffect } from 'react';
import {
  useDataProvider,
  LinearProgress,
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import { Box, CardContent, useMediaQuery } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CartItems from './CartItems';
import CartItemsMobileGrid from './CartItemsMobileGrid';
import PurchaseFootDetail from './PurchaseFootDetail';
import QrCodeScannerIcon from '@material-ui/icons/CropFree';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const AutocompleteItemsSearch = ({ formData, handleLoading, ...props }) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const value = '';
  const [products, setProducts] = useState([]);
  const [purchaseCart, setPurchaseCart] = useState([]);

  const [barcode, setBarcode] = useState(""); // Estado para el input del escáner

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    handleLoading(true); // Indicate loading started
    dataProvider
      .getList('items', {
        pagination: { page: 1, perPage: 999999 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
      })
      .then(({ data }) => {
        setProducts(data);
        if (props.id !== undefined) {
          let itemsOrder = formData.items;
          dataProvider.getMany('items', {
            ids: itemsOrder.map((item) => item.id),
          })
            .then(response => {
              let items = response.data;
              //CRUCE DE 2 OBJETOS POR ID Y PUSE PRIMERO EL "t2" PARA TOMAR EL PRICE DE OC
              const result = itemsOrder.map(t1 => ({ ...items.find(t2 => t2.id === t1.id), ...t1 }));
              setPurchaseCart(result);
              localStorage.setItem("purchaseCart", JSON.stringify(result));//TODO--VERIFICAR
              setLoading(false);
              handleLoading(false); // Actualiza el estado de loading
            });

        } else {
          if (JSON.parse(localStorage.getItem("purchaseCart"))) {
            setPurchaseCart(JSON.parse(localStorage.getItem("purchaseCart")));
          }
          setLoading(false);
          handleLoading(false); // Actualiza el estado de loading
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        handleLoading(false); // Actualiza el estado de loading
      });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F2") {
        e.preventDefault();
        document.getElementById('input-barcode').focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const saveCartToLocalStorage = (cart) => {
    localStorage.setItem(`purchaseCart`, JSON.stringify(cart));
  };

  const addToCart = (item) => {
    const productList = [...purchaseCart];
    let found = false;
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            found = true;
            break;
          }
        }
      }
      if (!found) {
        productList.push(item);
        setSnackbarMessage(`Artículo "${item.name}" agregado al carrito`);
        setSnackbarOpen(true);
      }

      setPurchaseCart(productList);
      saveCartToLocalStorage(productList);
    }
  }

  const deleteToCard = (item) => {
    const productList = [...purchaseCart];
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            productList.splice(i, 1);
            break;
          }
        }
      }
      setPurchaseCart(productList);
      saveCartToLocalStorage(productList);
    }
  }

  const changeQuantity = (item, e) => {
    const productList = [...purchaseCart];
    const index = productList.indexOf(item);
    productList[index].itemQuantity = (e.target.value === '' || e.target.value === 0) ? 1 : parseFloat(e.target.value);
    setPurchaseCart(productList);
    saveCartToLocalStorage(productList);
  }

  const changePrice = (item, e) => {
    const productList = [...purchaseCart];
    const index = productList.indexOf(item);
    productList[index].price = e.target.value === '' ? 0 : parseFloat(e.target.value);
    setPurchaseCart(productList);
    saveCartToLocalStorage(productList);
  }

  const handleBarcodeScan = (event) => {
    if (event.key === "Enter" && barcode.trim() !== "") {
      const item = products.find(p => p.code === barcode.trim());
      if (item) {
        addToCart({ ...item, itemQuantity: item.itemQuantity || 1 }, true);
        setBarcode("");
      } else {
        setSnackbarMessage(`Código de barras "${barcode}" no encontrado`);
        setSnackbarOpen(true);
        setBarcode("");
      }
    }
  };

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!products) return null;

  const choices = products.map((item) => ({
    id: item.id,
    code: item.code,
    name: item.name,
    itemQuantity: 1,
    minQuantity: item.minQuantity,
    maxQuantity: item.maxQuantity,
    quantity: item.quantity,
    warehouseQuantity: item.warehouseQuantity,
    priceLast: parseFloat(item.price),
    price: parseFloat(item.price)
  }));

  return (
    <CardContent>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes('sin stock') || snackbarMessage.includes('no encontrado') ? 'error' : 'success'}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <CardContent>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={8000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert
            onClose={handleSnackbarClose}
            severity={snackbarMessage.includes('sin stock') || snackbarMessage.includes('no encontrado') ? 'error' : 'success'}
            elevation={6}
            variant="filled"
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextField
                  id="input-barcode"
                  label="Escanear código de barras"
                  variant="filled"
                  size="small"
                  fullWidth
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value.toUpperCase())}
                  onKeyDown={handleBarcodeScan}
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <QrCodeScannerIcon />
                      </InputAdornment>
                    ),
                  }}
                  helperText="🔍 Presiona Enter para buscar. 📦 Se agregará automáticamente al carrito. ⌨️ Usa F2 para enfocar este campo."
                  FormHelperTextProps={{
                    sx: { fontSize: '0.9rem', color: 'primary.main', fontWeight: 'bold' }
                  }} />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <Autocomplete
                  id="combo-box"
                  options={choices}
                  getOptionLabel={(option) => option.name && option.code ? `${option.name} (${option.code})` : option.name}
                  value={value}
                  onChange={(event, newValue) => {
                    addToCart(newValue)
                  }}
                  size="small"
                  fullWidth
                  renderInput={
                    (params) => <TextField {...params} label="Buscar Articulo" variant="filled" />
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
      <CardContent>
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

            {isXsmall ? (
              <CartItemsMobileGrid
                id={props.id}
                products={purchaseCart}
                changeQuantity={changeQuantity}
                changePrice={changePrice}
                deleteToCard={deleteToCard}
              />
            ) : (
              <CartItems
                id={props.id}
                products={purchaseCart}
                changeQuantity={changeQuantity}
                changePrice={changePrice}
                deleteToCard={deleteToCard}
              />
            )}

            <Box mt="1em" />

            <PurchaseFootDetail products={purchaseCart} />
          </Box>
        </Box>
      </CardContent>
    </CardContent>
  );
}

export default AutocompleteItemsSearch;
