import * as React from 'react';
import {
    Datagrid,
    NumberField,
    Pagination,
    ReferenceManyField,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import CustomerFormAccountLines from './CustomerFormAccountLines';
import ReceiptLinkField from '../receipts/ReceiptLinkField';
import DateFieldFormatted from '../components/DateFieldFormatted';

const CustomerFormAccount = (props) => {
    const title = `${props.record.name}`;

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label={title} />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceManyField
                                //label="Lista"
                                perPage={10}
                                pagination={<Pagination />}
                                reference="receipts"
                                target="customerId"
                                sort={{ field: 'date', order: 'DESC' }}
                                fullWidth
                            >
                                <Datagrid
                                    optimized
                                    expand={<CustomerFormAccountLines />}
                                >
                                    <ReceiptLinkField />
                                    <DateFieldFormatted
                                        label="Fecha"
                                        source="date"
                                        showTime={false}
                                    />

                                    <NumberField
                                        label="Saldo"
                                        source="total"
                                        locales="es-AR"
                                        options={{ style: 'currency', currency: 'ARS' }}
                                    />

                                </Datagrid>
                            </ReferenceManyField>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default CustomerFormAccount;
