import * as React from 'react';
import {
    TextField,
    useTranslate,
    ReferenceField,
    NumberField,
    ChipField
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { voucherFormat } from '../utils/functions';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const CashListMobile = ({ ids, data }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div className={classes.name}>
                                    <div>
                                        {data[id].operationType === 'receipts' ? 'RECIBO' : 'ORDEN'}-{voucherFormat(data[id].id)}
                                    </div>
                                </div>
                            </div>
                        }
                        subheader={
                            <div>
                                <div>
                                    {translate('resources.invoices.fields.date')}
                                    :&nbsp;
                                    <DateFieldFormatted
                                        record={data[id]}
                                        source="date"
                                        showTime={false}
                                    />
                                </div>
                                <div>
                                    {'Cuenta'}:&nbsp;
                                    <ChipField
                                        record={data[id]}
                                        size="small"
                                        source="primaryAccount"
                                        emptyText
                                    />
                                </div>
                                {data[id].secondaryAccount !== null &&
                                    <div>
                                        {'Cuenta Secundaria'}:&nbsp;
                                        <ChipField
                                            record={data[id]}
                                            size="small"
                                            source="secondaryAccount"
                                            emptyText
                                        />
                                    </div>
                                }
                                {data[id].primaryAccount === 'CLIENTES' &&
                                    <div>
                                        <ReferenceField
                                            record={data[id]}
                                            source="customerId"
                                            reference="customers"
                                        >
                                            <TextField source="name" />
                                        </ReferenceField>
                                    </div>
                                }
                                {data[id].primaryAccount === 'PROVEEDORES' &&
                                    <div>
                                        <ReferenceField
                                            record={data[id]}
                                            source="supplierId"
                                            reference="suppliers"
                                        >
                                            <TextField source="name" />
                                        </ReferenceField>
                                    </div>
                                }

                            </div>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            {'Importe'}
                            :&nbsp;
                            <NumberField
                                record={data[id]}
                                source="totalPartial"
                                locales="es-AR"
                                options={{ style: 'currency', currency: 'ARS' }}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {'Saldo Parcial'}
                            :&nbsp;
                            <NumberField
                                record={data[id]}
                                source="total"
                                locales="es-AR"
                                options={{ style: 'currency', currency: 'ARS' }}
                            />
                        </span>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default CashListMobile;
