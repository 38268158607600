import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    DateTimeInput,
    ReferenceManyField,
    useDataProvider,
    Pagination,
    useNotify,
    useRefresh,
    useRedirect,
    usePermissions,
    ChipField
} from 'react-admin';
import {
    requiredValidate,
    absenceDateValidation
} from '../validators/validators';
import {
    dateParse
} from '../utils/functions';
import CustomToolbar from '../components/CustomToolbar';
import ImageQR from './ImageQR';
import TrackingHistory from './timeTracking/TrackingHistory';
import ShiftsChipField from './ShiftsChipField';
import SectionTitle from '../components/SectionTitle';
import { Box, CardContent } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { getRolesRRHH } from '../appRolesComponents';

const TrackingManual = (props) => {
    const { permissions } = usePermissions();

    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const employeeId = props.id;
    const fetchEmployee = useCallback(async () => {
        dataProvider.getOne('employees', { id: employeeId })
            .then(response => {
                let data = response.data;
                dataProvider.getMany('shifts', {
                    ids: data.shiftId,
                })
                    .then(response => {
                        let shifts = response.data;
                        setState(state => ({ ...state, data, shifts }));
                    });

            });
    }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchEmployee();
    }, [fetchEmployee]);

    const {
        data,
        shifts
    } = state;

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/tracking/${employeeId}/trackingHistory`);
        refresh();
    };

    const isRRHH = getRolesRRHH(permissions);

    return (
        <Edit
            {...props}
            title={`Fichar Empleado`}
            onSuccess={onSuccess}
            undoable={false}
        >
            <TabbedForm
                toolbar={<CustomToolbar />}
                validate={absenceDateValidation}
                redirect={redirect}
            >
                <FormTab label="Fichaje">
                    <ImageQR />
                    <CardContent>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                                <SectionTitle label="Empleado" />
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                                        <ChipField
                                            size="small"
                                            record={data}
                                            source="documentNumber"
                                            avatar={
                                                <Avatar>
                                                    {'DNI'}
                                                </Avatar>
                                            }
                                        />
                                    </Box>
                                    <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                                        <ChipField
                                            size="small"
                                            record={data}
                                            source="fullName"
                                        />
                                    </Box>
                                    <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                                        <ChipField
                                            size="small"
                                            record={data}
                                            source="role"
                                        />
                                    </Box>
                                </Box>
                                <SectionTitle label="Turnos" />
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <ShiftsChipField shifts={shifts} />
                                    </Box>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    </Box>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    </Box>
                                </Box>


                            </Box>


                            {(isRRHH) &&

                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                    <SectionTitle label="Fechas del Fichaje Manual" />
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                            <DateTimeInput
                                                label="Entrada"
                                                source="start"
                                                validate={requiredValidate}
                                                parse={dateParse}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                            <DateTimeInput
                                                label="Salida"
                                                source="end"
                                                validate={requiredValidate}
                                                parse={dateParse}
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            }

                        </Box>

                    </CardContent>
                </FormTab>

                <FormTab label="Seguimiento" path="trackingHistory">
                    <ReferenceManyField
                        label={""}
                        perPage={99999}
                        pagination={<Pagination />}
                        sort={{ field: 'start', order: 'DESC' }}
                        reference="timeTracking"
                        target="employeeId"
                        fullWidth
                    >
                        <TrackingHistory />
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default TrackingManual;
