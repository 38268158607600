import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        fontSize: 10,
        borderTop: 1,
        textAlign: 'left',
        alignItems: 'left',
        paddingTop: 10,
    },
    headerLeft: {
        width: '50%',
    },
    headerRight: {
        width: '50%',
        paddingLeft: 30,
    },
    name: {
        fontStyle: 'bold',
    },

});

const BillTo = ({ pdfDocument }) => {
    const customer = pdfDocument.customer || {};
    const supplier = pdfDocument.supplier || {};

    const getName = (entity, defaultName) => entity ? entity.name : defaultName;
    const getAddress = (entity, defaultAddress) => entity ? entity.address.address : defaultAddress;
    const getEmail = (entity, defaultEmail) => entity ? entity.email : defaultEmail;
    const getIva = (entity, defaultIva) => entity ? entity.fiscalCondition : defaultIva;
    const getCuit = (entity, defaultCuit) => entity ? entity.cuit : defaultCuit;
    const getCity = (entity, defaultCity) => entity && entity.address ? entity.address.city : defaultCity;
    const getCellphone = (entity, defaultCellphone) => entity ? entity.cellphone : defaultCellphone;
    const getSaleCondition = (entity, defaultSaleCondition) => entity ? entity.saleCondition : defaultSaleCondition;

    const name = pdfDocument.customer !== undefined
        ? `Apellido y Nombre / Razón Social: ${getName(customer, '')}`
        : `Señor(es): ${getName(supplier, '')}`;

    const address = pdfDocument.customer !== undefined
        ? `Domicilio: ${getAddress(customer, '')}`
        : `Domicilio: ${getAddress(supplier, '')}`;

    const email = pdfDocument.customer !== undefined
        ? `Email: ${getEmail(customer, '')}`
        : `Email: ${getEmail(supplier, '')}`;

    const iva = pdfDocument.customer !== undefined
        ? `Condición frente al IVA: ${getIva(customer, '')}`
        : `Condición frente al IVA: ${getIva(supplier, '')}`;

    const cuit = pdfDocument.customer !== undefined
        ? `CUIT/DNI: ${getCuit(customer, '')}`
        : `CUIT/DNI: ${getCuit(supplier, '')}`;

    const city = pdfDocument.customer !== undefined
        ? `Localidad: ${getCity(customer, '')}`
        : `Localidad: ${getCity(supplier, '')}`;

    const cellphone = pdfDocument.customer !== undefined
        ? `Tel.: ${getCellphone(customer, '')}`
        : `Tel.: ${getCellphone(supplier, '')}`;

    const saleCondition = pdfDocument.customer !== undefined
        ? `Cond. Venta: ${getSaleCondition(customer, '')}`
        : `Cond. Venta: ${getSaleCondition(supplier, '')}`;
    
    return (
        <View style={styles.container}>
            <View style={styles.headerLeft}>
                <Text style={styles.name}>{name}</Text>
                <Text>{address}</Text>
                <Text>{email}</Text>
                <Text>{iva}</Text>
            </View>
            <View style={styles.headerRight}>
                <Text>{cuit}</Text>
                <Text>{city}</Text>
                <Text>{cellphone}</Text>
                <Text>{saleCondition}</Text>
            </View>
        </View>
    )
};
export default BillTo;