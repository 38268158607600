import React from 'react';
import { Link } from 'react-admin';

const ToolLinkField = props => (
    <Link to={`/holidays/${props.record.id}`}>
        {props.record.description}
    </Link>
);

ToolLinkField.defaultProps = {
    source: 'id',
    label: 'Feriado',
};

export default ToolLinkField;
