import React from 'react';
import { Link } from 'react-admin';

const ToolLinkField = props => (
    <Link to={`/tools/${props.record.id}`}>
        SEG-{props.record.id}
    </Link>
);

ToolLinkField.defaultProps = {
    source: 'id',
    label: 'Seguimiento',
};

export default ToolLinkField;
