import * as React from 'react';
import { List, usePermissions } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import OrderListMobile from './OrderListMobile';
import OrderListDesktop from './OrderListDesktop';
import { getRolesOnlyDriverExceptAdmin, getRolesOnlyInspectorExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';

const OrderList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);

    if (!isWorkshopOnly && !isDriver && !isInspector) return null;

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />} // actions={false}
            filters={<InputFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
        >
            {(isXsmall || isDriver) ? (
                <OrderListMobile />
            ) : (
                (!isDriver) && (<OrderListDesktop />)
            )}
        </List>
    );
};

export default OrderList;
