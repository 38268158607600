import React from 'react';
import { Link } from 'react-admin';

const CustomerLinkField = props => (
    <Link to={`/customers/${props.record.id}`}>
        {props.record.name}
    </Link>
);

CustomerLinkField.defaultProps = {
    source: 'name',
    label: 'resources.vehicles.fields.customer',
};

export default CustomerLinkField;
