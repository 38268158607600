import * as React from 'react';
import {
    Datagrid,
    TextField,
    NumberField,
    ChipField,
    ReferenceField,
    usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import OrderLinkField from './OrderLinkField';
import InspectionField from './InspectionField';
import rowStyle from './rowStyle.js'
import { getRolesWorkshopOnly } from '../appRolesComponents';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    total: { fontWeight: 'bold' },
});

const OrderListDesktop = (props) => {
    const classes = useListStyles();
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);

    return (
        <Datagrid
            optimized
            rowStyle={rowStyle()}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            {...props}
        >
            <OrderLinkField
                sortable={false}
            />

            <ReferenceField
                label="Patente"
                source="inspectionId"
                reference="inspections"
                link={false}
                sortable={false}
            >
                <ReferenceField
                    source="vehicleId"
                    reference="vehicles"
                    link={false}
                >
                    <TextField
                        source="patent"
                    />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField
                label="Linea"
                source="inspectionId"
                reference="inspections"
                link={false}
                sortable={false}
            >
                <ReferenceField
                    source="vehicleId"
                    reference="vehicles"
                    link={false}
                >
                    <ReferenceField
                        source="lineId"
                        reference="busLines"
                        link={false}
                    >
                        <TextField
                            source="name"
                        />
                    </ReferenceField>
                </ReferenceField>
            </ReferenceField>

            <ReferenceField
                label="Interno"
                source="inspectionId"
                reference="inspections"
                link={false}
                sortable={false}
            >
                <ReferenceField
                    source="vehicleId"
                    reference="vehicles"
                    link={false}
                >
                    <TextField
                        source="internal"
                    />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField
                label="Canasto"
                source="basketId"
                reference="baskets"
                link={false}
                sortable={false}
            >
                <ChipField source="name" size="small" variant="outlined" />
            </ReferenceField>

            <DateFieldFormatted
                label="Fecha Inicio"
                source="start"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Fin"
                source="end"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Entrega"
                source="deliveryDate"
                sortable={false}
            />
            <NumberField
                label="Dias"
                source="daysInWorkshop"
                sortable={false}
            />
            {(isWorkshopOnly) && (
                <NumberField
                    label="Total"
                    source="total"
                    locales="es-AR"
                    options={{ style: 'currency', currency: 'ARS' }}
                    cellClassName={classes.total}
                />
            )}
            <ChipField
                label="Estado"
                source="workOrderStatus"
                size="small"
                sortable={false}
            />

            <InspectionField
                sortable={false}
            />


        </Datagrid>
    );
};

export default OrderListDesktop;
