import React from 'react';
import { Link } from 'react-admin';
import { voucherFormat } from '../utils/functions';

const CashLinkField = (props) => {

    const numberVoucher = voucherFormat(props.record.id);
    const operationType = props.record.operationType;
    const textVoucher = (operationType === 'payments') ? `ORDEN-${numberVoucher}` : `RECIBO-${numberVoucher}`;
    const urlTo = (operationType === 'payments') ? `/payments/${props.record.id}` : `/receipts/${props.record.id}`;

    return (
        <Link
            to={urlTo}
            onClick={
                () => {
                    localStorage.removeItem('invoicesReceipts')
                    localStorage.removeItem('purchasesPayments')
                }
            }
        >
            {textVoucher}
        </Link>
    )
};

CashLinkField.defaultProps = {
    source: 'id',
    label: 'Operacion Nro',
};

export default CashLinkField;
