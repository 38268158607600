import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        //borderBottomWidth: 1,
        alignItems: 'center',
        //height: 24,
        fontStyle: 'bold',
        color: 'white'
    },
    voucher: {
        width: '50%',
    },
    amount: {
        width: '50%',
    },

});

const PaymentTableBlankSpace = ({ rowsCount }) => {
    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map((x, i) =>
        <View style={styles.row} key={`BR${i}`}>
            <Text style={styles.voucher}>-</Text>
            <Text style={styles.amount}>-</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default PaymentTableBlankSpace;