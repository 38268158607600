import * as React from 'react';
import {
    List, usePermissions
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import InspectionMobileGrid from './InspectionMobileGrid';
import InspectionListDesktop from './InspectionListDesktop';
import {
    getRolesOnlyDriverExceptAdmin,
    getRolesOnlyInspectorExceptAdmin,
    getRolesWorkshopOnly
} from '../appRolesComponents';

const InspectionList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);

    if (!isWorkshopOnly && !isDriver && !isInspector) return null;

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <InspectionMobileGrid />
            ) : (
                <InspectionListDesktop />
            )}
        </List>
    );
};

export default InspectionList;
