import { Card, CardHeader } from '@material-ui/core';
import * as React from 'react';
import {
    Create,
    FormDataConsumer,
    FormTab,
    TabbedForm,
    useNotify
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import InvoiceForm from './InvoiceForm';
import InvoiceFormDetail from './InvoiceFormDetail';
import InvoiceFormTax from './InvoiceFormTax';

const InvoiceCreate = (props) => {
    const notify = useNotify();
    const redirect = `/invoices`;

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const validate = (values) => {
        const errors = {};
        const invoiceCart = JSON.parse(localStorage.getItem("invoiceCart"));
        
        if (invoiceCart === null || invoiceCart === undefined || (Object.keys(invoiceCart).length === 0)) {
            errors.total = ['Agregue un item'];            
        }
        return errors;
    };
    

    return (
        <Create
            {...props}
            onFailure={onFailure}
        >
            <TabbedForm
                // warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
                validate={validate}
            >
            
                <FormTab label="Cabecera" >
                    <InvoiceForm />
                </FormTab>

                <FormTab
                    label="Detalle"
                    path="details"
                >
                    <InvoiceFormDetail />
                </FormTab>

                <FormTab
                    label="Otros impuestos"
                    path="tax"
                >
                    <FormDataConsumer>
                        {({ formData }) => (formData.customerId !== undefined && formData.invoiceType !== undefined) ?
                            <InvoiceFormTax />
                            :
                            <Card key={0}>
                                <CardHeader
                                    title={
                                        <div>
                                            Por favor, selecciona un cliente y el tipo de comprobante y luego podrás cargar los conceptos facturados.
                                        </div>
                                    }
                                />
                            </Card>
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default InvoiceCreate;
