import PurchaseList from './PurchaseList';
import PurchaseCreate from './PurchaseCreate';
import PurchaseEdit from './PurchaseEdit';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

export default {
  list: PurchaseList,
  create: PurchaseCreate,
  edit: PurchaseEdit,
  icon: ShoppingCartIcon
};
