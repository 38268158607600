import React from 'react';
import Chip from '@material-ui/core/Chip';

const CustomChipField = ({ record, source }) => {
    const value = record[source];

    const displayText = value === 'FACTURADA' ? 'ARCHIVADA' : value;

    return (
        <Chip
            label={displayText}
            size="small"
            variant="outlined"
        />
    );
};

export default CustomChipField;