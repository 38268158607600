import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button
} from 'react-admin';
import BookmarksIcon from '@material-ui/icons/Bookmarks';

const onButtonClick = () => {
    if (localStorage.getItem("cart") !== null) {
        localStorage.removeItem("cart");
    }
}

const OrderCreateButton = ({ record }) => (
    <Button
        label="Crear OT"
        component={Link}
        variant="contained"
        to={{
            pathname: "/orders/create",
            search: `?id=${record.id}&employeeId=${record.employeeId}`,
        }}
        onClick={onButtonClick}
    >
        <BookmarksIcon fontSize="small" />
    </Button>
);

export default OrderCreateButton;