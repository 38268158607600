import React from 'react';
import {
    LinearProgress,
    useGetManyReference,
    SelectInput
} from 'react-admin';

const LineSelectInput = ({ customerId, validate }) => {
    const { data, ids, loading, error } = useGetManyReference(
        'busLines',
        'customerId',
        customerId,
        { page: 1, perPage: 50 },
        { field: 'name', order: 'ASC' },
        {},
        'busLines',
    );

    if (loading) { return <LinearProgress />; }
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    return (
        <div>
            {(data === undefined) || (Object.keys(data).length === 0) ?
                <span>Cliente sin lineas cargadas!</span> :
                <SelectInput
                    label="resources.vehicles.fields.line"
                    source="lineId"
                    sort={{ field: 'name', order: 'ASC' }}
                    choices={
                        ids.map((id) => ({
                            id: data[id].id,
                            name: data[id].name,
                        }))
                    }
                    validate={validate}
                    disabled={false}
                    fullWidth
                    resettable
                />
            }
        </div>
    );
};
export default LineSelectInput;
