import * as React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import SupplierForm from './SupplierForm';
import SupplierFormAccount from './SupplierFormAccount';

const SupplierEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/suppliers`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const SupplierTitle = ({ record }) => {
        return <span>{record ? `${record.name}` : ''}</span>;
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<SupplierTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <FormTab label="General" >
                    <SupplierForm />
                </FormTab>
                <FormTab
                    label="Ordenes"
                    path="account"
                >
                    <SupplierFormAccount />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default SupplierEdit;
