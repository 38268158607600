import * as React from 'react';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

const DashboardPendingOrders = ({ value }) => {
    const translate = useTranslate();

    return (
        <CardWithIcon
            to="/orders"
            icon={BookmarksIcon}
            title={translate('pos.dashboard.pending_orders')}
            subtitle={value || '0'}
        />
    );
};

export default DashboardPendingOrders;
