import * as React from 'react';
import {
    Edit,
    SimpleForm,
    useNotify
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import ShiftForm from './ShiftForm';

const ShiftTitle = ({ record }) => {
    return <span>{record ? `${record.description}` : ''}</span>;
};

const ShiftEdit = (props) => {
    const notify = useNotify();

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<ShiftTitle />}
            onFailure={onFailure}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
            >
                <ShiftForm />
            </SimpleForm>
        </Edit>
    );
};

export default ShiftEdit;
