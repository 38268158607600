import React, { useEffect, useState } from 'react';
import {
  Button,
  LinearProgress,
  useDataProvider
} from 'react-admin';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { voucherFormat } from '../utils/functions';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Invoice from '../components/pdf/Invoice';

const PDFButtonInvoice = (invoice) => {
  const dataProvider = useDataProvider();
  const [pdfDocument, setPdfDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getOne('customers', {
      id: invoice.customerId
    }).then(response => {
      const customer = response.data;
      const result = {
        ...invoice,
        customer: customer,
      }
      setPdfDocument(result);
      setLoading(false);
    }).catch((error) => {
      setError(error);
      setLoading(false);
    });
  }, [invoice]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!pdfDocument) return null;
  return (
    <Button
      label="Descargar"
      variant="contained"
      onClick={async () => {
        const doc = <Invoice pdfDocument={pdfDocument} />;
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `${pdfDocument.invoiceType}${pdfDocument.pointOfSale}-${voucherFormat(pdfDocument.id)}.pdf`);
      }}
    >
      <PictureAsPdfIcon fontSize="small" />
    </Button>
  )
};

export default PDFButtonInvoice;