import * as React from 'react';
import { Fragment } from 'react';
import {
    SaveButton,
    Toolbar
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
//import { currencyFormatter, voucherFormat } from '../utils/functions';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const PurchaseInvoiceEditToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    record,
    saving,
    loading,
}) => {
    const classes = useStyles();

    if (!record) return null;

    //const total = currencyFormatter(record.total);
    //const date = record.date;
    //const voucher = `${record.invoiceType} ${record.pointOfSale}-${voucherFormat(record.id)}`;

    return (
        <Toolbar className={classes.root}>
            {record.invoiceStatus === 'FACTURADA' ? (
                <Fragment>

                </Fragment>
            ) : (
                <Fragment>
                    <SaveButton
                        handleSubmitWithRedirect={handleSubmitWithRedirect}
                        invalid={invalid}
                        saving={saving}
                        redirect="invoices"
                        submitOnEnter={true}
                        disabled={loading}
                    />
                    {(record.invoiceStatus === 'CREADA') &&
                        <SaveButton
                            label='ARCHIVAR'
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            disabled={loading}
                            saving={saving}
                            icon={<SendIcon color="primary" style={{ color: 'red' }} />}
                            redirect="orders"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    invoiceStatus: 'FACTURADA'
                                }
                            )}
                        />
                    }
                </Fragment>
            )}
        </Toolbar>
    );
};

export default PurchaseInvoiceEditToolbar;
