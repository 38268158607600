import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    DateTimeInput
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { toUpperCase } from '../utils/functions';
import SectionTitle from '../components/SectionTitle';
import {
    dateParseDateTime
} from '../utils/functions';

const ToolForm = (props) => {
    const isReadOnly = props.record.id !== undefined;

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>

                    <SectionTitle label="Herramienta tomada" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={4} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="Herramienta"
                                source="itemId"
                                reference="items"
                                sort={{ field: 'name', order: 'ASC' }}
                                perPage={99999} //TODO NO TRAE MAS Q 99999
                                filter={{ category: 135 }} //TODO SOLO POR ID 
                                validate={requiredValidate}
                                fullWidth
                                resettable
                            >
                                <AutocompleteInput
                                    optionText="name"
                                    disabled={isReadOnly}
                                    autoFocus
                                />
                            </ReferenceInput>
                        </Box>
                    </Box>

                    <SectionTitle label="Empleado que toma la Herramienta" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <ReferenceInput
                            label="Empleado"
                            source="employeeId"
                            reference="employees"
                            sort={{ field: 'fullName', order: 'ASC' }}
                            validate={requiredValidate}
                            perPage={99999}
                            fullWidth
                            resettable
                        >
                            <AutocompleteInput
                                optionText="fullName"
                                disabled={isReadOnly}
                            />
                        </ReferenceInput>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Descripcion y/o comentarios"
                                source="description"
                                parse={toUpperCase}
                                multiline
                                resettable
                                fullWidth
                                rows={4}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    flex={2}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Seguimiento de la herramienta" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <DateTimeInput
                                    label="Fecha de retiro del pañol"
                                    source="start"
                                    defaultValue={dateParseDateTime(Date.now())}
                                    disabled={true}
                                    parse={dateParseDateTime}
                                    fullWidth
                                />
                                :
                                <DateTimeInput
                                    label="Fecha de retiro del pañol"
                                    source="start"
                                    disabled={true}
                                    fullWidth
                                />
                            }
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id !== undefined) && (
                                <DateTimeInput
                                    label="Fecha de devolucion al pañol"
                                    source="end"
                                    disabled={true}
                                    fullWidth
                                />
                            )}
                        </Box>
                    </Box>

                    <SectionTitle label="Datos de la Baja" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id !== undefined && (props.record.status === false || props.record.status === 'false')) && (
                                <DateTimeInput
                                    label="Fecha de baja"
                                    source="end"
                                    disabled={true}
                                    fullWidth
                                />
                            )}
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default ToolForm;
