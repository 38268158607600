import React, { useEffect, useState } from 'react';
import {
  Button,
  LinearProgress,
  useDataProvider
} from 'react-admin';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { voucherFormat } from '../utils/functions';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Payment from '../components/pdf/Payment';

const PDFButtonPayment = (payment) => {
  const dataProvider = useDataProvider();
  const [pdfDocument, setPdfDocument] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    if (payment.supplierId !== null) {
      dataProvider.getOne('suppliers', {
        id: payment.supplierId,
      }).then(response => {
        const supplier = response.data;
        dataProvider.getMany('purchases-invoices', {
          ids: payment.purchases.map((item) => item.id)
        }).then(response => {
          const purchases = response.data.map(item => (
            {
              id: item.id,
              date: item.date,
              invoiceType: item.invoiceType,
              pointOfSale: item.pointOfSale,
              totalInvoice: item.total,
              totalPurchase: payment.purchases.find(i => (i.id === item.id)).totalPurchase
            }
          ))
          const result = {
            ...payment,
            purchases: purchases,
            supplier: supplier,
          }
          setPdfDocument(result);
          setLoading(false);
        }).catch((error) => {
          setError(error);
          setLoading(false);
        });
      }).catch((error) => {
        setError(error);
        setLoading(false);
      });
    } else {
      setPdfDocument(payment);
      setLoading(false);
    }
  }, [payment]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error PDFButtonPayment, por favor contactese con el administrador del sistema</p>; }
  if (!pdfDocument) return null;
  return (
    <Button
      label="Descargar"
      variant="contained"
      onClick={async () => {
        const doc = <Payment pdfDocument={pdfDocument} />;
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `OP-${voucherFormat(payment.id)}.pdf`);
      }}
    >
      <PictureAsPdfIcon fontSize="small" />
    </Button>
  )
};

export default PDFButtonPayment;