import * as React from 'react';
import { Datagrid, NumberField } from 'react-admin';
import ConsumableLinkField from './ConsumableLinkField';

const ConsumableListDesktop = (props) => {
    return (
        <Datagrid {...props} optimized>
            <ConsumableLinkField
                label="Nombre (Código)"
                source="id"
                sortable={false}
            />
            <NumberField
                label="Stock del Pañol"
                source="quantity"
                sortable={false}
            />
            <NumberField
                label="Stock del Depósito"
                source="warehouseQuantity"
                sortable={false}
            />
            <NumberField
                label="Stock Minimo"
                source="minQuantity"
                sortable={false}
            />
            <NumberField
                label="Stock Maximo"
                source="maxQuantity"
                sortable={false}
            />
            <NumberField
                label="Ubicación"
                source="location"
                sortable={false}
            />
        </Datagrid>
    );
};

export default ConsumableListDesktop;
