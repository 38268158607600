import * as React from "react";
import {
    ImageInput,
    ImageField
} from 'react-admin';

const ImageItemInput = () => (
    <ImageInput
        label="Foto del Articulo:"
        source="image"
        accept="image/png, image/jpeg"
        maxSize={1000000}
        placeholder={
            <div>
                <p>Subir una foto del articulo</p>
                <em>
                    (El archivo no debe exceder 1MB)
                </em>
            </div>
        }
    >
        <ImageField source="image" />
    </ImageInput>
);

export default ImageItemInput;