import * as React from 'react';
import {
    EditButton
} from 'react-admin';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import imageDefault from './imageCategory/image-default.svg';
import LinkToRelatedItems from './LinkToRelatedItems';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 140,
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});

const CategoryGrid = props => {
    const classes = useStyles(props);
    const { data, ids } = props;

    const urlImageBuilder = (id) => {
        return `https://api.marasa.com.ar/workshop-api/api/categories/${id}/download`;
    }

    const onMediaFallback = (event) => {
        event.target.src = imageDefault
    };

    return ids ? (
        <Grid container spacing={1} className={classes.root}>
            {ids.map(id => (
                <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
                    <Card>
                        <CardMedia
                            image={urlImageBuilder(data[id].id)}
                            className={classes.media}
                            onError={onMediaFallback}
                        />
                        <CardContent className={classes.title}>
                            <Typography
                                variant="h5"
                                component="h2"
                                align="center"
                            >
                                {data[id].name}
                            </Typography>
                        </CardContent>
                        <CardActions
                            classes={{ spacing: classes.actionSpacer }}
                        >
                            <LinkToRelatedItems
                                 record={data[id]}                                      
                            />
                            <EditButton
                                basePath="/categories"
                                variant="outlined"
                                record={data[id]}
                            />
                        </CardActions>
                    </Card>
                </Grid>
            ))}
        </Grid>
    ) : null;
};

export default CategoryGrid;
