import React, {
    useEffect,
    useReducer
} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import imageDefault from './image-default.svg';
import { CardHeader } from '@material-ui/core';

const styles = {
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        width: 'initial',
        minWidth: 'initial',
        maxWidth: '42em',
        maxHeight: '15em',
    },
};

const useStyles = makeStyles(styles);

const ImageCategoryView = ({ record }) => {
    const classes = useStyles();
    const urlImage = `https://api.marasa.com.ar/workshop-api/api/categories/${record.id}/download`;

    function reducer(currentSrc, action) {

        if (action.type === 'main image loaded') {
            return action.src;
        }
        if (!currentSrc) {
            return action.src;
        }

        return currentSrc;
    }

    function useProgressiveImage({ src }) {
        const [currentSrc, dispatch] = useReducer(reducer);

        useEffect(() => {
            const mainImage = new Image();
            mainImage.onload = () => {
                dispatch({ type: 'main image loaded', src });
            };
            mainImage.src = src;
        }, [src]);

        return currentSrc;
    }

    const src = useProgressiveImage({
        src: urlImage,
    });

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                {src !== undefined ?
                    <img
                        src={src}
                        alt={record.name}
                        title={record.name}
                        className={classes.img}
                        onError={(e) => {
                            e.target.onerror = null
                            e.target.src = imageDefault
                        }}
                    />
                    :
                    <Card key={0}>
                        <CardHeader
                            title={
                                <div>
                                    Sin foto de la categoria!
                                </div>
                            }
                        />
                    </Card>
                }
            </CardContent>
        </Card>
    );
}
export default ImageCategoryView;
