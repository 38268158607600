import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { currencyFormatter } from '../../utils/functions';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        //borderBottomWidth: 1,
        alignItems: 'center',
        //height: 10,
        fontSize: 10,
        fontStyle: 'bold',
    },
    code: {
        width: '5%',
        textAlign: 'center',
        paddingRight: 8,
    },
    description: {
        width: '48%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    qty: {
        width: '7%',
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '10%',
        textAlign: 'right',
        paddingRight: 8,
    },
    price: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});


const InvoiceTableRow = ({ items }) => {
    const rows = items.map(item =>
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.code}>{item.code} </Text>
            <Text style={styles.description}>{item.name} {item.itemComment}</Text>
            <Text style={styles.qty}>{item.itemQuantity}</Text>
            <Text style={styles.rate}>{item.itemTax === 1 ? 'EXENTO' : item.itemTax * 100}</Text>
            <Text style={styles.price}>{currencyFormatter(item.price)}</Text>
            <Text style={styles.amount}>{currencyFormatter(item.price * item.itemQuantity)}</Text>
        </View>
    )
    return (
        <Fragment>
            {rows}
        </Fragment>
    )
};

export default InvoiceTableRow;