const choices = [
    {
        id: 'FA',
        name: 'Factura A',
        purchaser: ['RESPONSABLE_INSCRIPTO'],
        seller: ['RESPONSABLE_INSCRIPTO']
    },
    // { id: 'NDA', name: 'Nota de Débito A' },
    // { id: 'NCA', name: 'Nota de Crédito A' },
    {
        id: 'FB',
        name: 'Factura B',
        purchaser: ['MONOTRIBUTO', 'CONSUMIDOR_FINAL', 'EXENTO'],
        seller: []
    },
    // { id: 'NDB', name: 'Nota de Débito B' },
    // { id: 'NCB', name: 'Nota de Crédito B' },
    {
        id: 'FC',
        name: 'Factura C',
        purchaser: [],
        seller: ['MONOTRIBUTO'],
    },
    // { id: 'NDC', name: 'Nota de Débito C' },
    // { id: 'NCC', name: 'Nota de Crédito C' },
];

export default choices;
