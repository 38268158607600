import * as React from 'react';
import { List, usePermissions, ResourceContextProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import InputFilter from '../components/InputFilter';
import WarehouseListMobile from './WarehouseListMobile';
import WarehouseListDesktop from './WarehouseListDesktop';
import { getRolesOnlySupervisorExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';

const WarehouseList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions);
    
    if (!isWorkshopOnly) return null;

    return (
        <ResourceContextProvider value="items">
            <List
                syncWithLocation
                title="Depósito"
                basePath="/warehouse"
                actions={false}
                filters={<InputFilter />}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={25}
                bulkActionButtons={false}
            >
                {
                    isXsmall ? (
                        <WarehouseListMobile />
                    ) : (
                        <WarehouseListDesktop />
                    )
                }
            </List>
        </ResourceContextProvider>
    );
};

export default WarehouseList;
