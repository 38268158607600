import React from 'react';
import { Link } from 'react-admin';

const InspectionLinkField = props => (
    <Link to={`/inspections/${props.record.id}`}>  
        IN-{props.record.id}
    </Link>
);

InspectionLinkField.defaultProps = {
    source: 'id',
    label: 'Inspección',
};

export default InspectionLinkField;