import ToolList from './ToolList';
import ToolCreate from './ToolCreate';
import ToolEdit from './ToolEdit';
import BuildIcon from '@material-ui/icons/Build';

export default {
  list: ToolList,
  create: ToolCreate,
  edit: ToolEdit,
  icon: BuildIcon
};
