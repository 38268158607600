import * as React from 'react';
import {
    TextInput,
    SelectInput,
} from 'react-admin';
import {
    validateCuil,
    validateFullName,
    validateEmail,
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase,
    toLowerCase
} from '../utils/functions';
import { Box, CardContent } from '@material-ui/core';
import fiscalData from '../components/data/fiscalData';
import saleData from '../components/data/saleData';
import SectionTitle from '../components/SectionTitle';

const SupplierForm = () => {

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Datos de la Empresa / Proveedor" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {/* <SelectInput
                                autoFocus
                                label="Tipo de documento"
                                source="documentType"
                                choices={[
                                    { id: 'CUIT', name: 'CUIT' },
                                    { id: 'DNI', name: 'DNI' },
                                    { id: 'CI', name: 'Otro' },
                                    { id: 'LC', name: 'Pasaporte' },
                                ]}
                                validate={requiredValidate}
                                fullWidth
                            /> */}
                            <TextInput
                                autoFocus
                                label="CUIT"
                                source="cuit"
                                validate={validateCuil}
                                fullWidth
                            />

                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Nombre o Razón Social"
                                source="name"
                                validate={validateFullName}
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Nombre de fantasía"
                                source="nameFantasy"
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <TextInput
                        label="Email que se mostrará en el Comprobante"
                        type="email"
                        source="email"
                        parse={toLowerCase}
                        validation={{ email: true }}
                        validate={[requiredValidate, validateEmail]}
                        fullWidth
                    />

                    <SectionTitle label="Datos de Contacto" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Teléfono"
                                source="telephone"
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Teléfono altenativo"
                                source="cellphone"
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Teléfono altenativo"
                                source="telephoneAlternative"
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Teléfono altenativo"
                                source="cellphoneAlternative"
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>

                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Persona de contacto en la Empresa"
                                source="person"
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Email del Contacto"
                                type="email"
                                source="emailContact"
                                parse={toLowerCase}
                                validation={{ email: true }}
                                validate={[validateEmail]}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <SectionTitle label="Domicilio" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Domicilio"
                                source="address.address"
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Codigo Postal"
                                source="address.postalCode"
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Pais"
                                source="address.country"
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Ciudad"
                                source="address.city"
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <SectionTitle label="Otros datos Informativos" />
                    <TextInput
                        label="Detalles"
                        source="details"
                        parse={toUpperCase}
                        multiline
                        resettable
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Datos Impositivos" />
                    <div>
                        <SelectInput
                            label="Condición ante el IVA"
                            source="fiscalCondition"
                            choices={fiscalData}
                            validate={requiredValidate}
                            fullWidth
                        />
                    </div>
                    <div>
                        <SelectInput
                            label="Condición de Venta"
                            source="saleCondition"
                            choices={saleData}
                            validate={requiredValidate}
                            fullWidth
                        />
                    </div>
                </Box>
            </Box>
        </CardContent>
    );
};

export default SupplierForm;
