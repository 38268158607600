import * as React from 'react';
import {
    DateInput,
    TextInput,
    SelectInput,
} from 'react-admin';
import {
    validateDocumentNumber,
    validateCuil,
    validateFullName,
    validateEmail,
    validatePostalCode,
    requiredValidateNumber,
    requiredValidate,
    validateNumber,
} from '../validators/validators';
import {
    toUpperCase,
    toLowerCase
} from '../utils/functions';
import SectionTitle from '../components/SectionTitle';
import roleData from '../components/data/roleData';
import documentTypeData from '../components/data/documentTypeData';
import { Box, CardContent } from '@material-ui/core';

const EmployeeForm = () => {

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Indentidad" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="Tipo documento"
                                source="documentType"
                                choices={documentTypeData}
                                validate={requiredValidate}
                                autoFocus
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="DNI"
                                source="documentNumber"
                                validate={validateDocumentNumber}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="CUIT/CUIL"
                                source="cuil"
                                validate={validateCuil}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <TextInput
                        label="Apellido y Nombre"
                        source="fullName"
                        validate={validateFullName}
                        parse={toUpperCase}
                        fullWidth
                    />

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="Fecha nacimiento"
                                source="birthday"
                                validate={requiredValidate}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="Puesto"
                                source="role"
                                choices={roleData}
                                validate={requiredValidate}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <SectionTitle label="Datos de Contacto" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Teléfono Móvil"
                                source="cellphone"
                                validate={requiredValidateNumber}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Teléfono fijo"
                                source="telephone"
                                validate={validateNumber}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <TextInput
                        label="Email"
                        type="email"
                        source="email"
                        parse={toLowerCase}
                        validation={{ email: true }}
                        validate={[requiredValidate, validateEmail]}
                        fullWidth
                    />

                    <SectionTitle label="Ubicación" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Domicilio"
                                source="address.address"
                                parse={toUpperCase}
                                fullWidth
                                multiline
                                resettable
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Código postal"
                                source="address.postalCode"
                                validate={validatePostalCode}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="País"
                                source="address.country"
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Ciudad"
                                source="address.city"
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>

                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Contacto de Emergencia" />
                    <div>
                        <TextInput
                            label="Apellido y Nombre de Emergencia"
                            source="emergencyFullName"
                            parse={toUpperCase}
                            fullWidth
                        />
                    </div>
                    <div>
                        <TextInput
                            label="Teléfono de Emergencia"
                            source="emergencyTelephone"
                            fullWidth
                        />
                    </div>
                </Box>
            </Box>
        </CardContent>
    );
};

export default EmployeeForm;
