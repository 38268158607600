import React from 'react';
import {
    LinearProgress,
    useGetOne,
    TextField,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import inflection from 'inflection';

const SupplierCard = ({ id }) => {
    const { data, loaded, error } = useGetOne('suppliers', id);

    if (!loaded) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }

    return (
        <div>
            <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                    <PermIdentityIcon fontSize="small" />
                </Grid>
                <Grid item xs zeroMinWidth>
                    CUIT <TextField record={data} source="cuit" />
                </Grid>
            </Grid>

            <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                    <AccountBalanceWalletIcon fontSize="small" />
                </Grid>
                <Grid item xs zeroMinWidth>
                    {inflection.humanize(data.fiscalCondition)}
                </Grid>
            </Grid>

            <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                    <AccountBalanceIcon fontSize="small" />
                </Grid>
                <Grid item xs zeroMinWidth>
                    <TextField record={data} source="saleCondition" />
                </Grid>
            </Grid>

            <Grid container wrap="nowrap" spacing={1}>
                <Grid item>
                    <ContactMailIcon fontSize="small" />
                </Grid>
                <Grid item xs zeroMinWidth>
                    <TextField record={data} source="email" />
                </Grid>
            </Grid>
        </div>
    );
}

export default SupplierCard;