import { useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import {
    List,
    usePermissions
} from 'react-admin';
import { getRolesFinance } from '../appRolesComponents';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import CustomerListDesktop from './CustomerListDesktop';
import CustomerListMobile from './CustomerListMobile';

const CustomerList = (props) => {
    const { permissions } = usePermissions();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const isFinance = getRolesFinance(permissions);
    if (!isFinance) return null;
    
    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <CustomerListMobile />
            ) : (
                <CustomerListDesktop />       
            )}
        </List>
    );
};

export default CustomerList;
