import {
    required,
    email,
    minLength,
    maxLength,
    number,
    minValue,
    maxValue,
} from 'react-admin';

export const cuilValidation = (value) => {
    const regExpCuil = (/^(20|23|27|30|33)([0-9]{9}|[0-9]{8}[0-9]{1})$/g);
    const dni = value.documentNumber.toString().replace(/\D/g, '');
    const cuil = value.cuil.toString().replace(/\D/g, '');
    const dniCuil = cuil.substring(2, cuil.length - 1).toString();
 
    if (!regExpCuil.test(cuil) || dniCuil !== dni) {
        return 'Debe Ingresar un CUIT/CUIL Valido';
    }
}

export const employeeValidation = (value) => {
    let now = new Date();
    let birthday = new Date(value.birthday);
    let age = now.getFullYear() - birthday.getFullYear();
    let month = now.getMonth() - birthday.getMonth();
    const errors = {};

    if (month < 0 || (month === 0 && now.getDate() < birthday.getDate())) {
        age--;
    }
    if (!value.birthday) {
        errors.birthday = 'Requerido';
    }
    if (age < 18 || age > 70) {
        errors.birthday = 'La edad del empleado debe ser mayor a 17 años o menor a 71';
    }

    if (value.endDate && (value.startDate > value.endDate)) {
        errors.endDate = 'La fecha de egreso no debe ser menor a la fecha de ingreso';
    }

    if (value.cuil !== undefined && value.documentNumber !== undefined) {
        errors.cuil = cuilValidation(value);
    }

    return errors;
};

export const absenceDateValidation = (value) => {
    const errors = {};
    if (value.end && (value.start > value.end)) {
        errors.end = 'La fecha fin no debe ser menor a la fecha de inicio';
    }

    return errors;
};

export const warehouseValidation = (value) => {
    const errors = {};
    if (value.addQuantity > value.warehouseQuantity) {
        errors.addQuantity = 'La cantidad es incorrecta para ingresar al Pañol';
    }
    return errors;
};

export const patentValidation = (value) => {
    const errors = {};

    const regExpPatentNew = (/^[A-ZÑ]{2}[\d]{3}[A-ZÑ]{2}$/);
    const regExpPatentOld = (/^[A-ZÑ]{3}\d{3}$/);

    if (!regExpPatentNew.test(value.patent) && !regExpPatentOld.test(value.patent)) {
        errors.patent = 'Debe Ingresar una patente válida (AB123CD) o (ABC123)';
    }
    if (!value.patent) {
        errors.patent = 'Requerido';
    }

    return errors;
};


//EMPLOYEES
export const validateDocumentNumber = [required(), minLength(6), maxLength(8)];
export const validateCuil = [required(), number(), minLength(9), maxLength(11)];
//const validateCuil = regex(/^(20|23|27|30|33)([0-9]{9}|[0-9]{8}[0-9]{1})$/g, 'Debe Ingresar un CUIT/CUIL Valido');
export const validateFullName = [required(), minLength(2), maxLength(50)];
export const validateEmail = email();
export const validatePostalCode = [number(), minValue(1001), maxValue(9431)]; //CODIGOS POSTALES DE ARGENTINA

//SHIFTS
export const validateDecription = [required(), minLength(2), maxLength(50)];
export const requiredValidateHour = [required(), minValue(0), maxValue(23)];
export const requiredValidateMinutes = [required(), minValue(0), maxValue(59)];

//VEHICLES
export const requiredValidateKm = [minValue(0), maxLength(9)];
//export const validatePatentNew = regex(/^[A-ZÑ]{2}[\d]{3}[A-ZÑ]{2}$/, 'Debe Ingresar una patente valida (AB123CD)');
//export const validatePatentOld = regex(/^[A-ZÑ]{3}\d{3}$/, 'Debe Ingresar una patente valida (ABC123)');
//export const requiredValidatePatent = [required(), validatePatentNew];

//TODOS
export const requiredValidate = [required()];
export const validateNumber = [number()];
export const validationLength = [minLength(2), maxLength(50)];
export const validateValue = [minValue(0), maxValue(9999999)];
export const requiredValidateLength = [required(), minLength(2), maxLength(50)];
export const requiredValidateValue = [required(), minValue(0), maxValue(999999999)];
export const requiredValidateNumber = [required(), number()];
export const requiredValidateInternal = [required(), minValue(1), maxValue(9000)];

//
export const requiredDaysInWorkshopHour = [required(), minValue(1), maxValue(365)];
export const requiredValidateValueWithoutZero = [required(), minValue(1), maxValue(999999999)];

//INVOICES
export const requiredValidateLengthInvoice = [required(), number(), minLength(1), maxLength(8)];
export const requiredValidatePointOfSale = [required(), number(), minLength(1), maxLength(5)];

export const validateVoucher = [number(), minLength(1), maxLength(10)];
export const validateAmount = [minValue(0), maxValue(99999999)];
