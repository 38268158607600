import * as React from 'react';
import {
    Create,
    TabbedForm,
    TextInput,
    FormTab,
} from 'react-admin';
import {
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase
} from '../utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import CustomToolbar from '../components/CustomToolbar';
import ImageCategoryInput from './imageCategory/ImageCategoryInput';
import categoryStyle from './categoryStyle';

const useStyles = makeStyles(categoryStyle);

const CategoryCreate = (props) => {
    const classes = useStyles();
    const redirect = `/categories`;

    return (
        <Create {...props}>
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <FormTab 
                    label="General" 
                >
                    <TextInput
                        autoFocus
                        label="Nombre"
                        source="name"
                        validate={requiredValidate}
                        parse={toUpperCase}
                        formClassName={classes.name}
                    />
                    <TextInput
                        label="Descripción"
                        source="description"
                        parse={toUpperCase}
                        formClassName={classes.description}
                        rows={5}
                        multiline
                        resettable
                    />
                </FormTab>
                <FormTab
                    label="Foto Categoria"
                    path="image"
                    contentClassName={classes.image}
                >
                    <ImageCategoryInput />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default CategoryCreate;
