
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import NumberFormatCustom from '../components/NumberFormatCustom';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
        maxWidth: 150,
    },
}));

const ItemPriceInput = ({ product, changePrice }) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} size="small">
            <TextField
                id="item-price"
                label="Precio"
                value={product.price}
                name="price"
                size="small"
                variant="filled"
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: { style: { textAlign: 'right' } }
                }}
                onChange={(e) => changePrice(product, e)}
            />
        </FormControl>
    )
}

export default ItemPriceInput;