import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import ShiftList from './ShiftList';
import ShiftCreate from './ShiftCreate';
import ShiftEdit from './ShiftEdit';

export default {
  list: ShiftList,
  create: ShiftCreate,
  edit: ShiftEdit,
  icon: AccessAlarmsIcon
};
