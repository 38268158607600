import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import EmployeeField from '../components/EmployeeField';
import ConfirmConsumableButton from './ConfirmConsumableButton';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles({
    container: { minWidth: '35em', marginLeft: '0em' },
    boldCell: { fontWeight: 'bold' },
    cell: { border: '1px solid #ccc' },
    headerCell: { fontWeight: 'bold', border: '1px solid #ccc' },
});

const ConsumableDesktopLines = ({ record }) => {
    const classes = useStyles();
    if (!record || !record.items || !record.items.length) {
        return (
            <Paper className={classes.container} elevation={2}>
                <p>No hay órdenes de trabajo asociadas a este consumible.</p>
            </Paper>
        );
    }

    return (
        <Paper className={classes.container} elevation={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headerCell}>Unidades Solicitadas</TableCell>
                        <TableCell className={classes.headerCell}>Unidades Terminadas</TableCell>
                        <TableCell className={classes.headerCell}>Empleado Asignado</TableCell>
                        <TableCell className={classes.headerCell}>Fecha de Confirmación</TableCell>
                        <TableCell className={classes.headerCell}>Usuario Confirmador</TableCell>
                        <TableCell className={classes.headerCell}>Confirmar Unidades Terminadas</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell className={classes.cell}>
                                {item.itemQuantity}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {item.itemQuantityUsed}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {item.employeeId ? <EmployeeField id={item.employeeId} /> : 'NO ASIGNADO'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {item.dateTimeConfirmed ? <DateFieldFormatted record={item} source="dateTimeConfirmed" /> : 'NO CONFIRMADO'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {item.dateTimeConfirmed ? item.userNameConfirmed : 'NO CONFIRMADO'}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {item.dateTimeConfirmed ? 'CONFIRMADO' : <ConfirmConsumableButton workOrder={record} item={item} />}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default ConsumableDesktopLines;
