import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SupplierList from './SupplierList';
import SupplierCreate from './SupplierCreate';
import SupplierEdit from './SupplierEdit';

export default {
  list: SupplierList,
  create: SupplierCreate,
  edit: SupplierEdit,
  icon: PermContactCalendarIcon
};
