import * as React from 'react';
import {
    Create,
    TabbedForm,
    FormTab,
    useNotify,
    usePermissions,
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import ItemForm from './ItemForm';
import ImageItemInput from './imagenItem/ImageItemInput';
import { 
    getRolesOnlySupervisorExceptAdmin, 
    getRolesWorkshopOnly,
    getRolesOnlyStorekeeperExceptAdmin
 } from '../appRolesComponents';

const ItemCreate = (props) => {
    const notify = useNotify();
    const redirect = `/items`;
    const { permissions } = usePermissions();

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions) || getRolesOnlyStorekeeperExceptAdmin(permissions);
    
    if (!isWorkshopOnly) return null;

    const transform = (data) => {
        return {
            ...data,
            warehouseQuantity: 0,
        };
    };

    return (
        <Create 
            {...props}
            onFailure={onFailure}
            transform={transform}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <FormTab label="General" >
                    <ItemForm />
                </FormTab>
                <FormTab
                    label="Foto Articulo"
                    path="image"
                >
                    <ImageItemInput />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default ItemCreate;
