
import React from 'react';
import {
    useGetList,
    LinearProgress
} from 'react-admin';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 300,
    },
}));

const EmployeesSelectInput = ({ product, employees, addEmployeeToItem }) => {
    const classes = useStyles();
    const { data, loaded, error } = useGetList(
        'employees',
        { page: 1, perPage: 100 },
        { field: 'fullName', order: 'ASC' },
    );

    if (!loaded) return <LinearProgress />;
    if (error) { return <p>ERROR</p>; }

    const choices = employees.map((employee) => ({
        id: data[employee].id,
        name: data[employee].fullName,
    }));

    return (
        <FormControl className={classes.formControl} size="small">
            <InputLabel id="simple-select-label">&nbsp;Empleado</InputLabel>
            <Select
                labelId="simple-select-label"
                id="simple-select"
                value={product.employeeId}
                onChange={(e) => addEmployeeToItem(product, e)}
                variant="filled"
            >
                {choices.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default EmployeesSelectInput;