import * as React from 'react';
import { Fragment } from 'react';
import {
    SaveButton,
    Toolbar,
    usePermissions
} from 'react-admin';
import { dateParseDateTime } from '../utils/functions';
import { makeStyles } from '@material-ui/core/styles';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
//import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { getRolesWorkshopOnly, getRolesOnlyInspectorExceptAdmin } from '../appRolesComponents';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const OrderEditToolbar = ({
    handleSubmitWithRedirect,
    invalid,
    record,
    saving,
    loading
}) => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);

    const redirect = (isInspector || isWorkshopOnly) ? 'orders' : 'baskets';

    if (!record) return null;
    return (
        <Toolbar className={classes.root}>
            {record.workOrderStatus === 'NADA' ? (
                <Fragment>

                </Fragment>
            ) : (
                <Fragment>
                    {(record.workOrderStatus === 'PENDIENTE' || record.workOrderStatus === 'EN_PROGRESO') &&
                        <SaveButton
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            redirect={redirect}
                            submitOnEnter={true}
                            disabled={loading}
                        />
                    }
                    {(record.workOrderStatus === 'PENDIENTE') && (isInspector || isWorkshopOnly) &&
                        <SaveButton
                            label='pos.canceled'
                            handleSubmitWithRedirect={
                                () => {
                                    if (!window.confirm('¿Esta Seguro que desea cancelar la OT?'))
                                        return false;
                                    return handleSubmitWithRedirect();
                                }
                            }
                            invalid={invalid}
                            saving={saving}
                            icon={<ThumbDown
                                color="primary"
                                style={{ color: 'red' }}
                            />}
                            redirect="orders"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    workOrderStatus: 'CANCELADA',
                                    deletedAt: dateParseDateTime(Date.now())
                                }
                            )}
                            disabled={loading}
                        />
                    }
                    {(record.workOrderStatus === 'PENDIENTE') && (isInspector || isWorkshopOnly) &&
                        <SaveButton
                            label="pos.save_and_start_ot"
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            icon={<ThumbUp
                                color="primary"
                                style={{ color: 'grey' }}
                            />}
                            redirect="orders"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    workOrderStatus: 'EN_PROGRESO'
                                }
                            )}
                            disabled={loading}
                        />
                    }
                    {/* {(record.workOrderStatus === 'EN_PROGRESO') && (isInspector || isWorkshopOnly) &&
                        <SaveButton
                            label='pos.save_and_end_ot'
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            icon={<CheckBoxIcon
                                color="primary"
                                style={{ color: 'grey' }}
                            />}
                            redirect="orders"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    workOrderStatus: 'FINALIZADA',
                                    end: dateParseDateTime(Date.now())
                                }
                            )}
                            disabled={loading}
                        />
                    }                    
                    {(record.workOrderStatus === 'EN_PROGRESO' && isWorkshopOnly) &&
                        <SaveButton
                            label='pos.save_and_end_ot_delivered'
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            icon={<CheckBoxIcon
                                color="primary"
                                style={{ color: 'grey' }}
                            />}
                            redirect="orders"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    workOrderStatus: 'FINALIZADA_ENTREGAR',
                                    end: dateParseDateTime(Date.now())
                                }
                            )}
                            disabled={loading}
                        />
                    }
                    {(record.workOrderStatus === 'FINALIZADA' && isWorkshopOnly) &&
                        <SaveButton
                            label='pos.save_and_end_ot_delivered'
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            icon={<CheckBoxIcon
                                color="primary"
                                style={{ color: 'grey' }}
                            />}
                            redirect="orders"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    workOrderStatus: 'FINALIZADA_ENTREGAR',
                                    end: dateParseDateTime(Date.now())
                                }
                            )}
                            disabled={false} // porque no tiene autocomplete component
                        />
                    } */}
                    {(record.workOrderStatus === 'FINALIZADA_ENTREGAR' && isWorkshopOnly && record.deliveryDate === null) &&
                        <SaveButton
                            label='pos.delivered'
                            handleSubmitWithRedirect={handleSubmitWithRedirect}
                            invalid={invalid}
                            saving={saving}
                            icon={<ReceiptIcon
                                color="primary"
                                style={{
                                    color: '#ff9800',
                                    borderColor: '#ff9800',
                                }}
                            />}
                            style={{
                                color: '#ff9800',
                                borderColor: '#ff9800'
                            }}
                            redirect="orders"
                            submitOnEnter={false}
                            variant="outlined"
                            transform={data => (
                                {
                                    ...data,
                                    deliveryDate: dateParseDateTime(Date.now())
                                }
                            )}
                            disabled={false} // porque no tiene autocomplete component
                        />
                    }
                </Fragment>
            )}
        </Toolbar>
    );
};

export default OrderEditToolbar;
