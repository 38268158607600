import * as React from 'react';
import {
    Datagrid,
    TextField,
    NumberField,
    ChipField,
    usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import OrderLinkField from './OrderLinkField';
import InspectionField from './InspectionField';
import rowStyle from './rowStyle.js'
import { getRolesWorkshopOnly } from '../appRolesComponents';
import DateFieldFormatted from '../components/DateFieldFormatted';
import Chip from '@material-ui/core/Chip';
import ActionButtonsField from './components/ActionButtonsField';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    total: { fontWeight: 'bold' },
});

const getValue = (record, source) => {
    if (!record || !source) return null;
    return source.split('.').reduce((acc, part) => acc && acc[part], record);
};

const CustomChipField = ({ record, source, label, ...rest }) => {
    const value = getValue(record, source);

    if (!value) {
        return null;
    }

    return (
        <Chip
            label={value}
            size="small"
            variant="outlined"
            {...rest}
        />
    );
};

const OrderListDesktop = (props) => {
    const classes = useListStyles();
    const { permissions } = usePermissions();
    const isWorkshopOnly = getRolesWorkshopOnly(permissions);

    return (
        <Datagrid
            optimized
            rowStyle={rowStyle()}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            {...props}
        >
            <OrderLinkField
                sortable={false}
            />
            <TextField
                label="Patente"
                source="vehicle.patent"
                sortable={false}
            />
            <TextField
                label="Linea"
                source="vehicle.line.name"
                sortable={false}
            />
            <TextField
                label="Interno"
                source="vehicle.internal"
                sortable={false}
            />
            <CustomChipField
                label="Canasto"
                source="basket.name"
                size="small"
                variant="outlined"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Inicio"
                source="start"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Fin"
                source="end"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Entrega"
                source="deliveryDate"
                sortable={false}
            />
            <NumberField
                label="Dias"
                source="daysInWorkshop"
                sortable={false}
            />
            {(isWorkshopOnly) && (
                <NumberField
                    label="Total"
                    source="total"
                    locales="es-AR"
                    options={{ style: 'currency', currency: 'ARS' }}
                    cellClassName={classes.total}
                />
            )}
            <ChipField
                label="Estado"
                source="workOrderStatus"
                size="small"
                sortable={false}
            />
            <InspectionField
                sortable={false}
            />

            {isWorkshopOnly && <ActionButtonsField />}

        </Datagrid>
    );
};

export default OrderListDesktop;
