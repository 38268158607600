import * as React from 'react';
import {
    TextField,
    EditButton,
    useTranslate,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const EmployeeMobileGrid = ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();

    const urlImageBuilder = (id) => {
        return `https://api.marasa.com.ar/rrhh-api/api/imageProfile/${id}/download`;
    }

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div className={classes.name}>
                                    <Avatar
                                        src={urlImageBuilder(data[id].id)}
                                    />&nbsp;&nbsp;
                                    <h6>{`${data[id].fullName}`}</h6>
                                </div>
                                <EditButton
                                    resource="employees"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        subheader={
                            <div>
                                <div>
                                    {translate(
                                        'resources.employees.fields.start_date'
                                    )}
                                    :&nbsp;
                                    <DateFieldFormatted
                                        record={data[id]}
                                        source="startDate"
                                        showTime={false}
                                    />
                                </div>
                                <div>
                                    {translate(
                                        'resources.employees.fields.end_date'
                                    )}
                                    :&nbsp;
                                    <DateFieldFormatted
                                        record={data[id]}
                                        source="end"
                                        showTime={false}
                                    />
                                </div>
                            </div>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <div>
                            {translate(
                                'resources.employees.fields.documentNumber'
                            )}
                            :&nbsp;
                            {data[id].documentNumber}
                        </div>
                        <div>
                            {translate(
                                'resources.employees.fields.role'
                            )}
                            :&nbsp;
                            <TextField
                                record={data[id]}
                                source="role"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.employees.fields.cellphone'
                            )}
                            :&nbsp;
                            <TextField
                                record={data[id]}
                                source="cellphone"
                            />
                        </div>
                        <br />
                        <div>
                            {translate(
                                'resources.employees.fields.emergencyFullName'
                            )}
                            :&nbsp;
                            <TextField
                                record={data[id]}
                                source="emergencyFullName"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.employees.fields.emergencyTelephone'
                            )}
                            :&nbsp;
                            <TextField
                                record={data[id]}
                                source="emergencyTelephone"
                            />
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default EmployeeMobileGrid;
