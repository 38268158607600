import * as React from 'react';
import {
    Edit,
    SimpleForm,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import { patentValidation } from '../validators/validators';
import VehicleForm from './VehicleForm';

const VehicleEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/vehicles`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const VehicleTitle = ({ record }) => {
        return <span>{record ? `${record.patent}` : ''}</span>;
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<VehicleTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
                validate={patentValidation}
            >
                <VehicleForm />
            </SimpleForm>
        </Edit>
    );
};


export default VehicleEdit;
