import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useDataProvider,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
});

const DocumentFileView = ({ record }) => {
    const classes = useStyles();
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();

    const orderId = record.id;
    const fetchDocument = useCallback(async () => {
        const apiUrl = `https://api.marasa.com.ar/finance-api/api/purchases/document/${orderId}`;
        const response = await fetch(apiUrl).catch((error) =>
            console.log('Falló el finance-api', error)
        );

        if (response.ok) {
            const documentList = await response.json();
            setState(documentList.data);
        }

    }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchDocument();
    }, [fetchDocument]);

    let urlDocument = `https://api.marasa.com.ar/finance-api/api/purchases/document/${orderId}/download`;

    return (
        <div>
            {(state !== undefined && state.length > 0) ?
                <Card className={classes.root}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {'Nombre de archivo'}
                                </TableCell>
                                <TableCell>
                                    {'Fecha de última modificación'}
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{state[0].name}</TableCell>
                                <TableCell>{moment(state[0].lastUpdate).format('YYYY-MM-DD HH:mm')}</TableCell>
                                <TableCell>
                                    <Link href={urlDocument}> Descargar</Link>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
                :
                ''
            }
        </div>
    );
}
export default DocumentFileView;
