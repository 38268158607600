import React from 'react';
import {
    LinearProgress,
    useGetOne,
} from 'react-admin';
import TextField from '@material-ui/core/TextField';

const BusTemplateInput = ({ id }) => {
    const { data, loaded } = useGetOne('busTemplate', id);
    if (!loaded) return <LinearProgress />;
    localStorage.setItem("busTemplate", id);

    return (
        <TextField
            id="busTemplate"
            name="busTemplate"
            label="Plantilla"
            value={data.name}
            disabled={true}
            variant="filled"
            size="small"
        />
    );
}

export default BusTemplateInput;