import React, { useState } from 'react';
import { List, usePermissions, ResourceContextProvider, SearchInput, Filter, ReferenceInput, SelectInput } from 'react-admin';
import LabelListDesktop from './LabelListDesktop';
import BulkPDFButton from './BulkPDFButton';
import { getRolesWorkshopOnly, getRolesOnlySupervisorExceptAdmin, getRolesOnlyStorekeeperExceptAdmin } from '../appRolesComponents';
import { makeStyles } from '@material-ui/core/styles';
import { useListContext } from 'react-admin';
import { useEffect, useRef } from 'react';
import { FormHelperText } from '@material-ui/core';

const useFilterStyles = makeStyles({
    filterToolbar: {
        position: 'relative',
        zIndex: 1,
        marginBottom: '46px',
    },
});

const ListFilters = (props) => {
    const classes = useFilterStyles();
    const { setFilters } = useListContext();
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFilters({ q: '' });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setFilters]);

    return (
        <div>
            <Filter {...props} className={classes.filterToolbar}>
                <SearchInput
                    placeholder="Buscar por código o nombre"
                    parse={(value) => value.toUpperCase()}
                    source="q"
                    alwaysOn
                    autoFocus
                    resettable
                    fullWidth
                    ref={inputRef}
                />
                <ReferenceInput
                    label="Busque por categoría"
                    source="categoryId"
                    reference="categories"
                    perPage={40}
                    fullWidth
                    resettable
                    alwaysOn
                    className="custom-filter-width"
                >
                    <SelectInput
                        optionText={(record) =>
                            record && record.name
                                ? `${record.name}`
                                : (record && record.name ? record.name : 'TODOS')
                        }
                        emptyText="TODOS"
                        choices={[{ id: '', name: 'TODOS' }]}
                    />
                </ReferenceInput>
            </Filter>
            <FormHelperText><span role="img" aria-label="icono de limpiar">⌨️</span> Presione la tecla Escape (esc) para limpiar la búsqueda</FormHelperText>
        </div>
    );
};

const LabelList = (props) => {
    const { permissions } = usePermissions();
    const [quantities, setQuantities] = useState({});
    const [selectedIdsGlobal, setSelectedIdsGlobal] = useState([]);

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) ||
        getRolesOnlySupervisorExceptAdmin(permissions) ||
        getRolesOnlyStorekeeperExceptAdmin(permissions);
    if (!isWorkshopOnly) return null;

    return (
        <ResourceContextProvider value="items">
            <List
                syncWithLocation
                title="Etiquetas"
                basePath="/items"
                actions={false}
                filters={<ListFilters />}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={25}
                bulkActionButtons={
                    <BulkPDFButton
                        selectedIds={selectedIdsGlobal}
                        quantities={quantities}
                    />
                }
            >
                <LabelListDesktop
                    setQuantities={setQuantities}
                    selectedIds={selectedIdsGlobal}
                    setSelectedIds={setSelectedIdsGlobal}
                />
            </List>
        </ResourceContextProvider>
    );
};

export default LabelList;
