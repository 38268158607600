import React from 'react';
import {
    FunctionField,
    useGetOne,
    TextField,
    LinearProgress,
    BooleanField
} from 'react-admin';

import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EmployeeField from '../components/EmployeeField';
import VehicleField from '../components/VehicleField';
import InternalField from '../components/InternalField';

const InspectionDetailsField = ({ id }) => {
    const { data, loaded, error } = useGetOne('inspections', id);
    if (data === undefined) return <p>Error, se debe crear la OT desde el modulo de inspecciones!</p>;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!loaded) return <LinearProgress />;
    return (
        <div>
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'Insp.'}
                            </TableCell>
                            <TableCell>
                                {'Fecha Insp.'}
                            </TableCell>
                            <TableCell>
                                {'Inspector'}
                            </TableCell>
                            <TableCell>
                                {'Patente'}
                            </TableCell>
                            <TableCell>
                                {'Interno'}
                            </TableCell>
                            <TableCell>
                                {'Chocado'}
                            </TableCell>
                            <TableCell>
                                {'Pintura Completa'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <FunctionField
                                    record={data}
                                    render={record => `IN-${record.id}`}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    record={data}
                                    source="start"
                                />
                            </TableCell>
                            <TableCell>
                                <EmployeeField
                                    id={data.employeeId}
                                />
                            </TableCell>
                            <TableCell>
                                <VehicleField
                                    id={data.vehicleId}
                                />
                            </TableCell>
                            <TableCell>
                                <InternalField
                                    id={data.vehicleId}
                                />
                            </TableCell>
                            <TableCell>
                                <BooleanField
                                    record={data}
                                    source="crashed"
                                />
                            </TableCell>
                            <TableCell>
                                <BooleanField
                                    record={data}
                                    source="completePaint"
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
            <br />
        </div>
    );
}
export default InspectionDetailsField;