import * as React from 'react';
import {
    EditButton,
    useTranslate,
    ReferenceField,
    ChipField,
    usePermissions
} from 'react-admin';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import Tooltip from '@material-ui/core/Tooltip';
import EmployeeField from '../components/EmployeeField';
import InternalField from '../components/InternalField';
import { getRolesOnlyDriverExceptAdmin, getRolesOnlySupervisorExceptAdmin } from '../appRolesComponents';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

const InspectionMobileGrid = ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();

    const { permissions } = usePermissions();

    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isSupervisor = getRolesOnlySupervisorExceptAdmin(permissions);

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div>{`IN-${data[id].id}`}</div>
                                <EditButton
                                    resource="inspections"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                                {(!isDriver && !isSupervisor) &&
                                    <Tooltip title="Crear OT">
                                        <IconButton
                                            aria-label="delete"
                                            className={classes.margin}
                                            component={Link}
                                            color="primary"
                                            to={{
                                                pathname: "/orders/create",
                                                search: `?id=${data[id].id}&employeeId=${data[id].employeeId}`,
                                            }}
                                        >
                                            <BookmarksIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                        }
                        subheader={
                            <div>
                                <div>
                                    {translate('resources.inspections.fields.patent')}
                                    :&nbsp;
                                    <ReferenceField
                                        record={data[id]}
                                        source="vehicleId"
                                        reference="vehicles"
                                        link={false}
                                    >
                                        <ChipField
                                            size="small"
                                            source="patent"
                                        />
                                    </ReferenceField>
                                </div>
                                <div>
                                    {translate('resources.inspections.fields.internal')}
                                    :&nbsp;
                                    <InternalField
                                        id={data[id].vehicleId}
                                    />
                                </div>
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                            {translate('resources.inspections.fields.dateInspection')}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="start"
                                showTime={true}
                            />
                        </div>
                        <div>
                            {translate('resources.inspections.fields.inspector')}
                            :&nbsp;
                            <EmployeeField
                                id={data[id].employeeId}
                            />
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};


export default InspectionMobileGrid;
