import React from 'react';
import {
    FormDataConsumer
} from 'react-admin';
import { Card, CardHeader } from '@material-ui/core';
import AutocompleteDetail from './AutocompleteDetail';

const PurchaseInvoiceFormDetail = ({ formData, handleLoading, ...props }) => {

    return (
        <FormDataConsumer>
            {({ formData }) => (formData.supplierId !== undefined && formData.invoiceType !== undefined) ?
                <AutocompleteDetail formData={formData}  {...props} handleLoading={handleLoading}/>
                :
                <Card key={0}>
                    <CardHeader
                        title={
                            <div>
                                Por favor, selecciona un proveedor y el tipo de comprobante y luego podrás cargar los conceptos facturados.
                            </div>
                        }
                    />
                </Card>
            }
        </FormDataConsumer>
    );
};

export default PurchaseInvoiceFormDetail;
