import * as React from 'react';
import {
    List,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import ExtraHourListMobile from './ExtraHourListMobile';
import ExtraHourListDesktop from './ExtraHourListDesktop';

const ExtraHourList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={25}
            bulkActionButtons={false}
            title="Horas extras"
        >
            {isXsmall ? (
                <ExtraHourListMobile />
            ) : (
                <ExtraHourListDesktop />
            )}
        </List>
    );

};

export default ExtraHourList;
