const choices = [
    {
        id: 'BUENOS AIRES',
        name: 'BUENOS AIRES'
    },
    {
        id: 'CABA',
        name: 'CABA'
    },
];

export default choices
