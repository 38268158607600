import React from 'react';
import {
    calculateTotalPaymentsPurchases,
    calculateTotalReceipts,
    currencyFormatter
} from '../utils/functions';
import { useForm } from 'react-final-form';
import SectionTitle from '../components/SectionTitle';
import { Box, Typography } from '@material-ui/core';
import { TextInput } from 'react-admin';

const PaymentFootDetail = ({ id, methods, purchasesPayments }) => {
    const form = useForm();

    const methodsData = (id === undefined) ? JSON.parse(localStorage.getItem("methods")) : methods;
    const total = (methodsData !== null) ? calculateTotalReceipts(methodsData) : 0;
    const totalPurchases = (purchasesPayments !== undefined || purchasesPayments !== null) ? calculateTotalPaymentsPurchases(purchasesPayments) : 0;
    const totalDifferent = total - totalPurchases;

    form.change("total", total);

    return (
        <div>
            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Abonado" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(total)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Diferencia" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(totalDifferent)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            
            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}> 
                <TextInput
                    source="total"
                    defaultValue={total}
                    disabled={true}
                    fullWidth
                />
            </div>
        </div>

    );
};

export default PaymentFootDetail;
