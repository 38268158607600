import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import keyBy from 'lodash/keyBy';
import {
    Datagrid,
    TextField,
    Loading,
    ChipField,
} from 'react-admin';
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ItemsExport from './VehiclesExport';

const useStyles = makeStyles({
    root: {
        marginBottom: '1em',
        float: "right"

    }
});

const VehiclesDatagrid = ({ values }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'id', order: 'DESC' });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const classes = useStyles();

    const dataProvider = useDataProvider();

    useEffect(() => {
        setLoading(true);
        const customerId = values.customerId === '' ? undefined : values.customerId;
        const lineId = values.lineId === '' ? undefined : values.lineId;

        dataProvider.getList('vehicles', {
            pagination: { page, perPage: 99999 },
            sort,
            filter: { customerId: customerId, lineId: lineId }
        })
            .then(async ({ data }) => {
                setData(data);
                setLoading(false);
                setPage(page);
                setPerPage(perPage);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [page, perPage, sort, values.customerId, dataProvider, values.lineId]);

    if (loading) { return <Loading /> }
    if (error) { return <p>ERROR: {error.message}</p> }
    if (data.length === 0) { return <p>No hay resultados</p> }

    return (
        <Fragment>
            <div className={classes.root}>
                <ItemsExport dataExport={data} />
            </div>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({ id }) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({ field, order })}
                optimized
            >
                <TextField
                    label="Patente"
                    source="patent"
                    sortable={false}
                />
                <TextField
                    label="Cliente"
                    source="customer.name"
                    sortable={false}
                />

                <TextField
                    label="Linea de Colectivo"
                    source="busLine.name"
                    sortable={false}
                />
                <TextField
                    label="Interno"
                    source="internal"
                    sortable={false}
                />
                <TextField
                    label="Marca"
                    source="brand.name"
                    sortable={false}
                />
                <TextField
                    label="Modelo"
                    source="model"
                    sortable={false}
                />
                <TextField
                    label="Chasis"
                    source="chassis"
                    sortable={false}
                />
                <TextField
                    label="Color"
                    source="color"
                    sortable={false}
                />

                <ChipField
                    label="Plantilla a Dibujar"
                    size="small"
                    source="busTemplateObject.name"
                    sortable={false}
                />
            </Datagrid>
        </Fragment>
    );
}

export default VehiclesDatagrid;
