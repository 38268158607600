import React from 'react';
import {
    calculateTotal,
    currencyFormatter
  } from '../utils/functions';
  import { useForm } from 'react-final-form';
import SectionTitle from '../components/SectionTitle';
import { Box, Typography } from '@material-ui/core';
import { TextInput } from 'react-admin';

const PurchaseFootDetail = ({ products }) => {
    const form = useForm();

    const total = (products !== undefined) ? calculateTotal(products) : 0;
    const totalWithIVA = total * 1.21;

    form.change("total", total);
    form.change("totalWithIVA", totalWithIVA);

    return (
        <div>
            <Box display={{ xs: 'block', sm: 'flex' }}>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(total)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total (IVA)" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(totalWithIVA)}
                        </Typography>
                    </Box>
                </Box>

            </Box>
            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}> 
                <TextInput
                    source="total"
                    defaultValue={total}
                    disabled={true}
                    fullWidth
                />            
            </div>
        </div>

    );
};

export default PurchaseFootDetail;
