import React, { useState, useEffect } from 'react';
import {
  useDataProvider,
  LinearProgress,
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import { Box, CardContent, useMediaQuery } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CartItems from './CartItems';
import CartItemsMobileGrid from './CartItemsMobileGrid';
import SectionTitle from '../components/SectionTitle';
import PurchaseFootDetail from './PurchaseFootDetail';

const AutocompleteItemsSearch = ({ formData, handleLoading, ...props }) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const value = '';
  const [products, setProducts] = useState([]);
  const [purchaseCart, setPurchaseCart] = useState([]);

  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  useEffect(() => {
    handleLoading(true); // Indicate loading started
    dataProvider
      .getList('items', {
        pagination: { page: 1, perPage: 999999 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
      })
      .then(({ data }) => {
        setProducts(data);
        if (props.id !== undefined) {
          let itemsOrder = formData.items;
          dataProvider.getMany('items', {
            ids: itemsOrder.map((item) => item.id),
          })
            .then(response => {
              let items = response.data;
              //CRUCE DE 2 OBJETOS POR ID Y PUSE PRIMERO EL "t2" PARA TOMAR EL PRICE DE OC
              const result = itemsOrder.map(t1 => ({ ...items.find(t2 => t2.id === t1.id), ...t1 }));
              setPurchaseCart(result);
              localStorage.setItem("purchaseCart", JSON.stringify(result));//TODO--VERIFICAR
              setLoading(false);
              handleLoading(false); // Actualiza el estado de loading
            });

        } else {
          if (JSON.parse(localStorage.getItem("purchaseCart"))) {
            setPurchaseCart(JSON.parse(localStorage.getItem("purchaseCart")));
          }
          setLoading(false);
          handleLoading(false); // Actualiza el estado de loading
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        handleLoading(false); // Actualiza el estado de loading
      });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const saveCartToLocalStorage = (cart) => {
    localStorage.setItem(`purchaseCart`, JSON.stringify(cart));
  };

  const addToCart = (item) => {
    const productList = [...purchaseCart];
    let found = false;
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            found = true;
            break;
          }
        }
      }
      if (!found) {
        productList.push(item);
      }
      setPurchaseCart(productList);
      saveCartToLocalStorage(productList);
    }
  }

  const deleteToCard = (item) => {
    const productList = [...purchaseCart];
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            productList.splice(i, 1);
            break;
          }
        }
      }
      setPurchaseCart(productList);
      saveCartToLocalStorage(productList);
    }
  }

  const changeQuantity = (item, e) => {
    const productList = [...purchaseCart];
    const index = productList.indexOf(item);
    productList[index].itemQuantity = (e.target.value === '' || e.target.value === 0) ? 1 : parseFloat(e.target.value);
    setPurchaseCart(productList);
    saveCartToLocalStorage(productList);
  }

  const changePrice = (item, e) => {
    const productList = [...purchaseCart];
    const index = productList.indexOf(item);
    productList[index].price = e.target.value === '' ? 0 : parseFloat(e.target.value);
    setPurchaseCart(productList);
    saveCartToLocalStorage(productList);
  }

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!products) return null;

  const choices = products.map((item) => ({
    id: item.id,
    code: item.code,
    name: item.name,
    itemQuantity: 1,
    quantity: item.quantity,
    warehouseQuantity: item.warehouseQuantity,
    priceLast: parseFloat(item.price),
    price: parseFloat(item.price)
  }));

  return (
    <CardContent>
      <Box display={{ md: 'block', lg: 'flex' }}>
        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
          <SectionTitle label="Busqueda de Articulos" />
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <Autocomplete
                id="combo-box"
                options={choices}
                getOptionLabel={(option) => option.name && option.code ? `${option.name} (${option.code})` : option.name}
                value={value}
                onChange={(event, newValue) => {
                  addToCart(newValue)
                }}
                size="small"
                style={{ maxWidth: 600 }}
                renderInput={
                  (params) => <TextField {...params} label="Buscar Articulo" variant="filled" autoFocus />
                }
              />
              <br></br>

              {isXsmall ? (
                <CartItemsMobileGrid
                  id={props.id}
                  products={purchaseCart}
                  changeQuantity={changeQuantity}
                  changePrice={changePrice}
                  deleteToCard={deleteToCard}
                />
              ) : (
                <CartItems
                  id={props.id}
                  products={purchaseCart}
                  changeQuantity={changeQuantity}
                  changePrice={changePrice}
                  deleteToCard={deleteToCard}
                />
              )}
            </Box>
          </Box>

          <Box mt="1em" />

          <PurchaseFootDetail products={purchaseCart} />

        </Box>
      </Box>
    </CardContent>
  );
}

export default AutocompleteItemsSearch;
