const choices = [
    {
        id: 'GANANCIAS',
        name: 'GANANCIAS'
    },
    {
        id: 'INGRESOS BRUTOS',
        name: 'INGRESOS BRUTOS'
    },
    {
        id: 'IVA',
        name: 'IVA'
    }

];

export default choices
