import React, {
  useRef,
  useEffect,
  useState
} from 'react';
import useLocalStorage from 'react-use-localstorage';
import CanvasDraw from 'react-canvas-draw';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import UndoIcon from '@material-ui/icons/Undo';

const DrawImage = (props) => {
  const firstCanvas = useRef(null);
  const [state, setState] = useLocalStorage('savedDrawing', null);
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    setImgSrc(`https://api.marasa.com.ar/workshop-api/api/busTemplate/${localStorage.getItem("busTemplate")}/download`);
    if (Object.keys(props.record).length !== 0 || props.record.constructor !== Object) {
      const drawImage = JSON.stringify(props.record.draw);
      const inspectionId = props.record.id;

      if (inspectionId === undefined) {
        setState(null);
        localStorage.removeItem('savedDrawing');
      }

      if (drawImage !== 'null' && inspectionId !== undefined) {
        firstCanvas.current.loadSaveData(drawImage);
        localStorage.setItem('savedDrawing', drawImage);
        setState(state);
      }
    }

  }, [props.record]); // eslint-disable-line react-hooks/exhaustive-deps
  // Solo se vuelve a suscribir si la propiedad props.record cambia

  const handleClick = () => {
    if (firstCanvas.current !== null) {
      const data = firstCanvas.current.getSaveData();
      localStorage.setItem('savedDrawing', data);
      setState(data);
    }
  }

  const clear = () => {
    firstCanvas.current.clear();
    localStorage.removeItem('savedDrawing');
    setState(null);
  }

  const undo = () => {
    firstCanvas.current.undo();
  }

  return (
    <div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={clear}
          style={{ margin: '10px' }}
        >
          <DeleteIcon />&nbsp;BORRAR
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={undo}
          style={{ margin: '10px' }}
        >
          <UndoIcon />&nbsp;DESHACER
        </Button>
      </div>
      <CanvasDraw
        ref={firstCanvas}
        onChange={handleClick}
        brushColor={'red'}
        brushRadius={1}
        lazyRadius={1}
        canvasWidth={860}
        canvasHeight={473}
        imgSrc={imgSrc}
        style={{ border: '1px solid #000' }}
      />
    </div>
  );
}

export default DrawImage;
