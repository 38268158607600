
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 250,
        maxWidth: 250,
    },
}));

const ItemCommentInput = ({ product, changeComment }) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} margin="dense" size="small">
            <TextField
                id="item-comment"
                name="itemComment"
                label="Comentario"
                size="small"
                value={product.itemComment}
                variant="filled"
                onChange={(e) => changeComment(product, e)}
                multiline
                resettable
            />
        </FormControl>
    )
}

export default ItemCommentInput;