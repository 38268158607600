import React from 'react';
import { Link } from 'react-admin';
import { voucherFormat } from '../utils/functions';

const ReceiptLinkField = (props) => {
    let numberVoucher = voucherFormat(props.record.id);
    return (
        <Link 
            to={`/receipts/${props.record.id}`} 
            onClick={() => localStorage.removeItem('invoicesReceipts')}
        >
            RECIBO-{numberVoucher}
        </Link>
    )
};

ReceiptLinkField.defaultProps = {
    source: 'id',
    label: 'Recibo Nro',
};

export default ReceiptLinkField;
