import AbsenceList from './AbsenceList';
import AbsenceEdit from './AbsenceEdit';
import AbsenceCreate from './AbsenceCreate';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
export default {
  list: AbsenceList,
  create: AbsenceCreate,
  edit: AbsenceEdit,
  icon: PersonAddDisabledIcon
};
