import React, { useState, useEffect } from 'react';
import { LinearProgress } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CardContent, Box, useMediaQuery } from '@material-ui/core';
import CartItems from './CartItems';
import CartItemsMobileGrid from './CartItemsMobileGrid';
import SectionTitle from '../components/SectionTitle';
import InvoiceFootDetail from './InvoiceFootDetail';

const AutocompleteDetail = ({ formData }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  // const [value, setValue] = React.useState();
  const value = '';
  const [orders, setOrders] = useState([]);
  const [invoiceCart, setInvoiceCart] = useState([]);
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const apiUrl = `https://api.marasa.com.ar/workshop-api/api/orders/internal/customer/${formData.customerId}`;
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      const ordersData = await response.json();
      const itemNew = {
        id: 0,
        name: `ABONO MENSUAL DE CARROCERIA`,
        total: 0
      }
      ordersData.unshift(itemNew) //LO AGREGA AL PRINCIPIO
      setOrders(ordersData);
      if (formData.id !== undefined) {
        setInvoiceCart(formData.operationInfo);
        setLoading(false);
      } else {
        if (JSON.parse(localStorage.getItem("invoiceCart"))) {
          setInvoiceCart(JSON.parse(localStorage.getItem("invoiceCart")));
        }
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addToCart = (item) => {
    const orderList = [...invoiceCart];
    let found = false;
    if (item !== null) {
      if (orderList.length > 0) {
        for (var i = 0; i < orderList.length; i++) {
          if (orderList[i].id === item.id) {
            found = true;
            break;
          }
        }
      }
      if (!found) {
        orderList.push(item);
      }
      setInvoiceCart(orderList);
      localStorage.setItem("invoiceCart", JSON.stringify(orderList));
    }
  }

  const deleteToCard = (item) => {
    const orderList = [...invoiceCart];
    if (item !== null) {
      if (orderList.length > 0) {
        for (var i = 0; i < orderList.length; i++) {
          if (orderList[i].id === item.id) {
            orderList.splice(i, 1);
            break;
          }
        }
      }
      setInvoiceCart(orderList);
      localStorage.setItem("invoiceCart", JSON.stringify(orderList));
    }
  }

  const changeQuantity = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemQuantity = e.target.value === '' ? 0 : parseFloat(e.target.value);
    orderList[index].itemQuantity = itemQuantity;
    setInvoiceCart(orderList);
    localStorage.setItem("invoiceCart", JSON.stringify(orderList));
  }

  const changePrice = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemPrice = e.target.value === '' ? 0 : parseFloat(e.target.value);
    orderList[index].price = itemPrice;
    setInvoiceCart(orderList);
    localStorage.setItem("invoiceCart", JSON.stringify(orderList));
  }

  const changeTax = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemTax = (e.target.value === 0 || e.target.value === '1') ? 1 : parseFloat(e.target.value) / 100;
    orderList[index].itemTax = itemTax;
    setInvoiceCart(orderList);
    localStorage.setItem("invoiceCart", JSON.stringify(orderList));
  }

  const changeComment = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemComment = e.target.value;
    orderList[index].itemComment = itemComment;
    setInvoiceCart(orderList);
    localStorage.setItem("invoiceCart", JSON.stringify(orderList));
  }

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!orders) return null;

  const choices = orders.map((order) => ({
    id: order.id,
    name: order.name,
    itemComment: '',
    itemQuantity: 1,
    price: (order.total === null) ? 0 : order.total,
    itemBonus: 0, //POR AHORA NO SE USA
    itemTax: 0.21,
    subtotal: 0.0, //POR AHORA NO SE USA
    unit: 0, //NO SE USA
    code: "0" //NO SE USA
  }));

  return (
    <CardContent>
      <Box display={{ md: 'block', lg: 'flex' }}>
        <Box flex={1} mr={{ md: 0, lg: '1em' }}>
          <SectionTitle label="Selección de Abono y/o Ordenes" />
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <Autocomplete
                id="combo-box"
                options={choices}
                getOptionLabel={(option) => option.name}
                value={value}
                onChange={(event, newValue) => {
                  addToCart(newValue)
                }}
                size="small"
                style={{ maxWidth: 300 }}
                renderInput={
                  (params) =>
                    <TextField
                      {...params}
                      label="Buscar Abono y/o Ordenes"
                      variant="filled"
                      margin="dense"
                      autoFocus
                    />
                }
              />
            </Box>
          </Box>

          <Box mt="1em" />

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              {
                isXsmall ? (
                  <CartItemsMobileGrid
                    products={invoiceCart}
                    changeQuantity={changeQuantity}
                    changePrice={changePrice}
                    changeTax={changeTax}
                    changeComment={changeComment}
                    deleteToCard={deleteToCard}
                  />
                ) : (
                  <CartItems
                    products={invoiceCart}
                    changeQuantity={changeQuantity}
                    changePrice={changePrice}
                    changeTax={changeTax}
                    changeComment={changeComment}
                    deleteToCard={deleteToCard}
                  />)
              }
            </Box>
          </Box>

          <Box mt="1em" />

          <InvoiceFootDetail products={invoiceCart} />

        </Box>
      </Box>
    </CardContent>
  );
}

export default AutocompleteDetail;
