import * as React from 'react';
import {
    TextField,
    LinearProgress,
    useGetOne,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import CustomerField from '../components/CustomerField';
import LineField from '../components/LineField';
import BrandField from '../components/BrandField';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        flexDirection: 'row',
        alignItems: 'left',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    }
}));

const VehicleMobileDetailsField = ({ id }) => {
    const classes = useStyles();
    const { data, loaded, error } = useGetOne('vehicles', id);
    if (!loaded) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!data) return null;
    return (
        <div className={classes.root}>
            <Card key={id} className={classes.card}>
                <CardHeader
                    title={
                        <div>
                            <div className={classes.cardTitleContent}>
                                {`Cliente: `}<CustomerField id={data.customerId} />
                            </div>
                            <div className={classes.cardTitleContent}>
                                {'Linea: '}<LineField id={data.lineId} />
                            </div>
                            <div className={classes.cardTitleContent}>
                                {'Interno: '}<TextField record={data} source="internal" />
                            </div>
                        </div>
                    }
                />
                <CardContent className={classes.cardContent}>
                    <div>
                        {'Marca'}
                        :&nbsp;
                        <BrandField id={data.brandId} />
                    </div>
                    <div>
                        {'Carrocería'}
                        :&nbsp;
                        <TextField record={data} source="chassis" />
                    </div>

                    <div>
                        {'Año'}
                        :&nbsp;
                        <TextField record={data} source="year" />
                    </div>
                    <div>
                        {'Modelo'}
                        :&nbsp;
                        <TextField record={data} source="model" />
                    </div>
                    <div>
                        {'Color'}
                        :&nbsp;
                        <TextField record={data} source="color" />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default VehicleMobileDetailsField;
