import React, { useState, useEffect } from 'react';
import { LinearProgress } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CardContent, Box, useMediaQuery } from '@material-ui/core';
import CartItems from './CartItems';
import CartItemsMobileGrid from './CartItemsMobileGrid';
import SectionTitle from '../components/SectionTitle';
import { voucherFormat } from '../utils/functions';
import PurchaseInvoiceFootDetail from './PurchaseInvoiceFootDetail';

const AutocompleteDetail = ({ formData, handleLoading, ...props }) => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const value = '';
  const [orders, setOrders] = useState([]);
  const [invoiceCart, setInvoiceCart] = useState([]);
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const url = `https://api.marasa.com.ar/finance-api/api/purchases/internal/supplier/${formData.supplierId}`;
  const fetchData = async () => {
    try {
      const response = await fetch(url);
      const ordersData = await response.json();
      const itemNew = {
        id: 0,
        name: `GENERICA`, //TODO: FALTA EL CAMPO NAME
        total: 0
      }
      ordersData.unshift(itemNew) //LO AGREGA AL PRINCIPIO
      setOrders(ordersData);
      if (props.id !== undefined) {
        setInvoiceCart(formData.operationInfo);
        handleLoading(false); // Actualiza el estado de loading
        setLoading(false);
      } else {
        if (JSON.parse(localStorage.getItem("purchasesInvoiceCart"))) {
          setInvoiceCart(JSON.parse(localStorage.getItem("purchasesInvoiceCart")));
        }
        handleLoading(false); // Actualiza el estado de loading
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      handleLoading(false); // Actualiza el estado de loading
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLoading(true); // Indicate loading started
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addToCart = (item) => {
    const orderList = [...invoiceCart];
    let found = false;
    if (item !== null) {
      if (orderList.length > 0) {
        for (var i = 0; i < orderList.length; i++) {
          if (orderList[i].id === item.id) {
            found = true;
            break;
          }
        }
      }
      if (!found) {
        orderList.push(item);
      }
      setInvoiceCart(orderList);
      localStorage.setItem("purchasesInvoiceCart", JSON.stringify(orderList));
    }
  }

  const deleteToCard = (item) => {
    const orderList = [...invoiceCart];
    if (item !== null) {
      if (orderList.length > 0) {
        for (var i = 0; i < orderList.length; i++) {
          if (orderList[i].id === item.id) {
            orderList.splice(i, 1);
            break;
          }
        }
      }
      setInvoiceCart(orderList);
      localStorage.setItem("purchasesInvoiceCart", JSON.stringify(orderList));
    }
  }

  const changeQuantity = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemQuantity = e.target.value === '' ? 0 : parseFloat(e.target.value);
    orderList[index].itemQuantity = itemQuantity;
    setInvoiceCart(orderList);
    localStorage.setItem("purchasesInvoiceCart", JSON.stringify(orderList));
  }

  const changePrice = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemPrice = e.target.value === '' ? 0 : parseFloat(e.target.value);
    orderList[index].price = itemPrice;
    setInvoiceCart(orderList);
    localStorage.setItem("purchasesInvoiceCart", JSON.stringify(orderList));
  }

  const changeTax = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemTax = (e.target.value === 0 || e.target.value === '1') ? 1 : parseFloat(e.target.value) / 100;
    orderList[index].itemTax = itemTax;
    setInvoiceCart(orderList);
    localStorage.setItem("purchasesInvoiceCart", JSON.stringify(orderList));
  }

  const changeComment = (item, e) => {
    const orderList = [...invoiceCart];
    const index = orderList.indexOf(item);
    const itemComment = e.target.value;
    orderList[index].itemComment = itemComment;
    setInvoiceCart(orderList);
    localStorage.setItem("purchasesInvoiceCart", JSON.stringify(orderList));
  }

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!orders) return null;

  const choices = orders.map((order) => ({
    id: order.id,
    name: order.id === 0 ? `OC - GENERICA` : `OC - ${voucherFormat(order.id)}`,
    itemQuantity: 1,
    price: (order.total === null) ? 0 : order.total,
    itemBonus: 0, //POR AHORA NO SE USA
    itemTax: 0.21,
    subtotal: 0.0, //POR AHORA NO SE USA
    unit: 0, //NO SE USA
    code: order.id === 0 ? `GEN` : `OC`,
  }));

  return (
    <CardContent>
      <Box display={{ md: 'block', lg: 'flex' }}>
        <Box flex={1} mr={{ md: 0, lg: '1em' }}>
          <SectionTitle label="Selección de Ordenes de Compra" />
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <Autocomplete
                id="combo-box"
                options={choices}
                getOptionLabel={(option) => option.name}
                value={value}
                onChange={(event, newValue) => {
                  addToCart(newValue)
                }}
                size="small"
                style={{ maxWidth: 300 }}
                renderInput={
                  (params) => <TextField {...params} label="Buscar Ordenes" variant="filled" margin="dense" autoFocus />
                }
              />
            </Box>
          </Box>

          <Box mt="1em" />

          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              {isXsmall ? (
                <CartItemsMobileGrid
                  products={invoiceCart}
                  changeQuantity={changeQuantity}
                  changePrice={changePrice}
                  changeComment={changeComment}
                  changeTax={changeTax}
                  deleteToCard={deleteToCard}
                />
              ) : (
                <CartItems
                  products={invoiceCart}
                  changeQuantity={changeQuantity}
                  changePrice={changePrice}
                  changeTax={changeTax}
                  changeComment={changeComment}
                  deleteToCard={deleteToCard}
                />)}
            </Box>
          </Box>

          <Box mt="1em" />

          <PurchaseInvoiceFootDetail products={invoiceCart} invoiceStatus={"CREADA"} />

        </Box>
      </Box>
    </CardContent>
  );
}

export default AutocompleteDetail;
