import * as React from "react";
import {
  Admin,
  Resource
} from "react-admin";

//IDIOMAS
import polyglotI18nProvider from "ra-i18n-polyglot";
import spanishMessages from "./i18n/es";

//TEMA
import Theme from "./layout/Theme";

//SEGURIDAD
import authProvider from "./authProvider";

//SECCIONES
import { Login, Layout } from "./layout";
import { Dashboard } from './dashboard';
import profile from "./profile";

//RECURSOS HUMANOS
import employees from "./employees";
import imageProfile from './employees/imagenProfile/ImageProfileInput';
import document from './employees/document/DocumentFileInput';
import absences from "./absences";
import extraHour from "./extra-hour";
import shifts from "./shifts";
import tracking from "./tracking";
import timeTracking from "./tracking/timeTracking";
import reports from "./reports-rrhh";
import holidays from "./holidays";

//TALLER
import vehicles from "./vehicles";
import inspections from "./inspections";
import orders from "./orders";
import baskets from "./baskets";
import categories from "./categories";
import items from "./items";
import tools from "./tools";
import consumables from "./consumables";
import warehouse from "./warehouse";
import documentOrders from './orders/document/DocumentFileInput';

//CONTABILIDAD
import invoices from "./invoices";
import customers from "./customers";
import payments from "./payments";
import suppliers from "./suppliers";
import receipts from "./receipts";
import purchases from "./purchases";
import purchasesInvoices from "./purchases-invoices";

import checks from "./checks";
import cash from "./cash";

import customRoutes from './routes';

//import jsonServerProvider from 'ra-data-json-server';
//const dataProvider = jsonServerProvider('http://161.35.249.83:3000');

//DataProvider
import superDataProvider from './superDataProvider';
import {
  getAllRoles,
  getRolesFinance,
  getRolesOnlyStorekeeperExceptAdmin,
  getRolesOnlySupervisorExceptAdmin,
  getRolesRRHH,
  getAllRolesWorkshop,
  getRolesWorkshopOnly
} from "./appRolesComponents";

const i18nProvider = polyglotI18nProvider((locale) => {
  return spanishMessages;
});

const App = () => {
  return (
    <Admin
      title="Grupo Marasa"
      dataProvider={superDataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      theme={Theme}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
      customRoutes={customRoutes}
    >
      {
        permissions => [
          <Resource name="profile" {...profile} />,

          getAllRoles(permissions) ? <Resource name="employees" {...employees} /> : null,

          getRolesRRHH(permissions) ? <Resource name="imageProfile" {...imageProfile} /> : null,
          getRolesRRHH(permissions) ? <Resource name="document" {...document} /> : null,
          getRolesRRHH(permissions) ? <Resource name="absences" {...absences} /> : null,
          getRolesRRHH(permissions) ? <Resource name="extra-hour" {...extraHour} /> : null,
          getRolesRRHH(permissions) ? <Resource name="shifts" {...shifts} /> : null,

          getRolesRRHH(permissions) ||
            getRolesOnlySupervisorExceptAdmin(permissions) ||
            getRolesOnlyStorekeeperExceptAdmin(permissions) ? <Resource name="tracking" {...tracking} /> : null,

          getRolesRRHH(permissions) ||
            getRolesOnlySupervisorExceptAdmin(permissions) ||
            getRolesOnlyStorekeeperExceptAdmin(permissions) ? <Resource name="timeTracking" {...timeTracking} /> : null,

          getRolesRRHH(permissions) ? <Resource name="reports-rrhh" {...reports} /> : null,
          getRolesRRHH(permissions) ? <Resource name="holidays" {...holidays} /> : null,

          getRolesWorkshopOnly(permissions) ||
            getRolesOnlySupervisorExceptAdmin(permissions) ? <Resource name="categories" {...categories} /> : null,

          getAllRolesWorkshop(permissions) ? <Resource name="items" {...items} /> : null,

          getAllRolesWorkshop(permissions) ? <Resource name="warehouse" {...warehouse} /> : null,

          getAllRolesWorkshop(permissions) ? <Resource name="tools" {...tools} /> : null,
          getAllRolesWorkshop(permissions) ? <Resource name="consumables" {...consumables} /> : null,

          getAllRolesWorkshop(permissions) ? <Resource name="vehicles" {...vehicles} /> : null,
          getAllRolesWorkshop(permissions) ? <Resource name="busTemplate" /> : null,
          getAllRolesWorkshop(permissions) ? <Resource name="brands" /> : null,

          getAllRoles(permissions) ? <Resource name="customers" {...customers} /> : null,

          getAllRolesWorkshop(permissions) ? <Resource name="busLines" /> : null,
          getAllRolesWorkshop(permissions) ? <Resource name="inspections" {...inspections} /> : null,
          getAllRolesWorkshop(permissions) ? <Resource name="orders" {...orders} /> : null,

          getAllRolesWorkshop(permissions) ? <Resource name="baskets" {...baskets} /> : null,
          
          getAllRolesWorkshop(permissions) ? <Resource name="orders/document" {...documentOrders} /> : null,

          getRolesFinance(permissions) ? <Resource name="invoices" {...invoices} /> : null,
          getRolesFinance(permissions) ||
            getRolesOnlySupervisorExceptAdmin(permissions) ? <Resource name="suppliers" {...suppliers} /> : null,
          getRolesFinance(permissions) ||
            getRolesOnlySupervisorExceptAdmin(permissions) ? <Resource name="payments" {...payments} /> : null,
          getRolesFinance(permissions) ? <Resource name="receipts" {...receipts} /> : null,
          getRolesFinance(permissions) ||
            getRolesOnlySupervisorExceptAdmin(permissions) ? <Resource name="purchases" {...purchases} /> : null,
          getRolesFinance(permissions) ||
            getRolesOnlySupervisorExceptAdmin(permissions) ? <Resource name="purchases-invoices" {...purchasesInvoices} /> : null,
          getRolesFinance(permissions) ? <Resource name="checks" {...checks} /> : null,
          getRolesFinance(permissions) ? <Resource name="cash" {...cash} /> : null,

        ]}

    </Admin>
  );
};

export default App;
