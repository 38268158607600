import React, { useState } from 'react';
import { Fragment } from 'react';
import keyBy from 'lodash/keyBy';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading,
    NumberField,
} from 'react-admin';
import ReceiptLinkField from '../receipts/ReceiptLinkField';
import RetentionExport from './RetentionExport';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        marginBottom: '1em', 
        float: "right"
        
    }
});

const RetentionDatagrid = ({ values, customers }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'id', order: 'DESC' })
    const classes = useStyles();

    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'receipts',
        payload: {
            pagination: { page, perPage },
            sort,
            filter: {}

        }
    });

    if (loading) { return <Loading /> }
    if (error) { return <p>ERROR: {error}</p> }

    const dataFilterAux = data.filter(
        item => (item.customerId === values.customerId || values.customerId === undefined) && (item.primaryAccount === 'CLIENTES') && (item.date >= values.start && item.date <= values.end)
    );

    const dataFilter = dataFilterAux.map(item => ({
        ...item,
        customer: customers.find(customer => customer.id === item.customerId),
        methods: item.methods.filter(i => i.method === 'RETENCIONES')
    })).filter(i => i.methods.length > 0); //SACO LOS QUE NO TIENEN RETENCIONES

    const dataFilterMethod = dataFilter.map(item => (
        item.methods.map((i, index) => ({
            id: item.id,
            idAux: index + item.id, //SE CREO XQ SI NO TOMABA EL ULTIMO TOTAL SI REPETIA EL ID
            date: item.date,
            customerId: item.customerId,
            name: item.customer.name,
            cuit: item.customer.cuit,
            method: i.method,
            tax: i.tax,
            numberVoucher: i.numberVoucher,
            jurisdition: i.jurisdiction,
            total: i.total,
            totalVoucher: i.totalVoucher
        }))
    )).flat()

    const total = dataFilterMethod.length;

    return (
        <Fragment>
            <div className={classes.root}>
                <RetentionExport dataExport={dataFilterMethod} />
            </div>
            <Datagrid
                data={keyBy(dataFilterMethod, 'idAux')}
                ids={dataFilterMethod.map(({ idAux }) => idAux)}
                currentSort={sort}
                setSort={(field, order) => setSort({ field, order })}
                optimized
            >
                <TextField
                    label="Fecha"
                    source="date"
                />
                <ReceiptLinkField />
                <TextField
                    label="Cliente"
                    source="name"
                />
                <TextField
                    label="CUIT"
                    source="cuit"
                />
                <NumberField
                    label="Retencion"
                    source="total"
                    locales="es-AR"
                    options={{ style: 'currency', currency: 'ARS' }}
                />
            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />
        </Fragment>
    );
}

export default RetentionDatagrid;