const choices = [
    { 
        id: 'RESPONSABLE_INSCRIPTO', 
        name: 'Responsable Inscripto' 
    },
    { 
        id: 'MONOTRIBUTO', 
        name: 'Monotributo' 
    },
    { 
        id: 'EXENTO', 
        name: 'Exento' 
    },
    { 
        id: 'CONSUMIDOR_FINAL', 
        name: 'Consumidor Final' 
    },
    // { 
    //     id: 'Cliente del Exterior', 
    //     name: 'Cliente del Exterior' 
    // },
];

export default choices
