import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        alignItems: 'center',
        //height: 10,
        fontSize: 10,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    date: {
        width: '10%',
        borderRightWidth: 1,
    },
    voucher: {
        width: '30%',
        borderRightWidth: 1,
    },
    line: {
        width: '15%',
        borderRightWidth: 1,
    },
    lineOther: {
        width: '15%',
        borderRightWidth: 1,
    },
    tax: {
        width: '15%',
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
    name: {
        width: '85%'
    }
});

const TableHeader = ({ pdfDocument }) => {

    const rowGroupBy = (pdfDocument.groupBy !== undefined) ?
        (<View style={styles.container}>
            <Text style={styles.date}>Fecha</Text>
            <Text style={styles.voucher}>Comprobante</Text>
            <Text style={styles.line}>Grabado</Text>
            <Text style={styles.lineOther}>No Grabado</Text>
            <Text style={styles.tax}>IVA</Text>
            <Text style={styles.amount}>Saldo</Text>
        </View>)
        :
        (<View style={styles.container}>
            <Text style={styles.name}>Nombre (CUIT)</Text>
            <Text style={styles.amount}>Saldo</Text>
        </View>);

    return (
        rowGroupBy
    )
};

export default TableHeader;