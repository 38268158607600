import { useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import {
    List, usePermissions,
} from 'react-admin';
import { getRolesFinance, getRolesOnlySupervisorExceptAdmin } from '../appRolesComponents';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import SupplierListDesktop from './SupplierListDesktop';
import SupplierListMobile from './SupplierListMobile';

const SupplierList = (props) => {
    const { permissions } = usePermissions();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const isFinance = getRolesFinance(permissions);
    const isSupervisor = getRolesOnlySupervisorExceptAdmin(permissions);

    if (!isFinance && !isSupervisor) {
        return null;
    }

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <SupplierListMobile />
            ) : (
                <SupplierListDesktop />
            )}

        </List>
    );
};

export default SupplierList;
