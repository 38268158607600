import * as React from 'react';
import {
    Datagrid,
    NumberField,
    ChipField,
    ReferenceField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CustomerLinkField from '../customers/CustomerLinkField';
import StatusChipField from './StatusChipField';
import PaymentLinkField from './PaymentLinkField';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useListStyles = makeStyles({
    total: { fontWeight: 'bold' },
});

const PaymentListDesktop = (props) => {
    const classes = useListStyles();
    return (
        <Datagrid
            optimized
            {...props}
        >
            <PaymentLinkField 
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha"
                source="date"
                sortable={false}
                showTime={false}
            />
            <ChipField
                label="Cuenta Primaria"
                size="small"
                source="primaryAccount"
                sortable={false}
            />
            <ChipField
                label="Cuenta Secundaria"
                size="small"
                source="secondaryAccount"
                emptyText
                sortable={false}
            />
            <ReferenceField
                label="Proveedor"
                source="supplierId"
                reference="suppliers"
                link={false}
                sortable={false}
            >
                <CustomerLinkField />
            </ReferenceField>

            <NumberField
                label="Total Abonado"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />

            <StatusChipField 
                label="Estado"
                source="status"          
                sortable={false}      
            />
        </Datagrid>
    );
};

export default PaymentListDesktop;
