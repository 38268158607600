import * as React from "react";
import {
    Datagrid,
    TextField,
    ReferenceField,
} from "react-admin";
import ToolLinkField from "./ToolLinkField";
import StateChipField from "./StateChipField";
import DateFieldFormatted from "../components/DateFieldFormatted";

const ToolListDesktop = () => {
    return (
        <Datagrid
            optimized
            rowClick="edit" 
        >
            <ToolLinkField
                sortable={false}
            />
            <ReferenceField
                label="resources.tools.fields.tool"
                source="itemId"
                reference="items"
                link={false}
                sortable={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <DateFieldFormatted
                label="Fecha de Retiro"
                source="start"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Devolución"
                source="end"
                sortable={false}
            />
            <ReferenceField
                label="resources.tools.fields.employee"
                source="employeeId"
                reference="employees"
                link={false}
                sortable={false}
            >
                <TextField source="fullName" />
            </ReferenceField>

            <StateChipField
                label="Estado"
                source="end"
                sortable={false}
            />
        </Datagrid>
    );
};

export default ToolListDesktop;
