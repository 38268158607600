import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import { purple, green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const CheckTypeChipField = ({ record }) => {
    const classes = useStyles();
    const myCheck = record.myCheck ? "CHEQUE PROPIO" : "CHEQUE DE TERCEROS";

    return (
        <div className={classes.root}>
            <Chip
                label={myCheck}
                size="small"
                variant="outlined"
                style={{ color: purple[200] }}
            //color="secondary"
            />
            {record.checkStatus === 'EN_CAJA' ?
                <Chip
                    label={record.checkStatus}
                    size="small"
                    style={{ backgroundColor: green[200] }}
                />
                :
                <Chip
                    label={record.checkStatus}
                    size="small"
                    style={{ backgroundColor: red[200] }}
                />
            }

        </div>

    )
}

export default CheckTypeChipField;
