import * as React from 'react';
import {
    NumberField,
    ChipField
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import CheckTypeChipField from './CheckTypeChipField.jsx';
import { grey } from '@material-ui/core/colors';
import DateFieldFormatted from '../components/DateFieldFormatted.jsx';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const CheckListMobile = ({ ids, data }) => {
    const classes = useStyles();

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div>
                                <div className={classes.name}>
                                    <CheckTypeChipField record={data[id]} />
                                </div>
                                <div className={classes.cardTitleContent}>
                                    <div className={classes.name}>
                                        <div>Cheque# {data[id].numberCheck}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        subheader={
                            <div>
                                {data[id].bank}
                            </div>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <div>
                            {'Fecha de Ingreso'}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="date"
                                showTime={false}
                            />
                        </div>
                        <div>
                            {'Fecha de Emisión'}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="startDate"
                                showTime={false}
                            />
                        </div>
                        <div>
                            {'Fecha de Vto.'}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="dueDate"
                                showTime={false}
                            />
                        </div>
                        <br />
                        <span className={classes.cardContentRow}>
                            {'Importe'}
                            :&nbsp;
                            <NumberField
                                record={data[id]}
                                source="total"
                                locales="es-AR"
                                options={{ style: 'currency', currency: 'ARS' }}
                            />
                        </span>
                        <div>
                            {'Estado'}
                            :&nbsp;
                            <ChipField
                                label="Estado"
                                record={data[id]}
                                source="checkVision"
                                size="small"
                                style={{ backgroundColor: grey[200] }}
                            />
                        </div>

                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default CheckListMobile;
