import * as React from 'react';
import {
    useEffect, useState
} from 'react';
import {
    Edit,
    useNotify,
    useRefresh,
    useRedirect,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    useEditController
} from 'react-admin';
import PurchaseInvoiceForm from './PurchaseInvoiceForm';
import PurchaseInvoiceFormTax from './PurchaseInvoiceFormTax';
import PurchaseInvoiceEditToolbar from './PurchaseInvoiceEditToolbar';
import { Card, CardHeader, useMediaQuery } from '@material-ui/core';
import ListItemsMobile from './ListItemsMobile';
import ListItemsDesktop from './ListItemsDesktop';
import PurchaseInvoiceFormDetail from './PurchaseInvoiceFormDetail';

const showListItems = (isXsmall, formData) => {
    return isXsmall ? <ListItemsMobile formData={formData} /> : <ListItemsDesktop formData={formData} />;
}

const PurchaseInvoiceEdit = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const controllerProps = useEditController(props);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        if (controllerProps.record !== undefined) {
            localStorage.setItem("purchasesInvoiceCart", JSON.stringify(controllerProps.record.operationInfo));
        }
    }, [controllerProps.record]);

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/purchases-invoices`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const Title = ({ record }) => {
        return <span>{record ? `#${record.id}` : ''}</span>;
    };

    const validate = (values) => {
        const errors = {};
        const purchasesInvoiceCart = JSON.parse(localStorage.getItem("purchasesInvoiceCart"));
        if (purchasesInvoiceCart === null || purchasesInvoiceCart === undefined || (Object.keys(purchasesInvoiceCart).length === 0)) {
            errors.total = ['Agregue un item'];
        }
        return errors;//TODO
    };

    const handleLoading = (isLoading) => {
        setLoading(isLoading);
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<Title />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                toolbar={<PurchaseInvoiceEditToolbar loading={loading} />}
                redirect={redirect}
                validate={validate}
            >

                <FormTab label="Cabecera" >
                    <PurchaseInvoiceForm />
                </FormTab>

                <FormTab
                    label="Detalle"
                    path="details"
                >
                    <FormDataConsumer>
                        {({ formData }) => (formData.customerId !== undefined && formData.invoiceType !== undefined && formData.id !== undefined) && (formData.operationInfo.length > 0) && (formData.invoiceStatus === 'CREADA') ?
                            <PurchaseInvoiceFormDetail  {...props} handleLoading={handleLoading} />
                            :
                            showListItems(isXsmall, formData)
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab
                    label="Otros impuestos"
                    path="tax"
                >
                    <FormDataConsumer>
                        {({ formData }) => (formData.customerId !== undefined && formData.invoiceType !== undefined && formData.id !== undefined) && (formData.operationInfo.length > 0) ?
                            <PurchaseInvoiceFormTax formData={formData} />
                            :
                            <Card key={0}>
                                <CardHeader
                                    title={
                                        <div>
                                            Por favor, selecciona un proveedor y el tipo de comprobante y luego podrás cargar los conceptos facturados.
                                        </div>
                                    }
                                />
                            </Card>
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default PurchaseInvoiceEdit;
