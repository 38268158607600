import * as React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    useNotify
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import ReceiptForm from './ReceiptForm';
import { calculateTotalReceipts, calculateTotalReceiptsInvoices } from '../utils/functions';
import SelectInputMethod from './SelectInputMethod';

const ReceiptCreate = (props) => {
    const notify = useNotify();
    const redirect = `/receipts`;

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const validate = (values) => {
        const methods = JSON.parse(localStorage.getItem("methods"));
        const invoicesReceipts = JSON.parse(localStorage.getItem("invoicesReceipts"));
        const total = (methods === null) ? 0 : calculateTotalReceipts(methods);
        const totalReceipts = (invoicesReceipts === null) ? 0 : calculateTotalReceiptsInvoices(invoicesReceipts);
        const errors = {};

        if (values.customerId !== undefined) {
            if (total !== totalReceipts || totalReceipts === 0 || total === 0) {
                errors.total = 'Total Error';
            }
        } else {
            if (total === 0) {
                errors.total = 'Total Error';
            }
        }
        return errors;
    };

    return (
        <Create
            {...props}
            onFailure={onFailure}
            title={'Recibo de Cobro'}
        >
            <TabbedForm
                //warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
                validate={validate}
            >
                <FormTab label="General" >
                    <ReceiptForm />
                </FormTab>
                <FormTab label="Medios de Pago" path="method">
                    <SelectInputMethod {...props} />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default ReceiptCreate;
