import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteButtonCard from '../components/DeleteButtonCard';
import { currencyFormatter } from '../utils/functions';
import ItemQuantityInput from './ItemQuantityInput';
import ItemPriceInput from './ItemPriceInput';

const CartItems = ({ products, changeQuantity, changePrice, deleteToCard }) => {

    return (
        <div>
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'Nombre (Código)'}
                            </TableCell>
                            <TableCell align="right">
                                {'Cant. Pañol'}
                            </TableCell>
                            <TableCell align="right">
                                {'Cant. Depósito'}
                            </TableCell>
                            <TableCell align="center">
                                {'Comprar'}
                            </TableCell>
                            <TableCell align="center">
                                {'Precio Unit.'}
                            </TableCell>
                            <TableCell align="right">
                                {'Subtotal'}
                            </TableCell>
                            <TableCell>
                                {''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(products.length === 0) ?
                            <TableRow key={0}>
                                <TableCell colSpan={6}>
                                    Sin articulos cargados!
                                </TableCell>
                            </TableRow>
                            :
                            products.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell>
                                        {`${product.name} (${product.code})`}
                                    </TableCell>
                                    <TableCell align="right">
                                        {product.quantity}
                                    </TableCell>
                                    <TableCell align="right">
                                        {product.warehouseQuantity}
                                    </TableCell>
                                    <TableCell align="center">
                                        <ItemQuantityInput product={product} changeQuantity={changeQuantity} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <ItemPriceInput product={product} changePrice={changePrice} />
                                    </TableCell>
                                    <TableCell align="right">
                                        {currencyFormatter((product.itemQuantity * product.price))}
                                    </TableCell>
                                    <TableCell>
                                        <DeleteButtonCard product={product} deleteToCard={deleteToCard} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Card>
        </div>
    )
}

export default CartItems;