import * as React from 'react';
import {
    Datagrid,
    TextField,
    ReferenceField,
    usePermissions,
    ChipField
} from 'react-admin';
import { getRolesOnlyDriverExceptAdmin } from '../appRolesComponents';
import InspectionLinkField from './InspectionLinkField';
import OrderCreateButton from './OrderCreateButton';
import DateFieldFormatted from '../components/DateFieldFormatted';

const InspectionListDesktop = () => {
    const { permissions } = usePermissions();

    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);

    return (
        <Datagrid optimized >
            <InspectionLinkField
                sortable={false}
            />
            <DateFieldFormatted
                label="resources.inspections.fields.dateInspection"
                source="start"
                showTime={true}
            />
            <ReferenceField
                label="resources.inspections.fields.inspector"
                source="employeeId"
                reference="employees"
                link={false}
                sortable={false}
            >
                <TextField source="fullName" />
            </ReferenceField>
            <ReferenceField
                label="resources.inspections.fields.customer"
                source="vehicleId"
                reference="vehicles"
                link={false}
                sortable={false}
            >
                <ReferenceField
                    source="customerId"
                    reference="customers"
                    link={false}
                >
                    <TextField
                        source="name"
                    />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField
                label="resources.inspections.fields.line"
                source="vehicleId"
                reference="vehicles"
                link={false}
                sortable={false}
            >
                <ReferenceField
                    source="lineId"
                    reference="busLines"
                    link={false}
                >
                    <TextField source="name" />
                </ReferenceField>
            </ReferenceField>
            <ReferenceField
                label="resources.inspections.fields.internal"
                source="vehicleId"
                reference="vehicles"
                link={false}
                sortable={false}

            >
                <TextField source="internal" />
            </ReferenceField>
            <ReferenceField
                label="resources.inspections.fields.patent"
                source="vehicleId"
                reference="vehicles"
                link={false}
                sortable={false}

            >
                <ChipField
                    size="small"
                    source="patent"
                />
            </ReferenceField>

            {!isDriver && <OrderCreateButton />}
        </Datagrid>
    );
};

export default InspectionListDesktop;
