import ReceiptList from './ReceiptList';
import ReceiptCreate from './ReceiptCreate';
import ReceiptEdit from './ReceiptEdit';
import ReceiptIcon from '@material-ui/icons/Receipt';

export default {
  list: ReceiptList,
  create: ReceiptCreate,
  edit: ReceiptEdit,
  icon: ReceiptIcon
};
