import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import keyBy from 'lodash/keyBy';
import {
    Datagrid,
    TextField,
    //Pagination,
    Loading,
    NumberField,
    ReferenceField
} from 'react-admin';
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ItemsExport from './ItemsExport';
//import { set } from 'date-fns';

const useStyles = makeStyles({
    root: {
        marginBottom: '1em',
        float: "right"

    }
});

const ItemsDatagrid = ({ values }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({ field: 'id', order: 'DESC' });
    const [data, setData] = useState([]);
    //const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const classes = useStyles();

    const dataProvider = useDataProvider();

    useEffect(() => {
        setLoading(true);
        const quantity = values.quantity === true ? 0 : undefined;
        const category = values.categoryId === '' ? undefined : values.categoryId;

        dataProvider.getList('items', {
            pagination: { page, perPage: 99999 },
            sort,
            filter: { quantity: quantity, category: category }
        })
            .then(async ({ data, total }) => {
                const categoryData = await dataProvider.getOne('categories', { id: category });
                const updatedData = data.map(item => ({
                    ...item,
                    categoryName: categoryData.data.name
                }));
                
                setData(updatedData);
                //setTotal(total);
                setLoading(false);
                setPage(page);
                setPerPage(perPage);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [page, perPage, sort, values.quantity, dataProvider, values.categoryId]);

    if (loading) { return <Loading /> }
    if (error) { return <p>ERROR: {error.message}</p> }
    if (data.length === 0) { return <p>No hay resultados</p> }

    return (
        <Fragment>
            <div className={classes.root}>
                <ItemsExport dataExport={data} />
            </div>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({ id }) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({ field, order })}
                optimized
            >
                <TextField
                    label="Codigo"
                    source="code"
                    sortable={false}
                />
                <TextField
                    label="Nombre"
                    source="name"
                    sortable={false}
                />
                <ReferenceField
                    label="Categoria"
                    source="categoryId"
                    reference="categories"
                    link={false}
                    sortable={false}
                >
                    <TextField
                        source="name"
                    />
                </ReferenceField>
                <TextField
                    label="Cantidad Pañol"
                    source="quantity"
                    sortable={false}
                />
                <TextField
                    label="Cantidad Deposito"
                    source="warehouseQuantity"
                    sortable={false}
                />
                <NumberField
                    label="Precio"
                    source="price"
                    locales="es-AR"
                    options={{ style: 'currency', currency: 'ARS' }}
                    sortable={false}
                />
                <ReferenceField
                    label="Proveedor"
                    source="supplierId"
                    reference="suppliers"
                    link={false}
                    sortable={false}
                >
                    <TextField
                        source="name"
                    />
                </ReferenceField>
                <TextField
                    label="Localizacion"
                    source="location"
                    sortable={false}
                />
            </Datagrid>
            {/* <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />  */}
        </Fragment>
    );
}

export default ItemsDatagrid;
