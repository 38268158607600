import React from 'react';
import {
    Edit,
    useNotify,
    useRefresh,
    useRedirect,
    SimpleForm,
    usePermissions
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import InspectionForm from './InspectionForm';
import { getRolesOnlyDriverExceptAdmin, getRolesOnlyInspectorExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';

const InspectionEdit = (props) => {
    const { permissions } = usePermissions();
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/inspections`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const InspectionTitle = ({ record }) => {
        return <span>IN-{record ? `${record.id}` : ''}</span>;
    };

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);

    if (!isWorkshopOnly && !isDriver && !isInspector) return null;

    return (
        <Edit
            {...props}
            undoable={false}
            title={<InspectionTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <InspectionForm />
            </SimpleForm>
        </Edit>
    );
};

export default InspectionEdit;
