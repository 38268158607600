import React from 'react';
import {
    LinearProgress,
    useGetOne,
} from 'react-admin';
import { voucherFormat } from '../utils/functions';

const ItemPurchaseInvoiceField = ({ id }) => {
    const { data, loaded } = useGetOne('purchases-invoices', id);
    if (!loaded) return <LinearProgress />;
    return (
        <div>
            COMPROBANTE DE COMPRA-{voucherFormat(data.id)}
        </div>
    );
}

export default ItemPurchaseInvoiceField;