import * as React from 'react';
import {
    EditButton,
    useTranslate,
    ReferenceField,
    ChipField,
    TextField
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import CustomerField from '../components/CustomerField';
import LineField from '../components/LineField';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const VehicleMobileGrid = ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div>{`${data[id].patent}`}</div>
                                <EditButton
                                    resource="vehicles"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                            {translate('resources.vehicles.fields.customer')}
                            :&nbsp;
                            <CustomerField
                                id={data[id].customerId}
                            />
                        </div>
                        <div>
                            {translate('resources.vehicles.fields.internal')}
                            :&nbsp;
                            <TextField
                                record={data[id]}
                                source="internal"
                            />
                        </div>
                        <div>
                            {translate('resources.inspections.fields.line')}
                            :&nbsp;
                            <LineField
                                id={data[id].lineId}
                            />
                        </div>
                        <div>
                            {'Plantilla a Dibujar'}
                            :&nbsp;
                            <ReferenceField
                                record={data[id]}
                                source="busTemplate"
                                reference="busTemplate"
                                link={false}
                            >
                                <ChipField
                                    size="small"
                                    source="name"
                                />
                            </ReferenceField>
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};


export default VehicleMobileGrid;
