import * as React from 'react';
import {
    SearchInput,
    Filter,
    useListContext,
} from 'react-admin';
import { useRef, useEffect } from 'react';
import { FormHelperText } from '@material-ui/core';

const InputFilter = (props) => {
    const { resource } = props;
    const { setFilters } = useListContext();
    const inputRef = useRef(null);

    const resourcePlaceholderMap = {
        employees: 'Buscar por nombre',
        extraHour: 'Buscar', // TODO NO FUNCIONA EN BACKEND
        shifts: 'Buscar por descripción',
        holidays: 'Buscar', // TODO NO FUNCIONA EN BACKEND
        vehicles: 'Buscar por patente',
        inspections: 'Buscar por patente',
        orders: 'Buscar por patente',
        tools: 'Buscar', // TODO NO FUNCIONA EN BACKEND
        items: 'Nombre o Código',
        consumables: 'Nombre o Código',
        customers: 'Buscar por cliente',
        suppliers: 'Proveedor o Nom. Fantasia',
        purchases: 'Proveedor o Nom. Fantasia',
    };

    const placeholder = resourcePlaceholderMap[resource] || 'Buscar...';

    let source;

    switch (resource) {
        case 'vehicles':
            source = 'patent';
            break;
        case 'purchases':
            source = 'supplierName';
            break;
        default:
            source = 'q';
            break;
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFilters({ q: '' });
                setFilters({ supplierName: '' });
                setFilters({ patent: '' });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setFilters]);

    return (
        <div>
            <Filter {...props}>
                <SearchInput
                    source={source}
                    alwaysOn
                    autoFocus
                    placeholder={placeholder}
                    resettable
                    ref={inputRef}
                    parse={(value) => value.toUpperCase()}
                />
            </Filter>
            <FormHelperText><span role="img" aria-label="icono de limpiar">⌨️</span> Presione la tecla Escape (esc) para limpiar la búsqueda</FormHelperText>
        </div>
    );
};

export default InputFilter;
