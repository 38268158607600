import * as React from 'react';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
} from 'react-admin';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import methodData from '../components/data/methodData';
import inflection from 'inflection';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '15em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const CashListAside = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <FilterLiveSearch />

                <FilterList
                    label="Tipo de Operación"
                    icon={<ReceiptIcon />}
                >
                    <FilterListItem
                        label="Recibos de Cobro (ingresos)"
                        value={{
                            operationType: 'receipts',
                        }}
                    />
                    <FilterListItem
                        label="Ordenes de Pago (egresos)"
                        value={{
                            operationType: 'payments',
                        }}
                    />
                </FilterList>


                <FilterList
                    label="Medios de Pago"
                    icon={<PaymentIcon />}
                >
                    {methodData.map((item, i) => (
                        <FilterListItem
                            label={inflection.humanize(item.method)}
                            key={i}
                            value={{ method: item.method }}
                        />
                    ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default CashListAside;
