import * as React from 'react';
import {
    Datagrid,
    TextField,
    NumberField,
    ChipField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CheckTypeChipField from './CheckTypeChipField.jsx';
import { grey } from '@material-ui/core/colors';
import CheckListDesktopLines from './CheckListDesktopLines.jsx';
import DateFieldFormatted from '../components/DateFieldFormatted.jsx';

const useListStyles = makeStyles({
    total: { fontWeight: 'bold' },
});

const CheckListDesktop = (props) => {
    const classes = useListStyles();
    return (
        <Datagrid
            optimized
            expand={<CheckListDesktopLines />}
            {...props}
        >
            <CheckTypeChipField
                record={props.record}
                sortable={false}
            />
            <TextField
                label="Cheque Nro."
                source="numberCheck"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Ingreso"
                source="startDate"
                sortable={false}
                showTime={false}
            />
            <NumberField
                label="Importe"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />
            <ChipField
                label="Estado"
                source="checkVision"
                size="small"
                style={{ backgroundColor: grey[200] }}
                sortable={false}
            />
            {/* <TextField
                label="Sigue en Cartera?"
            /> */}
        </Datagrid>
    );
};

export default CheckListDesktop;
