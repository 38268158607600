import React from 'react';
import {
    TopToolbar,
    LinearProgress
} from 'react-admin';
import PDFButtonPurchase from './PDFButtonPurchase';

const EditActionsPurchase = ({ data }) => {

    return (
        <TopToolbar>
            {(data !== undefined) ?
                <PDFButtonPurchase {...data}/>
                :
                <LinearProgress />
            }
        </TopToolbar>
    )
};

export default EditActionsPurchase;