import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles({
    container: { minWidth: '35em', marginLeft: '1em' },
    boldCell: { fontWeight: 'bold' },
});

const CheckListDesktopLines = ({ record }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.container} elevation={2}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {'Banco'}
                        </TableCell>
                        <TableCell>
                            {'Fecha de Emisión'}
                        </TableCell>
                        <TableCell>
                            {'Fecha de Vencimiento'}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {record.bank}
                        </TableCell>
                        <TableCell>
                            <DateFieldFormatted
                                record={record}
                                source="startDate"
                                showTime={false}
                            />
                        </TableCell>
                        <TableCell>
                            <DateFieldFormatted
                                record={record}
                                source="dueDate"
                                showTime={false}
                            />
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </Paper>
    );
};

export default CheckListDesktopLines;
