import * as React from 'react';
import {
    Datagrid,
    NumberField,
} from 'react-admin';
import DateFieldFormatted from '../../components/DateFieldFormatted';

const TrackingHistory = () => {
    return (
        <Datagrid optimized>
            <DateFieldFormatted
                label="Primer Fichaje"
                source="start"
                showTime={true}
            />
            <DateFieldFormatted
                label="Último Fichaje"
                source="end"
                showTime={true}
            />
            <NumberField
                label="Duración"
                source="duration"
                options={{ maximumFractionDigits: 2 }}
            />
        </Datagrid>

    );
};

export default TrackingHistory;
