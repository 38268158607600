import * as React from 'react';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';

const DashboardVehiclesInWorkshop = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/orders"
            icon={DirectionsBusIcon}
            title={translate('pos.dashboard.vehiclesInWorkshop')}
            subtitle={value}
        />
    );
};

export default DashboardVehiclesInWorkshop;
