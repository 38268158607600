import React, { useState, useEffect } from 'react';
import {
    Button,
    SimpleForm,
    TextField,
    BooleanField,
    LinearProgress,
    SingleFieldList,
    useDataProvider,
    ArrayField,
    ChipField,
    Labeled,
    NumberField,
    FunctionField
} from 'react-admin';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Avatar, Chip } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import InspectionDrawImageView from './InspectionDrawImageView';
import BusTemplateHidden from '../components/BusTemplateHidden';
import inspectionData from '../components/data/inspectionData';
import PDFButton from './PDFButton';
import PDFLogo from './PDFLogo';
import WorkOrderStatusColorChip from '../components/WorkOrderStatusColorChip';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 10,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    //---Titulo---//
    contentHeaderOrder: {
        textAlign: 'center',
        fontSize: 20,
        flex: 1,
    },
    //---Titulo---//
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 600,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '25%',
    },
    drawerPaper: {
        zIndex: 100,
    },
    chip: {
        display: 'flex',
        justifyContent: 'right',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const PDFViewer = (order) => {
    const classes = useStyles();
    const [showPanel, setShowPanel] = useState(false);

    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const [data, setData] = useState();
    const [inspection, setInspection] = useState();
    const [inspectionDetails, setInspectionDetails] = useState();

    useEffect(() => {
        dataProvider.getMany('items', {
            ids: order.items.map((item) => item.id)
        }).then(response => {

            const items = response.data; //TODOS LOS ITEMS
            const itemsOrder = order.items.map(t1 => ({ ...items.find(t2 => t2.id === t1.id), ...t1 })); //TRAE SOLO LOS ITEMS DE LA ORDEN

            dataProvider.getList('employees', {
                sort: { field: 'fullName', order: 'ASC' },
                pagination: { page: 1, perPage: 100 },
                filter: {},
            }).then(response => {

                const employees = response.data;//TODOS LOS EMPLEADOS
                const employeesOrder = order.employeeId.map(emp => {
                    return employees.find(employee => employee.id === emp)
                });//SOLO LOS EMPLEADOS DE LA ORDEN

                let itemsOrderAux = {};
                const itemsOrderComplete = itemsOrder.map(item => {
                    return itemsOrderAux = {
                        ...item,
                        employee: (item.employeeId !== null) && employeesOrder.find(e => e.id === item.employeeId)
                    }
                });//BUSQUEDA EMPLEADO QUE TIENE EL ITEM

                console.log("itemsOrderAux" + itemsOrderAux);

                dataProvider.getOne('inspections', {
                    id: order.inspectionId
                }).then(response => {

                    const inspection = response.data;
                    const employee = employees.find(employee => employee.id === inspection.employeeId);

                    const inspectionDetails = (inspection.inspectionDetails !== null) &&
                        inspection.inspectionDetails.map(i => {
                            return inspectionData.find(ins => ins.id === i)
                        });

                    dataProvider.getOne('vehicles', {
                        id: inspection.vehicleId
                    }).then(response => {
                        const vehicle = response.data;
                        dataProvider.getOne('busLines', {
                            id: vehicle.lineId
                        }).then(response => {

                            //RESULTADO FINAL DE LA ORDEN 
                            const result = {
                                ...order,
                                employees: employeesOrder,
                                items: itemsOrderComplete,
                                inspection: {
                                    ...inspection,
                                    employee: employee,
                                    inspectionDetails: inspectionDetails,
                                    vehicle: {
                                        ...vehicle,
                                        line: response.data
                                    }
                                }
                            }

                            setInspectionDetails(inspectionDetails);
                            setInspection(inspection);
                            setData(result);
                            setLoading(false);
                        }).catch((error) => {
                            setError(error);
                            setLoading(false);
                        });
                    }).catch((error) => {
                        setError(error);
                        setLoading(false);
                    });
                }).catch((error) => {
                    setError(error);
                    setLoading(false);
                });
            }).catch((error) => {
                setError(error);
                setLoading(false);
            });
        }).catch((error) => {
            setError(error);
            setLoading(false);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = () => { setShowPanel(true); };
    const handleCloseClick = () => { setShowPanel(false); };

    if (loading) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!data || !inspection) return null;

    const InspectionDetailsField = ({ inspectionDetails }) => {
        return (
            <div className={classes.chip}>
                {inspectionDetails.map(item => (
                    <Chip key={item.id} label={item.name} size="small" />
                ))}
            </div>
        )
    }

    const ItemsField = ({ order }) => {
        return (
            <Labeled label="Materiales y/o Repuestos">
                <div className={classes.chip}>
                    {order.items.map(item => (
                        <Chip
                            key={item.id}
                            size="small"
                            avatar={
                                <Avatar style={{ backgroundColor: green[100], border: 1 }}>
                                    {item.itemQuantity}
                                </Avatar>
                            }
                            label={item.name}
                            style={{ backgroundColor: green[300], border: 0 }}
                        />
                    ))}
                </div>
            </Labeled>
        )
    }

    return (
        <div align="right">
            <Button
                label="Vista Previa"
                variant="contained"
                onClick={handleClick}
            >
                <PictureAsPdfIcon fontSize="small" />
            </Button>
            <Drawer
                anchor="right"
                open={showPanel}
                onClose={handleCloseClick}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.root}>
                    <div className={classes.title}>
                        <div id='logo-image'>
                            <PDFLogo />
                        </div>
                        <Typography variant="h6" className={classes.contentHeaderOrder}>
                            {`ORDEN DE TRABAJO - ${order.id}`}
                        </Typography>
                        <IconButton onClick={handleCloseClick}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className={classes.title}>
                        <PDFButton {...data} />

                        <WorkOrderStatusColorChip record={order} />
                    </div>
                    <SimpleForm
                        record={data}
                        basePath="/orders"
                        resource="orders"
                        className={classes.form}
                        toolbar={false}
                    >
                        <TextField
                            label="Fecha"
                            source="start"
                            formClassName={classes.inlineField}
                        />
                        <TextField
                            label="resources.vehicles.fields.line"
                            source="inspection.vehicle.line.name"
                            formClassName={classes.inlineField}
                        />
                        <TextField
                            label="resources.inspections.fields.internal"
                            source="inspection.vehicle.internal"
                            formClassName={classes.inlineField}
                        />
                        <TextField
                            label="resources.inspections.fields.patent"
                            source="inspection.vehicle.patent"
                            formClassName={classes.inlineField}
                        />

                        <TextField
                            label="resources.vehicles.fields.chassis"
                            source="inspection.vehicle.chassis"
                            formClassName={classes.inlineField}
                        />
                        <BooleanField
                            label="Chocado"
                            source="inspection.crashed"
                            formClassName={classes.inlineField}
                        />
                        <BooleanField
                            label="Pintura Completa"
                            source="inspection.completePaint"
                            formClassName={classes.inlineField}
                        />

                        <BusTemplateHidden id={inspection.vehicleId} />
                        <InspectionDrawImageView inspection={inspection} />

                        <TextField
                            label="Observaciones Inspección"
                            source="inspection.details"
                        />

                        {(inspectionDetails) &&
                            (<InspectionDetailsField inspectionDetails={inspectionDetails} />)
                        }

                        <ItemsField order={data} />

                        <ArrayField
                            label="Grupo de Trabajo"
                            source="employees"
                        >
                            <SingleFieldList>
                                <ChipField size="small" source="fullName" />
                            </SingleFieldList>
                        </ArrayField>
                        <TextField
                            label="Descripción del Grupo de Trabajo"
                            source="description"
                        />
                        <ChipField
                            label="resources.inspections.fields.inspector"
                            size="small"
                            source="inspection.employee.fullName"
                        />
                        <FunctionField
                            label="Facturado"
                            render={record => record.invoiced ? 'SI' : 'NO'}
                            formClassName={classes.inlineField}
                        />
                        <TextField
                            label="Dias en el Taller"
                            source="daysInWorkshop"
                            formClassName={classes.inlineField}
                        />
                        <TextField
                            label="Fecha de Entrega"
                            source="deliveryDate"
                            formClassName={classes.inlineField}
                        />
                        <NumberField
                            label="Total"
                            source="total"
                            locales="es-AR"
                            options={{ style: 'currency', currency: 'ARS' }}
                        />
                    </SimpleForm>
                </div>
            </Drawer>
        </div>
    );
};

export default PDFViewer;
