import * as React from 'react';
import {
    DateInput,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    BooleanInput
} from 'react-admin';
import {
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase,
} from '../utils/functions';
import { Box, CardContent } from '@material-ui/core';

const EmployeeFormHour = () => {

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>

                    <ReferenceArrayInput
                        label="Turno"
                        source="shiftId"
                        reference="shifts"
                        perPage={2000}
                        sort={{ field: 'id', order: 'ASC' }}
                        fullWidth
                    >
                        <SelectArrayInput
                            optionText="description"
                            validate={requiredValidate}
                        />
                    </ReferenceArrayInput>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="Fecha ingreso"
                                source="startDate"
                                validate={requiredValidate}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="Fecha egreso"
                                source="endDate"
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <TextInput
                        label="Comentarios"
                        source="comments"
                        parse={toUpperCase}
                        rows="3"
                        multiline
                        resettable
                        fullWidth
                    />

                    <BooleanInput
                        label="Hora extra"
                        source="extraHoursAvailable"
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <div>
                    </div>
                </Box>
            </Box>
        </CardContent>
    );
};

export default EmployeeFormHour;
