import * as React from 'react';
import {
    EditButton, NumberField,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const ExtraHourListMobile = ({ ids, data, basePath }) => {
    const classes = useStyles();

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div className={classes.name}>
                                    {`${data[id].fullName}`}
                                </div>
                                <EditButton
                                    resource="extra-hour"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                            <div>
                                {'Fecha'}
                                :&nbsp;
                                <DateFieldFormatted
                                    record={data[id]}
                                    source="start"
                                    showTime={false}
                                />
                            </div>
                            <div>
                                {'Valor Hora Extra'}
                                :&nbsp;
                                <NumberField
                                    record={data[id]}
                                    source="amount"
                                    locales="es-AR"
                                    options={{ style: 'currency', currency: 'ARS' }}
                                />
                            </div>

                            <div>
                                {'Valor Presentismo'}
                                :&nbsp;
                                <NumberField
                                    record={data[id]}
                                    source="amountPresenteeism"
                                    locales="es-AR"
                                    options={{ style: 'currency', currency: 'ARS' }}
                                />
                            </div>
                            <div>
                                {'Descripcion'}
                                :&nbsp;
                                {data[id].details}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};


export default ExtraHourListMobile;
