import React from 'react';
import { useRecordContext } from 'react-admin';

const CustomOTField = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <span>OT-{record.id}</span>
    );
};

export default CustomOTField;
