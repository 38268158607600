import React, {
  useState,
} from 'react';
import {
  fetchStart,
  fetchEnd,
  useNotify,
  Loading,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import QrReader from 'react-qr-reader';
import { dateParseDateTime } from '../../utils/functions';

const QrReaderEmployee = () => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleScan = (path) => {
    if (path) {
      const request = new Request(`https://api.marasa.com.ar/tracking-api/api/tracking/automatic/${path}`, {
        method: 'PUT',
        body: '',
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });
      setLoading(true);
      dispatch(fetchStart());
      fetch(request)
        .then((response) => {
          if (response.ok) {
            const apiUrl = `https://api.marasa.com.ar/rrhh-api/api/employees/${path}`;
            fetch(apiUrl)
              .then(response => response.json())
              .then(data => {
                const fullName = data["data"].fullName;
                notify(
                  'resources.tracking.notification.approved_success',
                  'info',
                  { employee: fullName, date_now: dateParseDateTime(Date.now()) },
                  false
                );
              })
              .catch((error) =>
                console.log('Falló la API: rrhh-api', error)
              );
          } else {
            if (response.status === 406) {
              //TODO - VER COMO TOMAR EL mensaje desde el backend??
              notify(`El empleado no puede fichar en este horario, por favor dirijirse a RRHH`, 'warning');
            } else {
              notify(`El código QR no existe!`, 'warning');
            }
          }
        })
        .catch((error) => {
          console.log(`Error: ${error.message}`);
        })
        .finally(() => {
          setLoading(false);
          dispatch(fetchEnd());
        });
    }
  };

  const handleError = error => {
    console.log(`Error: ${error.message}`);
  }

  const previewStyle = {
    width: '100%'
  }

  if (loading) return <Loading />;

  return (
    <div>
      <QrReader
        onError={handleError}
        onScan={handleScan}
        resolution={1000}
        style={previewStyle}
      />
    </div>
  )
}

export default QrReaderEmployee;
