import * as React from 'react';
import {
    Datagrid,
    TextField,
    EmailField,
} from 'react-admin';
import FiscalConditionChipField from '../components/FiscalConditionChipField';
import SupplierLinkField from './SupplierLinkField';

const SupplierListDesktop = () => {
    return (
        <Datagrid
            optimized
            rowClick="edit"
        >
            <SupplierLinkField
                sortable={false}
            />
            <TextField
                label="Nombre Fantasía"
                source="nameFantasy"
                sortable={false}
            />
            <TextField
                label="CUIT"
                source="cuit"
                sortable={false}
            />
            <FiscalConditionChipField
                label="Condición Fiscal"
                source="fiscalCondition"
                sortable={false}
            />

            <TextField
                label="Teléfono"
                source="telephone"
                sortable={false}
            />
            <EmailField
                label="Email"
                source="email"
                sortable={false}
            />
        </Datagrid>
    );
};

export default SupplierListDesktop;
