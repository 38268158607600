import React from 'react';
import { Link } from 'react-admin';
import { voucherFormat } from '../utils/functions';

const InvoiceLinkField = (props) => {
    let numberVoucher = voucherFormat(props.record.id, 8);
    return (
        <Link 
            to={`/invoices/${props.record.id}`} 
            onClick={() => localStorage.removeItem('invoiceCart')}
        >
            {props.record.invoiceType} {props.record.pointOfSale}-{numberVoucher}
        </Link>
    )
};

InvoiceLinkField.defaultProps = {
    source: 'id',
    label: 'Factura Nro.',
};

export default InvoiceLinkField;
