import React, {
    useState,
    useEffect,
} from 'react';
import {
    AutocompleteArrayInput,
    useDataProvider,
    LinearProgress,
    required
} from 'react-admin';

const EmployeesInput = () => {
    const dataProvider = useDataProvider();
    const [employees, setEmployees] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider
            .getList('employees', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'fullName', order: 'ASC' },
                filter: { status: true },
            })
            .then(({ data }) => {
                setEmployees(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!employees) return null;

    const choices = employees.map((employee) => ({
        id: employee.id,
        name: employee.fullName,
    }));

    return (
        <AutocompleteArrayInput
            label="Empleados"
            source="employeeId"
            choices={choices}
            validate={required()}
            translateChoice={false}
            helperText={"*El supervisor de la inspección siempre pertence al grupo de trabajo, con lo cual no se puede eliminar, como asi también los empleados que participaron de la OT."}
            fullWidth
        />
    );
};

export default EmployeesInput;
