import React, { useState, useEffect } from 'react';
import {
  useDataProvider,
  LinearProgress,
  //useNotify,
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, CardContent, useMediaQuery } from '@material-ui/core';
import CartItems from './CartItems';
import CartItemsMobileGrid from './CartItemsMobileGrid';
import SectionTitle from '../components/SectionTitle';
import authProvider from "../authProvider";
import { dateParseDateTime } from '../utils/functions';
import QrCodeScannerIcon from '@material-ui/icons/CropFree';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const OrderFormItemsSearch = ({ formData, handleLoading, ...props }) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  //const notify = useNotify();
  const value = '';
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const user = authProvider.getIdentity();
  const [barcode, setBarcode] = useState(""); // Estado para el input del escáner

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    handleLoading(true); // Indicate loading started
    dataProvider
      .getList('items', {
        pagination: { page: 1, perPage: 999999 },
        sort: { field: 'name', order: 'ASC' },
        filter: { status: true },
      })
      .then(({ data }) => {
        let itemsFilter = {};
        if (props.id === undefined || formData.workOrderStatus === 'PENDIENTE') {
          //TODO - SOLO REPUESTOS, NEUMATICOS Y VIDRIOS
          itemsFilter = data.filter(item => item.categoryId !== '135')
        } else {
          //TODO - TODO MENOS HERRAMIENTAS
          itemsFilter = data.filter(item => item.categoryId !== '135')
        }
        setProducts(itemsFilter);
        if (props.id !== undefined) {
          let itemsOrder = formData.items;
          dataProvider.getMany('items', {
            ids: itemsOrder.map((item) => item.id),
          })
            .then(response => {
              let items = response.data;
              //Merge objects by id
              const result = itemsOrder.map(t1 => ({ ...t1, ...items.find(t2 => t2.id === t1.id) }));
              setCart(result);
              setLoading(false);
              handleLoading(false);
            });

        } else {
          if (JSON.parse(localStorage.getItem("cart"))) {
            setCart(JSON.parse(localStorage.getItem("cart")));
          }
          setLoading(false);
          handleLoading(false); // Actualiza el estado de loading
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        handleLoading(false); // Actualiza el estado de loading
      });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "F2") {
        e.preventDefault();
        document.getElementById('input-barcode').focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const addToCart = (item, fromBarcode = false) => {
    if (!item) return;

    if (!fromBarcode) { // Solo muestra confirmación si no es escaneo de código
      const confirmAdd = window.confirm(`¿Deseas agregar el artículo "${item.name}" al carrito?`);
      if (!confirmAdd) return;
    } else {
      setSnackbarMessage(`Artículo "${item.name}" agregado al carrito`);
      setSnackbarOpen(true);
    }

    const productList = [...cart];
    let found = false;

    if (productList.length > 0) {
      for (var i = 0; i < productList.length; i++) {
        if (productList[i].id === item.id) {
          productList[i].itemQuantity++;
          productList[i].userName = user.username;
          productList[i].updatedAt = dateParseDateTime(Date.now());
          found = true;
          break;
        }
      }
    }

    if (!found) {
      if (item.quantity === 0) {
        //notify('resources.orders.notification.stock_error', 'warning', {}, false);
        setSnackbarMessage(`Artículo "${item.name}" sin stock`);
        setSnackbarOpen(true);
      } else {
        item.userName = user.username;
        item.createdAt = dateParseDateTime(Date.now());
        productList.push(item);
      }
    } else {
      setSnackbarMessage(`Artículo "${item.name}" agregado al carrito`);
      setSnackbarOpen(true);
    }
    setCart(productList);
    localStorage.setItem("cart", JSON.stringify(productList));
  };


  const addEmployeeToItem = (item, e) => {
    const productList = [...cart];
    const employeeId = e.target.value;
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            productList[i].employeeId = employeeId;
            productList[i].userName = user.username;
            productList[i].updatedAt = dateParseDateTime(Date.now());
            break;
          }
        }
      }
      setCart(productList);
      localStorage.setItem("cart", JSON.stringify(productList));
    }
  }

  const deleteToCard = (item) => {
    const productList = [...cart];
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            productList.splice(i, 1);
            break;
          }
        }
      }
      setCart(productList);
      localStorage.setItem("cart", JSON.stringify(productList));
    }
  }

  const changeQuantity = (item, e) => {
    const productList = [...cart];
    const index = productList.indexOf(item);
    if (e === '+') {
      productList[index].itemQuantity++;
      setSnackbarMessage(`Se incrementó la cantidad del artículo "${item.name}"`);
      setSnackbarOpen(true);
    }
    else {
      if (productList[index].itemQuantity > 1) {
        productList[index].itemQuantity--;
        setSnackbarMessage(`Se decrementó la cantidad del artículo "${item.name}"`);
        setSnackbarOpen(true);
      }
      else {
        productList.splice(index, 1);
        setCart(productList);
        localStorage.setItem("cart", JSON.stringify(productList));
        return;
      }
    }

    if (productList[index]) {
      productList[index].userName = user.username;
      productList[index].updatedAt = dateParseDateTime(Date.now());
    }

    setCart(productList);
    localStorage.setItem("cart", JSON.stringify(productList));
  }

  const handleBarcodeScan = (event) => {
    if (event.key === "Enter" && barcode.trim() !== "") {
      const item = products.find(p => p.code === barcode.trim());
      if (item) {
        addToCart({ ...item, itemQuantity: item.itemQuantity || 1 }, true);
        setBarcode("");
      } else {
        //notify("Código de barras no " + barcode + " no encontrado", "warning", { autoHideDuration: 10 } );
        setSnackbarMessage(`Código de barras "${barcode}" no encontrado`);
        setSnackbarOpen(true);
        setBarcode("");
      }
    }
  };

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!products || products.length === 0) return <p>No hay datos</p>;

  const choices = products.map((item) => ({
    id: item.id,
    code: item.code,
    name: item.name,
    itemQuantity: 1,
    price: item.price,
    quantity: item.quantity,
    minQuantity: item.minQuantity,
    maxQuantity: item.maxQuantity,
    categoryId: parseFloat(item.categoryId),
    employeeId: null,
    userName: item.userName || null,
    createdAt: item.createdAt || null,
    updatedAt: item.updatedAt || null,
  }));

  return (
    <CardContent>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes('sin stock') || snackbarMessage.includes('no encontrado') ? 'error' : 'success'}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <CardContent>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            {/* <SectionTitle label="Repuestos y/o Materiales" /> */}
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextField
                  id="input-barcode"
                  label="Escanear código de barras"
                  variant="filled"
                  size="small"
                  fullWidth
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value.toUpperCase())}
                  onKeyDown={handleBarcodeScan}
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <QrCodeScannerIcon />
                      </InputAdornment>
                    ),
                  }}
                  helperText="🔍 Presiona Enter para buscar. 📦 Se agregará automáticamente al carrito. ⌨️ Usa F2 para enfocar este campo."
                  FormHelperTextProps={{
                    sx: { fontSize: '0.9rem', color: 'primary.main', fontWeight: 'bold' }
                  }} />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <Autocomplete
                  id="combo-box"
                  options={choices}
                  getOptionLabel={(option) => option.name && option.code ? `${option.name} (${option.code})` : option.name}
                  value={value}
                  onChange={(event, newValue) => {
                    addToCart(newValue)
                  }}
                  size="small"
                  //style={{ maxWidth: 600 }}
                  renderInput={
                    (params) => <TextField {...params} label="Buscar por nombre y código" variant="filled" />
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>

      <CardContent>
        <SectionTitle label="Lista de Repuestos y/o Materiales" />
        <Box display={{ xs: 'block', sm: 'flex' }}>
          {isXsmall ? (
            <CartItemsMobileGrid
              id={props.id}
              employees={(props.id !== undefined && formData.employeeId)}
              products={cart}
              changeQuantity={changeQuantity}
              addEmployeeToItem={addEmployeeToItem}
              deleteToCard={deleteToCard}
            />
          ) : (
            <CartItems
              id={props.id}
              employees={(props.id !== undefined && formData.employeeId)}
              products={cart}
              changeQuantity={changeQuantity}
              addEmployeeToItem={addEmployeeToItem}
              deleteToCard={deleteToCard}
            />)}
        </Box>
      </CardContent>
    </CardContent>
  );
}

export default OrderFormItemsSearch;
