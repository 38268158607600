import * as React from 'react';
import {
    List,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import AbsenceListDatagrid from './AbsenceListDatagrid';
import AbsenceListMobile from './AbsenceListMobile';

const AbsenceList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            sort={{ field: 'fullName', order: 'ASC' }}
            perPage={25}
            bulkActionButtons={false}
            title="Ausencias"
        >
            {isXsmall ? (
                <AbsenceListMobile />
            ) : (
                <AbsenceListDatagrid />
            )}
        </List>
    );

};

export default AbsenceList;
