import React, { useState, useEffect } from 'react';
import {
    SelectInput,
    TextInput,
    LinearProgress,
    FormDataConsumer,
} from 'react-admin';
import {
    TextField,
    Button,
    Box,
    Card,
    CardHeader,
    CardContent,
} from '@material-ui/core';
import { Form } from 'react-final-form'
import methodData from '../components/data/methodData';
import NumberFormatCustom from '../components/NumberFormatCustom';
import { dateParseWithoutTime, uid } from '../utils/functions';
import taxData from '../components/data/taxData';
import jurisdictionData from '../components/data/jurisdictionData';
import MethodsList from '../receipts/MethodsList';
import SectionTitle from '../components/SectionTitle';
import PaymentFootDetail from './PaymentFootDetail';
import SelectInputChecks from './SelectInputChecks';
import { validateAmount, validateVoucher } from '../validators/validators';

const SelectInputMethod = (props) => {
    const [state, setState] = useState();
    const [methods, setMethods] = useState([]);
    const [purchasesPayments, setPurchasesPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [itemsChoices, setItemsChoices] = useState([]);

    useEffect(() => {
        try {
            if (JSON.parse(localStorage.getItem("purchasesPayments"))) {
                setPurchasesPayments(JSON.parse(localStorage.getItem("purchasesPayments")));
            } else {
                localStorage.setItem("purchasesPayments", JSON.stringify([]));
            }
            if (props.record.id !== undefined) {
                setMethods(props.record.methods);
            } else {
                if (JSON.parse(localStorage.getItem("methods"))) {
                    setMethods(JSON.parse(localStorage.getItem("methods")));
                } else {
                    localStorage.setItem("methods", JSON.stringify([]));
                }
            }
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }, [props.record]);

    const onSubmit = async (values, e) => {
        const methodList = [...JSON.parse(localStorage.getItem("methods"))];

        setItemsChoices(array => [...array, values.check]);

        let result = {
            name: uid(),
            method: values.method,
            idCheck: (values.check === undefined) ? null : values.check,
            numberCheck: (values.numberCheck === undefined) ? '' : values.numberCheck,
            myCheck: (values.myCheck === undefined) ? true : values.myCheck,
            bank: (values.bank === undefined) ? '' : values.bank,
            bankTransfer: (values.bankTransfer === undefined) ? '' : values.bankTransfer,
            startDate: (values.startDate === undefined) ? dateParseWithoutTime(Date.now()) : values.startDate,
            dueDate: (values.dueDate === undefined) ? dateParseWithoutTime(Date.now()) : values.dueDate,
            tax: (values.tax === undefined) ? '' : values.tax,
            jurisdiction: (values.jurisdiction === undefined) ? '' : values.jurisdiction,
            regime: '', //No se usa
            numberVoucher: (values.numberVoucher === undefined) ? '' : values.numberVoucher,
            totalVoucher: parseFloat((values.totalVoucher === undefined) ? 0 : values.totalVoucher),

            total: (values.method === 'CHEQUE') ? parseFloat(values.totalCheck) : parseFloat(values.total)
        }
        methodList.push(result);
        localStorage.setItem("methods", JSON.stringify(methodList));
        setMethods(methodList);
        e.reset();
        setState(undefined);
    }

    const handleChange = (event) => {
        if (event.target !== undefined) {
            setState(event.target.value);
        } else {
            setState(undefined);
        }
    }

    const deleteToCard = (item) => {
        const methodList = [...JSON.parse(localStorage.getItem("methods"))];

        if (item.idCheck !== null) {
            setItemsChoices(itemsChoices.filter((i) => i !== item.idCheck));
        }

        if (item !== null && methodList.length > 0) {
            const deteledMethodList = methodList.filter((i) => i.name !== item.name)
            setMethods(deteledMethodList);
            localStorage.setItem("methods", JSON.stringify(deteledMethodList));
        }
    }

    const renderInput = ({
        meta: { touched, error } = { touched: false, error: undefined },
        input: { ...inputProps },
        ...props
    }) => (
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
    );

    const validate = (values) => {
        const errors = {};

        if (!values.method) {
            errors.method = 'Requerido';
        }

        if (values.method !== 'CHEQUE') {
            if (!values.total || values.total <= 0) {
                errors.total = 'Requerido';
            }
        }

        if (values.method === 'CHEQUE') {
            if (!values.check) {
                errors.check = 'Requerido';
            }
        }

        if (values.method === 'RETENCIONES') {
            if (!values.tax) {
                errors.tax = 'Requerido';
            }
            if (!values.jurisdiction) {
                errors.jurisdiction = 'Requerido';
            }
            if (!values.numberVoucher || values.numberVoucher <= 0 || values.numberVoucher > 9999999999) {
                errors.numberVoucher = 'Requerido, Ingresar de 0 a 10 caracteres';
            }
        }
        return errors;
    };

    const choices = methodData.map((item) => ({
        id: item.method,
        name: item.method,
    }));

    if (loading) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!methodData && !methods) return null;

    return (
        <FormDataConsumer>
            {({ formData }) => (formData.primaryAccount !== undefined && formData.secondaryAccount !== undefined) || (formData.primaryAccount !== undefined && formData.supplierId !== undefined) ?
                <div>
                    {props.record.id === undefined &&
                        <Form
                            onSubmit={(values, e) => onSubmit(values, e)}
                            validate={validate}
                            render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} >
                                    <CardContent>
                                        <Box display={{ md: 'block', lg: 'flex' }}>
                                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                                <SectionTitle label="Medios de Pago" />
                                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                        <SelectInput
                                                            autoFocus
                                                            label="Agregue un Medio de Pago"
                                                            source="method"
                                                            name="method"
                                                            component={renderInput}
                                                            initialValue={"EFECTIVO"}
                                                            choices={choices}
                                                            onChange={handleChange}
                                                            resettable
                                                            fullWidth
                                                        />
                                                    </Box>
                                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                        {state !== 'CHEQUE' &&
                                                            <TextInput
                                                                label="Importe"
                                                                source="total"
                                                                name="total"
                                                                component={renderInput}
                                                                InputProps={{
                                                                    inputComponent: NumberFormatCustom
                                                                }}
                                                                validate={validateAmount}
                                                                resettable
                                                                fullWidth
                                                                autoFocus
                                                            />
                                                        }
                                                    </Box>
                                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                        >
                                                            {'Agregar Medio de Pago'}
                                                        </Button>
                                                    </Box>
                                                </Box>

                                                {state === 'TRANSFERENCIA_BANCARIA' &&
                                                    <div>
                                                        <SectionTitle label="Datos de la Transferencia" />
                                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                                <TextInput
                                                                    label="Banco"
                                                                    source="bank"
                                                                    name="bank"
                                                                    component={renderInput}
                                                                    resettable
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                                <TextInput
                                                                    label="Transferencia N°"
                                                                    source="bankTransfer"
                                                                    name="bankTransfer"
                                                                    component={renderInput}
                                                                    resettable
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                }


                                                {state === 'CHEQUE' &&
                                                    <div>
                                                        <SectionTitle label="Datos del Cheque" />
                                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                                            <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                                                                <SelectInputChecks
                                                                    component={renderInput}
                                                                    itemsChoices={itemsChoices}
                                                                />
                                                            </Box>
                                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                                                            </Box>
                                                        </Box>
                                                    </div>
                                                }

                                                {state === 'RETENCIONES' &&
                                                    <div>
                                                        <SectionTitle label="Datos de la Retención" />
                                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                                <SelectInput
                                                                    label="Impuesto"
                                                                    source="tax"
                                                                    name="tax"
                                                                    component={renderInput}
                                                                    choices={taxData}
                                                                    resettable
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                                <SelectInput
                                                                    label="Juridicción"
                                                                    source="jurisdiction"
                                                                    name="jurisdiction"
                                                                    component={renderInput}
                                                                    choices={jurisdictionData}
                                                                    resettable
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Box>

                                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                                <TextInput
                                                                    label="Comprobante de Origen"
                                                                    source="numberVoucher"
                                                                    name="numberVoucher"
                                                                    component={renderInput}
                                                                    validate={validateVoucher}
                                                                    resettable
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                                <TextInput
                                                                    label="Monto del Comprobante S/IVA"
                                                                    source="totalVoucher"
                                                                    name="totalVoucher"
                                                                    component={renderInput}
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom
                                                                    }}
                                                                    validate={validateAmount}
                                                                    resettable
                                                                    fullWidth
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                }
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </form>

                            )}
                        />
                    }

                    {methods !== null &&
                        <div>
                            <MethodsList id={props.record.id} methods={methods} deleteToCard={deleteToCard} />

                            <PaymentFootDetail id={props.record.id} methods={methods} purchasesPayments={purchasesPayments} />
                        </div>
                    }


                </div>
                :
                <Card key={0}>
                    <CardHeader
                        title={
                            <div>
                                Por favor, selecciona las cuentas y/o un proveedor y luego podrás cargar los Medios de Pagos.
                            </div>
                        }
                    />
                </Card>
            }
        </FormDataConsumer>
    )
}

export default SelectInputMethod;
