import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import CustomerList from './CustomerList';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';

export default {
  list: CustomerList,
  create: CustomerCreate,
  edit: CustomerEdit,
  icon: ContactPhoneIcon
};
