const choices = [
    { id: 'FACTURAS A', name: 'FACTURAS A' },
    { id: 'NOTAS DE DÉBITO A', name: 'NOTAS DE DÉBITO A' },
    { id: 'NOTAS DE CRÉDITO A', name: 'NOTAS DE CRÉDITO A' },
    { id: 'RECIBOS A', name: 'RECIBOS A' },
    { id: 'NOTAS DE VENTA AL CONTADO A', name: 'NOTAS DE VENTA AL CONTADO A' },
    { id: 'FACTURAS B', name: 'FACTURAS B' },
    { id: 'NOTAS DE DÉBITO B', name: 'NOTAS DE DÉBITO B' },
    { id: 'NOTAS DE CRÉDITO B', name: 'NOTAS DE CRÉDITO B' },
    { id: 'RECIBOS B', name: 'RECIBOS B' },
    { id: 'NOTAS DE VENTA AL CONTADO B', name: 'NOTAS DE VENTA AL CONTADO B' },
    { id: 'FACTURAS C', name: 'FACTURAS C' },
    { id: 'NOTAS DE DÉBITO C', name: 'NOTAS DE DÉBITO C' },
    { id: 'NOTAS DE CRÉDITO C', name: 'NOTAS DE CRÉDITO C' },
    { id: 'RECIBOS C', name: 'RECIBOS C' },
    { id: 'NOTAS DE VENTA AL CONTADO C', name: 'NOTAS DE VENTA AL CONTADO C' },
    { id: 'LIQUIDACIÓN DE SERVICIOS PÚBLICOS CLASE A', name: 'LIQUIDACIÓN DE SERVICIOS PÚBLICOS CLASE A' },
    { id: 'LIQUIDACIÓN DE SERVICIOS PÚBLICOS CLASE B', name: 'LIQUIDACIÓN DE SERVICIOS PÚBLICOS CLASE B' },
    { id: 'FACTURAS DE EXPORTACIÓN', name: 'FACTURAS DE EXPORTACIÓN' },
    { id: 'NOTAS DE DÉBITO POR OPERACIONES CON EL EXTERIOR', name: 'NOTAS DE DÉBITO POR OPERACIONES CON EL EXTERIOR' },
    { id: 'NOTAS DE CRÉDITO POR OPERACIONES CON EL EXTERIOR', name: 'NOTAS DE CRÉDITO POR OPERACIONES CON EL EXTERIOR' },
    { id: 'FACTURAS - PERMISO EXPORTACIÓN SIMPLIFICADO - DTO. 855/97', name: 'FACTURAS - PERMISO EXPORTACIÓN SIMPLIFICADO - DTO. 855/97' },
    { id: 'COMPROBANTES “A” DE COMPRA PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO', name: 'COMPROBANTES “A” DE COMPRA PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO' },
    { id: 'COMPROBANTES “A” DE CONSIGNACIÓN PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO', name: 'COMPROBANTES “A” DE CONSIGNACIÓN PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO' },
    { id: 'COMPROBANTES “B” DE COMPRA PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO', name: 'COMPROBANTES “B” DE COMPRA PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO' },
    { id: 'COMPROBANTES “B” DE CONSIGNACIÓN PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO', name: 'COMPROBANTES “B” DE CONSIGNACIÓN PRIMARIA PARA EL SECTOR PESQUERO MARÍTIMO' },
    { id: 'LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE A', name: 'LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE A' },
    { id: 'LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE B', name: 'LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE B' },
    { id: 'LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE C', name: 'LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE C' },
    { id: 'COMPROBANTES DE COMPRA DE BIENES USADOS', name: 'COMPROBANTES DE COMPRA DE BIENES USADOS' },
    { id: 'MANDATO - CONSIGNACIÓN', name: 'MANDATO - CONSIGNACIÓN' },
    { id: 'COMPROBANTES PARA RECICLAR MATERIALES', name: 'COMPROBANTES PARA RECICLAR MATERIALES' },
    { id: 'LIQUIDACIÓN PRIMARIA DE GRANOS', name: 'LIQUIDACIÓN PRIMARIA DE GRANOS' },
    { id: 'COMPROBANTES A DEL APARTADO A INCISO F) R.G. N° 1415', name: 'COMPROBANTES A DEL APARTADO A INCISO F) R.G. N° 1415' },
    { id: 'COMPROBANTES B DEL ANEXO I, APARTADO A, INC. F), R.G. N° 1415', name: 'COMPROBANTES B DEL ANEXO I, APARTADO A, INC. F), R.G. N° 1415' },
    { id: 'COMPROBANTES C DEL ANEXO I, APARTADO A, INC. F), R.G. N° 1415', name: 'COMPROBANTES C DEL ANEXO I, APARTADO A, INC. F), R.G. N° 1415' },
    { id: 'NOTAS DE DÉBITO O DOCUMENTO EQUIVALENTE QUE CUMPLAN CON LA R.G. N° 1415', name: 'NOTAS DE DÉBITO O DOCUMENTO EQUIVALENTE QUE CUMPLAN CON LA R.G. N° 1415' },
    { id: 'NOTAS DE CRÉDITO O DOCUMENTO EQUIVALENTE QUE CUMPLAN CON LA R.G. N° 1415', name: 'NOTAS DE CRÉDITO O DOCUMENTO EQUIVALENTE QUE CUMPLAN CON LA R.G. N° 1415' },
    { id: 'OTROS COMPROBANTES A QUE CUMPLEN CON LA R.G. 1415', name: 'OTROS COMPROBANTES A QUE CUMPLEN CON LA R.G. 1415' },
    { id: 'OTROS COMPROBANTES B QUE CUMPLEN CON LA R.G. N° 1415', name: 'OTROS COMPROBANTES B QUE CUMPLEN CON LA R.G. N° 1415' },
    { id: 'OTROS COMPROBANTES C QUE CUMPLEN CON LA R.G. N° 1415', name: 'OTROS COMPROBANTES C QUE CUMPLEN CON LA R.G. N° 1415' },
    { id: 'NOTA DE CRÉDITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE B', name: 'NOTA DE CRÉDITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE B' },
    { id: 'NOTA DE CRÉDITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE C', name: 'NOTA DE CRÉDITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE C' },
    { id: 'NOTA DE DÉBITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE A', name: 'NOTA DE DÉBITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE A' },
    { id: 'NOTA DE DÉBITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE B', name: 'NOTA DE DÉBITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE B' },
    { id: 'NOTA DE DÉBITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE C', name: 'NOTA DE DÉBITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE C' },
    { id: 'NOTA DE CRÉDITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE A', name: 'NOTA DE CRÉDITO LIQUIDACIÓN ÚNICA COMERCIAL IMPOSITIVA CLASE A' },
    { id: 'COMPROBANTES DE COMPRA DE BIENES NO REGISTRABLES A CONSUMIDORES FINALES', name: 'COMPROBANTES DE COMPRA DE BIENES NO REGISTRABLES A CONSUMIDORES FINALES' },
    { id: 'RECIBO FACTURA A RÉGIMEN DE FACTURA DE CRÉDITO', name: 'RECIBO FACTURA A RÉGIMEN DE FACTURA DE CRÉDITO' },
    { id: 'FACTURAS M', name: 'FACTURAS M' },
    { id: 'NOTAS DE DÉBITO M', name: 'NOTAS DE DÉBITO M' },
    { id: 'NOTAS DE CRÉDITO M', name: 'NOTAS DE CRÉDITO M' },
    { id: 'RECIBOS M', name: 'RECIBOS M' },
    { id: 'NOTAS DE VENTA AL CONTADO M', name: 'NOTAS DE VENTA AL CONTADO M' },
    { id: 'COMPROBANTES M DEL ANEXO I APARTADO A INC F) R.G. N° 1415', name: 'COMPROBANTES M DEL ANEXO I APARTADO A INC F) R.G. N° 1415' },
    { id: 'OTROS COMPROBANTES M QUE CUMPLEN CON LA R.G. N° 1415', name: 'OTROS COMPROBANTES M QUE CUMPLEN CON LA R.G. N° 1415' },
    { id: 'CUENTAS DE VENTA Y LÍQUIDO PRODUCTO M', name: 'CUENTAS DE VENTA Y LÍQUIDO PRODUCTO M' },
    { id: 'LIQUIDACIONES M', name: 'LIQUIDACIONES M' },
    { id: 'CUENTAS DE VENTA Y LÍQUIDO PRODUCTO A', name: 'CUENTAS DE VENTA Y LÍQUIDO PRODUCTO A' },
    { id: 'CUENTAS DE VENTA Y LÍQUIDO PRODUCTO B', name: 'CUENTAS DE VENTA Y LÍQUIDO PRODUCTO B' },
    { id: 'LIQUIDACIONES A', name: 'LIQUIDACIONES A' },
    { id: 'LIQUIDACIONES B', name: 'LIQUIDACIONES B' },
    { id: 'DESPACHO DE IMPORTACIÓN', name: 'DESPACHO DE IMPORTACIÓN' },
    { id: 'LIQUIDACIÓN C', name: 'LIQUIDACIÓN C' },
    { id: 'RECIBOS FACTURA DE CRÉDITO', name: 'RECIBOS FACTURA DE CRÉDITO' },
    { id: 'INFORME DIARIO DE CIERRE (ZETA) - CONTROLADORES FISCALES', name: 'INFORME DIARIO DE CIERRE (ZETA) - CONTROLADORES FISCALES' },
    { id: 'TIQUE FACTURA A', name: 'TIQUE FACTURA A' },
    { id: 'TIQUE FACTURA B', name: 'TIQUE FACTURA B' },
    { id: 'TIQUE', name: 'TIQUE' },
    { id: 'REMITO ELECTRÓNICO', name: 'REMITO ELECTRÓNICO' },
    { id: 'RESUMEN DE DATOS', name: 'RESUMEN DE DATOS' },
    { id: 'OTROS COMPROBANTES - DOCUMENTOS EXCEPTUADOS - NOTAS DE CRÉDITO', name: 'OTROS COMPROBANTES - DOCUMENTOS EXCEPTUADOS - NOTAS DE CRÉDITO' },
    { id: 'REMITOS R', name: 'REMITOS R' },
    { id: 'OTROS COMPROBANTES QUE NO CUMPLEN O ESTÁN EXCEPTUADOS DE LA R.G. 1415 Y SUS MODIFICACIONES', name: 'OTROS COMPROBANTES QUE NO CUMPLEN O ESTÁN EXCEPTUADOS DE LA R.G. 1415 Y SUS MODIFICACIONES' },
    { id: 'TIQUE NOTA DE CRÉDITO', name: 'TIQUE NOTA DE CRÉDITO' },
    { id: 'TIQUE FACTURA C', name: 'TIQUE FACTURA C' },
    { id: 'TIQUE NOTA DE CRÉDITO A', name: 'TIQUE NOTA DE CRÉDITO A' },
    { id: 'TIQUE NOTA DE CRÉDITO B', name: 'TIQUE NOTA DE CRÉDITO B' },
    { id: 'TIQUE NOTA DE CRÉDITO C', name: 'TIQUE NOTA DE CRÉDITO C' },
    { id: 'TIQUE NOTA DE DÉBITO A', name: 'TIQUE NOTA DE DÉBITO A' },
    { id: 'TIQUE NOTA DE DÉBITO B', name: 'TIQUE NOTA DE DÉBITO B' },
    { id: 'TIQUE NOTA DE DÉBITO C', name: 'TIQUE NOTA DE DÉBITO C' },
    { id: 'TIQUE FACTURA M', name: 'TIQUE FACTURA M' },
    { id: 'TIQUE NOTA DE CRÉDITO M', name: 'TIQUE NOTA DE CRÉDITO M' },
    { id: 'TIQUE NOTA DE DÉBITO M', name: 'TIQUE NOTA DE DÉBITO M' },
    { id: 'FACTURA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) A', name: 'FACTURA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) A' },
    { id: 'NOTA DE DÉBITO ELECTRÓNICA MIPYMES (FCE) A', name: 'NOTA DE DÉBITO ELECTRÓNICA MIPYMES (FCE) A' },
    { id: 'NOTA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) A', name: 'NOTA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) A' },
    { id: 'FACTURA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) B', name: 'FACTURA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) B' },
    { id: 'NOTA DE DÉBITO ELECTRÓNICA MIPYMES (FCE) B', name: 'NOTA DE DÉBITO ELECTRÓNICA MIPYMES (FCE) B' },
    { id: 'NOTA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) B', name: 'NOTA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) B' },
    { id: 'FACTURA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) C', name: 'FACTURA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) C' },
    { id: 'NOTA DE DÉBITO ELECTRÓNICA MIPYMES (FCE) C', name: 'NOTA DE DÉBITO ELECTRÓNICA MIPYMES (FCE) C' },
    { id: 'NOTA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) C', name: 'NOTA DE CRÉDITO ELECTRÓNICA MIPYMES (FCE) C' },
    { id: 'LIQUIDACIÓN DE COMPRA DE TABACO VERDE', name: 'LIQUIDACIÓN DE COMPRA DE TABACO VERDE' },
    { id: 'LIQUIDACIÓN DE COMPRA DE PESCADO', name: 'LIQUIDACIÓN DE COMPRA DE PESCADO' },
    { id: 'REMITO ELECTRÓNICO CÁRNICO', name: 'REMITO ELECTRÓNICO CÁRNICO' },
];

export default choices
