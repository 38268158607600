import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InvoiceList from './InvoiceList';
import InvoiceCreate from './InvoiceCreate';
import InvoiceEdit from './InvoiceEdit';

export default {
  list: InvoiceList,
  create: InvoiceCreate,
  edit: InvoiceEdit,
  icon: AttachMoneyIcon
};
