import * as React from 'react';
import {
    Datagrid,
    TextField,
    EmailField,
} from 'react-admin';
import CustomerLinkField from './CustomerLinkField';
import FiscalConditionChipField from '../components/FiscalConditionChipField';

const CustomerListDesktop = () => {
    return (
        <Datagrid
            optimized
            rowClick="edit"
        >
            <CustomerLinkField
                sortable={false}
            />
            <TextField
                label="CUIT"
                source="cuit"
                sortable={false}
            />
            <FiscalConditionChipField
                label="Condición Fiscal"
                source="fiscalCondition"
                sortable={false}
            />
            <TextField
                label="Teléfono"
                source="telephone"
                sortable={false}
            />
            <EmailField
                label="Email"
                source="email"
                sortable={false}
            />
        </Datagrid>
    );
};

export default CustomerListDesktop;
