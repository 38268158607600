import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import BillTo from './BillTo';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceHeader from './InvoiceHeader';
import ThankYouMsg from './ThankYouMsg';

const styles = StyleSheet.create({
    page: {
        fontSize: 15,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: 'column',
    }
});

const Invoice = ({ pdfDocument }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <InvoiceHeader pdfDocument={pdfDocument} />
            <BillTo pdfDocument={pdfDocument} />
            <InvoiceItemsTable pdfDocument={pdfDocument} />
            <ThankYouMsg />
        </Page>

    </Document>
);

export default Invoice;