import * as React from 'react';
import { Button } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import { currencyFormatter } from '../utils/functions';

const DeleteButtonCard = (({ product, deleteToCard }) => {
    const name = (product.name !== undefined) ? product.name : '';

    const item = (product.method !== undefined) ? `${product.method} de ${currencyFormatter(product.total)}` : name;

    return (
        <Button
            size="small"
            variant="contained"
            label="Eliminar"
            onClick={
                () => {
                    if (!window.confirm(`¿Esta Seguro que desea eliminar el item ${item}?`))
                        return false;
                    return deleteToCard(product);
                }
            }
        >
            <DeleteIcon />
        </Button>
    )
});

export default DeleteButtonCard;