import * as React from 'react';
import {
    Datagrid,
    NumberField,
    TextField,
} from 'react-admin';
import DateFieldFormatted from '../components/DateFieldFormatted';

const ExtraHourListDesktop = () => {

    return (
        <Datagrid
            optimized
            rowClick="edit"
        >

            <TextField
                label="Empleado"
                source="fullName"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha"
                source="start"
                showTime={false}
                sortable={false}
            />
            <NumberField
                label="Valor Hora Extra"
                source="amount"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                sortable={false}
            />
            <NumberField
                label="Valor Presentismo"
                source="amountPresenteeism"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                sortable={false}
            />
            <TextField
                label="Decripción"
                source="details"
                sortable={false}
            />
        </Datagrid>
    );
};

export default ExtraHourListDesktop;
