import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    DateInput
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Button, Card, Box, CardContent, CardActions, CircularProgress, makeStyles } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import SectionTitle from '../components/SectionTitle';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const ReportTax = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    };

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'IVA Compras-Ventas'} />
                            <CardContent>
                                <SectionTitle label="Desde aquí podrás solicitar el reporte de IVA compras-ventas para el período indicado." />
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="Período"
                                                    source="start"
                                                    validate={requiredValidate}
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={4}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={loading}
                                                    >
                                                        {loading && (<CircularProgress size={25} thickness={2} />)}

                                                        {'Descargar'}
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    </form>
                )}
            />


        </div>

    );
};

export default ReportTax;
