 const choices = [
    { id: 'ENCARGADO', name: 'ENCARGADO' },
    { id: 'PINTOR', name: 'PINTOR' },
    { id: 'AYUDANTE_DE_PINTOR', name: 'AYUDANTE DE PINTOR' },
    { id: 'CHAPISTA', name: 'CHAPISTA' },
    { id: 'AYUDANTE_DE_CHAPISTA', name: 'AYUDANTE DE CHAPISTA' },
    { id: 'TERMINADOR', name: 'TERMINADOR' },
    { id: 'MANTENIMIENTO', name: 'MANTENIMIENTO' },
    { id: 'PAÑOLERO', name: 'PAÑOLERO' },
    { id: 'PLAYERO', name: 'PLAYERO' },
    { id: 'SERENO', name: 'SERENO' }
];

export default choices
