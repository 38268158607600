import * as React from 'react';
import {
    useRecordContext
} from 'react-admin';
import { getTypeAbsence } from '../utils/functions';
import { Chip } from '@material-ui/core';

const AbsenceChipField = ({ source }) => {
    const record = useRecordContext();
    return (
        <Chip
            label={record && getTypeAbsence(record[source])}
            size="small"
        />
    );
};

export default AbsenceChipField;