import React from 'react';
import {
    Edit,
    useNotify,
    useRefresh,
    useRedirect,
    TabbedForm,
    FormTab,
} from 'react-admin';
import ReceiptForm from './ReceiptForm';
import ReceiptEditToolbar from './ReceiptEditToolbar';
import SelectInputMethod from './SelectInputMethod';

const InvoiceEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/receipts`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const Title = ({ record }) => {
        return <span>{record ? `#${record.id}` : ''}</span>;
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<Title />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                //warnWhenUnsavedChanges
                toolbar={<ReceiptEditToolbar />}
                redirect={redirect}
            >
                <FormTab label="Cabecera" >
                    <ReceiptForm />
                </FormTab>
                <FormTab label="Medios de Pago" path="method">
                    <SelectInputMethod {...props} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default InvoiceEdit;
