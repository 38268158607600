import React from 'react';
import { Button, useNotify, useRefresh } from 'react-admin';
import { Tooltip } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import authProvider from '../../authProvider';

const API_BASE_URL = "https://api.marasa.com.ar"; // "http://localhost:9096";
const API_WORKSHOP = "/workshop-api/api"; // "/api";

const OrderDeliveryButton = ({ record, isXsmall }) => {
    const user = authProvider.getIdentity();
    const notify = useNotify();
    const refresh = useRefresh();

    if (!record || !(record.workOrderStatus === 'EN_PROGRESO' || record.workOrderStatus === 'FINALIZADA')) {
        return null;
    }

    const handleConfirm = async () => {
        const confirmMessage = `¿Está seguro de que desea FINALIZADA PARA ENTREGAR la OT ${record.id}? (Esta acción no se puede deshacer)`;
        if (!window.confirm(confirmMessage)) return;

        try {
            const response = await fetch(
                `${API_BASE_URL}${API_WORKSHOP}/orders/${record.id}/workOrderStatus/FINALIZADA_ENTREGAR`,
                {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        user_id: user.id,
                        user_name: user.username,
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.text();
                throw new Error(errorData || 'Error en la confirmación');
            }

            notify(`Finalizada para entregar la OT #${record.id}`, 'info');
            refresh();
        } catch (error) {
            notify(error.message, 'error');
            console.error(error);
        }
    };

    return (
        <Tooltip title="Finalizar para entregar OT" arrow>
            {isXsmall ? (
                <Button
                    size="large"
                    label="FINALIZAR ENTREGAR"
                    variant="outlined"
                    onClick={handleConfirm}
                    style={{
                        color: '#2196f3',
                        borderColor: '#2196f3'
                    }}
                >
                    <LocalShippingIcon style={{ color: '#2196f3' }} />
                </Button>
            ) : (
                <Button
                    size="small"
                    label="FINALIZAR ENTREGAR"
                    variant="outlined"
                    onClick={handleConfirm}
                    style={{
                        color: '#2196f3',
                        borderColor: '#2196f3',
                        minWidth: 20,
                        padding: '2px 3px',
                        fontSize: '10px',
                        lineHeight: '12px'
                    }}
                >
                    <LocalShippingIcon style={{ color: '#2196f3', fontSize: '14px' }} />
                </Button>
            )}
        </Tooltip>
    );
};

export default OrderDeliveryButton;
