import React, { useEffect, useState } from 'react';
import {
    LinearProgress,
    useDataProvider,
} from 'react-admin';
import {
    Box,
    Card,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
    CardContent
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import SectionTitle from '../components/SectionTitle';
import {
    calculatePartialTotal,
    calculateTotalReceiptsInvoices,
    currencyFormatter,
    voucherFormat
} from '../utils/functions';
import NumberFormatCustom from '../components/NumberFormatCustom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const InvoiceEditCard = ({ customerId }) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [invoices, setInvoices] = useState([]);
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();
    
    useEffect(() => {
        dataProvider
            .getList('invoices', {
                pagination: { page: 1, perPage: 999999 },
                sort: { field: 'id', order: 'ASC' },
                filter: { status: true },
            })
            .then(response => {

                const invoices = response.data.filter(
                    item => (item.customerId === customerId) && (item.invoiceStatus === 'FACTURADA') && (item.invoicePaymentStatus === 'IMPAGA' || item.invoicePaymentStatus === 'PAGO_PARCIAL') && (item.partialTotal > 0)
                ).map((item) => ({
                    id: item.id,
                    invoiceType: item.invoiceType,
                    pointOfSale: item.pointOfSale,
                    customerId: item.customerId,
                    total: item.total,
                    partialTotal: item.partialTotal,
                    totalReceipt: item.partialTotal
                }));

                setInvoices(invoices);
                localStorage.setItem("invoicesReceipts", JSON.stringify(invoices));

                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [customerId]) // eslint-disable-line react-hooks/exhaustive-deps

    const changePrice = (item, e) => {
        const invoiceList = [...invoices];
        const index = invoiceList.indexOf(item);
        invoiceList[index].totalReceipt = (e.target.value !== '' && e.target.value <= invoiceList[index].partialTotal) ? parseFloat(e.target.value) : 0;
        setInvoices(invoiceList);
        localStorage.setItem("invoicesReceipts", JSON.stringify(invoiceList));
        localStorage.removeItem('methods');
    }

    if (loading) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!invoices) return null;

    const total = calculatePartialTotal(invoices);
    const totalReceipts = calculateTotalReceiptsInvoices(invoices);

    return (
        <div>
            <SectionTitle label="¿Qué te pagaron?" />
            <Box mt="1em" />
            {isXsmall ?
                (invoices.length === 0) ?
                    <div>
                        <p>&nbsp;&nbsp;Sin facturas de compra para pagar!</p>
                    </div>
                    :
                    invoices.map((item, i) => (
                        <Card key={item.id} className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <div>
                                    {'Comprobante'}
                                    :&nbsp;
                                    {item.invoiceType} {item.pointOfSale}-{voucherFormat(item.id)}
                                </div>
                                <div>
                                    {'Importe Total'}
                                    :&nbsp;
                                    <NumberFormat
                                        value={item.total}
                                        displayType={'text'}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        decimalScale={2}
                                        isNumericString={true}
                                        prefix={'$ '}
                                    />
                                </div>
                                <div>
                                    {'Falta Cancelar'}
                                    :&nbsp;
                                    <NumberFormat
                                        value={item.partialTotal}
                                        displayType={'text'}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        decimalScale={2}
                                        isNumericString={true}
                                        prefix={'$ '}
                                    />
                                </div>
                                <div>
                                    {'¿Cuanto te pagó?'}
                                    :&nbsp;
                                    <FormControl size="small">
                                        <TextField
                                            id="item-totalReceipt"
                                            label="Importe Total"
                                            value={item.totalReceipt}
                                            name="totalReceipt"
                                            size="small"
                                            variant="filled"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                                inputProps: { style: { textAlign: 'right' } }
                                            }}
                                            onChange={(e) => changePrice(item, e)}
                                        />
                                    </FormControl>
                                </div>
                            </CardContent>
                        </Card>
                    ))
                :
                <Card>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {'Comprobante'}
                                </TableCell>
                                <TableCell>
                                    {'Importe Total'}
                                </TableCell>
                                <TableCell>
                                    {'Falta Cancelar'}
                                </TableCell>
                                <TableCell>
                                    {'¿Cuanto te pagó?'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(invoices.length === 0) ?
                                <div>
                                    <p>&nbsp;&nbsp;Sin facturas por cobrar!</p>
                                </div>
                                :
                                invoices.map((item) => (
                                    <TableRow>
                                        <TableCell>
                                            {item.invoiceType} {item.pointOfSale}-{voucherFormat(item.id)}
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                value={item.total}
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={2}
                                                isNumericString={true}
                                                prefix={'$ '}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                value={item.partialTotal}
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={2}
                                                isNumericString={true}
                                                prefix={'$ '}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl size="small">
                                                <TextField
                                                    id="item-totalReceipt"
                                                    label="Importe Total"
                                                    value={item.totalReceipt}
                                                    name="totalReceipt"
                                                    size="small"
                                                    variant="filled"
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                        inputProps: { style: { textAlign: 'right' } }
                                                    }}
                                                    onChange={(e) => changePrice(item, e)}
                                                />
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Card>
            }
            <Box mt="1em" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Comprobantes a Cancelar" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(total)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Cancelado" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(totalReceipts)}
                        </Typography>
                    </Box>
                </Box>
            </Box>

        </div>
    );
}

export default InvoiceEditCard;