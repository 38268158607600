import * as React from 'react';
import {
    Edit,
    SimpleForm,
    useNotify,
    useRefresh,
    useRedirect
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import ExtraHourForm from './ExtraHourForm';

const Title = ({ record }) => {
    return <span>{record ? `${record.id}` : ''}</span>;
};

const ExtraHourEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/employees/${data.employeeId}/extra-hour`);
        refresh();
    };

    return (
        <Edit
            {...props}
            title={<Title />}
            onSuccess={onSuccess}
            undoable={false}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
            >
                <ExtraHourForm />
            </SimpleForm>
        </Edit>
    );
};


export default ExtraHourEdit;
