import * as React from 'react';
import {
    DateInput,
    FormDataConsumer,
    NumberInput,
    TextInput,
} from 'react-admin';
import {
    requiredValidate,
} from '../validators/validators';
import {
    dateParse,
    dateParseWithoutTime,
    toUpperCase,
} from '../utils/functions';
import authProvider from "../authProvider";
import { Box, CardContent, InputAdornment } from '@material-ui/core';

const ExtraHourForm = (props) => {
    const user = authProvider.getIdentity();

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <DateInput
                                    label="Fecha"
                                    source="start"
                                    defaultValue={dateParseWithoutTime(Date.now())}
                                    validate={requiredValidate}
                                    parse={dateParse}
                                    fullWidth
                                />
                                :
                                <DateInput
                                    label="Fecha"
                                    source="start"
                                    validate={requiredValidate}
                                    parse={dateParse}
                                    fullWidth
                                />
                            }
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id !== undefined) &&
                                <TextInput
                                    label="Nombre"
                                    source="fullName"
                                    disabled={true}
                                    validate={requiredValidate}
                                    parse={toUpperCase}
                                    fullWidth
                                />
                            }
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Valor de la Hora Extra"
                                source="amount"
                                fullWidth
                                validate={requiredValidate}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>

                            <NumberInput
                                label="Valor del Presentismo"
                                source="amountPresenteeism"
                                fullWidth
                                validate={requiredValidate}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>

                    <TextInput
                        label="Descripción"
                        source="details"
                        validate={requiredValidate}
                        parse={toUpperCase}
                        rows={3}
                        multiline
                        resettable
                        fullWidth
                    />

                    <FormDataConsumer>
                        {({ formData }) => {
                            formData.userId = user.id;
                            formData.end = formData.start;
                            formData.lastUpdate = formData.start;
                        }}
                    </FormDataConsumer>
                </Box>

            </Box>
        </CardContent>
    );
};

export default ExtraHourForm;
