import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    usePermissions
} from 'react-admin';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
//RRHH
import employees from '../employees'; //EMPLEADOS
import absences from '../absences'; //AUSENCIAS
import extraHour from '../extra-hour'; //HORAS EXTRAS
import shifts from '../shifts'; //TURNOS
import tracking from '../tracking'; //FICHAJE
import holidays from '../holidays';
import reportsEmployees from '../reports-rrhh'; //REPORTES EMPLEADOS
//TALLER
import vehicles from '../vehicles';//VEHICULOS
import orders from '../orders';//ORDENTES DE TRABAJO
//import baskets from '../baskets';//CANASTOS
import inspections from '../inspections';//INSPECCIONES
import categories from '../categories';//CATEGORIAS
import items from '../items';//PANOL
import warehouse from '../warehouse';//DEPOSITO
import tools from '../tools';//HERRAMIENTAS
import consumables from '../consumables';//CONSUMIBLES
//CONTABILIDAD
import invoices from '../invoices'; //FACTURAS
import customers from '../customers'; //CLIENTES
import payments from '../payments'; //ORDENES DE PAGO
import purchases from '../purchases'; //COMPRAS
import purchasesInvoices from '../purchases-invoices'; //FACTURAS DE COMPRAS
import suppliers from '../suppliers'; //PROVEEDORES
import receipts from '../receipts'; //RECIBOS DE COBRO
import cash from '../cash'; //FLUJOS DE CAJA
import checks from '../checks'; //CHEQUES
//SUBMENU
import SubMenu from './SubMenu';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {
    getAllRolesWorkshop,
    getRolesFinance,
    getRolesOnlyDriverExceptAdmin,
    getRolesOnlyInspectorExceptAdmin,
    getRolesOnlyStorekeeperExceptAdmin,
    getRolesOnlySupervisorExceptAdmin,
    getRolesRRHH,
    getRolesWorkshopOnly
} from '../appRolesComponents';

const Menu = ({ onMenuClick, dense, logout }) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const [state, setState] = useState({
        menuEmployees: false,
        menuWorkshop: false,
        menuFinance: false,
        menuPurchases: false,
        menuCustomers: false,
        menuSuppliers: false,
        menuTreasury: false,
    });

    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const isRRHH = getRolesRRHH(permissions);
    const isAllWorkshop = getAllRolesWorkshop(permissions);
    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isFinance = getRolesFinance(permissions);
    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isStorekeeper = getRolesOnlyStorekeeperExceptAdmin(permissions);
    const isSupervisor = getRolesOnlySupervisorExceptAdmin(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);

    return (
        <div>
            {' '}
            {(isRRHH || isFinance || isWorkshopOnly || isStorekeeper || isSupervisor) &&
                <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            }

            {(isRRHH || isStorekeeper || isSupervisor) &&
                <SubMenu
                    handleToggle={() => handleToggle('menuEmployees')}
                    isOpen={state.menuEmployees}
                    sidebarIsOpen={open}
                    name="pos.menu.employees"
                    icon={<employees.icon />}
                >
                    {(isRRHH) &&
                        <MenuItemLink
                            to={'/employees'}
                            primaryText={translate('resources.employees.name')}
                            leftIcon={<employees.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isRRHH) &&
                        <MenuItemLink
                            to={'/absences'}
                            primaryText={translate('resources.absences.name')}
                            leftIcon={<absences.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isRRHH) &&
                        <MenuItemLink
                            to={'/extra-hour'}
                            primaryText={translate('resources.extra_hour.name')}
                            leftIcon={<extraHour.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isRRHH) &&
                        <MenuItemLink
                            to={'/shifts'}
                            primaryText={translate('resources.shifts.name')}
                            leftIcon={<shifts.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isRRHH || isStorekeeper || isSupervisor) &&
                        <MenuItemLink
                            to={'/tracking'}
                            primaryText={translate('resources.tracking.name')}
                            leftIcon={<tracking.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isRRHH) &&
                        <MenuItemLink
                            to={'/holidays'}
                            primaryText={translate('resources.holidays.name')}
                            leftIcon={<holidays.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isRRHH) &&
                        <MenuItemLink
                            to={'/reports-rrhh'}
                            primaryText={translate('resources.reports_rrhh.name')}
                            leftIcon={<reportsEmployees.icon />}
                            onClick={onMenuClick}
                        />
                    }
                </SubMenu>
            }
            
            {(isAllWorkshop) &&
                <SubMenu
                    handleToggle={() => handleToggle('menuWorkshop')}
                    isOpen={state.menuWorkshop}
                    sidebarIsOpen={open}
                    name="pos.menu.workshop"
                    icon={<vehicles.icon />}
                >

                    {(isWorkshopOnly || isDriver || isInspector) &&
                        <MenuItemLink
                            to={'/vehicles'}
                            primaryText={translate('resources.vehicles.name')}
                            leftIcon={<vehicles.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isDriver || isInspector || isSupervisor) &&
                        <MenuItemLink
                            to={'/inspections'}
                            primaryText={translate('resources.inspections.name')}
                            leftIcon={<inspections.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isDriver || isInspector) &&
                        <MenuItemLink
                            to={'/orders'}
                            primaryText={translate('resources.orders.name')}
                            leftIcon={<orders.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isStorekeeper || isSupervisor) &&
                        <MenuItemLink
                            to={'/baskets'}
                            primaryText={translate('resources.baskets.name')}
                            leftIcon={<orders.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isSupervisor) &&
                        <MenuItemLink
                            to={'/categories'}
                            primaryText={translate('resources.categories.name')}
                            leftIcon={<categories.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isStorekeeper || isSupervisor) &&
                        <MenuItemLink
                            to={'/items'}
                            primaryText={translate('resources.items.name')}
                            leftIcon={<items.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isStorekeeper || isSupervisor) &&
                        <MenuItemLink
                            to={'/warehouse'}
                            primaryText={translate('pos.menu.warehouse')}
                            leftIcon={<warehouse.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isStorekeeper || isSupervisor) &&
                        <MenuItemLink
                            to={'/tools'}
                            primaryText={translate('resources.tools.name')}
                            leftIcon={<tools.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isStorekeeper || isSupervisor) &&
                        <MenuItemLink
                            to={'/consumables'}
                            primaryText={translate('resources.consumables.name')}
                            leftIcon={<consumables.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {(isWorkshopOnly || isSupervisor) &&
                        <MenuItemLink
                            to={'/reports-workshop'}
                            primaryText={translate('resources.reports_workshop.name')}
                            leftIcon={<TrendingUpIcon />}
                            onClick={onMenuClick}
                        />
                    }
                </SubMenu>
            }

            {(isFinance || isSupervisor) &&
                <SubMenu
                    handleToggle={() => handleToggle('menuFinance')}
                    isOpen={state.menuFinance}
                    sidebarIsOpen={open}
                    name="pos.menu.finance"
                    icon={<invoices.icon />}
                >

                    {(isFinance) &&
                        <MenuItemLink
                            to={'/invoices'}
                            primaryText={translate('resources.invoices.name')}
                            leftIcon={<invoices.icon />}
                            onClick={onMenuClick}
                        />
                    }

                    {/* SUBMENU CLIENTES */}
                    {(isFinance) &&
                    <MenuItemLink
                        to={'/customers'}
                        primaryText={translate('pos.menu.customers')}
                        leftIcon={<customers.icon />}
                        onClick={onMenuClick}
                    />
                    }
                    {/* SUBMENU PROVEEDORES */}
                    {(isFinance || isSupervisor) &&
                    <MenuItemLink
                        to={'/suppliers'}
                        primaryText={translate('pos.menu.suppliers')}
                        leftIcon={<suppliers.icon />}
                        onClick={onMenuClick}
                    />
                    }
                    {/* SUBMENU COMPRAS */}
                    {(isFinance || isSupervisor) &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuPurchases')}
                        isOpen={state.menuPurchases}
                        sidebarIsOpen={open}
                        name="resources.purchases.name"
                        icon={<purchases.icon />}
                    >
                        <MenuItemLink
                            to={'/purchases-invoices'}
                            primaryText={translate('pos.menu.purchasesInvoices')}
                            leftIcon={<purchasesInvoices.icon />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/purchases'}
                            primaryText={translate('pos.menu.ordersPurchases')}
                            leftIcon={<purchases.icon />}
                            onClick={onMenuClick}
                        />
                    </SubMenu>
                    }

                    {/* SUBMENU TESORERIA */}
                    {(isFinance) &&
                    <SubMenu
                        handleToggle={() => handleToggle('menuTreasury')}
                        isOpen={state.menuTreasury}
                        sidebarIsOpen={open}
                        name="pos.menu.treasury"
                        icon={<cash.icon />}
                    >

                        <MenuItemLink
                            to={'/receipts'}
                            primaryText={translate('pos.menu.receipts')}
                            leftIcon={<receipts.icon />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={'/payments'}
                            primaryText={translate('pos.menu.payments')}
                            leftIcon={<payments.icon />}
                            onClick={onMenuClick}
                        />

                        <MenuItemLink
                            to={'/cash'}
                            primaryText={translate('pos.menu.cash')}
                            leftIcon={<cash.icon />}
                            onClick={onMenuClick}
                        />

                        <MenuItemLink
                            to={'/checks'}
                            primaryText={translate('pos.menu.checks')}
                            leftIcon={<checks.icon />}
                            onClick={onMenuClick}
                        />

                        <MenuItemLink
                            to={'/accounts'}
                            primaryText={translate('pos.menu.accounts')}
                            leftIcon={<AccountBalanceWalletIcon />}
                            onClick={onMenuClick}
                        />

                    </SubMenu>
                    }
                    {/* SUBMENU REPORTES */}
                    {(isFinance) &&
                        <MenuItemLink
                            to={'/reports-finance'}
                            primaryText={translate('resources.reports_finance.name')}
                            leftIcon={<TrendingUpIcon />}
                            onClick={onMenuClick}
                        />
                    }

                </SubMenu>
            }
            {isXSmall && (
                <MenuItemLink
                    to="/profile"
                    primaryText={translate('pos.profile')}
                    leftIcon={<AccountCircleIcon />}
                    onClick={onMenuClick}
                />
            )}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
