const choices = [
    { 
        id: 'Contado', 
        name: 'Contado' 
    },
    { 
        id: 'Cuenta Corriente', 
        name: 'Cuenta Corriente' 
    },
    { 
        id: 'Tarjeta Débito', 
        name: 'Tarjeta Débito' 
    },
    { 
        id: 'Tarjeta Crédito', 
        name: 'Tarjeta Crédito' 
    },
    { 
        id: 'Cheque', 
        name: 'Cheque' 
    },
    { 
        id: 'Transferencia Bancaria', 
        name: 'Transferencia Bancaria' 
    },
    { 
        id: 'Mercado Pago', 
        name: 'Mercado Pago' 
    },
    {
         id: 'Otra', 
         name: 'Otra' 
    },
    { 
        id: 'Sin Detallar', 
        name: 'Sin Detallar' 
    },
];

export default choices
