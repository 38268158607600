const choices = [
    { id: 'CLIENTES', name: 'CLIENTES', type: 'RECIBO' }, 
    { id: 'PROVEEDORES', name: 'PROVEEDORES', type: 'ORDEN' },  
 
    { id: 'INGRESOS_VARIOS', name: 'INGRESOS_VARIOS', type: 'RECIBO' },  

    { id: 'BANCO', name: 'BANCO', type: 'AMBOS' },  
    { id: 'MOVIMIENTOS_DE_CAJA', name: 'MOVIMIENTOS_DE_CAJA', type: 'AMBOS' }, 
 
    { id: 'GASTOS_VARIOS', name: 'GASTOS_VARIOS', type: 'ORDEN'},  
    { id: 'GASTOS_DE_OFICINA', name: 'GASTOS_DE_OFICINA', type: 'ORDEN' },  
    { id: 'SUELDOS', name: 'SUELDOS', type: 'ORDEN' },  
  
];
//AMBOS = RECIBOS Y ORDEN DE PAGO
//RECIBOS DE COBRO
//ORDEN DE PAGO

export default choices
