import React from 'react';
import {
    TopToolbar,
    LinearProgress,
} from 'react-admin';
import PDFButtonInvoice from './PDFButtonInvoice';

const EditActionsInvoice = ({ data }) => {
    return (
        <TopToolbar>
            {(data !== undefined) ?
                <PDFButtonInvoice {...data} />
                :
                <LinearProgress />
            }
        </TopToolbar>
    )
};

export default EditActionsInvoice;