import * as React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';

const IconButtonRemove = (({ product, changeQuantity }) => (
    <IconButton
        size="small"
        color="secondary"
        onClick={() => changeQuantity(product, '-')}
    >
        <RemoveIcon fontSize="small" />
    </IconButton>
));

export default IconButtonRemove;