import React from 'react';
import moment from "moment";
import { Chip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

const DaysInWorkshopChip = ({ startDate, endDate }) => {
    let m1 = moment(startDate);
    let m2 = moment(endDate);
    let m3 = m2.diff(m1, 'minutes');
    //let m4 = m2.diff(m1, 'h'); Calcula la cantidad de horas

    let numdays = Math.floor(m3 / 1440);
    let numhours = Math.floor((m3 % 1440) / 60);
    let numminutes = Math.floor((m3 % 1440) % 60);
    let result = `Calculo de dias: ${numdays} dia(s) ${numhours}h ${numminutes}m`;

    return (
        <Chip label={result} icon={<HelpIcon />} />
    )
};

export default DaysInWorkshopChip;