import React from 'react';
import {
    SelectInput,
    NumberInput,
    TextInput
} from 'react-admin';
import { useForm } from 'react-final-form';
import { InputAdornment, Box, CardContent } from '@material-ui/core';
import jurisdictionData from '../components/data/jurisdictionData';
import SectionTitle from '../components/SectionTitle';
import NumberFormatCustom from '../components/NumberFormatCustom';
import { calculateTotalTaxBase } from '../utils/functions';

const InvoiceFormTax = (props) => {
    const form = useForm();
    const invoiceCart = JSON.parse(localStorage.getItem("invoiceCart"));
    const subtotalTax = (invoiceCart) ? calculateTotalTaxBase(invoiceCart) : 0;
    form.change("taxBase", subtotalTax);

    const changeAlicuota = (e) => {
        const iibb = (e.target.value / 100) * subtotalTax;
        form.change("iibb", Number.parseFloat(iibb).toFixed(2));
    }

    const isReadOnly = (props.id !== undefined);
    
    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Percepción IIBB" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="Jurisdicción"
                                source="juridiction"
                                choices={jurisdictionData}
                                initialValue={"BUENOS AIRES"}
                                fullWidth
                                disabled={isReadOnly}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Base Imponible"
                                source="taxBase"
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                                fullWidth
                                disabled={true}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Alícuota"
                                source="alicuota"
                                defaultValue={0}
                                max="100"
                                min="1"
                                step={0.1}
                                onChange={(e) => changeAlicuota(e)}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={isReadOnly}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Importe IIBB"
                                source="iibb"
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                                fullWidth
                                defaultValue={0}
                                disabled={true}
                            />
                        </Box>
                    </Box>

                    {/* <SectionTitle label="Percepción IIBB (2)" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="Jurisdicción"
                                source="jurisdiction"
                                choices={jurisdictionData}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Base imponible"
                                source="taxBase"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Alícuota"
                                source="Alícuota"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Importe"
                                source="Importe"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>

                    <SectionTitle label="Percepción IVA" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Base imponible"
                                source="taxBase"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Alícuota"
                                source="Alícuota"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Importe"
                                source="Importe"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>

                    <SectionTitle label="Impuestos internos" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Base imponible"
                                source="taxBase"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Alícuota"
                                source="Alícuota"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Importe"
                                source="Importe"
                                disabled={true}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Box>*/}

                </Box>
            </Box>
        </CardContent>
    );
};

export default InvoiceFormTax;
