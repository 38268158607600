import React from 'react';
import { Link } from 'react-admin';
import { voucherFormat } from '../utils/functions';

const PurchaseLinkField = props => {
    let numberVoucher = voucherFormat(props.record.id);

    return (
        <Link to={`/purchases/${props.record.id}`}
            onClick={() => localStorage.removeItem('purchaseCart')}
        >
            OC-{numberVoucher}
        </Link>
    )
};

PurchaseLinkField.defaultProps = {
    source: 'id',
    label: 'Compra Nro.',
};

export default PurchaseLinkField;
