
import * as React from 'react';
import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    TextInput,
    NumberInput,
    AutocompleteInput,
    ReferenceInput,
    useDataProvider,
    SelectInput,
    FormDataConsumer
} from 'react-admin';
import {
    requiredValidate,
    requiredValidateInternal,
    validationLength,
} from '../validators/validators';
import {
    toUpperCase
} from '../utils/functions';
import LineSelectInput from './LineSelectInput';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';

const VehicleForm = (props) => {
    const [customer, setCustomer] = useState();
    const dataProvider = useDataProvider();
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(50), (val, index) => year - index);

    const fetchVehicle = useCallback(async () => {
        dataProvider.getOne('vehicles', { id: props.record.id })
            .then(response => {
                setCustomer(response.data.customerId);
            }).catch((error) => {
                console.log(`Error: ${error.message}`);
            });
    }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (props.record.id !== undefined) &&
            fetchVehicle();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleCustomer = (customerId) => {
        setCustomer(customerId);
    }

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>

                    <SectionTitle label="Datos del vehículo" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                autoFocus
                                label="resources.vehicles.fields.patent"
                                source="patent"
                                parse={toUpperCase}
                                //   validate={requiredValidatePatent}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="Año"
                                source="year"
                                translateChoice={false}
                                choices={
                                    years.map((year) => ({
                                        id: year,
                                        name: year,
                                    }))
                                }
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Modelo"
                                source="model"
                                parse={toUpperCase}
                                fullWidth
                                resettable
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="resources.vehicles.fields.brand"
                                source="brandId"
                                reference="brands"
                                sort={{ field: 'name', order: 'ASC' }}
                                resettable
                            >
                                <AutocompleteInput
                                    optionText="name"
                                    validate={requiredValidate}
                                    fullWidth
                                />
                            </ReferenceInput>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.vehicles.fields.chassis"
                                source="chassis"
                                parse={toUpperCase}
                                validate={validationLength}
                                fullWidth
                                resettable
                            />
                        </Box>
                    </Box>

                    <SectionTitle label="Datos del cliente" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <ReferenceInput
                            label="resources.vehicles.fields.customer"
                            source="customerId"
                            reference="customers"
                            sort={{ field: 'name', order: 'ASC' }}
                            onChange={handleCustomer}
                            fullWidth
                            resettable
                        >
                            <AutocompleteInput
                                optionText="name"
                                validate={requiredValidate}
                            />
                        </ReferenceInput>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="resources.vehicles.fields.internal"
                                source="internal"
                                min="1"
                                max="9000"
                                validate={requiredValidateInternal}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <FormDataConsumer>
                                {({ formData }) => (formData.customerId !== undefined) &&
                                    <LineSelectInput
                                        customerId={customer}
                                        validate={requiredValidate}
                                    />
                                }
                            </FormDataConsumer>
                        </Box>
                    </Box>

                </Box>
                <Box
                    flex={1}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Plantilla a Dibujar" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="Plantilla"
                                source="busTemplate"
                                reference="busTemplate"
                                sort={{ field: 'name', order: 'ASC' }}
                                fullWidth
                            >
                                <SelectInput
                                    optionText="name"
                                    validate={requiredValidate}
                                />
                            </ReferenceInput>
                        </Box>
                    </Box>
                    <SectionTitle label="Otros Datos" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="resources.vehicles.fields.color"
                                source="color"
                                parse={toUpperCase}
                                validate={validationLength}
                                rows={4}
                                multiline
                                fullWidth
                                resettable
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default VehicleForm;
