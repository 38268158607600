import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    useGetList,
    useListContext,
} from 'react-admin';
import { useEffect, useRef } from 'react';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            width: '15em',
            marginRight: '1em',
            overflow: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

const AsideItems = () => {
    const { data, ids } = useGetList(
        'categories',
        { page: 1, perPage: 30 },
        { field: 'name', order: 'ASC' },
        { filter: '' }
    );
    const classes = useStyles();
    const { setFilters } = useListContext();
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFilters({ q: '' });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setFilters]);

    return (
        <Card className={classes.root}>
            <CardContent>
            <div>
                <FilterLiveSearch
                    label="Buscar (nombre o código)"
                    parse={(value) => value.toUpperCase()}
                    source="q"
                    resettable
                    autoFocus
                    alwaysOn
                    ref={inputRef}
                />
                <FormHelperText><span role="img" aria-label="icono de limpiar">⌨️</span> Escape (esc) para limpiar la búsqueda</FormHelperText>
                </div>
                <FilterList
                    label="Stock"
                    icon={<BarChartIcon />}
                >
                    <FilterListItem
                        label="Sin stock"
                        value={{
                            quantity: 0,
                        }}
                    />
                </FilterList>

                <FilterList
                    label="Tipo Consumible"
                    icon={<LocalDrinkIcon />}
                >
                    <FilterListItem
                        label="Consumibles"
                        value={{
                            isConsumable: true,
                        }}
                    />
                </FilterList>

                <FilterList
                    label="Categorias"
                    icon={<LocalOfferIcon />}
                >
                    {ids.map((id) => (
                        <FilterListItem
                            label={inflection.humanize(data[id].name)}
                            key={data[id].id}
                            value={{ category: data[id].id }}
                        />
                    ))}
                </FilterList>

            </CardContent>
        </Card>
    );
};

export default AsideItems;
