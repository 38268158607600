import React, { useState, useEffect } from 'react';
import {
	LinearProgress,
	useDataProvider
} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import { calculateQuantity, calculateTotal, currencyFormatter } from '../utils/functions';

const ListItemsDisplay = (props) => {
	const dataProvider = useDataProvider();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const [products, setProducts] = useState([]);

	useEffect(() => {
		dataProvider.getOne('purchases', { id: props.id })
			.then(response => {
				let itemsOrder = response.data.items;
				dataProvider.getMany('items', {
					ids: itemsOrder.map((item) => item.id),
				})
					.then(response => {
						let items = response.data;
						//CRUCE DE 2 OBJETOS POR ID Y PUSE PRIMERO EL "t2" PARA TOMAR EL PRICE DE OC
						const result = itemsOrder.map(t1 => ({ ...items.find(t2 => t2.id === t1.id), ...t1 }));
						setProducts(result);
						setLoading(false);
					});
			})
			.catch((error) => {
				setError(error);
				setLoading(false);
			});
	}, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps

	if (loading) return <LinearProgress />;
	if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
	if (!products) return null;

	const total = currencyFormatter(calculateTotal(products));
	const quantity = calculateQuantity(products);

	return (
		<CardContent>
			<SectionTitle label="Detalle de la OC" />
			<Box mt="1em" />
			<Box display={{ md: 'block', lg: 'flex' }}>
				<Box flex={2} mr={{ md: 0, lg: '1em' }}>
					<Box display={{ xs: 'block', sm: 'flex' }}>
						<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
							<Card>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>
												{'Nombre (Código)'}
											</TableCell>
											<TableCell align="right">
												{'Comprar'}
											</TableCell>
											<TableCell align="right">
												{'Precio Unit.'}
											</TableCell>
											<TableCell align="right">
												{'Subtotal'}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{products.map((product) => (
											<TableRow key={product.id}>
												<TableCell>
													{`${product.name} (${product.code})`}
												</TableCell>
												<TableCell align="right">
													{product.itemQuantity}
												</TableCell>
												<TableCell align="right">
													{currencyFormatter(product.price)}
												</TableCell>
												<TableCell align="right">
													{currencyFormatter((product.itemQuantity * product.price))}
												</TableCell>
											</TableRow>
										))
										}
										<TableRow >
											<TableCell style={{ color: 'red' }} >TOTAL</TableCell>
											<TableCell style={{ color: 'red' }} align="right">{quantity}</TableCell>
											<TableCell ></TableCell>
											<TableCell style={{ color: 'red' }} align="right">{currencyFormatter(total)}</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Card>
						</Box>
					</Box>
				</Box>
			</Box>
		</CardContent>
	)
}

export default ListItemsDisplay;