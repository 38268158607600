import * as React from 'react';
import {
    TextField,
    NumberInput
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';

const EmployeeFormAdditional = () => {

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Valor de la Hora Extra"
                                source="extraHourAmount"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                    </Box>

                    <TextField
                        label="Última actualización:"
                        source="extraHourAmountLastUpdate"
                        emptyText="No hay última actualización"
                        fullWidth
                    />
                </Box>

                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <div>
                    </div>
                </Box>
            </Box>
        </CardContent>
    );
};

export default EmployeeFormAdditional;
