import React, { useState } from 'react';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { Paper, Typography, Link as MuiLink, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import WorkOrderStatusColorChip from '../components/WorkOrderStatusColorChip';
import {
    TextField,
    ChipField,
} from 'react-admin';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(theme => ({
    gridList: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 1008,
        gap: '10px',
    },
    paper: {
        height: 150,
        width: 193.5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '1em',
    },
    identity: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    name: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
    stats: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
    },
    singleStat: {
        display: 'flex',
        alignItems: 'center',
    },
    statIcon: {
        marginRight: theme.spacing(1),
    },
}));

const getColsForWidth = width => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    return 6;
};

const BasketOrdersGrid = ({ orders = [], width }) => {
    const classes = useStyles();
    const [elevation, setElevation] = useState(1);

    return (
        <Box className={classes.gridList}>
            {orders.map(record => (
                <MuiLink
                    key={record.id}
                    component={Link}
                    to={`/orders/${record.id}/items`}
                    cols={getColsForWidth(width)}
                    underline="none"
                    onMouseEnter={() => setElevation(3)}
                    onMouseLeave={() => setElevation(1)}
                >
                    <Paper className={classes.paper} elevation={elevation}>
                        <div className={classes.identity}>
                            <div className={classes.name}>
                                <Typography variant="subtitle1">
                                    OT-{record.id}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                    style={{ marginTop: 5 }}
                                >
                                    Patente: {record.vehicle.patent}
                                </Typography>
                                <ChipField                                    
                                    record={record}
                                    source="vehicle.line.name"
                                    size="small"
                                    style={{ backgroundColor: green[200] }}
                                />
                            </div>
                        </div>
                        <div className={classes.stats}>
                            <div className={classes.singleStat}>
                                <WorkOrderStatusColorChip
                                    record={record}
                                />
                            </div>
                        </div>
                        <div className={classes.stats}>
                            <div className={classes.singleStat}>
                                <ShoppingBasketIcon
                                    color="disabled"
                                    className={classes.statIcon}
                                />
                                <Typography variant="subtitle2">
                                    {record.basketId === '' ?
                                        'SIN ASIGNAR'
                                        :
                                        <TextField record={record} source="basket.name" />
                                    }
                                </Typography>

                            </div>
                        </div>
                    </Paper>
                </MuiLink>
            ))}
        </Box>
    );
}
const enhance = compose(
    withWidth()
);

export default enhance(BasketOrdersGrid);
