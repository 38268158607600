import React, { useState } from 'react';
import {
    Button,
    SimpleForm,
    TextField,
    useGetOne,
    ReferenceField,
    BooleanField,
    LinearProgress
} from 'react-admin';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InspectionDrawImageView from './InspectionDrawImageView';
import BusTemplateHidden from '../components/BusTemplateHidden';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 10,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 600,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const InspectionDetailsView = ({ id }) => {
    const classes = useStyles();
    const { data, loaded } = useGetOne('inspections', id);
    const [showPanel, setShowPanel] = useState(false);

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    if (!loaded) return <LinearProgress />;

    return (
        <div align="right">
            <Button
                label="Mostrar Inspección Detallada"
                onClick={handleClick}
            >
                <IconImageEye />
            </Button>
            <Drawer
                anchor="right"
                open={showPanel}
                onClose={handleCloseClick}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.root}>
                    <div className={classes.title}>
                        <Typography variant="h6">
                            {`Inspección: IN-${id}`}
                        </Typography>
                        <IconButton onClick={handleCloseClick}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <SimpleForm
                        record={data}
                        basePath="/orders"
                        resource="orders"
                        className={classes.form}
                        toolbar={false}
                    >
                        <TextField
                            label="resources.inspections.fields.dateInspection"
                            source="start"
                            formClassName={classes.inlineField}
                        />
                        <ReferenceField
                            label="resources.inspections.fields.patent"
                            source="vehicleId"
                            reference="vehicles"
                            link={false}
                            formClassName={classes.inlineField}
                        >
                            <TextField source="patent" />
                        </ReferenceField>
                        <ReferenceField
                            label="resources.inspections.fields.inspector"
                            source="employeeId"
                            reference="employees"
                            link={false}
                            formClassName={classes.inlineField}
                        >
                            <TextField source="fullName" />
                        </ReferenceField>

                        <ReferenceField
                            label="resources.vehicles.fields.line"
                            source="vehicleId"
                            reference="vehicles"
                            link={false}
                            formClassName={classes.inlineField}
                        >
                            <ReferenceField
                                source="lineId"
                                reference="busLines"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </ReferenceField>
                        <ReferenceField
                            label="resources.vehicles.fields.customer"
                            source="vehicleId"
                            reference="vehicles"
                            link={false}
                            formClassName={classes.inlineField}
                        >
                            <ReferenceField
                                source="customerId"
                                reference="customers"
                                link={false}
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </ReferenceField>

                        <ReferenceField
                            label="resources.vehicles.fields.internal"
                            source="vehicleId"
                            reference="vehicles"
                            link={false}
                            formClassName={classes.inlineField}
                        >
                            <TextField source="internal" />
                        </ReferenceField>

                        <BusTemplateHidden id={data.vehicleId} />

                        <InspectionDrawImageView inspection={data} />

                        <BooleanField
                            label="Chocado"
                            source="crashed"
                            formClassName={classes.inlineField}
                        />
                        <BooleanField
                            label="Pintura Completa"
                            source="completePaint"
                            formClassName={classes.inlineField}
                        />

                    </SimpleForm>
                </div>
            </Drawer>
        </div>
    );
};

export default InspectionDetailsView;
