import * as React from 'react';
import {
    DateInput,
} from 'react-admin';
import { Box } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { dateParseWithoutTime } from '../utils/functions';
import SectionTitle from '../components/SectionTitle';

const InvoiceDates = (props) => {
    return (
        <div>
            <SectionTitle label="Período Facturado" />
            {(props.record.id === undefined) ?
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            label="Período facturado (desde)"
                            source="startDate"
                            defaultValue={dateParseWithoutTime(Date.now())}
                            validate={requiredValidate}
                            parse={dateParseWithoutTime}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            label="Período facturado (hasta)"
                            source="endDate"
                            defaultValue={dateParseWithoutTime(Date.now())}
                            validate={requiredValidate}
                            parse={dateParseWithoutTime}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            label="Vto. de Pago"
                            source="dueDate"
                            defaultValue={dateParseWithoutTime(Date.now())}
                            validate={requiredValidate}
                            parse={dateParseWithoutTime}
                            fullWidth
                        />
                    </Box>
                </Box>
                :
                <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            label="Período facturado (desde)"
                            source="startDate"
                            validate={requiredValidate}
                            parse={dateParseWithoutTime}
                            disabled={true}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            label="Período facturado (hasta)"
                            source="endDate"
                            validate={requiredValidate}
                            parse={dateParseWithoutTime}
                            disabled={true}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <DateInput
                            label="Vto. de Pago"
                            source="dueDate"
                            validate={requiredValidate}
                            parse={dateParseWithoutTime}
                            disabled={true}
                            fullWidth
                        />
                    </Box>
                </Box>
            }
        </div>
    );
};

export default InvoiceDates;
