import React from "react";
import ReactExport from "react-export-excel";
import { Button } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { dateParseWithoutTime, getOperationTypeFormat } from '../utils/functions';

const AccountExport = ({ dataExport }) => {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const dataSet = dataExport.map(item => ({
        id: getOperationTypeFormat(item),
        date: item.date,
        debit: item.debit,
        assets: item.assets,
        balance: item.balance
    }));

    const filename = `cc-${dateParseWithoutTime(Date.now())}`;

    return (
        <ExcelFile
            filename={filename}
            element={
                <Button
                    label="Exportar a Excel"
                    size="small"
                    variant="contained"
                >
                    <DownloadIcon />
                </Button>
            }>
            <ExcelSheet data={dataSet} name="Cuentas Corrientes">
                <ExcelColumn label="Fecha" value="date" />
                <ExcelColumn label="Comprobante" value="id" />
                <ExcelColumn label="Debe" value="debit" />
                <ExcelColumn label="Haber" value="assets" />
                <ExcelColumn label="Saldo" value="balance" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default AccountExport;
