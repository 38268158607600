import * as React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    useNotify,
    useRefresh,
    useRedirect,
    usePermissions,
    TabbedFormTabs,
    ReferenceManyField,
    Pagination
} from 'react-admin';
import {
    employeeValidation
} from '../validators/validators';
import { useMediaQuery } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CustomToolbar from '../components/CustomToolbar';
import ImageProfileInput from './imagenProfile/ImageProfileInput';
import ImageProfileView from './imagenProfile/ImageProfileView';
import DocumentFileInput from './document/DocumentFileInput';
import DocumentFileView from './document/DocumentFileView';
import EmployeeForm from './EmployeeForm';
import EmployeeFormHour from './EmployeeFormHour';
import AbsenceListDatagrid from '../absences/AbsenceListDatagrid';
import AbsenceListMobile from '../absences/AbsenceListMobile';
import AddAbsenceButton from './AddAbsenceButton';

import AddExtraHourButton from './AddExtraHourButton';
import ExtraHourListDesktop from '../extra-hour/ExtraHourListDesktop';
import ExtraHourListMobile from '../extra-hour/ExtraHourListMobile';

import { getRolesRRHH } from '../appRolesComponents';

const EmployeeEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/employees`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const EmployeeTitle = ({ record }) => {
        return <span>{record ? `${record.fullName}` : ''}</span>;
    };

    const isRRHH = getRolesRRHH(permissions);

    if (!isRRHH) return null;

    return (
        <Edit
            {...props}
            undoable={false}
            title={<EmployeeTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                validate={employeeValidation}
                tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
            >
                <FormTab
                    label={!isXsmall && "General"}
                    icon={isXsmall && <AccountBoxIcon />}
                >
                    <EmployeeForm />
                </FormTab>

                <FormTab
                    label={!isXsmall && "Horarios"}
                    icon={isXsmall && <AccessAlarmsIcon />}
                    path="hours"
                >
                    <EmployeeFormHour />
                </FormTab>

                <FormTab
                    label={!isXsmall && "Foto Empleado"}
                    icon={isXsmall && <AddAPhotoIcon />}
                    path="imageProfile"
                >
                    <ImageProfileInput />
                    <ImageProfileView />
                </FormTab>

                <FormTab
                    label={!isXsmall && "Documentación"}
                    icon={isXsmall && <NoteAddIcon />}
                    path="documents"
                >
                    <DocumentFileInput />
                    <DocumentFileView />
                </FormTab>

                <FormTab
                    label={!isXsmall && "Ausencias"}
                    icon={isXsmall && <PlaylistAddCheckIcon />}
                    path="absences"
                >
                    <AddAbsenceButton />
                    <ReferenceManyField
                        label={false}
                        perPage={10}
                        sort={{ field: 'start', order: 'DESC' }}
                        pagination={<Pagination />}
                        reference="absences"
                        target="employeeId"
                        fullWidth
                    >
                        {isXsmall ? (
                            <AbsenceListMobile />
                        ) : (
                            <AbsenceListDatagrid />
                        )}
                    </ReferenceManyField>
                </FormTab>

                <FormTab
                    label={!isXsmall && "Adicional"}
                    icon={isXsmall && <AddIcon />}
                    path="extra-hour"
                >

                    <AddExtraHourButton />
                    <ReferenceManyField
                        label={false}
                        perPage={10}
                        sort={{ field: 'start', order: 'DESC' }}
                        pagination={<Pagination />}
                        reference="extra-hour"
                        target="employeeId"
                        fullWidth
                    >
                        {isXsmall ? (
                            <ExtraHourListMobile />
                        ) : (
                            <ExtraHourListDesktop />
                        )}
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default EmployeeEdit;
