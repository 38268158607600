import * as React from 'react';
import {
    Edit,
    useNotify,
    useRefresh,
    useRedirect,
    TabbedForm,
    FormTab,
} from 'react-admin';
import PaymentForm from './PaymentForm';
import PaymentEditToolbar from './PaymentEditToolbar';
import EditActionsPayment from './EditActionsPayment';
import SelectInputMethod from './SelectInputMethod';

const PaymentEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/payments`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const Title = ({ record }) => {
        return <span>{record ? `#${record.id}` : ''}</span>;
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<Title />}
            actions={<EditActionsPayment />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                // warnWhenUnsavedChanges
                toolbar={<PaymentEditToolbar />}
                redirect={redirect}
            >
                <FormTab label="Cabecera" >
                    <PaymentForm />
                </FormTab>
                <FormTab label="Medios de Pago" path="method">
                    <SelectInputMethod {...props} />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default PaymentEdit;
