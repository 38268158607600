import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import PaymentHeader  from './PaymentHeader';
import BillTo from './BillTo';
import PaymentItemsTable from './PaymentItemsTable';
import ThankYouMsg from './ThankYouMsg';

const styles = StyleSheet.create({
    page: {
        fontSize: 15,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: 'column',
    }
});

const Payment = ({ pdfDocument }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <PaymentHeader pdfDocument={pdfDocument} />
            <BillTo pdfDocument={pdfDocument} />
            <PaymentItemsTable pdfDocument={pdfDocument} />
            <ThankYouMsg />
        </Page>
    </Document>
);

export default Payment;