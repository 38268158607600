import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { Notification } from 'react-admin';

const MyNotification = props => (<Notification {...props} autoHideDuration={9000} />);

const CustomSidebar = props => (<Sidebar {...props} size={200} />);

const CustomLayout = props => (
    <Layout
        {...props}
        notification={MyNotification}
        appBar={AppBar}
        sidebar={CustomSidebar}
        menu={Menu}
    />
);

export default CustomLayout;
