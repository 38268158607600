const choices = [
    { id: 1, name: 'Pintar llantas' },
    { id: 2, name: 'Pintar estribos' },
    { id: 3, name: 'Sacar marcas' },
    { id: 4, name: 'Lustrar paneles todos marcados' },
    { id: 5, name: 'Lustrar techo' },
    { id: 6, name: 'Sacar reflectiva' },
    { id: 7, name: 'Sacar grafica' },
    { id: 8, name: 'Lustrar completo' },
    { id: 9, name: 'Lustrar parte baja' },
    { id: 10, name: 'Piso' },
    { id: 11, name: 'Estribo delantero' },
    { id: 12, name: 'Estribo medio' },
    { id: 13, name: 'Cambiar fenolico' },
    { id: 14, name: 'Goma nueva' },
    { id: 15, name: 'Pegar contornos de pasarruedas' },
    { id: 16, name: 'Emprolijar puertas' },
    { id: 17, name: 'Pollera suelta pegar con fastix' },
    { id: 18, name: 'Pollera suelta soldar' },
    { id: 19, name: 'Paragolpe trasero suelto' },
    { id: 20, name: 'Paragolpe delantero suelto' },
    { id: 21, name: 'Cambiar paragolpe trasero' },
    { id: 22, name: 'Cambiar paragolpe delantero' },
    { id: 23, name: 'Usar los mismo tornillos' },
    { id: 24, name: 'Soldar puerta ' },
    { id: 25, name: 'Puerta caída' },
    { id: 26, name: 'Cambiar medio frente' },
    { id: 27, name: 'Cambiar farolera trasera' },
    { id: 28, name: 'Parabrisa entérizo nuevo' },
    { id: 29, name: 'Parabrisa izquierdo nuevo' },
    { id: 30, name: 'Parabrisa derecho nuevo' },
    { id: 31, name: 'Custodio nuevo' },
    { id: 32, name: 'Cambiar grafica' },
];

export default choices
