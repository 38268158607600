import HolidayList from './HolidayList';
import HolidayCreate from './HolidayCreate';
import HolidayEdit from './HolidayEdit';
import CalendarToday from '@material-ui/icons/CalendarToday';

export default {
  list: HolidayList,
  create: HolidayCreate,
  edit: HolidayEdit,
  icon: CalendarToday
};
