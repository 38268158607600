import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace';
import InvoiceTableFooter from './InvoiceTableFooter';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    borderWidth: 1,
  },
});

const tableRowsCount = 25;

const InvoiceItemsTable = ({ pdfDocument }) => {
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceTableRow items={pdfDocument.operationInfo} />
      <InvoiceTableBlankSpace rowsCount={tableRowsCount - pdfDocument.operationInfo.length} />
      <InvoiceTableFooter pdfDocument={pdfDocument} />
    </View>
  )
};

export default InvoiceItemsTable;