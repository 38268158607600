import * as React from 'react';
import {
    DateInput,
    TextInput,
    RadioButtonGroupInput,
} from 'react-admin';
import {
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase,
} from '../utils/functions';
import { Box, CardContent } from '@material-ui/core';
import absenceTypeData from '../components/data/absenceTypeData';

const AbsenceForm = () => {

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <RadioButtonGroupInput
                                label="Tipo Ausencia"
                                source="type"
                                choices={absenceTypeData}
                                validate={requiredValidate}
                                row={false}
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="Fecha Inicio"
                                source="start"
                                validate={requiredValidate}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="Fecha Fin"
                                source="end"
                                validate={requiredValidate}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <TextInput
                        label="Descripción"
                        source="description"
                        validate={requiredValidate}
                        parse={toUpperCase}
                        rows={3}
                        multiline
                        resettable
                        fullWidth
                    />
                </Box>

                <Box flex={2} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <div></div>
                </Box>
            </Box>
        </CardContent>
    );
};

export default AbsenceForm;
