import * as React from 'react';
import {
    ListBase,
    usePermissions,
} from 'react-admin';
import {
    useMediaQuery
} from '@material-ui/core';
import ItemListView from './ItemListView';
import ItemFilter from './ItemFilter';
import { getRolesOnlySupervisorExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';

const ItemList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions);

    if (!isWorkshopOnly) return null;

    return (
        <ListBase
            {...props}
            filters={isSmall ? <ItemFilter /> : null}
            perPage={25}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <ItemListView isSmall={isSmall} />
        </ListBase>
    );
};

export default ItemList;
