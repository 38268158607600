import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FormDataConsumer,
    useDataProvider,
    Button,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Card, Box, CardContent, CardActions, makeStyles } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { secondaryAccountsData } from '../components/data/secondaryAccountsData';
import primaryAccountsData from '../components/data/primaryAccountsData';
import { dateParseWithoutTime, toChoices, addDays } from '../utils/functions';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReportExport from '../components/pdf-reports/ReportExport';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const ReportAccount = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const [error, setError] = useState();

    const handleExport = async (result) => {
        const doc = <ReportExport pdfDocument={result} />;
        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, `Reporte-Facturas.pdf`);
    }

    const handleSubmit = (values) => {

        if (Object.keys(values).length > 0) {

            if (document.getElementById('customerId') === null) { delete values.customerId; }
            if (document.getElementById('supplierId') === null) { delete values.supplierId; }

            dataProvider
                .getList('accounts', {
                    pagination: { page: 1, perPage: 99999 },
                    sort: { field: 'id', order: 'DESC' },
                    filter: { status: true },
                })
                .then(response => {
                    //addDays(values.date, 2) para que sume dos dia a la fecha xq compara con una de hora
                    const invoices = values.primaryAccount === 'CLIENTES' ?
                        response.data.filter(
                            item => (item.customerId === values.customerId || values.customerId === undefined) && (item.date <= addDays(values.date, 2)) && (item.primaryAccount === 'CLIENTES')
                        )
                        :
                        response.data.filter(
                            item => (item.supplierId === values.supplierId || values.supplierId === undefined) && (item.date <= addDays(values.date, 2)) && (item.primaryAccount === 'PROVEEDORES')
                        );

                    let totalDebit = invoices.reduce((group, debit) => {
                        group[debit.name] = (group[debit.name] || 0) + debit.debit || 0;//TODO --GROUP BY
                        return group;
                    }, {})

                    let totalAssets = invoices.reduce((group, assets) => {
                        group[assets.name] = (group[assets.name] || 0) + assets.assets || 0;//TODO --GROUP BY
                        return group;
                    }, {})

                    let total = Object.keys(totalDebit).reduce((a, k) => {
                        a[k] = totalDebit[k] - totalAssets[k];
                        return a;
                    }, {});

                    const result = {
                        ...values,
                        invoices: total
                    }

                    handleExport(result);
                })
                .catch((error) => {
                    setError(error);
                });
        }
    };

    const choicesPrimaryAccounts = primaryAccountsData.filter(i => i.id === 'CLIENTES' || i.id === 'PROVEEDORES');

    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Reporte Saldos Clientes/Proveedor'} />
                            <CardContent>
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="resources.reports_rrhh.filters.startDate"
                                                    source="date"
                                                    defaultValue={dateParseWithoutTime(Date.now())}
                                                    validate={requiredValidate}
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <SelectInput
                                                    label="Cuenta Principal"
                                                    source="primaryAccount"
                                                    choices={choicesPrimaryAccounts}
                                                    validate={requiredValidate}
                                                    resettable
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => (formData.primaryAccount === 'PROVEEDORES' && formData.primaryAccount !== undefined) ?
                                                        <ReferenceInput
                                                            label="Busque a su Proveedor"
                                                            source="supplierId"
                                                            reference="suppliers"
                                                            sort={{ field: 'name', order: 'ASC' }}
                                                            perPage={99999} //TODO NO TRAE MAS Q 99999
                                                            fullWidth
                                                            resettable
                                                        >
                                                            <AutocompleteInput
                                                                optionText="name"
                                                            />
                                                        </ReferenceInput>
                                                        :
                                                        (formData.primaryAccount === 'CLIENTES' && formData.primaryAccount !== undefined) ?
                                                            <ReferenceInput
                                                                label="Busque a su Cliente"
                                                                source="customerId"
                                                                reference="customers"
                                                                sort={{ field: 'name', order: 'ASC' }}
                                                                fullWidth
                                                                resettable
                                                            >
                                                                <AutocompleteInput
                                                                    optionText="name"
                                                                />
                                                            </ReferenceInput>
                                                            :
                                                            (formData.primaryAccount !== undefined) &&
                                                            <SelectInput
                                                                label="Cuenta Secundaria"
                                                                source="secondaryAccount"
                                                                choices={
                                                                    formData.primaryAccount
                                                                        ? toChoices(secondaryAccountsData[formData.primaryAccount])
                                                                        : []
                                                                }
                                                                validate={requiredValidate}
                                                                fullWidth
                                                                resettable
                                                                {...rest}
                                                            />
                                                    }
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        label="Descargar"
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        size="medium"
                                                    >
                                                        <PictureAsPdfIcon fontSize="small" />
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>

    );
};

export default ReportAccount;
