import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SelectTaxInput from '../components/SelectTaxInput';
import DeleteButtonCard from '../components/DeleteButtonCard';
import { currencyFormatter } from '../utils/functions';
import ItemPriceInput from '../components/ItemPriceInput';
import ItemQuantityInput from '../components/ItemQuantityInput';
import ItemCommentInput from '../components/ItemCommentInput';
import { calculateTotalWithoutTax } from '../utils/functions';

const CartItems = ({ products, changeQuantity, changePrice, changeComment, changeTax, deleteToCard, changeBonus }) => {
    return (
        <div>
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'#'}
                            </TableCell>
                            <TableCell>
                                {'Descripción'}
                            </TableCell>
                            <TableCell>
                                {'Comentario Adicional'}
                            </TableCell>
                            <TableCell align="center">
                                {'Cantidad'}
                            </TableCell>
                            <TableCell align="center">
                                {'Precio Sin IVA'}
                            </TableCell>
                            <TableCell align="right">
                                {'Total Sin IVA'}
                            </TableCell>
                            <TableCell align="center">
                                {'IVA'}
                            </TableCell>
                            <TableCell>
                                {''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(products.length === 0) ?
                            <TableRow key={0}>
                                <TableCell colSpan={6}>
                                    Sin abonos y/o ordenes cargadas!
                                </TableCell>
                            </TableRow>
                            :
                            products.map((product, i) => (
                                <TableRow key={product.id}>
                                    <TableCell>
                                        {i + 1}
                                    </TableCell>
                                    <TableCell>
                                        {product.name}
                                    </TableCell>
                                    <TableCell>
                                        <ItemCommentInput product={product} changeComment={changeComment} />
                                    </TableCell>
                                    
                                    <TableCell align="center">
                                    { product.code === 'GEN' ? 
                                        <ItemQuantityInput product={product} changeQuantity={changeQuantity}  />
                                        :
                                        product.itemQuantity
                                    }
                                    </TableCell>
                                    <TableCell align="center">
                                        <ItemPriceInput product={product} changePrice={changePrice} />
                                    </TableCell>
                                    <TableCell align="right">
                                            {// DESCUENTO = (SUBTOTAL * BONIFICATION)
                                                // TOTAL = (SUBTOTAL - DESCUENTO)
                                                currencyFormatter(calculateTotalWithoutTax(product))
                                                }
                                    </TableCell>
                                    <TableCell>
                                        <SelectTaxInput product={product} changeTax={changeTax} />
                                    </TableCell>
                                    <TableCell>
                                        <DeleteButtonCard product={product} deleteToCard={deleteToCard} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Card>
        </div>
    )
}

export default CartItems;