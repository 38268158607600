import * as React from 'react';
import { LinearProgress } from '@material-ui/core';
import {
    List,
    usePermissions,
    ResourceContextProvider,
    SearchInput,
    Filter,
    SelectInput,
    useGetList,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import WarehouseListMobile from './WarehouseListMobile';
import WarehouseListDesktop from './WarehouseListDesktop';
import { 
    getRolesOnlySupervisorExceptAdmin, 
    getRolesWorkshopOnly, 
    getRolesOnlyStorekeeperExceptAdmin
} from '../appRolesComponents';

const CategorySelectInput = () => {
    const { data, ids, loaded, error } = useGetList(
        'categories',
        { page: 1, perPage: 30 },
        { field: 'name', order: 'ASC' }
    );

    if (error) {
        return <div>Error cargando categorías</div>;
    }

    if (!loaded) {
        return <LinearProgress />;
    }

    const choices = [
        { id: undefined, name: 'Todos' }, // Opción adicional
        ...ids.map((id) => ({
            id: data[id].id,
            name: data[id].name,
        })),
    ];

    return (
        <SelectInput
            label="Categoría"
            source="category"
            choices={choices}
            style={{ width: 'auto', margin : '0 0 -16px 0px' }} // Ajuste de tamaño
        />
    );
};

const ListFilters = (props) => (
    <Filter {...props}>
        <SearchInput
            placeholder="Buscar por código o nombre"
            source="q"
            alwaysOn
            autoFocus
            resettable
            parse={(value) => value.toUpperCase()}
        />
        <CategorySelectInput alwaysOn />
    </Filter>
);

const WarehouseList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions) || getRolesOnlyStorekeeperExceptAdmin(permissions);

    if (!isWorkshopOnly) return null;

    return (
        <ResourceContextProvider value="warehouse">
            <List
                syncWithLocation
                title="Depósito"
                basePath="/warehouse"
                actions={false}
                filters={<ListFilters />}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={25}
                bulkActionButtons={false}
            >
                {isXsmall ? <WarehouseListMobile /> : <WarehouseListDesktop />}
            </List>
        </ResourceContextProvider>
    );
};

export default WarehouseList;
