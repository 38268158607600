import React from 'react';
import {
    LinearProgress,
    useGetList,
    SelectInput,
    required,
    usePermissions
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import { getRolesOnlyStorekeeperExceptAdmin, getRolesOnlySupervisorExceptAdmin } from '../appRolesComponents';

const BasketSelectInput = (props) => {
    const { data, loading, error } = useGetList(
        'baskets',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' }
    );
    const { permissions } = usePermissions();


    if (loading) { return <LinearProgress />; }
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }

    const basketsSort = Object.keys(data).map((id) => (data[id])).sort((b1, b2) => {
        return b1.name - b2.name || b2.available - b1.available //ordeno por nombre y si esta disponible el canasto
    });

    const choices = basketsSort.map((basket) => ({
        id: basket.id,
        name: basket.name,
        not_available: (basket.available) ? false : true //para que funcione disableValue (EVITO EL CAMBIO DESDE BACKEND)
    }))

    const isStorekeeper = getRolesOnlyStorekeeperExceptAdmin(permissions);
    const isSupervisor = getRolesOnlySupervisorExceptAdmin(permissions);

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Selección de Canasto" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {((isStorekeeper || isSupervisor) && props.record.workOrderStatus === 'PENDIENTE') ?
                                <SelectInput
                                    label="Agregue un canasto a la OT"
                                    source="basketId"
                                    sort={{ field: 'name', order: 'ASC' }}
                                    choices={choices}
                                    validate={required()}
                                    disableValue="not_available"
                                    disabled={false}                                    
                                    fullWidth
                                />
                                : (props.record.workOrderStatus !== 'PENDIENTE') ?
                                    <SelectInput
                                        label="Agregue un canasto a la OT"
                                        source="basketId"
                                        sort={{ field: 'name', order: 'ASC' }}
                                        choices={choices}
                                        disableValue="not_available"
                                        disabled={true}                                        
                                        fullWidth
                                    />
                                    :
                                    <SelectInput
                                        label="Agregue un canasto a la OT"
                                        source="basketId"
                                        sort={{ field: 'name', order: 'ASC' }}
                                        choices={choices}
                                        disableValue="not_available"
                                        disabled={false}
                                        fullWidth
                                    />
                            }
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};
export default BasketSelectInput;
