import * as React from "react";
import {
  Datagrid,
  List,
  Responsive,
  SimpleList
} from "react-admin";

import ActionsTopToolbar from "../components/ActionsTopToolbar";
import InputFilter from "../components/InputFilter";
import HolidayLinkField from "./HolidayLinkField";
import DateFieldFormatted from "../components/DateFieldFormatted";

const HolidayList = (props) => {
  return (
    <List
      {...props}
      actions={<ActionsTopToolbar />}
      filters={<InputFilter />}
      sort={{ field: 'date', order: 'ASC' }}
      perPage={25}
      bulkActionButtons={false}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={record =>
              <DateFieldFormatted
                record={record}
                source="date"
                showTime={false}
              />
            }
            secondaryText={record => record.description}
            linkType={record => record.id ? "edit" : "show"}
          />
        }

        medium={
          <Datagrid optimized >
            <HolidayLinkField
              sortable={false}
            />
            <DateFieldFormatted
              label="resources.holidays.fields.date"
              source="date"
              showTime={false}
              sortable={false}
            />
          </Datagrid>
        }
      />

    </List>
  );
};

export default HolidayList;
