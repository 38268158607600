import React, { useEffect, useState } from 'react';
import {
  Button, LinearProgress, useDataProvider,
} from 'react-admin';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PDFDocumentPurchase from './PDFDocumentPurchase';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { calculateTotal } from '../utils/functions';

const PDFButtonPurchase = (order) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    dataProvider.getMany('items', {
      ids: order.items.map((item) => item.id)
    }).then(response => {
      const items = response.data;
      const itemsOrder = order.items.map(t1 => ({ ...items.find(t2 => t2.id === t1.id), ...t1 }));
      const total = calculateTotal(order.items);

      dataProvider.getList('suppliers', {
        sort: { field: 'name', order: 'ASC' },
        pagination: { page: 1, perPage: 100 },
        filter: {},
      })
        .then(response => {
          const suppliers = response.data;
          const supplier = suppliers.find(supplier => supplier.id === order.supplierId);
          
          const result = {
            ...order,
            supplier: supplier,
            items: itemsOrder,
            total: total
          }

          setData(result);
          setLoading(false);
        }).catch((error) => {
          setError(error);
          setLoading(false);
        });
    }).catch((error) => {
      setError(error);
      setLoading(false);
    });
  }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!data) return null;

  return (
    <div>
      <Button
        label="Descargar"
        variant="contained"
        onClick={async () => {
          const doc = <PDFDocumentPurchase record={data} />;
          const asPdf = pdf();
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, `OC-${order.id}.pdf`);
        }}
      >
        <PictureAsPdfIcon fontSize="small" />
      </Button>
    </div>
  )
};

export default PDFButtonPurchase;