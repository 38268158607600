import * as React from 'react';
import { useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { Button, TextField, Grid } from '@material-ui/core';
import authProvider from '../authProvider';

const ConfirmConsumableButton = ({ workOrder, item }) => {
    const [confirmAmount, setConfirmAmount] = useState(0);
    const user = authProvider.getIdentity();

    const notify = useNotify();
    const refresh = useRefresh();

    const handleConfirm = async () => {
        const confirmMessage = `¿Está seguro de que desea dar como finalizado ${confirmAmount} consumible(s) del pañol para restarlos del stock? (Esta acción no se puede deshacer, asegúrese de que la cantidad sea correcta)`;
        const userConfirmed = window.confirm(confirmMessage);

        if (!userConfirmed) return;

        if (confirmAmount < 0 || confirmAmount > workOrder.itemQuantity) {
            notify('Cantidad inválida', 'warning');
            return;
        }

        try {
            const response = await fetch(
                `https://api.marasa.com.ar/workshop-api/api/consumables/work-orders/${workOrder.id}/items/${item.id}/quantity/${confirmAmount}/username/${user.username}`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            if (!response.ok) {
                const errorData = await response.text();
                throw new Error(errorData || 'Error en la confirmación');
            }

            notify(`Confirmado ${confirmAmount} consumible(s) para la orden de trabajo #${workOrder.id}`, 'info');
            refresh();

        } catch (error) {
            notify(error.message, 'error');
            console.error(error);
        }
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <TextField
                    label="Cantidad a confirmar"
                    value={confirmAmount}
                    onChange={e => setConfirmAmount(parseInt(e.target.value, 10))}
                    type="number"
                    inputProps={{ min: 0, max: workOrder.itemQuantity }}
                    helperText={"*Es la cantidad de consumibles que se darán como finalizados"}
                />
            </Grid>
            <Grid item>
                <Button onClick={handleConfirm} color="primary" variant="contained">
                    Confirmar
                </Button>
            </Grid>
        </Grid>
    );
};

export default ConfirmConsumableButton;
