import React from 'react';
import { Datagrid, TextField, NumberField, ReferenceField, FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import TextFieldMui from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const useListStyles = makeStyles({
    quantity: { color: 'purple' },
    warehouseQuantity: { color: 'green' },
    helperText: { fontSize: '0.75rem', color: 'gray' },
});

const LabelListDesktop = ({ setQuantities, selectedIds, setSelectedIds, ...props }) => {
    const classes = useListStyles();

    const handleQuantityChange = (id, value) => {
        const quantity = Math.max(1, Math.min(10000, Number(value))); // Limita entre 1 y 10000
        setQuantities(prev => ({ ...prev, [id]: quantity }));
    };

    return (
        <Datagrid {...props} optimized bulkActionButtons={false}>
            <TextField label="Código" source="code" sortable={false} />
            <TextField label="Nombre" source="name" sortable={false} />
            <ReferenceField
                label="Categoría"
                source="categoryId"
                reference="categories"
                link={false}
                sortable={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <NumberField
                label="Cantidad Pañol"
                source="quantity"
                cellClassName={classes.quantity}
                sortable={false}
            />
            <FunctionField
                label="Cantidad de Etiquetas"
                render={record =>
                    record ? (
                        <div>
                            <TextFieldMui
                                type="number"
                                inputProps={{ min: 1, max: 10000 }}
                                defaultValue={1}
                                onChange={(e) => handleQuantityChange(record.id, e.target.value)}
                            />
                            <FormHelperText className={classes.helperText}>
                                Máximo 10000 etiquetas en total
                            </FormHelperText>
                        </div>
                    ) : null
                }
            />
        </Datagrid>
    );
};

export default LabelListDesktop;
