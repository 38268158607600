import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useDataProvider,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
});

const DocumentFileView = ({ record }) => {
    const classes = useStyles();
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();

    const workOrderId = record.id;
    const fetchDocument = useCallback(async () => {
        const apiUrl = `https://api.marasa.com.ar/workshop-api/api/orders/document/${workOrderId}`;
        const response = await fetch(apiUrl).catch((error) =>
            console.log('Falló el workshop-api', error)
        );

        if (response.ok) {
            const documentList = await response.json();
            setState(documentList.data);
        }

    }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchDocument();
    }, [fetchDocument]);

    const handleDownload = async () => {
        const downloadUrl = `https://api.marasa.com.ar/workshop-api/api/orders/document/${workOrderId}/download`;
        const response = await fetch(downloadUrl);
        const blob = await response.blob();

        // Crear un enlace temporal y simular un clic para iniciar la descarga
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', state[0].name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            {(state !== undefined && state.length > 0) ?
                <Card className={classes.root}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {'Nombre de archivo'}
                                </TableCell>
                                <TableCell>
                                    {'Fecha de última modificación'}
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{state[0].name}</TableCell>
                                <TableCell>{moment(state[0].lastUpdate).format('YYYY-MM-DD HH:mm')}</TableCell>
                                <TableCell>
                                    <span
                                        style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                                        onClick={handleDownload}
                                    >
                                        Descargar
                                    </span>                               
                             </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card> : null
            }
        </div>
    );
}
export default DocumentFileView;
