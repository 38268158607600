import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import { grey } from '@material-ui/core/colors';

const rowStyle = () => (
    record
) => {
    let style = {};
    if (record.workOrderStatus === 'PENDIENTE')
        return {
            ...style,
            borderLeftColor: grey[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.workOrderStatus === 'FINALIZADA' || record.workOrderStatus === 'FINALIZADA_ENTREGAR')
        return {
            ...style,
            borderLeftColor: green[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.workOrderStatus === 'EN_PROGRESO')
        return {
            ...style,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.workOrderStatus === 'CANCELADA')
        return {
            ...style,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    return style;
};

export default rowStyle;
