import React from 'react';
import {
    useRecordContext
} from "react-admin";
import { Chip } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const StatusChipField = ({ source }) => {
    const record = useRecordContext();
    const colors = record[source] ? green[200] : red[200];

    return (
        <Chip
            label={record[source] ? 'PAGADO' : 'CANCELADO'}
            size="small"
            style={{ backgroundColor: colors, border: 0 }}
        />
    );
};

export default StatusChipField;
