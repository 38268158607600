import * as React from "react";
import {
    ImageInput,
    ImageField
} from 'react-admin';

const ImageCategoryInput = () => (
    <ImageInput
        label="Foto de la Categoria:"
        source="image"
        accept="image/png, image/jpeg"
        maxSize={1000000}
        placeholder={
            <div>
                <p>Subir una foto de la Categoria</p>
                <em>
                    (El archivo no debe exceder 1MB)
                </em>
            </div>
        }
    >
        <ImageField source="image" />
    </ImageInput>
);

export default ImageCategoryInput;