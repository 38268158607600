import * as React from 'react';
import { Datagrid, TextField, NumberField, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import WarehouseLinkField from './WarehouseLinkField';

const useListStyles = makeStyles({
    quantity: { color: 'purple' },
    warehouseQuantity: { color: 'green' },
});

const WarehouseListDesktop = (props) => {
    const classes = useListStyles();
    return (
        <Datagrid
            {...props}
            rowClick="edit"
            optimized
        >
            <WarehouseLinkField 
                sortable={false}
            />
            <ReferenceField
                label="Categoría"
                source="categoryId"
                reference="categories"
                sort={{ field: 'name', order: 'ASC' }}
                link={false}
                fullWidth
                sortable={false}
            >
                <TextField source="name" />
            </ReferenceField>
            <NumberField
                label="Cant. Pañol"
                source="quantity"
                cellClassName={classes.quantity}
                sortable={false}
            />
            <NumberField
                label="Cant. Depósito"
                source="warehouseQuantity"
                cellClassName={classes.warehouseQuantity}
                sortable={false}
            />
        </Datagrid>
    );
};

export default WarehouseListDesktop;
