import * as React from 'react';
import {
    TextInput,
    DateInput
} from 'react-admin';
import {
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase,
} from '../utils/functions';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';

const HolidayForm = () => {

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Feriado" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <DateInput
                                label="Fecha"
                                source="date"
                                validate={requiredValidate}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Descripción del Feriado"
                                source="description"
                                parse={toUpperCase}
                                validate={requiredValidate}
                                rows={5}
                                multiline
                                resettable
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>

                <Box flex={2} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <div>
                    </div>
                </Box>
            </Box>
        </CardContent>
    );
};

export default HolidayForm;
