import * as React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    TextInput,
    SelectInput,
} from 'react-admin';
import {
    validateFullName,
    validateEmail,
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase,
    toLowerCase
} from '../utils/functions';

import CustomToolbar from '../components/CustomToolbar';

const ProfileEdit = (props) => {

    return (
        <Create
            title={'Mi Perfil'}
            {...props}
        >
        <TabbedForm
                toolbar={<CustomToolbar />}
            >
                <FormTab label="General" >
                    <SelectInput
                        label="Tipo documento"
                        source="documentType"
                        choices={[
                            { id: 'DNI', name: 'Documento Nacional de Identidad' },
                            { id: 'CI', name: 'Cédula de Identidad' },
                            { id: 'LC', name: 'Libreta Cívica' },
                            { id: 'DNIE', name: 'Documento Extranjero' },
                        ]}
                        validate={requiredValidate}
                    />
                    <TextInput
                        label="Apellido y Nombre"
                        source="fullName"
                        validate={validateFullName}
                        parse={toUpperCase}
                        fullWidth
                    />
                    <TextInput
                        label="Email"
                        type="email"
                        source="email"
                        parse={toLowerCase}
                        validation={{ email: true }}
                        validate={[requiredValidate, validateEmail]}
                        fullWidth
                    />
                </FormTab>

                <FormTab label="Foto Empleado" path="imageProfile">
                </FormTab>

            </TabbedForm>
        </Create>
    );
};

export default ProfileEdit;
