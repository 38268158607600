import React from 'react';
import { Avatar, Chip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    chip: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const ShiftsChipField = ({ shifts = {} }) => {
    const classes = useStyles();

    return (
        <div className={classes.chip}>
            {Object.keys(shifts).map(   
                (k, i) => (
                    <Chip
                        key={i}
                        size="small"
                        avatar={
                            <Avatar style={{ backgroundColor: green[100], border: 1 }}>
                                {i + 1}
                            </Avatar>
                        }
                        label={shifts[k].description}
                        style={{ backgroundColor: green[300], border: 0 }}
                    />
                ))
            }
        </div>
    );
}

export default ShiftsChipField;