import * as React from "react";
import {
  List,
} from "react-admin";
import ActionsTopToolbar from "../components/ActionsTopToolbar";
import InputFilter from "../components/InputFilter";
import ToolListMobile from "./ToolListMobile";
import ToolListDesktop from "./ToolListDesktop";
import { useMediaQuery } from "@material-ui/core";

const ToolList = (props) => {
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <List
      {...props}
      actions={<ActionsTopToolbar />}
      filters={<InputFilter />}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={false}
    >
      {
        isXsmall ? (
          <ToolListMobile />
        ) : (
          <ToolListDesktop />
        )
      }
    </List>
  );
};

export default ToolList;
