export const getAllRoles = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_ADMIN") ||
            permissions.includes("ROLE_RRHH") ||
            permissions.includes("ROLE_TALLER") ||
            permissions.includes("ROLE_CONTADOR") ||
            permissions.includes("ROLE_PLAYERO") ||
            permissions.includes("ROLE_INSPECTOR") ||
            permissions.includes("ROLE_PAÑOL") ||
            permissions.includes("ROLE_SUPERVISOR_PAÑOL")
        )
}
//RECURSOS HUMANOS  
export const getRolesRRHH = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_ADMIN") ||
            permissions.includes("ROLE_RRHH")
        )
}
//TALLER
//SOLO TALLER
export const getRolesWorkshopOnly = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_ADMIN") ||
            permissions.includes("ROLE_TALLER")
        )
}
export const getAllRolesWorkshop = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_ADMIN") ||
            permissions.includes("ROLE_TALLER") ||
            permissions.includes("ROLE_PAÑOL") ||
            permissions.includes("ROLE_PLAYERO") ||
            permissions.includes("ROLE_INSPECTOR") ||
            permissions.includes("ROLE_SUPERVISOR_PAÑOL")
        )
}
//CONTABILIDAD
export const getRolesFinance = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_ADMIN") ||
            permissions.includes("ROLE_CONTADOR")
        )
}

//OTROS


//SOLO SUPERVISOR PAÑOL
export const getRolesOnlySupervisorExceptAdmin = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_SUPERVISOR_PAÑOL")
        )
}
//SOLO PAÑOLERO
export const getRolesOnlyStorekeeperExceptAdmin = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_PAÑOL")
        )
}
//SOLO PLAYERO
export const getRolesOnlyDriverExceptAdmin = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_PLAYERO")
        )
}

//SOLO INSPECTOR
export const getRolesOnlyInspectorExceptAdmin = (permissions) => {
    return (typeof permissions !== 'undefined') &&
        (
            permissions.includes("ROLE_INSPECTOR")
        )
}