import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FormDataConsumer,
    useDataProvider
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Button, Card, Box, CardContent, CardActions, CircularProgress, makeStyles } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { secondaryAccountsData } from '../components/data/secondaryAccountsData';
import primaryAccountsData from '../components/data/primaryAccountsData';
import { dateParseWithoutTime, getFirstDay, getLastDay, toChoices } from '../utils/functions';
import RetentionDatagrid from './RetentionDatagrid';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const ReportRetention = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(false);
    const [values, setValues] = useState();
    const dataProvider = useDataProvider();
    const [error, setError] = useState();
    const [customers, setCustomers] = useState();

    const handleSubmit = (values) => {
        if (Object.keys(values).length > 0) {

            dataProvider
                .getList('customers', {
                    pagination: { page: 1, perPage: 99999 },
                    sort: { field: 'id', order: 'DESC' },
                    filter: { status: true },
                })
                .then(response => {
                    setCustomers(response.data);
                })
                .catch((error) => {
                    setError(error);
                });

            setLoading(true);
            setState(true);
            setValues(values);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const choicesPrimaryAccounts = primaryAccountsData.filter(i => i.id === 'CLIENTES');
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Reporte Retenciones'} />
                            <CardContent>
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="resources.reports_rrhh.filters.startDate"
                                                    source="start"
                                                    defaultValue={dateParseWithoutTime(getFirstDay())}
                                                    validate={requiredValidate}
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="resources.reports_rrhh.filters.endDate"
                                                    source="end"
                                                    defaultValue={dateParseWithoutTime(getLastDay())}
                                                    validate={requiredValidate}
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>


                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <SelectInput
                                                    label="Cuenta Principal"
                                                    source="primaryAccount"
                                                    choices={choicesPrimaryAccounts}
                                                    defaultValue={'CLIENTES'}
                                                    validate={requiredValidate}
                                                    resettable
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => (formData.primaryAccount === 'PROVEEDORES' && formData.primaryAccount !== undefined) ?
                                                        <ReferenceInput
                                                            label="Busque a su Proveedor"
                                                            source="supplierId"
                                                            reference="suppliers"
                                                            sort={{ field: 'name', order: 'ASC' }}
                                                            perPage={99999} //TODO NO TRAE MAS Q 99999
                                                            fullWidth
                                                            resettable
                                                        >
                                                            <AutocompleteInput
                                                                optionText="name"
                                                            />
                                                        </ReferenceInput>
                                                        :
                                                        (formData.primaryAccount === 'CLIENTES' && formData.primaryAccount !== undefined) ?
                                                            <ReferenceInput
                                                                label="Busque a su Cliente"
                                                                source="customerId"
                                                                reference="customers"
                                                                sort={{ field: 'name', order: 'ASC' }}
                                                                fullWidth
                                                                resettable
                                                            >
                                                                <AutocompleteInput
                                                                    optionText="name"
                                                                />
                                                            </ReferenceInput>
                                                            :
                                                            (formData.primaryAccount !== undefined) &&
                                                            <SelectInput
                                                                label="Cuenta Secundaria"
                                                                source="secondaryAccount"
                                                                choices={
                                                                    formData.primaryAccount
                                                                        ? toChoices(secondaryAccountsData[formData.primaryAccount])
                                                                        : []
                                                                }
                                                                validate={requiredValidate}
                                                                fullWidth
                                                                resettable
                                                                {...rest}
                                                            />
                                                    }
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={loading}
                                                    >
                                                        {loading && (<CircularProgress size={25} thickness={2} />)}

                                                        {'Ver Rentenciones'}
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {state &&
                                    <RetentionDatagrid values={values} customers={customers} />
                                }
                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>

    );
};

export default ReportRetention;
