import React from 'react';
import {
  Button,
} from 'react-admin';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PDFDocumentOrder from './PDFDocumentOrder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import html2canvas from 'html2canvas';

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

const getDraft = async () => {
  await delay(100);
  const input = document.getElementById('draw-image');
  const promise = html2canvas(input, {
    useCORS: true
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/png');
    return imgData;
  });
  const image = await promise;
  return ({
    draft: image,
  });
}

const getLogo = async () => {
  await delay(100);
  const input = document.getElementById('logo-image');
  const promise = html2canvas(input, {
    useCORS: true
  }).then(canvas => {
    const imgData = canvas.toDataURL('image/svg');
    return imgData;
  });
  const image = await promise;
  return ({
    logo: image,
  });
}

const PDFButton = (order) => {

  return (
    <div>
      <Button
        label="Descargar"
        variant="contained"
        onClick={async () => {
          const draft = await getDraft();
          const logo = await getLogo();
          let result = {}
          result = { ...order, ...draft, ...logo }
          const doc = <PDFDocumentOrder record={result} />;
          const asPdf = pdf();

          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, `OT-${order.id}.pdf`);
        }}
      >
        <PictureAsPdfIcon fontSize="small" />
      </Button>
    </div>
  )
};

export default PDFButton;