import React from 'react';
import { Box } from '@material-ui/core';
import OrderFinishButton from './OrderFinishButton';
import OrderDeliveryButton from './OrderDeliveryButton';
import OrderInvoicedButton from './OrderInvoicedButton';
import { useMediaQuery } from '@material-ui/core';

const ActionButtonsField = ({ record }) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    if (!record) return null;

    if (record.workOrderStatus === 'FINALIZADA_ENTREGAR' && record.deliveryDate === null) {
        return (
            <Box
                display="flex"
                alignItems="center"
                gap={1}
            >
                <OrderInvoicedButton record={record} isXsmall={isXsmall} />
            </Box>
        );
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1}
        >
            <OrderFinishButton record={record} isXsmall={isXsmall} />
            <OrderDeliveryButton record={record} isXsmall={isXsmall} />
        </Box>
    );
};

export default ActionButtonsField;



