import * as React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

import items from '../items';

const useStyles = makeStyles({
    icon: { paddingRight: '0.3em' },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
});

const LinkToRelatedItems = ({ record }) => {
    const classes = useStyles();
    return record ? (
        <Button
            size="small"
            color="primary"
            variant="outlined"
            component={Link}
            to={{
                pathname: '/items',
                search: stringify({
                    page: 1,
                    perPage: 20,
                    sort: 'name',
                    order: 'ASC',
                    filter: JSON.stringify({ category: record.id }),
                }),
            }}
            className={classes.link}
        >
            <items.icon className={classes.icon} />
            {'Articulos'}
        </Button>
    ) : null;
};

export default LinkToRelatedItems;
