import * as React from 'react';
import {
    Create,
    SimpleForm,
    useNotify
} from 'react-admin';
import ToolCustomToolbar from './ToolCustomToolbar';
import ToolForm from './ToolForm';

const ToolCreate = (props) => {
    const notify = useNotify();
    const redirect = `/tools`;

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };
    return (
        <Create {...props}
            onFailure={onFailure}
        >
            <SimpleForm
                toolbar={<ToolCustomToolbar />}
                redirect={redirect}
            >
                <ToolForm />

            </SimpleForm>
        </Create>
    );
};

export default ToolCreate;
