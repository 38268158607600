import * as React from 'react';
import {
    Edit,
    SimpleForm,
    useNotify,
    useRefresh,
    useRedirect
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import HolidayForm from './HolidayForm';

const HolidayTitle = ({ record }) => {
    return <span>{record ? `${record.description}` : ''}</span>;
};

const HolidayEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/holidays`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<HolidayTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <HolidayForm />
            </SimpleForm>
        </Edit>
    );
};

export default HolidayEdit;
