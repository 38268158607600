import * as React from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    useNotify,
    useRefresh,
    useRedirect,
    usePermissions
} from 'react-admin';
import { 
    getRolesOnlySupervisorExceptAdmin, 
    getRolesWorkshopOnly,
    getRolesOnlyStorekeeperExceptAdmin
} from '../appRolesComponents';
import CustomToolbar from '../components/CustomToolbar';
import ImageItemInput from './imagenItem/ImageItemInput';
import ImageItemView from './imagenItem/ImageItemView';
import ItemForm from './ItemForm';

const ItemTitle = ({ record }) => {
    return <span>{record ? `${record.name}` : ''}</span>;
};

const ItemEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { permissions } = usePermissions();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/items`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions) || getRolesOnlyStorekeeperExceptAdmin(permissions);
   
    if (!isWorkshopOnly) return null;
    
    return (
        <Edit
            {...props}
            undoable={false}
            title={<ItemTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <FormTab label="General" >
                    <ItemForm />
                </FormTab>
                <FormTab
                    label="Foto Articulo"
                    path="image"
                >
                    <ImageItemInput />
                    <ImageItemView />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default ItemEdit;
