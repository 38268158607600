import * as React from 'react';
import {
    Datagrid,
    NumberField,
    ChipField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CashLinkField from './CashLinkField';
// import CashNameLinkField from './CashNameLinkField';
import CashListDesktopLines from './CashListDesktopLines';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useListStyles = makeStyles({
    total: { fontWeight: 'bold' },
});

const CashListDesktop = (props) => {
    const classes = useListStyles();

    return (
        <Datagrid
            optimized
            expand={<CashListDesktopLines />}
            {...props}
        >
            <CashLinkField
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha"
                source="date"
                sortable={false}
                showTime={false}
            />
            <ChipField
                label="Cuenta Primaria"
                size="small"
                source="primaryAccount"
                sortable={false}
            />
            {/* <ChipField
                label="Cuenta Secundaria"
                size="small"
                source="secondaryAccount"
                emptyText
            /> */}
            {/* <CashNameLinkField /> */}
            <NumberField
                label="Importe"
                source="totalPartial"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />
            <NumberField
                label="Saldo Parcial"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />
        </Datagrid>
    );
};

export default CashListDesktop;
