import React, { useState, useEffect } from 'react';
import {
    calculateSubtotal,
    calculateSubtotalTax,
    calculateTotalExempt,
    currencyFormatter,
    roundToTwoDecimals
} from '../utils/functions';
import { useForm } from 'react-final-form';
import SectionTitle from '../components/SectionTitle';
import { Box, Typography } from '@material-ui/core';
import { TextInput } from 'react-admin';
import NumberFormatCustom from '../components/NumberFormatCustom';
import TextField from '@material-ui/core/TextField';

const PurchaseInvoiceFootDetail = ({ products, invoiceStatus }) => {
    const form = useForm();

    const totalExempt = (products !== undefined) ? calculateTotalExempt(products) : 0;
    const subtotal = (products !== undefined) ? calculateSubtotal(products) : 0;
    const subtotalTax = (products !== undefined) ? calculateSubtotalTax(products) : 0;

    const [adjustment, setAdjustment] = useState(() => form.getState().values.adjustment || 0);
    const [discount, setDiscount] = useState(() => form.getState().values.discount || 0);

    const total = roundToTwoDecimals(subtotal + subtotalTax);

    // Aplicar ajuste (recargo o descuento) al subtotal sin impuestos
    const subtotalWithAdjustment = roundToTwoDecimals(subtotal + adjustment - discount);

    // Calcular el total final agregando los impuestos
    const totalWithAdjustment = roundToTwoDecimals(subtotalWithAdjustment + subtotalTax);

    useEffect(() => {
        form.change("totalExempt", totalExempt);
        form.change("subtotal", subtotal);
        form.change("total", totalWithAdjustment);
        form.change("partialTotal", totalWithAdjustment);
    }, [totalExempt, subtotal, totalWithAdjustment]); // eslint-disable-line react-hooks/exhaustive-deps

    const isDisabled = invoiceStatus === 'FACTURADA';

    return (
        <div>
            <SectionTitle label="Detalle del Comprobante" />

            <Box display={{ xs: 'block', sm: 'flex' }}>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Exento" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(totalExempt)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Subtotal sin Impuestos" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(subtotal)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Impuestos" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(subtotalTax)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total con Impuestos" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(total)}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Subtotal con Ajustes " />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                        {`${currencyFormatter(subtotalWithAdjustment)}`}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total con Ajustes" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(totalWithAdjustment)}
                        </Typography>
                    </Box>
                </Box>

            </Box>

            <Box mt="1em" display="flex" alignItems="center">
                <Box width="200px" mr="1em">
                    <TextField
                        label="Recargo"
                        name="adjustment"
                        value={adjustment}
                        size="small"
                        variant="filled"
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                            inputProps: { style: { textAlign: 'right' } },
                            readOnly: isDisabled
                        }}
                        onChange={(e) => setAdjustment(parseFloat(e.target.value) || 0)}
                        fullWidth
                        disabled={isDisabled}
                    />
                </Box>
                <Box width="200px">
                    <TextField
                        label="Descuento"
                        name="discount"
                        value={discount}
                        size="small"
                        variant="filled"
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                            inputProps: { style: { textAlign: 'right' } },
                            readOnly: isDisabled
                        }}
                        onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
                        fullWidth
                        disabled={isDisabled}
                    />
                </Box>
            </Box>

            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}>
                <TextInput
                    source="totalExempt"
                    defaultValue={totalExempt}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="subtotal"
                    defaultValue={subtotalWithAdjustment}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="total"
                    defaultValue={totalWithAdjustment}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="partialTotal"
                    defaultValue={totalWithAdjustment}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="adjustment"
                    defaultValue={adjustment}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="discount"
                    defaultValue={discount}
                    disabled={true}
                    fullWidth
                />
            </div>
        </div>
    );
};

export default PurchaseInvoiceFootDetail;
