import NoteIcon from '@material-ui/icons/Note';
import PurchaseInvoiceList from './PurchaseInvoiceList';
import PurchaseInvoiceCreate from './PurchaseInvoiceCreate';
import PurchaseInvoiceEdit from './PurchaseInvoiceEdit';

export default {
  list: PurchaseInvoiceList,
  create: PurchaseInvoiceCreate,
  edit: PurchaseInvoiceEdit,
  icon: NoteIcon
};
