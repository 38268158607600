import { useMediaQuery } from '@material-ui/core';
import * as React from 'react';
import {
    DateInput,
    Filter,
    List,
} from 'react-admin';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import ReceiptListDesktop from './ReceiptListDesktop';
import ReceiptListMobile from './ReceiptListMobile';

const ListFilters = (props) => (
    <Filter {...props}>
        <DateInput
            label="resources.reports_rrhh.filters.startDate"
            source="start"
            alwaysOn
        />
        <DateInput
            label="resources.reports_rrhh.filters.endDate"
            source="end"
            alwaysOn
        />
    </Filter>
);

const ReceiptList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<ListFilters />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
            title={'Recibos de Cobro'}
        >

            {isXsmall ? (
                <ReceiptListMobile />
            ) : (
                <ReceiptListDesktop />
            )}
        </List>
    );
};

export default ReceiptList;
