import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { voucherFormat } from '../../utils/functions';

const styles = StyleSheet.create({
    subHeader: {
        textAlign: 'center',
        border: 1,
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        paddingTop: 15,
        paddingBottom: 25,
        textAlign: 'center',
    },
    title: {
        fontSize: 15,
        fontStyle: 'bold',
    },
    headerLeft: {
        width: '50%'
    },
    headerRight: {
        width: '50%'
    },
    voucher: {
        fontSize: 12,
        fontStyle: 'bold',
    }
});

const PaymentHeader = ({ pdfDocument }) => {

    const voucher = `ORDEN DE PAGO: ${pdfDocument.pointOfSale}-${voucherFormat(pdfDocument.id)}`;
    const cuit = `30-70941531-6`;
    const iibb = `9012763200`;
    const activitiesStart = `11/2005`;

    return (
        <View>
            <View style={styles.subHeader}>
                <Text>DOCUMENTO NO VALIDO COMO FACTURA</Text>
            </View>
            <View style={styles.container}>
                <View style={styles.headerLeft}>
                    <Text style={styles.title}>GRUPO M.M. S.R.L.</Text>
                    <Text>Al servicio del transporte</Text>
                    <Text>Garcia de Cossio 7479</Text>
                    <Text>Ciudad de Buenos Aires</Text>
                    <Text>011-4208-0271</Text>
                    <Text>info@marasa.com.ar</Text>
                    <Text>IVA RESPONSABLE INSCRIPTO</Text>
                </View>
                <View style={styles.headerRight}>
                    <Text style={styles.voucher}>{voucher}</Text>
                    <Text style={styles.voucher}>Fecha: {pdfDocument.date}</Text>
                    <Text>CUIT: {cuit}</Text>
                    <Text>Ingresos Brutos: {iibb}</Text>
                    <Text>Inicio de Actividades: {activitiesStart}</Text>
                </View>
            </View>
        </View>
    )
};

export default PaymentHeader;