import * as React from 'react';
import {
    Datagrid,
    List,
    Responsive,
    SimpleList,
    Pagination
} from 'react-admin';

import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import ShiftLinkField from './ShiftLinkField';

const FunctionStart = ({ record }) => {
    let startHour = String(record.startHour).padStart(2, "0");
    let startMinutes = String(record.startMinutes).padStart(2, "0");
    return (
        <div>
            {startHour}:{startMinutes} Hrs.
        </div>
    )
};

const FunctionEnd = ({ record }) => {
    let endHour = String(record.endHour).padStart(2, "0");
    let endMinutes = String(record.endMinutes).padStart(2, "0");
    return (
        <div>
            {endHour}:{endMinutes} Hrs.
        </div>
    )
};

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const ShiftList = (props) => {
    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            perPage={25}
            bulkActionButtons={false}
            pagination={<PostPagination />}
        >
            <Responsive
                small={
                    <SimpleList
                        primaryText={record => `Turno: ${record.description} `}
                        secondaryText={
                            record =>
                                `Inicio: ${String(record.startHour).padStart(2, "0")}:${String(record.startMinutes).padStart(2, "0")} Hrs.`
                        }
                        tertiaryText={
                            record =>
                                `Fin: ${String(record.endHour).padStart(2, "0")}:${String(record.endMinutes).padStart(2, "0")} Hrs.`
                        }
                        linkType={record => record.description ? "edit" : "show"}
                    />
                }
                medium={
                    <Datagrid
                        optimized
                        rowClick="edit"
                    >
                        <ShiftLinkField
                            sortable={false}
                        />
                        <FunctionStart
                            label="Hora Inicio"
                            sortable={false}
                        />
                        <FunctionEnd
                            label="Hora Fin"
                            sortable={false}
                        />
                    </Datagrid>
                }
            />

        </List>
    );
};

export default ShiftList;
