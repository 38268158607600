import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { Button, useDataProvider, LinearProgress } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { dateParseWithoutTime } from '../../utils/functions';

const ItemsExport = ({ dataExport }) => {
    const [suppliers, setSuppliers] = useState([]);
    const dataProvider = useDataProvider();
    const [error, setError] = useState(null);
    const [exportLoading, setExportLoading] = useState(false);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const { data } = await dataProvider.getList('suppliers', {
                    pagination: { page: 1, perPage: 99999 },
                    sort: { field: 'id', order: 'DESC' },
                    filter: {}
                });
                setSuppliers(data);
                setExportLoading(false);
                setError(null);
            } catch (error) {
                setError(error);
                setExportLoading(false);
            }
        };

        fetchSuppliers();
    }, [dataProvider]);

    if (error) {
        return <p>Error al cargar los proveedores</p>;
    }

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const dataSet = dataExport.map(item => {
        const supplier = suppliers.find(supplier => supplier.id.toString() === item.supplierId.toString());
        return {
            code: item.code,
            name: item.name,
            category: item.categoryName,
            quantity: item.quantity,
            maxQuantity: item.maxQuantity,
            minQuantity: item.minQuantity,
            warehouseQuantity: item.warehouseQuantity,
            price: item.price,
            supplier: supplier ? supplier.name : 'Proveedor no encontrado',
            location: item.location,
            description: item.description,
        };
    });

    const handleExportClick = () => {
        setExportLoading(true);
        setTimeout(() => {
            setExportLoading(false);
        }, 1000);
    };

    const filename = `articulos-taller-${dateParseWithoutTime(Date.now())}`
    return (
        <ExcelFile
            filename={filename}
            element={
                <Button
                    label="Exportar a Excel"
                    size="small"
                    variant="contained"
                    onClick={handleExportClick}
                    disabled={exportLoading}
                >
                    {exportLoading ? <LinearProgress /> : <DownloadIcon />}
                </Button>
            }>
            <ExcelSheet data={dataSet} name="ARTICULOS">
                <ExcelColumn label="Codigo" value="code" />
                <ExcelColumn label="Nombre" value="name" />
                <ExcelColumn label="Categoria" value="category" />
                <ExcelColumn label="Cantidad Pañol" value="quantity" />
                <ExcelColumn label="Cantidad Maximo" value="maxQuantity" />
                <ExcelColumn label="Cantidad Minimo" value="minQuantity" />
                <ExcelColumn label="Cantidad Deposito" value="warehouseQuantity" />
                <ExcelColumn label="Precio" value="price" />
                <ExcelColumn label="Proveedor" value="supplier" />
                <ExcelColumn label="Localizacion" value="location" />
                <ExcelColumn label="Descripcion" value="description" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default ItemsExport;
