import * as React from 'react';
import {
    Create,
    SimpleForm,
    useNotify
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import HolidayForm from './HolidayForm';

const HolidayCreate = (props) => {
    const redirect = `/holidays`;
    const notify = useNotify();

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    return (
        <Create
            {...props}
            onFailure={onFailure}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <HolidayForm />
            </SimpleForm>
        </Create>
    );
};

export default HolidayCreate;
