import React from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
    makeStyles
} from '@material-ui/core';
import DeleteButtonCard from '../components/DeleteButtonCard';
import { currencyFormatter, voucherFormat } from '../utils/functions';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: '1em' 
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const MethodsList = ({ id, methods, deleteToCard }) => {
    const classes = useStyles();
    const methodsData = (id === undefined) ? JSON.parse(localStorage.getItem("methods")) : methods;

    if (methodsData === null || methodsData.length === 0) return null;

    return (
        methodsData.map((item, i) => (
            <div className={classes.root}>
                <Card key={i} className={classes.card}>
                    <CardHeader
                        title={
                            <Typography gutterBottom>
                                {`${i + 1} - ${item.method}`}
                            </Typography>
                        }
                        action={
                            <CardActions>
                                {id === undefined && <DeleteButtonCard product={item} deleteToCard={deleteToCard} />}
                            </CardActions>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        Importe Abonado: {currencyFormatter(item.total)}
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                flex={3}
                                ml={{ xs: 0, lg: '1em' }}
                                mt={{ xs: '1em', lg: 0 }}
                            >
                                {item.method === 'TRANSFERENCIA_BANCARIA' &&
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                            {item.myBank ? 'Cuenta Propia' : 'Cuenta de Terceros'}<br />
                                            Banco: {item.bank}<br />
                                            Nro. Transferencia: {item.bankTransfer}<br />
                                        </Box>
                                    </Box>
                                }

                                {item.method === 'CHEQUE' &&
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                            {item.myCheck ? 'Cheque Propio' : 'Cheque de Terceros'} #{voucherFormat(item.numberCheck)}<br />
                                            Banco: {item.bank}<br />
                                            Fecha de Emisión: {item.startDate}<br />
                                            Fecha de vto: {item.dueDate}<br />
                                        </Box>
                                    </Box>
                                }
                                {item.method === 'RETENCIONES' &&
                                    <Box display={{ xs: 'block', sm: 'flex' }}>
                                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                            Impuesto: {item.tax}<br />
                                            Comprobante Origen: {voucherFormat(item.numberVoucher)}<br />
                                            Juridicción: {item.jurisdiction}<br />
                                            Monto Comprobante S/IVA: {currencyFormatter(item.totalVoucher)}<br />
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </div>
        ))
    )
}

export default MethodsList;
