import React from 'react';
import {
    LinearProgress,
    useGetOne,
    TextField
} from 'react-admin';

const LineField = ({ id }) => {
    const { data, loaded } = useGetOne('busLines', id);
    if (!loaded) return <LinearProgress />;
    return (
        <TextField record={data} source="name" />
    );
}

export default LineField;
