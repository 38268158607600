import * as React from 'react';
import {
    Create,
    SimpleForm,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import CustomerForm from './CustomerForm';

const CustomerCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/customers`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    return (
        <Create
            {...props}
            undoable={false}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
            >
                <CustomerForm />
            </SimpleForm>
        </Create>
    );
};

export default CustomerCreate;
