import React from 'react';
import {
    LinearProgress,
    useGetOne,
} from 'react-admin';

const BusTemplateHidden = ({ id }) => {
    const { data, loaded } = useGetOne('vehicles', id);
    if (!loaded) return <LinearProgress />;
    localStorage.setItem("busTemplate", data.busTemplate);
    return (
        // <TextField record={data} source="busTemplate" /> Bustemplate
        null
    );
}

export default BusTemplateHidden;