
import React, { useState, useEffect } from 'react';
import {
    LinearProgress,
    AutocompleteInput,
    TextInput
} from 'react-admin';
import { currencyFormatter } from '../utils/functions';

const SelectInputChecks = ({ component, itemsChoices }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [checks, setChecks] = useState([]);
    const [check, setCheck] = useState();

    const apiUrl = "https://api.marasa.com.ar/finance-api/api/checks/";//TODO -- FIJARSE-- /caja
    const fetchData = async () => {
        try {
            const response = await fetch(apiUrl);
            const checksData = await response.json();
            setChecks(checksData.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) return <LinearProgress />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!checks) return null;

    const choicesAux = checks.map((check) => ({
        id: check.id,
        name: (check.myCheck) ?
            `Cheque Propio #${check.numberCheck} Total: ${currencyFormatter(check.total)}` :
            `Cheque de Terceros #${check.numberCheck} Total: ${currencyFormatter(check.total)}`
    }));

    const choices = choicesAux.filter(item => !itemsChoices.includes(item.id)); // LEFT JOIN CON UN OBJETO Y ARRAY

    if (choices.length === 0) return 'No hay cheques en cartera';

    const handleChange = (event) => {
        const apiUrl = `https://api.marasa.com.ar/finance-api/api/checks/${event}`;
        fetch(apiUrl)
            .then((response) => response.json())
            .then(({ data }) => {
                setCheck(data);
            })
            .catch(error => {
                console.log(`fetchCheck: ${error}`);
            });
    }

    return (
        <div>
            <AutocompleteInput
                label="Seleccione el Cheque"
                source="check"
                name="check"
                choices={choices}
                component={component}
                onChange={handleChange}
                resettable
                fullWidth
            />

            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}>
                <TextInput
                    source="numberCheck"
                    defaultValue={(check !== undefined) && check.numberCheck}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="myCheck"
                    defaultValue={(check !== undefined) && check.myCheck}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="bank"
                    defaultValue={(check !== undefined) && check.bank}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="startDate"
                    defaultValue={(check !== undefined) && check.startDate}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="dueDate"
                    defaultValue={(check !== undefined) && check.dueDate}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="totalCheck"
                    defaultValue={(check !== undefined) && check.total}
                    disabled={true}
                    fullWidth
                />
            </div>
        </div>
    )
}

export default SelectInputChecks;