import React from 'react';
import {
    calculateSubtotal,
    calculateSubtotalTax,
    calculateTotalExempt,
    currencyFormatter,
    roundToTwoDecimals
} from '../utils/functions';
import { useForm } from 'react-final-form';
import SectionTitle from '../components/SectionTitle';
import { Box, Typography } from '@material-ui/core';
import { TextInput } from 'react-admin';

const InvoiceFootDetail = ({ products }) => {
    const form = useForm();
    const totalExempt = (products !== undefined && products !== null) ? calculateTotalExempt(products) : 0;
    const subtotal = (products !== undefined && products !== null) ? calculateSubtotal(products) : 0;
    const subtotalTax = (products !== undefined && products !== null) ? calculateSubtotalTax(products) : 0;

    const total = roundToTwoDecimals(subtotal + subtotalTax);

    form.change("totalExempt", totalExempt);
    form.change("subtotal", subtotal);
    form.change("total", total);
    form.change("partialTotal", total);

    return (
        <div>
            <Box display={{ xs: 'block', sm: 'flex' }}>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Exento" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(totalExempt)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Subtotal sin Impuestos" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(subtotal)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Impuestos" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(subtotalTax)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total con Impuestos" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(total)}
                        </Typography>
                    </Box>
                </Box>

            </Box>
            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}>
                <TextInput
                    source="totalExempt"
                    defaultValue={totalExempt}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="subtotal"
                    defaultValue={subtotal}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="total"
                    defaultValue={total}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="partialTotal"
                    defaultValue={total}
                    disabled={true}
                    fullWidth
                />
            </div>
        </div>

    );
};

export default InvoiceFootDetail;
