import React from 'react';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    CardContent
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import SectionTitle from '../components/SectionTitle';
import {
    calculateTotalReceipts,
    calculateTotalReceiptsInvoices,
    currencyFormatter,
} from '../utils/functions';
import ItemInvoiceField from '../components/ItemInvoiceField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const InvoiceListCard = (props) => {
    const invoices = props.record.invoices;
    const total = calculateTotalReceipts(invoices);
    const totalReceipts = calculateTotalReceiptsInvoices(invoices);
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const classes = useStyles();
    
    return (
        <div>
            <SectionTitle label="¿Qué te pagaron?" />
            <Box mt="1em" />
            {isXsmall ?
                (invoices.length === 0) ?
                    <div>
                        <p>&nbsp;&nbsp;Sin facturas de compra para pagar!</p>
                    </div>
                    :
                    invoices.map((item, i) => (
                        <Card key={item.id} className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <div>
                                    <ItemInvoiceField id={item.id} />
                                </div>
                                <div>
                                    {'Falta Cancelar'}
                                    :&nbsp;
                                    <NumberFormat
                                        value={item.total}
                                        displayType={'text'}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        decimalScale={2}
                                        isNumericString={true}
                                        prefix={'$ '}
                                    />
                                </div>
                                <div>
                                    {'¿Cuanto te pagó?'}
                                    :&nbsp;
                                    <NumberFormat
                                        value={item.totalReceipt}
                                        displayType={'text'}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        decimalScale={2}
                                        isNumericString={true}
                                        prefix={'$ '}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    ))
                :
                <Card>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {'Comprobante'}
                                </TableCell>
                                <TableCell>
                                    {'Falta Cancelar'}
                                </TableCell>
                                <TableCell>
                                    {'¿Cuanto te pagó?'}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(invoices.length === 0) ?
                                <div>
                                    <p>&nbsp;&nbsp;Sin facturas por cobrar!</p>
                                </div>
                                :
                                invoices.map((item) => (
                                    <TableRow>
                                        <TableCell>
                                            <ItemInvoiceField id={item.id} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                value={item.total}
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={2}
                                                isNumericString={true}
                                                prefix={'$ '}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                value={item.totalReceipt}
                                                displayType={'text'}
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={2}
                                                isNumericString={true}
                                                prefix={'$ '}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Card>
            }

            <Box mt="1em" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Facturas a Cancelar" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4">
                            {currencyFormatter(total)}
                        </Typography>
                    </Box>
                </Box>

                <Box flex={1} mr={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Total Cancelado" />
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <Typography variant="h4" color="secondary">
                            {currencyFormatter(totalReceipts)}
                        </Typography>
                    </Box>
                </Box>
            </Box>

        </div>
    );
}

export default InvoiceListCard;