import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useDataProvider,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        marginTop: 16,
    },
});

const DocumentFileView = ({ record }) => {
    const classes = useStyles();
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const employeeId = record.id;

    const fetchDocument = useCallback(async () => {
        const apiUrl = `https://api.marasa.com.ar/rrhh-api/api/document/${employeeId}`;
        const response = await fetch(apiUrl).catch((error) =>
            console.log('Falló el rrhh-api', error)
        );

        if (response.ok) {
            const documentList = await response.json();
            setState(documentList.data);
        }

    }, [dataProvider]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchDocument();
    }, [fetchDocument]);

    let urlDocument = `https://api.marasa.com.ar/rrhh-api/api/document/${employeeId}/download`;

    return (
        <div>
            {(state !== undefined && state.length > 0) ?
                isXsmall ? (

                    <Card className={classes.root}>
                        <CardContent className={classes.cardContent}>
                            <div>
                                {'Nombre de archivo'}
                                :&nbsp;
                                {state[0].name}
                            </div>
                            <div>
                                {'Fecha de última modificación'}
                                :&nbsp;
                                {moment(state[0].lastUpdate).format('YYYY-MM-DD HH:mm')}
                            </div>
                            <Link href={urlDocument}> Descargar</Link>

                        </CardContent>
                    </Card>
                ) : (
                    <Card className={classes.root}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {'Nombre de archivo'}
                                    </TableCell>
                                    <TableCell>
                                        {'Fecha de última modificación'}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{state[0].name}</TableCell>
                                    <TableCell>{moment(state[0].lastUpdate).format('YYYY-MM-DD HH:mm')}</TableCell>
                                    <TableCell>
                                        <Link href={urlDocument}> Descargar</Link>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Card>
                )
                :
                ''
            }
        </div>
    );
}
export default DocumentFileView;
