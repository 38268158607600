import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import VehicleList from './VehicleList';
import VehicleCreate from './VehicleCreate';
import VehicleEdit from './VehicleEdit';

export default {
  list: VehicleList,
  create: VehicleCreate,
  edit: VehicleEdit,
  icon: DirectionsBusIcon
};
