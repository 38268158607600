import React from 'react';
import { useRecordContext } from 'react-admin';
import DateFieldFormatted from '../components/DateFieldFormatted';

const CustomDateTimeConfirmedField = () => {
    const record = useRecordContext();

    if (!record) return null;

    const isConfirmed = record.items[0].dateTimeConfirmed;

    return (
        isConfirmed ? (
            <DateFieldFormatted
                record={record.items[0]}
                source="dateTimeConfirmed"
            />
        ) : (
            <span style={{ color: 'red', fontWeight: 'bold' }}>NO CONFIRMADO</span>
        )
    );
};

export default CustomDateTimeConfirmedField;
