import * as React from 'react';
import {
    Filter,
    ReferenceInput,
    SearchInput,
    SelectInput,
    NumberInput,
    BooleanInput,
    useListContext,
} from 'react-admin';
import { useRef, useEffect } from 'react';

const ItemFilter = props => {
    const { setFilters } = useListContext();
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFilters({ q: '' });
                setFilters({ supplierName: '' });
                setFilters({ patent: '' });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setFilters]);

    return (
        <Filter {...props}>
            <SearchInput
                source="q"
                alwaysOn
                resettable
                autoFocus
                placeholder="Buscar por nombre o código"
                ref={inputRef}
                parse={(value) => value.toUpperCase()}
            />
            <ReferenceInput
                label="Categoria"
                source="category"
                reference="categories"
                sort={{ field: 'id', order: 'ASC' }}
            >
                <SelectInput source="name" />
            </ReferenceInput>
            <NumberInput
                label="Stock"
                source="quantity"
            />
            <BooleanInput
                label="Tipo Consumible"
                source="isConsumable"
            />
        </Filter>
    );
};

export default ItemFilter;