import { Card, CardHeader } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import {
    Create,
    FormDataConsumer,
    FormTab,
    TabbedForm,
    useNotify
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import PurchaseInvoiceForm from './PurchaseInvoiceForm';
import PurchaseInvoiceFormDetail from './PurchaseInvoiceFormDetail';
import PurchaseInvoiceFormTax from './PurchaseInvoiceFormTax';

const PurchaseInvoiceCreate = (props) => {
    const notify = useNotify();
    const redirect = `/purchases-invoices`;
    const [loading, setLoading] = useState(true);

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const validate = (values) => {
        const errors = {};
        const purchasesInvoiceCart = JSON.parse(localStorage.getItem("purchasesInvoiceCart"));

        if ((purchasesInvoiceCart === null || purchasesInvoiceCart === undefined || (Object.keys(purchasesInvoiceCart).length === 0))) {
            errors.total = ['Agregue un item'];
        }

        return errors;
    };

    const handleLoading = (isLoading) => {
        setLoading(isLoading);
    };

    return (
        <Create
            {...props}
            onFailure={onFailure}
            title={'Comprobantes de Compras'}
        >
            <TabbedForm
                toolbar={<CustomToolbar loading={loading} />}
                redirect={redirect}
                validate={validate}
            >

                <FormTab label="Cabecera" >
                    <PurchaseInvoiceForm />
                </FormTab>

                <FormTab
                    label="Detalle"
                    path="details"
                >
                    <PurchaseInvoiceFormDetail  {...props} handleLoading={handleLoading} />
                </FormTab>

                <FormTab
                    label="Otros impuestos"
                    path="tax"
                >
                    <FormDataConsumer>
                        {({ formData }) => (formData.supplierId !== undefined && formData.invoiceType !== undefined) ?
                            <PurchaseInvoiceFormTax />
                            :
                            <Card key={0}>
                                <CardHeader
                                    title={
                                        <div>
                                            Por favor, selecciona un proveedor y el tipo de comprobante y luego podrás cargar los conceptos facturados.
                                        </div>
                                    }
                                />
                            </Card>
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default PurchaseInvoiceCreate;
