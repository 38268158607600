import * as React from 'react';
import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useDataProvider,
    Loading
} from 'react-admin';
import BasketOrdersGrid from './BasketOrdersGrid';

const BasketOrdersList = (props) => {
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const dataProvider = useDataProvider();

    const fetchOrders = useCallback(async () => {
        dataProvider
            .getList('orders', {
                pagination: { page: 1, perPage: 999999 },
                sort: { field: 'id', order: 'DESC' },
                filter: {},
            })
            .then(response => {
                let orders = response.data.filter(order => order.workOrderStatus === 'PENDIENTE' || order.workOrderStatus === 'EN_PROGRESO');
                setState(state => ({ ...state, orders }));
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });

    }, [dataProvider]);

    useEffect(() => {
        fetchOrders();
    }, [fetchOrders]);

    const {
        orders,
    } = state;

    if (loading) return <Loading />;
    if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
    if (!orders) return null;

    return (
        <BasketOrdersGrid orders={orders} {...props}/>
    );
};

export default BasketOrdersList;
