import * as React from "react";
import { ImageInput, ImageField } from 'react-admin';

const ImageProfileInput = () => (
    <ImageInput
        label="Foto del empleado:"
        source="imageProfile"
        accept="image/png, image/jpeg"
        maxSize={1000000}
        placeholder={
            <div>
                <p>Subir una foto del empleado</p>
                <em>
                    (El archivo no debe exceder 1MB)
                </em>
            </div>
        }
    >
        <ImageField source="imageProfile" />
    </ImageInput>
);

export default ImageProfileInput;