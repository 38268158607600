import * as React from 'react';
import {
    DateInput,
    Filter,
    List,
    SearchInput,
} from 'react-admin';
import CheckListAside from './CheckListAside';
import CheckListDesktop from './CheckListDesktop';
import CheckListMobile from './CheckListMobile';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import { useMediaQuery } from '@material-ui/core';

const ListFilters = (props) => (
    <Filter {...props}>
        <DateInput
            label="resources.reports_rrhh.filters.startDate"
            source="start"
            alwaysOn
        />
        <DateInput
            label="resources.reports_rrhh.filters.endDate"
            source="end"
            alwaysOn
        />
    </Filter>
);

const SmallFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <DateInput
            label="resources.reports_rrhh.filters.startDate"
            source="start"
            alwaysOn
        />
        <DateInput
            label="resources.reports_rrhh.filters.endDate"
            source="end"
            alwaysOn
        />
    </Filter>
);

const CheckList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    return (
        <List
            {...props}
            filters={isSmall ? <SmallFilter /> : <ListFilters />}
            aside={<CheckListAside />}
            actions={<ActionsTopToolbar />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
            title={'Cartera de Cheques'}
        >

            {isXsmall ? (
                <CheckListMobile />
            ) : (
                <CheckListDesktop />
            )}
        </List>
    );
};

export default CheckList;
