import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        alignItems: 'center',
        //height: 10,
        fontSize: 10,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    code: {
        width: '5%',
        borderRightWidth: 1,
    },
    description: {
        width: '48%',
        borderRightWidth: 1,
    },
    qty: {
        width: '7%',
        borderRightWidth: 1,
    },
    rate: {
        width: '10%',
        borderRightWidth: 1,
    },
    price: {
        width: '15%',
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
});

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.code}>Cód</Text>
        <Text style={styles.description}>Descripción</Text>
        <Text style={styles.qty}>Cant</Text>
        <Text style={styles.rate}>%IVA</Text>
        <Text style={styles.price}>P. Unitario</Text>
        <Text style={styles.amount}>Total</Text>
    </View>
);

export default InvoiceTableHeader;