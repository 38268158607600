import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import { currencyFormatter } from '../utils/functions';
import InvoiceFootDetail from './PurchaseInvoiceFootDetail';
import { calculateTotalWithoutTax } from '../utils/functions';

const ListItemsDesktop = ({ formData }) => {
	console.log('formData', formData);
	return (
		<CardContent>
			<Box display={{ md: 'block', lg: 'flex' }}>
				<Box flex={2} mr={{ md: 0, lg: '1em' }}>
					<SectionTitle label="Detalle del Comprobante" />
					<Box display={{ xs: 'block', sm: 'flex' }}>
						<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
							<Card>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>
												{'#'}
											</TableCell>
											<TableCell>
												{'Descripción'}
											</TableCell>
											<TableCell>
												{'Comentario Adicional'}
											</TableCell>
											<TableCell align="center">
												{'Cantidad'}
											</TableCell>
											<TableCell align="right">
												{'Precio Sin IVA'}
											</TableCell>
											<TableCell align="right">
												{'Total Sin IVA'}
											</TableCell>
											<TableCell align="center">
												{'IVA'}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{formData.operationInfo.map((item, i) => (
											<TableRow key={item.id}>
												<TableCell>
													{i + 1}
												</TableCell>
												<TableCell>
													{item.name}
												</TableCell>
												<TableCell>
													{item.itemComment}
												</TableCell>
												<TableCell align="center">
													{item.itemQuantity}
												</TableCell>
												<TableCell align="right">
													{currencyFormatter(item.price)}
												</TableCell>
												<TableCell align="right">
													{currencyFormatter(calculateTotalWithoutTax(item))}
												</TableCell>
												<TableCell align="center">
													{(item.itemTax === 1) ? 'EXENTO' : `${item.itemTax * 100}%`}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Card>

							<Box mt="1em" />

							<InvoiceFootDetail
								 products={formData.operationInfo} 
								 invoiceStatus={formData.invoiceStatus} 
							/>

						</Box>
					</Box>
				</Box>


			</Box>
		</CardContent>
	)
}

export default ListItemsDesktop;
