import React from 'react';
import {
    TextInput,
    BooleanInput,
    usePermissions
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { toUpperCase } from '../utils/functions';
import SectionTitle from '../components/SectionTitle';
import DrawImage from './DrawImage';
import { getRolesOnlyDriverExceptAdmin } from '../appRolesComponents';

const InspectionFormVehicle = (props) => {
    const { permissions } = usePermissions();

    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    {!isDriver &&
                        <div>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput
                                        label="Chocado"
                                        source="crashed"
                                    />
                                </Box>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput
                                        label="Pintura Completa"
                                        source="completePaint"
                                    />
                                </Box>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <DrawImage {...props} />
                                </Box>
                            </Box>

                            <Box mt="1em" />
                        </div>
                    }
                    <SectionTitle label="Observaciones y detalles" />
                    <TextInput
                        label="Ingrese las observaciones y detalles"
                        source="details"
                        parse={toUpperCase}
                        fullWidth
                        multiline
                        resettable
                    />
                </Box>
            </Box>
        </CardContent>
    );
};

export default InspectionFormVehicle;
