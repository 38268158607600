const choices = [
    { id: 'BANCO DE GALICIA Y BUENOS AIRES S.A.U.', name: 'BANCO DE GALICIA Y BUENOS AIRES S.A.U.' },
    { id: 'BANCO DE LA NACION ARGENTINA', name: 'BANCO DE LA NACION ARGENTINA' },
    { id: 'BANCO DE LA PROVINCIA DE BUENOS AIRES', name: 'BANCO DE LA PROVINCIA DE BUENOS AIRES' },
    { id: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA', name: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA' },
    { id: 'CITIBANK N.A.', name: 'CITIBANK N.A.' },
    { id: 'BANCO BBVA ARGENTINA S.A.', name: 'BANCO BBVA ARGENTINA S.A.' },
    { id: 'BANCO DE LA PROVINCIA DE CORDOBA S.A.', name: 'BANCO DE LA PROVINCIA DE CORDOBA S.A.' },
    { id: 'BANCO SUPERVIELLE S.A.', name: 'BANCO SUPERVIELLE S.A.' },
    { id: 'BANCO DE LA CIUDAD DE BUENOS AIRES', name: 'BANCO DE LA CIUDAD DE BUENOS AIRES' },
    { id: 'BANCO PATAGONIA S.A.', name: 'BANCO PATAGONIA S.A.' },
    { id: 'BANCO HIPOTECARIO S.A.', name: 'BANCO HIPOTECARIO S.A.' },
    { id: 'BANCO DE SAN JUAN S.A.', name: 'BANCO DE SAN JUAN S.A.' },
    { id: 'BANCO MUNICIPAL DE ROSARIO', name: 'BANCO MUNICIPAL DE ROSARIO' },
    { id: 'BANCO SANTANDER RIO S.A.', name: 'BANCO SANTANDER RIO S.A.' },
    { id: 'BANCO DEL CHUBUT S.A.', name: 'BANCO DEL CHUBUT S.A.' },
    { id: 'BANCO DE SANTA CRUZ S.A.', name: 'BANCO DE SANTA CRUZ S.A.' },
    { id: 'BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M', name: 'BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M' },
    { id: 'BANCO DE CORRIENTES S.A.', name: 'BANCO DE CORRIENTES S.A.' },
    { id: 'BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ', name: 'BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ' },
    { id: 'BRUBANK S.A.U.', name: 'BRUBANK S.A.U.' },
    { id: 'BANCO INTERFINANZAS S.A.', name: 'BANCO INTERFINANZAS S.A.' },
    { id: 'HSBC BANK ARGENTINA S.A.', name: 'HSBC BANK ARGENTINA S.A.' },
    { id: 'OPEN BANK ARGENTINA S.A.', name: 'OPEN BANK ARGENTINA S.A.' },
    { id: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO', name: 'JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO' },
    { id: 'BANCO CREDICOOP COOPERATIVO LIMITADO', name: 'BANCO CREDICOOP COOPERATIVO LIMITADO' },
    { id: 'BANCO DE VALORES S.A.', name: 'BANCO DE VALORES S.A.' },
    { id: 'BANCO ROELA S.A.', name: 'BANCO ROELA S.A.' },
    { id: 'BANCO MARIVA S.A.', name: 'BANCO MARIVA S.A.' },
    { id: 'BANCO ITAU ARGENTINA S.A.', name: 'BANCO ITAU ARGENTINA S.A.' },
    { id: 'BANK OF AMERICA, NATIONAL ASSOCIATION', name: 'BANK OF AMERICA, NATIONAL ASSOCIATION' },
    { id: 'BNP PARIBAS', name: 'BNP PARIBAS' },
    { id: 'BANCO PROVINCIA DE TIERRA DEL FUEGO', name: 'BANCO PROVINCIA DE TIERRA DEL FUEGO' },
    { id: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGU', name: 'BANCO DE LA REPUBLICA ORIENTAL DEL URUGU' },
    { id: 'BANCO SAENZ S.A.', name: 'BANCO SAENZ S.A.' },
    { id: 'BANCO MERIDIAN S.A.', name: 'BANCO MERIDIAN S.A.' },
    { id: 'BANCO MACRO S.A.', name: 'BANCO MACRO S.A.' },
    { id: 'BANCO COMAFI SOCIEDAD ANONIMA', name: 'BANCO COMAFI SOCIEDAD ANONIMA' },
    { id: 'BANCO DE INVERSION Y COMERCIO EXTERIOR S', name: 'BANCO DE INVERSION Y COMERCIO EXTERIOR S' },
    { id: 'BANCO PIANO S.A.', name: 'BANCO PIANO S.A.' },
    { id: 'BANCO JULIO SOCIEDAD ANONIMA', name: 'BANCO JULIO SOCIEDAD ANONIMA' },
    { id: 'BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL', name: 'BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL' },
    { id: 'BANCO DEL SOL S.A.', name: 'BANCO DEL SOL S.A.' },
    { id: 'NUEVO BANCO DEL CHACO S. A.', name: 'NUEVO BANCO DEL CHACO S. A.' },
    { id: 'BANCO VOII S.A.', name: 'BANCO VOII S.A.' },
    { id: 'BANCO DE FORMOSA S.A.', name: 'BANCO DE FORMOSA S.A.' },
    { id: 'BANCO CMF S.A.', name: 'BANCO CMF S.A.' },
    { id: 'BANCO DE SANTIAGO DEL ESTERO S.A.', name: 'BANCO DE SANTIAGO DEL ESTERO S.A.' },
    { id: 'BANCO INDUSTRIAL S.A.', name: 'BANCO INDUSTRIAL S.A.' },
    { id: 'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA', name: 'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA' },
    { id: 'BANCO CETELEM ARGENTINA S.A.', name: 'BANCO CETELEM ARGENTINA S.A.' },
    { id: 'BANCO DE SERVICIOS FINANCIEROS S.A.', name: 'BANCO DE SERVICIOS FINANCIEROS S.A.' },
    { id: 'BANCO BRADESCO ARGENTINA S.A.U.', name: 'BANCO BRADESCO ARGENTINA S.A.U.' },
    { id: 'BANCO DE SERVICIOS Y TRANSACCIONES S.A.', name: 'BANCO DE SERVICIOS Y TRANSACCIONES S.A.' },
    { id: 'RCI BANQUE S.A.', name: 'RCI BANQUE S.A.' },
    { id: 'BACS BANCO DE CREDITO Y SECURITIZACION S', name: 'BACS BANCO DE CREDITO Y SECURITIZACION S' },
    { id: 'BANCO MASVENTAS S.A.', name: 'BANCO MASVENTAS S.A.' },
    { id: 'WILOBANK S.A.', name: 'WILOBANK S.A.' },
    { id: 'NUEVO BANCO DE ENTRE RÍOS S.A.', name: 'NUEVO BANCO DE ENTRE RÍOS S.A.' },
    { id: 'BANCO COLUMBIA S.A.', name: 'BANCO COLUMBIA S.A.' },
    { id: 'BANCO BICA S.A.', name: 'BANCO BICA S.A.' },
    { id: 'BANCO COINAG S.A.', name: 'BANCO COINAG S.A.' },
    { id: 'BANCO DE COMERCIO S.A.', name: 'BANCO DE COMERCIO S.A.' },
    { id: 'BANCO SUCREDITO REGIONAL S.A.U.', name: 'BANCO SUCREDITO REGIONAL S.A.U.' },
    { id: 'BANCO DINO S.A.', name: 'BANCO DINO S.A.' },
    { id: 'BANK OF CHINA LIMITED SUCURSAL BUENOS AI', name: 'BANK OF CHINA LIMITED SUCURSAL BUENOS AI' },
    { id: 'FORD CREDIT COMPAÑIA FINANCIERA S.A.', name: 'FORD CREDIT COMPAÑIA FINANCIERA S.A.' },
    { id: 'COMPAÑIA FINANCIERA ARGENTINA S.A.', name: 'COMPAÑIA FINANCIERA ARGENTINA S.A.' },
    { id: 'VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F', name: 'VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F' },
    { id: 'CORDIAL COMPAÑÍA FINANCIERA S.A.', name: 'CORDIAL COMPAÑÍA FINANCIERA S.A.' },
    { id: 'FCA COMPAÑIA FINANCIERA S.A.', name: 'FCA COMPAÑIA FINANCIERA S.A.' },
    { id: 'GPAT COMPAÑIA FINANCIERA S.A.U.', name: 'GPAT COMPAÑIA FINANCIERA S.A.U.' },
    { id: 'MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT', name: 'MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT' },
    { id: 'ROMBO COMPAÑÍA FINANCIERA S.A.', name: 'ROMBO COMPAÑÍA FINANCIERA S.A.' },
    { id: 'JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.', name: 'JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.' },
    { id: 'PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER', name: 'PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER' },
    { id: 'TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA', name: 'TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA' },
    { id: 'NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A.', name: 'NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A.' },
    { id: 'MONTEMAR COMPAÑIA FINANCIERA S.A.', name: 'MONTEMAR COMPAÑIA FINANCIERA S.A.' },
    { id: 'TRANSATLANTICA COMPAÑIA FINANCIERA S.A.', name: 'TRANSATLANTICA COMPAÑIA FINANCIERA S.A.' },
    { id: 'CREDITO REGIONAL COMPAÑIA FINANCIERA S.A', name: 'CREDITO REGIONAL COMPAÑIA FINANCIERA S.A' },
];

export default choices
