import React from 'react';
import {
    ChipField
} from 'react-admin';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const WorkOrderStatusColorChip = ({ record }) => {

    if (record.workOrderStatus === 'PENDIENTE')
        return (
            <ChipField
                record={record}
                source="workOrderStatus"
                size="small"
                style={{ backgroundColor: grey[200] }}
            />)

    if (record.workOrderStatus === 'FINALIZADA' || record.workOrderStatus === 'FINALIZADA_ENTREGAR')
        return (
            <ChipField
                record={record}
                source="workOrderStatus"
                size="small"
                style={{ backgroundColor: green[200] }}
            />)
    if (record.workOrderStatus === 'EN_PROGRESO')
        return (
            <ChipField
                record={record}
                source="workOrderStatus"
                size="small"
                style={{ backgroundColor: orange[200] }}
            />)
    if (record.workOrderStatus === 'CANCELADA')
        return (
            <ChipField
                record={record}
                source="workOrderStatus"
                size="small"
                style={{ backgroundColor: red[200] }}
            />)
}

export default WorkOrderStatusColorChip;
