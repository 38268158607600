
export const secondaryAccountsData = {
    MOVIMIENTOS_DE_CAJA: ['COBRO_DE_CHEQUES', 'PASES_DE_CAJA'],
    INGRESOS_VARIOS: ['VARIOS', 'IVA', 'INTERESES_BANCARIOS'],
    GASTOS_DE_OFICINA: ['EQUIPAMIENTO', 'LIBRERIA'],
    GASTOS_VARIOS: ['SUPERMERCADO', 'ALMACEN'],
    BANCO: ['GASTOS_BANCARIOS', 'IMPUESTOS_MUNICIPALES'],
    SUELDOS: ['SUELDO_BASICO', 'HORAS_EXTRAS'],
};

export const secondaryAccountsDataAux = [
    {
        id: 'COBRO_DE_CHEQUES',
        name: 'COBRO_DE_CHEQUES',
        primaryAccount: 'MOVIMIENTOS_DE_CAJA'
    }, //AMBOS -- Movimientos de Caja
    {
        id: 'PASES_DE_CAJA', 
        name: 'PASES_DE_CAJA', 
        primaryAccount: 'MOVIMIENTOS_DE_CAJA'
    }, //AMBOS -- Movimientos de Caja
    {
        id: 'VARIOS', 
        name: 'VARIOS', 
        primaryAccount: 'INGRESOS_VARIOS'
    }, //RC -- Ingresos Varios
    {
        id: 'IVA',
        name: 'IVA',
        primaryAccount: 'INGRESOS_VARIOS'
    }, //RC -- Ingresos Varios
    {
        id: 'INTERESES_BANCARIOS',
        name: 'INTERESES_BANCARIOS',
        primaryAccount: 'INGRESOS_VARIOS'
    }, //RC -- Ingresos Varios
    {
        id: 'LIBRERIA', 
        name: 'LIBRERIA', 
        primaryAccount: 'GASTOS_DE_OFICINA'
    },
    {
        id: 'EQUIPAMIENTO',
        name: 'EQUIPAMIENTO',
        primaryAccount: 'GASTOS_DE_OFICINA'
    },
    {
        id: 'SUPERMERCADO', 
        name: 'SUPERMERCADO', 
        primaryAccount: 'GASTOS_VARIOS'
    },
    {
        id: 'ALMACEN',
        name: 'ALMACEN',
        primaryAccount: 'GASTOS_VARIOS'
    },
    {
        id: 'GASTOS_BANCARIOS',
        name: 'GASTOS_BANCARIOS',
        primaryAccount: 'BANCO'
    },
    {
        id: 'IMPUESTOS_MUNICIPALES',
        name: 'IMPUESTOS_MUNICIPALES',
        primaryAccount: 'BANCO'
    },
    {
        id: 'INGRESOS_BRUTOS',
        name: 'INGRESOS_BRUTOS',
        primaryAccount: 'BANCO'
    },
    {
        id: 'HORAS_EXTRAS',
        name: 'HORAS_EXTRAS',
        primaryAccount: 'SUELDOS' // TODO -- A AGREGAR EN LA BASE DE DATOS
    },
    {
        id: 'SUELDO_BASICO', 
        name: 'SUELDO_BASICO', 
        primaryAccount: 'SUELDOS' // TODO -- A AGREGAR EN LA BASE DE DATOS
    },
];
