import React from 'react';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EmployeesSelectInput from './EmployeesSelectInput';
import IconButtonRemove from '../components/IconButtonRemove';
import IconButtonAdd from '../components/IconButtonAdd';
import DeleteButtonCard from '../components/DeleteButtonCard';

const CartItems = ({ id, employees, products, changeQuantity, addEmployeeToItem, deleteToCard }) => {

    return (
        <div>
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'Nombre (Código)'}
                            </TableCell>
                            <TableCell align="right">
                                {'Cant. Asignada'}
                            </TableCell>
                            <TableCell align="right">
                                {'Cant. Pañol'}
                            </TableCell>
                            <TableCell>
                                {''}
                            </TableCell>
                            <TableCell>
                                {''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.length === 0 ?
                            <div>
                                <p>&nbsp;&nbsp;Sin articulos cargados!</p>
                            </div>
                            :
                            products.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell>
                                        {`${product.name} (${product.code})`}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButtonRemove product={product} changeQuantity={changeQuantity} />
                                        &nbsp;&nbsp;
                                        {product.itemQuantity}
                                        &nbsp;&nbsp;
                                        <IconButtonAdd product={product} changeQuantity={changeQuantity} />
                                    </TableCell>
                                    <TableCell align="right">
                                        {product.quantity}
                                    </TableCell>
                                    <TableCell>
                                        {//TODO - TODO MENOS HERRAMIENTAS
                                            (parseFloat(product.categoryId) !== 135 && id !== undefined) ?
                                                <div>
                                                    <EmployeesSelectInput
                                                        product={product}
                                                        employees={employees}
                                                        addEmployeeToItem={addEmployeeToItem}
                                                    />
                                                </div>
                                                : null
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <DeleteButtonCard product={product} deleteToCard={deleteToCard} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Card>
        </div>
    )
}

export default CartItems;