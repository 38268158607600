import React from 'react';
import { Link } from 'react-admin';
import { voucherFormat } from '../utils/functions';

const PaymentLinkField = (props) => {
    let numberVoucher = voucherFormat(props.record.id);
    return (
        <Link 
            to={`/payments/${props.record.id}`} //TODO: Cambiar a payments
            onClick={() => localStorage.removeItem('purchasesPayments')}
        >
            ORDEN-{numberVoucher}
        </Link>
    )
};

PaymentLinkField.defaultProps = {
    source: 'id',
    label: 'Orden de Pago Nro',
};

export default PaymentLinkField;
