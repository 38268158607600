import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
//import TableBlankSpace from './TableBlankSpace';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    borderWidth: 1,
  },
});

//const tableRowsCount = 25;

const ItemsTable = ({ pdfDocument }) => {
  return (
    <View style={styles.tableContainer}>
      <TableHeader pdfDocument={pdfDocument}  />
      <TableRow pdfDocument={pdfDocument} />
      {/* <TableBlankSpace rowsCount={tableRowsCount - pdfDocument.invoices.length} /> */}
    </View>
  )
};

export default ItemsTable;