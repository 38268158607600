import React from 'react';
import { Link } from 'react-admin';

const VehicleLinkField = props => (
    <Link to={`/vehicles/${props.record.id}`}>  
        {props.record.patent}
    </Link>
);

VehicleLinkField.defaultProps = {
    source: 'patent',
    addLabel: true,
};

export default VehicleLinkField;
