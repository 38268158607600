import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import DeleteButtonCard from '../components/DeleteButtonCard';
import { currencyFormatter } from '../utils/functions';
import ItemQuantityInput from './ItemQuantityInput';
import ItemPriceInput from './ItemPriceInput';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const CartItemsMobileGrid = ({ id, products, changeQuantity, changePrice, deleteToCard }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {(products.length) === 0 ?
                <div>
                    <Card key={0}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sin articulos cargados!</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Card>
                </div>
                :
                products.map((product) => (
                    <Card key={product.id} className={classes.card}>
                        <CardHeader
                            title={
                                <div className={classes.cardTitleContent}>
                                    <h3>{`${product.name} (${product.code})`}</h3>
                                </div>
                            }
                            subheader={
                                <div className={classes.card}>
                                    <div>
                                        <ItemQuantityInput product={product} changeQuantity={changeQuantity} />
                                    </div>
                                    <div>
                                        <ItemPriceInput product={product} changePrice={changePrice} />
                                    </div>
                                </div>
                            }

                        />
                        <CardContent className={classes.cardContent}>
                            <div>
                                {'Cantidad Pañol'}
                                :&nbsp;
                                {product.quantity}
                            </div>
                            <div>
                                {'Cantidad Depósito'}
                                :&nbsp;
                                {product.warehouseQuantity}
                            </div>
                            <div>
                                {'Cantidad Minima'}
                                :&nbsp;
                                {product.minQuantity}
                            </div>
                            <div>
                                {'Cantidad Maxima'}
                                :&nbsp;
                                {product.maxQuantity}
                            </div>
                            <div>
                                {'Subtotal'}
                                :&nbsp;
                                {currencyFormatter((product.itemQuantity * product.price))}
                            </div>
                        </CardContent>
                        <CardActions>
                            <DeleteButtonCard product={product} deleteToCard={deleteToCard} />
                        </CardActions>
                    </Card>
                ))}
        </div>
    );
};


export default CartItemsMobileGrid;
