import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    usePermissions,
    Filter,
    SearchInput,
    SelectInput,
    useListContext,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import EmployeeLinkField from './EmployeeLinkField';
import EmployeeMobileGrid from './EmployeeMobileGrid';
import FullNameField from './FullNameField';
import { getRolesRRHH } from '../appRolesComponents';
import DateFieldFormatted from '../components/DateFieldFormatted';
import { useRef, useEffect } from 'react';
import { FormHelperText } from '@material-ui/core';

const ListFilters = (props) => {
    const { setFilters } = useListContext();
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFilters({ q: '' });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setFilters]);

    return (
        <div>
            <Filter {...props}>
                <SearchInput
                    placeholder="Buscar por nombre"
                    source="q"
                    alwaysOn
                    autoFocus
                    resettable
                    ref={inputRef}
                    parse={(value) => value.toUpperCase()}
                />
                <SelectInput
                    label="Estado"
                    source="status"
                    choices={[
                        { id: 'true', name: 'ACTIVO' },
                        { id: 'false', name: 'INACTIVO' },
                    ]}
                    alwaysOn
                />
            </Filter>
            <FormHelperText><span role="img" aria-label="icono de limpiar">⌨️</span> Presione la tecla Escape (esc) para limpiar la búsqueda</FormHelperText>
        </div>
    );
};

const EmployeeList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isRRHH = getRolesRRHH(permissions);

    if (!isRRHH) return null;

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            //filters={<InputFilter />}
            filters={<ListFilters />}
            sort={{ field: 'fullName', order: 'ASC' }}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <EmployeeMobileGrid />
            ) : (
                <Datagrid
                    optimized
                    rowClick="edit"
                >
                    <EmployeeLinkField
                        sortable={false}
                    />
                    <FullNameField sortable={false}
                    />
                    <ChipField
                        label="Puesto"
                        source="role"
                        size="small"
                        variant="outlined"
                        sortable={false}
                    />
                    <TextField
                        label="resources.employees.fields.cellphone"
                        source="cellphone"
                        sortable={false}
                    />
                    <ReferenceArrayField
                        label="resources.employees.fields.shift"
                        source="shiftId"
                        reference="shifts"
                        link={false}
                        sortable={false}

                    >
                        <SingleFieldList>
                            <ChipField
                                source="description"
                                size="small"
                            />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <DateFieldFormatted
                        label="resources.employees.fields.end_date"
                        source="endDate"
                        sortable={false}
                        showTime={false}
                    />
                    <DateFieldFormatted
                        label="resources.employees.fields.end_date"
                        source="endDate"
                        sortable={false}
                        showTime={false}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default EmployeeList;
