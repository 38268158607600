import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    ReferenceField,
    ChipField
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import InputFilter from '../components/InputFilter';
import VehicleLinkField from './VehicleLinkField';
import VehicleMobileGrid from './VehicleMobileGrid';

const VehicleList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<InputFilter />}
            perPage={25}
            bulkActionButtons={false}
        >
            {isXsmall ? (
                <VehicleMobileGrid />
            ) : (
                <Datagrid optimized rowClick="edit" >
                    <VehicleLinkField sortable={false} />
                    <ReferenceField
                        label="resources.vehicles.fields.customer"
                        source="customerId"
                        reference="customers"
                        link={false}
                        sortable={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    {/* //TODO: FIJARSE XQ ME TRAE UNA LINEA SI O SI  */}
                    <ReferenceField
                        label="resources.vehicles.fields.line"
                        source="lineId"
                        reference="busLines"
                        link={false}
                        sortable={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField
                        label="resources.vehicles.fields.internal"
                        source="internal"
                        sortable={false}
                    />
                    <ReferenceField
                        label="resources.vehicles.fields.brand"
                        source="brandId"
                        reference="brands"
                        link={false}
                        sortable={false}
                    >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                        label="Plantilla a Dibujar"
                        source="busTemplate"
                        reference="busTemplate"
                        link={false}
                        sortable={false}
                    >
                        <ChipField
                            size="small"
                            source="name"
                        />
                    </ReferenceField>
                </Datagrid>
            )}
        </List>
    );
};

export default VehicleList;
