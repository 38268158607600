import React, { useState, useEffect } from 'react';
import {
	LinearProgress,
	useDataProvider
} from 'react-admin';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EmployeeField from '../components/EmployeeField';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';

const ListItemsDisplay = (props) => {
	const dataProvider = useDataProvider();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const [products, setProducts] = useState([]);

	useEffect(() => {
		dataProvider.getOne('orders', { id: props.id })
			.then(response => {
				let itemsOrder = response.data.items;
				dataProvider.getMany('items', {
					ids: itemsOrder.map((item) => item.id),
				})
					.then(response => {
						let items = response.data;
						//Merge objects by id
						const result = itemsOrder.map(t1 => ({ ...t1, ...items.find(t2 => t2.id === t1.id) }));
						setProducts(result);
						setLoading(false);
					});
			})
			.catch((error) => {
				setError(error);
				setLoading(false);
			});
	}, [props.id]) // eslint-disable-line react-hooks/exhaustive-deps

	if (loading) return <LinearProgress />;
	if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
	if (!products) return null;

	return (
		<CardContent>
			<Box display={{ md: 'block', lg: 'flex' }}>
				<Box flex={2} mr={{ md: 0, lg: '1em' }}>
					<SectionTitle label="Repuestos y/o Materiales" />
					<Box display={{ xs: 'block', sm: 'flex' }}>
						<Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
							<Card>
								<Table size="small">
									<TableHead>
										<TableRow>
											{/* <TableCell>
												{'id'}
											</TableCell> */}
											<TableCell>
												{'Nombre (Código)'}
											</TableCell>
											<TableCell align="right">
												{'Cant. Asignada'}
											</TableCell>
											<TableCell align="right">
												{'Cant. Pañol'}
											</TableCell>
											<TableCell>
												{'Empleado'}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{(products.length) === 0 ?
											<div>
												<p>&nbsp;&nbsp;Sin articulos cargados!</p>
											</div>
											:
											products.map((product) => (
												<TableRow key={product.id}>
													{/* <TableCell>
															{product.id}
														</TableCell> */}
													<TableCell>
														{`${product.name} (${product.code})`}
													</TableCell>
													<TableCell align="right">
														{product.itemQuantity}
													</TableCell>
													<TableCell align="right">
														{product.quantity}
													</TableCell>
													<TableCell>
														{//MATERIALES
															(product.employeeId !== null) ?
																<EmployeeField
																	id={product.employeeId}
																/>
																:
																null
														}
													</TableCell>
												</TableRow>
											))
										}
									</TableBody>
								</Table>
							</Card>
						</Box>
					</Box>
				</Box>
			</Box>
		</CardContent>
	)
}

export default ListItemsDisplay;
