import * as React from 'react';
import {
    Datagrid,
    NumberField,
    ChipField,
    FunctionField,
    ReferenceField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import rowStyle from './rowStyle.js'
import InvoiceLinkField from './InvoiceLinkField';
import CustomerLinkField from '../customers/CustomerLinkField.jsx';
import DateFieldFormatted from '../components/DateFieldFormatted.jsx';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'white',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    total: { fontWeight: 'bold' },
});

const InvoiceListDesktop = (props) => {
    const classes = useListStyles();

    return (
        <Datagrid
            optimized
            rowStyle={rowStyle()}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            {...props}
        >
            <InvoiceLinkField
                sortable={false}
            />
            <ChipField
                label="Pago"
                size="small"
                source="invoicePaymentStatus"
                sortable={false}
            />
            <ReferenceField
                label="Cliente"
                source="customerId"
                reference="customers"
                link={false}
                sortable={false}
            >
                <CustomerLinkField />
            </ReferenceField>
            <DateFieldFormatted
                label="Fecha"
                source="date"
                sortable={false}
                showTime={false}
            />
            <FunctionField
                label="Periodo Facturado"
                render={record => (
                    <div>
                        <DateFieldFormatted record={record} source="startDate" showTime={false} />
                        {' al '}
                        <DateFieldFormatted record={record} source="endDate" showTime={false} />
                    </div>
                )}
                sortable={false}
            />
            <DateFieldFormatted
                label="Vto. de Pago"
                source="dueDate"
                sortable={false}
                showTime={false}
            />
            {/* <NumberField
                label="Subtotal S/Imp."
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
            />
            <NumberField
                label="Total IVA"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
            /> */}
            <NumberField
                label="Importe Total"
                source="total"
                locales="es-AR"
                options={{ style: 'currency', currency: 'ARS' }}
                cellClassName={classes.total}
                sortable={false}
            />
            <ChipField
                label="Estado"
                size="small"
                source="invoiceStatus"
                variant="outlined"
                sortable={false}
            />
        </Datagrid>
    );
};

export default InvoiceListDesktop;
