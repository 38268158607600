import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    SelectInput,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Button, Card, Box, CardContent, CardActions, CircularProgress, makeStyles } from '@material-ui/core';
import ItemsByBusLinesDatagrid from './ItemsByBusLinesDatagrid';
import { requiredValidate } from '../../validators/validators';
import LineSelectInput from '../../vehicles/LineSelectInput';
import InfoMessage from '../components/InfoMessage';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const ReportItemsByBusLines = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(false);
    const [values, setValues] = useState();
    const year = (new Date()).getFullYear();
    const startingYear = 2024; // TODO: ACA VA EL AÑO DE INICIO DE GASTOS DE ARTICULOS POR LINEA DE COLECTIVO
    const yearsRange = year - startingYear + 1;

    const years = Array.from({ length: yearsRange }, (_, index) => year - index);


    const handleSubmit = (values) => {
        if (values && Object.keys(values).length > 0) {
            setLoading(true);
            setValues(values);
            setState(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const handleButtonClick = () => {
        if (state) {
            setState(false);
            setValues(undefined);
        } else {
            handleSubmit(values);
        }
    };

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={{ year }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Reporte de Gastos de Articulos por Linea de Colectivo'} />
                            <CardContent>
                                <InfoMessage
                                    message="Ten en cuenta que los gastos de artículos por línea de colectivo se calculan en base a las órdenes de trabajo que se han realizado en el mes y año seleccionado. Además, se incluyen todas las órdenes en todos sus estados: pendiente, en proceso, finalizada o facturada."
                                />
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <ReferenceInput
                                                    label="Busque a su Cliente"
                                                    source="customerId"
                                                    reference="customers"
                                                    sort={{ field: 'name', order: 'ASC' }}
                                                    perPage={99999} //TODO NO TRAE MAS Q 99999
                                                    fullWidth
                                                    resettable
                                                    onChange={() => {
                                                        form.change('lineId', "");
                                                    }}
                                                >
                                                    <AutocompleteInput
                                                        optionText="name"
                                                        validate={requiredValidate}
                                                    />
                                                </ReferenceInput>
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => formData.customerId !== undefined ? (
                                                        <LineSelectInput
                                                            customerId={formData.customerId}
                                                            validate={requiredValidate}
                                                            {...rest}
                                                        />
                                                    ) : null}
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <SelectInput
                                                    label="Año"
                                                    source="year"
                                                    translateChoice={false}
                                                    choices={
                                                        years.map((year) => ({
                                                            id: year,
                                                            name: year,
                                                        }))
                                                    }
                                                    validate={requiredValidate}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={loading}
                                                        onClick={handleButtonClick}
                                                    >
                                                        {loading && (<CircularProgress size={25} thickness={2} />)}
                                                        {state ? 'Volver a Buscar Gastos de Articulos por Linea de Colectivo' : 'Buscar Gastos de Articulos por Linea de Colectivo'}
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {state && values && values.customerId && values.lineId &&
                                    <ItemsByBusLinesDatagrid values={values} />
                                }
                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>
    );
};

export default ReportItemsByBusLines;
