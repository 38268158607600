import * as React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import backgroundImage from './welcome_bg.png';

const useStyles = makeStyles(theme => ({
    root: {
        background: `url(${backgroundImage})`,
        color: '#fff',
        padding: 20,
        marginTop: theme.spacing(2),
        marginBottom: '1em',
    },
}));

const Welcome = () => {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        {'Sistema de gestión y administración empresarial'}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );
};

export default Welcome;
