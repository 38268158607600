import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Chip, makeStyles } from '@material-ui/core';
import CustomerField from '../components/CustomerField';
import SupplierField from '../components/SupplierField';

const useStyles = makeStyles({
    container: { minWidth: '35em', marginLeft: '1em' },
    boldCell: { fontWeight: 'bold' },
});

const CashListDesktopLines = ({ record }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.container} elevation={2}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {(record.secondaryAccount !== null) &&
                                <div>
                                    Cuenta Secundaria:<br />
                                    <Chip
                                        label={record.secondaryAccount}
                                        size="small"
                                        variant="outlined"
                                    />
                                </div>
                            }
                            {(record.primaryAccount === 'CLIENTES') &&
                                <div>
                                    Cliente:<br />
                                    <CustomerField id={record.customerId} />
                                </div>
                            }
                            {(record.primaryAccount === 'PROVEEDORES') &&
                                <div>
                                    Proveedor:<br />
                                    <SupplierField id={record.supplierId} />
                                </div>
                            }
                        </TableCell>
                        <TableCell>
                            Medios de Pagos:
                            <br />
                            {record.methods.map((item) =>
                            (<div>
                                <Chip
                                    label={item}
                                    size="small"
                                    variant="outlined"
                                /><br />
                            </div>)
                            )
                            }
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </Paper>
    );
};

export default CashListDesktopLines;
