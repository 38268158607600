import React from 'react';
import { Link } from 'react-admin';

const SupplierLinkField = props => (
    <Link to={`/suppliers/${props.record.id}`}>
        {props.record.name}
    </Link>
);

SupplierLinkField.defaultProps = {
    source: 'name',
    label: 'Proveedor (Razón Social)',
};

export default SupplierLinkField;
