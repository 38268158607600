import ExtraHourList from './ExtraHourList';
import ExtraHourEdit from './ExtraHourEdit';
import ExtraHourCreate from './ExtraHourCreate';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
export default {
  list: ExtraHourList,
  create: ExtraHourCreate,
  edit: ExtraHourEdit,
  icon: HourglassFullIcon
};
