import * as React from "react";
import {
    useRecordContext
} from "react-admin";
import { Chip } from "@material-ui/core";
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';

const StateChipField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;

    let label = '';
    let color = '';

    if (record[source] === null && record['status'] === true) {
        label = 'EN USO';
        color = red[200];
    } else if (record[source] !== null && record['status'] === true) {
        label = 'DEVUELTA';
        color = green[500];
    } else if (record[source] !== null && record['status'] === false) {
        label = 'DADA DE BAJA';
        color = orange[500]; // Naranja
    }

    return (
        <Chip
            label={label}
            size="small"
            style={{ backgroundColor: color, border: 0 }}
        />
    );
};

export default StateChipField;
