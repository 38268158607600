import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import ItemsTable from './ItemsTable';
import ThankYouMsg from './ThankYouMsg';

const styles = StyleSheet.create({
    page: {
        fontSize: 15,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        flexDirection: 'column',
    }
});

const ReportExport = ({ pdfDocument }) => {

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Header pdfDocument={pdfDocument} />
                <ItemsTable pdfDocument={pdfDocument} />
                <ThankYouMsg />
            </Page>
        </Document>
    )
};

export default ReportExport;