import React from "react";
import ReactExport from "react-export-excel";
import { Button } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { dateParseWithoutTime, voucherFormat } from '../utils/functions';

const RetentionExport = ({ dataExport }) => {

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const dataSet = dataExport.map(item => ({
        id: `RECIBO-${voucherFormat(item.id)}`,
        date: item.date,
        name: item.name,
        cuit: item.cuit,
        total: item.total
    }));

    const filename = `retenciones-clientes-${dateParseWithoutTime(Date.now())}`
    return (
        <ExcelFile
            filename={filename}
            element={
                <Button
                    label="Exportar a Excel"
                    size="small"
                    variant="contained"
                >
                    <DownloadIcon />
                </Button>
            }>
            <ExcelSheet data={dataSet} name="RETENCIONES">
                <ExcelColumn label="Fecha" value="date" />
                <ExcelColumn label="Recibo Nro." value="id" />
                <ExcelColumn label="Cliente" value="name" />
                <ExcelColumn label="CUIT" value="cuit" />
                <ExcelColumn label="Retención" value="total" />
            </ExcelSheet>
        </ExcelFile>
    );

};

export default RetentionExport;
