import * as React from 'react';
import {
    List,
    usePermissions,
    Filter,
    SearchInput,
    SelectInput,
    useListContext,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import ActionsTopToolbar from '../components/ActionsTopToolbar';
import OrderListMobile from './OrderListMobile';
import OrderListDesktop from './OrderListDesktop';
import { getRolesOnlyDriverExceptAdmin, getRolesOnlyInspectorExceptAdmin, getRolesWorkshopOnly } from '../appRolesComponents';
import { useEffect, useRef } from 'react';
import { FormHelperText } from '@material-ui/core';

const ListFilters = (props) => {
    const { setFilters } = useListContext();
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFilters({ q: '' });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setFilters]);

    return (
        <div>
            <Filter {...props}>
                <SearchInput
                    placeholder="Buscar por patente"
                    source="q"
                    alwaysOn
                    autoFocus
                    resettable
                    parse={(value) => value.toUpperCase()}
                    ref={inputRef}
                />
                <SelectInput
                    label="Estado de OT"
                    source="workOrderStatus"
                    choices={[
                        { id: 'PENDIENTE', name: 'PENDIENTE' },
                        { id: 'EN_PROGRESO', name: 'EN PROGRESO' },
                        { id: 'FINALIZADA', name: 'FINALIZADA' },
                        { id: 'FINALIZADA_ENTREGAR', name: 'FINALIZADA_ENTREGAR' },
                        { id: 'CANCELADA', name: 'CANCELADA' },
                    ]}
                    alwaysOn
                    size="small"
                    emptyText="TODOS"
                />
            </Filter>
            <FormHelperText><span role="img" aria-label="icono de limpiar">⌨️</span> Presione la tecla Escape (esc) para limpiar la búsqueda</FormHelperText>
        </div>
    );
};

const OrderList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);

    if (!isWorkshopOnly && !isDriver && !isInspector) return null;

    return (
        <List
            {...props}
            actions={<ActionsTopToolbar />}
            filters={<ListFilters />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={false}
        >
            {(isXsmall || isDriver) ? (
                <OrderListMobile />
            ) : (
                (!isDriver) && (<OrderListDesktop />)
            )}
        </List>
    );
};

export default OrderList;
