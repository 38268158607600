import * as React from 'react';
import {
    TextField,
    EditButton,
    useTranslate,
    ReferenceField,
    NumberField,
    ChipField
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { voucherFormat } from '../utils/functions';
import { Chip } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import DateFieldFormatted from '../components/DateFieldFormatted';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const PaymentListMobile = ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (!ids || !data) {
        return null;
    }
    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div className={classes.name}>
                                    <h6>ORDEN-{voucherFormat(data[id].id)}</h6>
                                </div>
                                <EditButton
                                    resource="payments"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        subheader={
                            <div>
                                <div>
                                    {translate('resources.invoices.fields.date')}
                                    :&nbsp;
                                    <DateFieldFormatted
                                        record={data[id]}
                                        source="date"
                                        sortable={false}
                                        showTime={false}
                                    />
                                </div>
                                <div>
                                    {'Cuenta Primaria'}:&nbsp;
                                    <ChipField
                                        record={data[id]}
                                        size="small"
                                        source="primaryAccount"
                                    />
                                </div>
                                <div>
                                    {'Cuenta Secundaria'}:&nbsp;
                                    <ChipField
                                        record={data[id]}
                                        size="small"
                                        source="secondaryAccount"
                                        emptyText
                                    />
                                </div>
                                <div>
                                    {translate('resources.invoices.fields.supplier')}:&nbsp;
                                    <ReferenceField
                                        record={data[id]}
                                        source="supplierId"
                                        reference="suppliers"
                                    >
                                        <TextField source="name" />
                                    </ReferenceField>
                                </div>
                            </div>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            {translate('resources.invoices.fields.total')}
                            :&nbsp;
                            <NumberField
                                record={data[id]}
                                source="total"
                                locales="es-AR"
                                options={{ style: 'currency', currency: 'ARS' }}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            {translate('resources.invoices.fields.status')}
                            :&nbsp;
                            <Chip
                                label={(data[id].status) ? 'PAGADO' : 'CANCELADO'}
                                size="small"
                                style={{ backgroundColor: (data[id].status) ? green[200] : red[200] }}
                            />
                        </span>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default PaymentListMobile;
