import OrderList from './OrderList';
import OrderCreate from './OrderCreate';
import OrderEdit from './OrderEdit';
import BookmarksIcon from '@material-ui/icons/Bookmarks';

export default {
  list: OrderList,
  create: OrderCreate,
  edit: OrderEdit,
  icon: BookmarksIcon
};
