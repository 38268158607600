import React from 'react';
import { Link } from 'react-admin';

const WarehouseLinkField = props => (
    <Link to={`/warehouse/${props.record.id}`}>
        {props.record.name} ({props.record.code})
    </Link>
);

WarehouseLinkField.defaultProps = {
    source: 'id',
    label: 'Nombre (Código)',
};

export default WarehouseLinkField;