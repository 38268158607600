import * as React from 'react';
import {
    Datagrid,
    TextField,
} from 'react-admin';
import AbsenceChipField from './AbsenceChipField';
import DateFieldFormatted from '../components/DateFieldFormatted';

const AbsenceListDatagrid = () => {
    return (
        <Datagrid
            optimized
            rowClick="edit"
        >
            <TextField
                label="Empleado"
                source="fullName"
                sortable={false}
            />
            <AbsenceChipField
                label="Tipo Ausencia"
                source="type"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Inicio"
                source="start"
                sortable={false}
                showTime={false}
            />
            <DateFieldFormatted
                label="Fecha Fin"
                source="end"
                sortable={false}
                showTime={false}
            />
            <TextField
                label="Decripción"
                source="description"
                sortable={false}
            />
        </Datagrid>
    );
};

export default AbsenceListDatagrid;
