import React from 'react';
import { useRecordContext } from 'react-admin';
import { Chip } from '@material-ui/core';
import inflection from 'inflection';

const FiscalConditionChipField = ({ source }) => {
    const record = useRecordContext();
    return (
        <Chip
            label={record && inflection.humanize(record[source])}
            size="small"
        />
    );
};

export default FiscalConditionChipField;