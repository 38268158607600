
import React from 'react';
import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 90,
        maxWidth: 90,
    },
}));

const SelectTaxInput = ({ product, changeTax }) => {
    const classes = useStyles();

    const choices = [
        { id: '21', name: '21%' },
        { id: '10.5', name: '10.5%' },
        { id: '0', name: '0%' },
        { id: '27', name: '27%' },
        { id: '5', name: '5%' },
        { id: '2.5', name: '2.5%' },
        { id: '1', name: 'EXENTO' },
    ]

    //PORQUE EL DATO VIENE YA DIVIDO POR 100 (Ejemplo: 0.21)
    //LA CONDICION !== 1 XQ ES EXENTO Y SI NO LO MULTIPLICA POR 100 
    const itemTax = (product.itemTax !== 1) ? (product.itemTax * 100) : (product.itemTax);

    return (
        <FormControl variant="filled" size="small" margin="dense" className={classes.formControl}>
            <Select
                id="simple-tax"
                value={itemTax}
                onChange={(e) => changeTax(product, e)}
            >
                {choices.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectTaxInput;