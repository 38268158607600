import React, {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    TextInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    DateInput,
    FormDataConsumer,
    useDataProvider,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { toUpperCase, dateParse, voucherFormat, dateParseWithoutTime } from '../utils/functions';
import voucherData from '../components/data/voucherData';
import SectionTitle from '../components/SectionTitle';
import InvoiceDates from './InvoiceDates';
import CustomerCard from './CustomerCard';
import conceptsData from '../components/data/conceptsData';
import { useForm } from 'react-final-form';

const InvoiceForm = (props) => {
    const form = useForm();
    const pointOfSale = '00001';
    const [fiscalCondition, setFiscalCondition] = useState();
    const [invoiceType, setInvoiceType] = useState();
    const dataProvider = useDataProvider();
    let numberVoucher = voucherFormat(props.record.id);
    form.change("pointOfSale", pointOfSale);
    if (props.record.id !== undefined) {
        form.change("id", numberVoucher);
    }
    const isDisabledCustomer = (props.record.id !== undefined);

    //HELP -- TUVE QUE HACER ESTE ENGENDRO PARA FILTRAT TIPOS DE FACTURAS SEGUN fiscalCondition
    // purchaser es un array comprador ver: voucherData
    const fetch = useCallback(async (value) => {
        dataProvider.getOne('customers', { id: value })
            .then(({ data }) => {
                setFiscalCondition(data.fiscalCondition);
            })
            .catch(error => {
                console.log("Ver InvoiceForm.jsx:" + error);
            })
    }, [dataProvider]);

    useEffect(() => {
        if (fiscalCondition !== undefined) {
            const voucherDataFilter = voucherData.filter((record) => {
                return record.purchaser.includes(fiscalCondition)
            });
            setInvoiceType(voucherDataFilter);
        }
    }, [fiscalCondition]);

    const handleCustomers = (value) => {
        localStorage.removeItem('invoiceCart');
        fetch(value);
    }
    //FIN DEL ENGENDRO
    const isReadOnly = (props.record.id !== undefined);
    
    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Cabecera del Comprobante" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <DateInput
                                    label="Fecha"
                                    source="date"
                                    defaultValue={dateParseWithoutTime(Date.now())}
                                    validate={requiredValidate}
                                    parse={dateParse}
                                    fullWidth
                                />
                                :
                                <DateInput
                                    label="Fecha"
                                    source="date"
                                    validate={requiredValidate}
                                    disabled={true}
                                    parse={dateParse}
                                    fullWidth
                                />
                            }
                        </Box>
                        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <SelectInput
                                    label="Conceptos a Incluir"
                                    source="concepts"
                                    choices={conceptsData}
                                    initialValue={2}
                                    validate={requiredValidate}
                                    fullWidth
                                /> :
                                <SelectInput
                                    label="Conceptos a Incluir"
                                    source="concepts"
                                    choices={conceptsData}
                                    validate={requiredValidate}
                                    disabled={true}
                                    fullWidth
                                />
                            }
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label={false}
                                source="pointOfSale"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label={false}
                                source="id"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                    </Box>

                    <FormDataConsumer>
                        {({ formData }) => (formData.concepts !== 1 && formData.concepts !== undefined) &&
                            <InvoiceDates {...props} />
                        }
                    </FormDataConsumer>

                    <SectionTitle label="Datos del Cliente" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="Busque a su Cliente"
                                source="customerId"
                                reference="customers"
                                sort={{ field: 'name', order: 'ASC' }}
                                onChange={handleCustomers}
                                fullWidth
                                resettable
                                autoFocus
                            >
                                <AutocompleteInput
                                    optionText="name"
                                    disabled={isDisabledCustomer}
                                    validate={requiredValidate}
                                />
                            </ReferenceInput>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <FormDataConsumer>
                                    {({ formData }) => (formData.fiscalCondition !== undefined) &&
                                        <SelectInput
                                            label="Tipo de Comprobante"
                                            source="invoiceType"
                                            choices={invoiceType}
                                            validate={requiredValidate}
                                            fullWidth
                                        />
                                    }
                                </FormDataConsumer>
                                :
                                <SelectInput
                                    label="Tipo de Comprobante"
                                    source="invoiceType"
                                    choices={voucherData}
                                    validate={requiredValidate}
                                    disabled={isDisabledCustomer}
                                    fullWidth
                                />
                            }
                        </Box>
                        <div style={{ display: 'none' }}> 
                            <TextInput
                                source="fiscalCondition"
                                defaultValue={fiscalCondition}
                                disabled={true}
                                fullWidth
                            />
                        </div>
                    </Box>

                    <FormDataConsumer>
                        {({ formData }) => (formData.customerId !== undefined) &&
                            <CustomerCard id={formData.customerId} />
                        }
                    </FormDataConsumer>
                </Box>
                <Box
                    flex={1}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Observaciones Adicionales" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Observaciones"
                                source="observations"
                                parse={toUpperCase}
                                rows={14}
                                disabled={isReadOnly}
                                multiline
                                resettable
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default InvoiceForm;
