import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { toUpperCase } from '../../utils/functions';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        paddingTop: 15,
        paddingBottom: 25,
        textAlign: 'center',
    },
    subHeader: {
        textAlign: 'center',
        border: 1,
    },
    title: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    header: {
        width: '100%'
    }
});

const Header = ({ pdfDocument }) => {
    const cuit = `30-70941531-6`;

    const rowDate= (pdfDocument.groupBy !== undefined) ? `Comprobantes con fecha entre: ${pdfDocument.start} al ${pdfDocument.end}` : `${pdfDocument.primaryAccount} - SALDOS AL ${pdfDocument.date}` ;

    const rowGroupBy = (pdfDocument.groupBy !== undefined) ? `Agrupador por: ${toUpperCase(pdfDocument.groupBy)}` : '' ;
    
    return (
        <View>
            <View style={styles.subHeader}>
                <Text>GRUPO M.M. S.R.L. CUIT: {cuit}</Text>
            </View>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text style={styles.title}></Text>
                    <Text style={styles.title}>{rowDate}</Text>
                    <Text style={styles.title}>{rowGroupBy}</Text>
                </View>
            </View>
        </View>
    )
};

export default Header;