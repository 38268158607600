import * as React from 'react';
import { useEffect, useRef } from 'react';
import { LinearProgress } from '@material-ui/core';
import {
    List,
    usePermissions,
    ResourceContextProvider,
    SearchInput,
    Filter,
    SelectInput,
    useGetList,
    useListContext,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import WarehouseListMobile from './WarehouseListMobile';
import WarehouseListDesktop from './WarehouseListDesktop';
import {
    getRolesOnlySupervisorExceptAdmin,
    getRolesWorkshopOnly,
    getRolesOnlyStorekeeperExceptAdmin
} from '../appRolesComponents';
import { FormHelperText } from '@material-ui/core';

const CategorySelectInput = () => {
    const { data, ids, loaded, error } = useGetList(
        'categories',
        { page: 1, perPage: 30 },
        { field: 'name', order: 'ASC' }
    );

    if (error) {
        return <div>Error cargando categorías</div>;
    }

    if (!loaded) {
        return <LinearProgress />;
    }

    const choices = [
        { id: undefined, name: 'Todos' }, // Opción adicional
        ...ids.map((id) => ({
            id: data[id].id,
            name: data[id].name,
        })),
    ];

    return (
        <SelectInput
            label="Categoría"
            source="category"
            choices={choices}
            style={{ width: 'auto', margin: '0 0 -16px 0px' }} // Ajuste de tamaño
        />
    );
};

const ListFilters = (props) => {
    const { setFilters } = useListContext();
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setFilters({ q: '' });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setFilters]);

    return (
        <div>
            <Filter {...props}>
                <SearchInput
                    placeholder="Buscar por código o nombre"
                    source="q"
                    alwaysOn
                    autoFocus
                    resettable
                    ref={inputRef}
                    parse={(value) => value.toUpperCase()}
                />
                <CategorySelectInput alwaysOn />
            </Filter>
            <FormHelperText><span role="img" aria-label="icono de limpiar">⌨️</span> Presione la tecla Escape (esc) para limpiar la búsqueda</FormHelperText>
        </div>
    );
};

const WarehouseList = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions) || getRolesOnlyStorekeeperExceptAdmin(permissions);

    if (!isWorkshopOnly) return null;

    return (
        <ResourceContextProvider value="warehouse">
            <List
                syncWithLocation
                title="Depósito"
                basePath="/warehouse"
                actions={false}
                filters={<ListFilters />}
                sort={{ field: 'name', order: 'ASC' }}
                perPage={25}
                bulkActionButtons={false}
            >
                {isXsmall ? <WarehouseListMobile /> : <WarehouseListDesktop />}
            </List>
        </ResourceContextProvider>
    );
};

export default WarehouseList;
