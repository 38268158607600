import CropFreeIcon from '@material-ui/icons/CropFree';
import TrackingAutomatic from './TrackingAutomatic';
import GridEmployeeList from './GridEmployeeList';
import TrackingManual from './TrackingManual';

export default {
  list: GridEmployeeList,
  create: TrackingAutomatic,
  edit: TrackingManual,
  icon: CropFreeIcon
};
