import React, { useEffect, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import { useMediaQuery } from '@material-ui/core';

const InspectionDrawImageView = ({ inspection }) => {
  const [imgSrc, setImgSrc] = useState('');
  const isXsmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      let busTemplate = `https://api.marasa.com.ar/workshop-api/api/busTemplate/${localStorage.getItem("busTemplate")}/download`;
      setImgSrc(busTemplate);
      const drawImage = inspection.draw;

      if (drawImage && drawImage.lines && Array.isArray(drawImage.lines) && drawImage.lines.length > 0) {
        // Calcular el factor de escala
        const scaleFactor = isXsmall ? 367 / drawImage.width : 525 / drawImage.width;

        // Escalar las coordenadas del dibujo
        const scaledLines = drawImage.lines.map((line) => ({
          ...line,
          points: line.points.map((point) => ({
            x: point.x * scaleFactor,
            y: point.y * scaleFactor,
          })),
        }));


        // Indicar que la carga inicial ha terminado
        setIsInitialLoadComplete({
          scaledLines,
          canvasWidth: isXsmall ? 367 : 525,
          canvasHeight: isXsmall ? 206 : 295,
        });
      }
    };

    // Llamada a la carga inicial
    loadInitialData();
  }, [isXsmall]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div id='draw-image' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {isInitialLoadComplete && (
        <CanvasDraw
          disabled
          hideGrid
          canvasWidth={isInitialLoadComplete.canvasWidth}
          canvasHeight={isInitialLoadComplete.canvasHeight}
          imgSrc={imgSrc}
          immediateLoading={true}
          saveData={JSON.stringify({
            lines: isInitialLoadComplete.scaledLines,
            width: isInitialLoadComplete.canvasWidth,
            height: isInitialLoadComplete.canvasHeight,
          })}
          //style={{ border: '0.5px solid #000' }}
        />
      )}
    </div>
  );
};

export default InspectionDrawImageView;
