import * as React from 'react';
import {
    Datagrid,
    NumberField,
    Pagination,
    ReferenceManyField,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import SupplierFormAccountLines from './SupplierFormAccountLines';
import PaymentLinkField from '../payments/PaymentLinkField';
import DateFieldFormatted from '../components/DateFieldFormatted';

const SupplierFormAccount = (props) => {
    const title = `${props.record.name}`;
    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label={title} />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceManyField
                                //label="Lista"
                                perPage={99999}
                                pagination={<Pagination />}
                                reference="payments"
                                target="supplierId"
                                fullWidth
                            >
                                <Datagrid 
                                    optimized
                                    expand={<SupplierFormAccountLines />}
                                >
                                    <PaymentLinkField />

                                    <DateFieldFormatted
                                        label="Fecha"
                                        source="date"
                                        showTime={false}
                                    />
                                    <NumberField
                                        label="Sado"
                                        source="total"
                                        locales="es-AR"
                                        options={{ style: 'currency', currency: 'ARS' }}
                                    />
                                </Datagrid>
                            </ReferenceManyField>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default SupplierFormAccount;
