import React from 'react';
import {
    Create,
    SimpleForm,
    useNotify,
    usePermissions
} from 'react-admin';
import { 
    getRolesOnlyDriverExceptAdmin, 
    getRolesOnlyInspectorExceptAdmin, 
    getRolesWorkshopOnly,
    getRolesOnlySupervisorExceptAdmin
} from '../appRolesComponents';
import CustomToolbar from '../components/CustomToolbar';
import InspectionForm from './InspectionForm';

const InspectionCreate = (props) => {
    const { permissions } = usePermissions();
    const notify = useNotify();
    const redirect = `/inspections`;

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const isWorkshopOnly = getRolesWorkshopOnly(permissions);
    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);
    const isInspector = getRolesOnlyInspectorExceptAdmin(permissions);
    const isSupervisor = getRolesOnlySupervisorExceptAdmin(permissions);

    if (!isWorkshopOnly && !isDriver && !isInspector && !isSupervisor) return null;

    return (
        <Create
            {...props}
            onFailure={onFailure}
        >
            <SimpleForm
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <InspectionForm />

            </SimpleForm>
        </Create>
    );
};

export default InspectionCreate;
