import * as React from 'react';
import {
    Edit,
    useNotify,
    useRefresh,
    useRedirect,
    FormTab,
    TabbedForm,
} from 'react-admin';
import CustomToolbar from '../components/CustomToolbar';
import CustomerForm from './CustomerForm';
import CustomerFormAccount from './CustomerFormAccount';

const CustomerEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/customers`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const CustomerTitle = ({ record }) => {
        return <span>{record ? `${record.name}` : ''}</span>;
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<CustomerTitle />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
            >
                <FormTab label="General" >
                    <CustomerForm />
                </FormTab>
                <FormTab
                    label="Recibos"
                    path="account"
                >
                    <CustomerFormAccount />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default CustomerEdit;
