import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { calculateSubtotalTax, calculateTotalExempt, currencyFormatter, toUpperCase } from '../../utils/functions';
import writtenNumber from 'written-number';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        fontSize: 10,
        fontStyle: 'bold',
    },
    columnLeft: {
        width: '25%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    columnRight: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
    },
    columnLeftTotal: {
        width: '25%',
        textAlign: 'left',
        fontSize: 15,
        paddingLeft: 8,
    },
    columnRightTotal: {
        width: '25%',
        textAlign: 'right',
        fontSize: 15,
        paddingRight: 8,
    },
    letter: {
        textAlign: 'right',
        paddingRight: 8,
        fontSize: 10,
    },
});

const InvoiceTableFooter = ({ pdfDocument }) => {
    writtenNumber.defaults.lang = 'es';

    const items = pdfDocument.operationInfo;

    const totalExempt = (items !== undefined) ? calculateTotalExempt(items) : 0;
    const totalTax = (items !== undefined) ? calculateSubtotalTax(items) : 0;

    return (
        <div>
            <View >
                <View style={styles.row}>
                    <Text style={styles.columnLeft}>Detalle de otros tributos:</Text>
                    <Text style={styles.columnLeft}></Text>
                    <Text style={styles.columnLeft}>Total Impuestos</Text>
                    <Text style={styles.columnRight}>{currencyFormatter(totalTax)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.columnLeft}>Per./Ret {pdfDocument.juridiction}</Text>
                    <Text style={styles.columnRight}>{currencyFormatter(pdfDocument.iibb)}</Text>
                    <Text style={styles.columnLeft}>Exentos</Text>
                    <Text style={styles.columnRight}>{currencyFormatter(totalExempt)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.columnLeft}></Text>
                    <Text style={styles.columnLeft}></Text>
                    <Text style={styles.columnLeft}></Text>
                    <Text style={styles.columnLeft}></Text>
                </View>
                <View style={styles.row}>
                    <Text style={styles.columnLeft}></Text>
                    <Text style={styles.columnLeft}></Text>
                    <Text style={styles.columnLeftTotal}>Importe Total</Text>
                    <Text style={styles.columnRightTotal}>{currencyFormatter(pdfDocument.total)}</Text>
                </View>
            </View> 
            <View style={styles.row}>
                <Text style={styles.letter}>{toUpperCase(writtenNumber(pdfDocument.total))}</Text>
            </View>
        </div>
    )
};

export default InvoiceTableFooter;