import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

const IconButtonAdd = (({ product, changeQuantity }) => (
    <IconButton
        size="small"
        color="secondary"
        onClick={() => changeQuantity(product, '+')}
    >
        <AddIcon fontSize="small" />
    </IconButton>
));

export default IconButtonAdd;