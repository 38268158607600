import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        alignItems: 'center',
        //height: 10,
        fontSize: 10,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    date: {
        width: '20%',
        borderRightWidth: 1,
    },
    voucher: {
        width: '40%',
        borderRightWidth: 1,
    },
    total: {
        width: '20%',
        borderRightWidth: 1,
    },
    amount: {
        width: '20%',
        borderRightWidth: 1,
    }
});

const PaymentTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.date}>Fecha</Text>
        <Text style={styles.voucher}>Comprobante</Text>
        <Text style={styles.total}>Importe</Text>
        <Text style={styles.amount}>Imputado</Text>
    </View>
);

export default PaymentTableHeader;