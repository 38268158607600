import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { currencyFormatter, voucherFormat } from '../../utils/functions';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        borderTop: 1,
        color: 'grey',
    },
    rowTotal: {
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
        borderTop: 1,
    },
    group: {
        fontSize: 10,
        width: '100%',
        backgroundColor: '#000000',
        color: '#e3f2fd',
        textAlign: 'center',
    },
    date: {
        width: '10%',
        textAlign: 'center',
        paddingRight: 8,
    },
    voucher: {
        width: '30%',
        textAlign: 'left',
        paddingLeft: 8,
    },
    line: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    lineOther: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    tax: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    name: {
        width: '85%',
        textAlign: 'left',
        paddingLeft: 8,
    },
});

const TableRow = ({ pdfDocument }) => {

    let rows = '';

    if (pdfDocument.groupBy === undefined) {

        rows = Object.keys(pdfDocument.invoices).map(item => {
            return (
                <View style={styles.row}>
                    <Text style={styles.name}>{item}</Text>
                    <Text style={styles.amount}>{currencyFormatter(pdfDocument.invoices[item])}</Text>
                </View>
            )
        })

    } else {
        if (pdfDocument.groupBy === 'proveedor' || pdfDocument.groupBy === 'cliente') {

            const groups = pdfDocument.invoices;

            rows = Object.keys(groups).map(key => {

                let grandSubtotal = 0;
                let grandTotalExempt = 0;
                let grandTotalTax = 0;
                let grandTotal = 0;

                return (
                    <View>
                        <Text style={styles.group}>{key}</Text>
                        {
                            groups[key].map(item => {

                                grandSubtotal += item.subtotal;
                                grandTotalExempt += item.totalExempt;
                                grandTotalTax += (item.total - item.subtotal);
                                grandTotal += item.total;
                                return (
                                    <View style={styles.row} key={item.id.toString()}>
                                        <Text style={styles.date}>{item.date}</Text>
                                        <Text style={styles.voucher}>{item.invoiceType} {item.pointOfSale}-{voucherFormat(item.id)}</Text>
                                        <Text style={styles.line}>{currencyFormatter(item.subtotal)}</Text>
                                        <Text style={styles.lineOther}>{currencyFormatter(item.totalExempt)}</Text>
                                        <Text style={styles.tax}>{currencyFormatter(item.total - item.subtotal)}</Text>
                                        <Text style={styles.amount}>{currencyFormatter(item.total)}</Text>
                                    </View>
                                )
                            })
                        }
                        <View style={styles.rowTotal}>
                            <Text style={styles.date}></Text>
                            <Text style={styles.voucher}></Text>
                            <Text style={styles.line}>{currencyFormatter(grandSubtotal)}</Text>
                            <Text style={styles.lineOther}>{currencyFormatter(grandTotalExempt)}</Text>
                            <Text style={styles.tax}>{currencyFormatter(grandTotalTax)}</Text>
                            <Text style={styles.amount}>{currencyFormatter(grandTotal)}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.date}>{'    '}</Text>
                        </View>
                    </View>
                )
            })
        } else {

            if (pdfDocument.groupBy === 'comprobante') {
                rows = pdfDocument.invoices.map(item => {
                    return (
                        <View style={styles.row} key={item.id.toString()}>
                            <Text style={styles.date}>{item.date}</Text>
                            <Text style={styles.voucher}>{item.invoiceType} {item.pointOfSale}-{voucherFormat(item.id)}</Text>
                            <Text style={styles.line}>{currencyFormatter(item.subtotal)}</Text>
                            <Text style={styles.lineOther}>{currencyFormatter(item.totalExempt)}</Text>
                            <Text style={styles.tax}>{currencyFormatter(item.total - item.subtotal)}</Text>
                            <Text style={styles.amount}>{currencyFormatter(item.total)}</Text>
                        </View>
                    )
                })
            }
        }
    }
    return (
        <Fragment>
            {rows}
        </Fragment>
    )
};



export default TableRow;