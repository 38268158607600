import React from 'react';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import imageDefault from '../employees/imagenProfile/image-default.svg';

const styles = () => ({
    root: {
        margin: '-2px',
    },
    gridList: {
        width: '100%',
        margin: 0,
    },
    tileNameBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 70%, rgba(0,0,0,0) 100%)',
    },
    tileBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.2) 70%,rgba(0,0,0,0) 100%)',
    },
    placeholder: {
        height: '100%',
    },
    link: {
        color: '#fff',
    },
});

const getColsForWidth = width => {
    if (width === 'xs') return 2;
    if (width === 'sm') return 3;
    if (width === 'md') return 4;
    if (width === 'lg') return 5;
    return 6;
};

const GridEmployees = ({ employees = [], translate, classes, width }) => {

    const urlImageBuilder = (id) => {
        return `https://api.marasa.com.ar/rrhh-api/api/imageProfile/${id}/download`;
    }

    return (
        <div className={classes.root}>
            <Card className={classes.root}>
                <CardHeader title={translate('resources.tracking.gridList')} />
                <MuiGridList
                    cellHeight={180}
                    cols={getColsForWidth(width)}
                    className={classes.gridList}
                >
                    {employees.map(record => (
                        <GridListTile
                            component={Link}
                            key={record.id}
                            to={`/tracking/${record.id}`}
                        >
                            <img
                                src={urlImageBuilder(record.id)}
                                alt={record.fullName}
                                title={record.fullName}
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = imageDefault
                                }}
                            />
                            <GridListTileBar
                                className={classes.tileNameBar}
                                title={
                                    (record.shifts.length > 0) ?
                                        `${record.shifts[0].description}` : 'ERROR (SIN TURNO PRIMARIO)'
                                }
                                titlePosition="top"
                            />
                            <GridListTileBar
                                title={record.fullName}
                                className={classes.tileBar}
                                subtitle={
                                    <span>
                                        {record.role}
                                    </span>
                                }
                            />
                        </GridListTile>
                    ))}
                </MuiGridList>
            </Card>
        </div>
    );
}
const enhance = compose(
    withWidth(),
    withStyles(styles),
    translate
);

export default enhance(GridEmployees);
