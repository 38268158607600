import React from 'react';
import {
    FormDataConsumer
} from 'react-admin';
import { Card, CardHeader } from '@material-ui/core';
import AutocompleteDetail from './AutocompleteDetail';

const InvoiceFormDetail = () => {
    return (
        <FormDataConsumer>
            {({ formData }) => (formData.customerId !== undefined && formData.invoiceType !== undefined) ?
                <AutocompleteDetail formData={formData} />
                :
                <Card key={0}>
                    <CardHeader
                        title={
                            <div>
                                Por favor, selecciona un cliente y el tipo de comprobante y luego podrás cargar los conceptos facturados.
                            </div>
                        }
                    />
                </Card>
            }
        </FormDataConsumer>
    );
};

export default InvoiceFormDetail;
