import * as React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    useNotify,
    usePermissions
} from 'react-admin';
import {
    employeeValidation
} from '../validators/validators';
import ImageProfileInput from './imagenProfile/ImageProfileInput';
import DocumentFileInput from './document/DocumentFileInput';
import CustomToolbar from '../components/CustomToolbar';
import EmployeeForm from './EmployeeForm';
import EmployeeFormAdditional from './EmployeeFormAdditional';
import EmployeeFormHour from './EmployeeFormHour';
import { useMediaQuery } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddIcon from '@material-ui/icons/Add';
import { getRolesRRHH } from '../appRolesComponents';

const EmployeeCreate = (props) => {
    const redirect = `/employees`;
    const notify = useNotify();
    const { permissions } = usePermissions();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const isRRHH = getRolesRRHH(permissions);

    if (!isRRHH) return null;

    return (
        <Create
            {...props}
            onFailure={onFailure}
        >
            <TabbedForm
                warnWhenUnsavedChanges
                toolbar={<CustomToolbar />}
                redirect={redirect}
                validate={employeeValidation}
            >
                <FormTab
                    label={!isXsmall && "General"}
                    icon={isXsmall && <AccountBoxIcon />}
                >
                    <EmployeeForm />
                </FormTab>

                <FormTab
                    label={!isXsmall && "Horarios"}
                    icon={isXsmall && <AccessAlarmsIcon />}
                    path="hours"
                >
                    <EmployeeFormHour />
                </FormTab>

                <FormTab
                    label={!isXsmall && "Foto Empleado"}
                    icon={isXsmall && <AddAPhotoIcon />}
                    path="imageProfile"
                >
                    <ImageProfileInput />
                </FormTab>

                <FormTab
                    label={!isXsmall && "Documentación"}
                    icon={isXsmall && <NoteAddIcon />}
                    path="documents"
                >
                    <DocumentFileInput />
                </FormTab>
                <FormTab
                    label={!isXsmall && "Adicional"}
                    icon={isXsmall && <AddIcon />}
                    path="additional"
                >
                    <EmployeeFormAdditional />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default EmployeeCreate;
