import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    FormDataConsumer,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Button, Card, Box, CardContent, CardActions, CircularProgress, makeStyles } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { secondaryAccountsData } from '../components/data/secondaryAccountsData';
import primaryAccountsData from '../components/data/primaryAccountsData';
import AccountDatagrid from './AccountDatagrid';
import { toChoices } from '../utils/functions';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const Account = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(false);
    const [value, setValue] = useState();

    const handleSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setLoading(true);
            setState(true);
            setValue(values);
            setLoading(false)
        } else {
            setLoading(false);
            setState(false);
        }
    };

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Cuentas Corrientes'} />
                            <CardContent>
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="resources.reports_rrhh.filters.startDate"
                                                    source="start"
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                <DateInput
                                                    label="resources.reports_rrhh.filters.endDate"
                                                    source="end"
                                                    alwaysOn
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>

                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <SelectInput
                                                    label="Cuenta Principal"
                                                    source="primaryAccount"
                                                    choices={primaryAccountsData}
                                                    validate={requiredValidate}
                                                    resettable
                                                    fullWidth
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => (formData.primaryAccount === 'PROVEEDORES' && formData.primaryAccount !== undefined) ?
                                                        <ReferenceInput
                                                            label="Busque a su Proveedor"
                                                            source="supplierId"
                                                            reference="suppliers"
                                                            sort={{ field: 'name', order: 'ASC' }}
                                                            perPage={99999} //TODO NO TRAE MAS Q 99999
                                                            fullWidth
                                                            resettable
                                                        >
                                                            <AutocompleteInput
                                                                optionText="name"
                                                                validate={requiredValidate}
                                                            />
                                                        </ReferenceInput>
                                                        :
                                                        (formData.primaryAccount === 'CLIENTES' && formData.primaryAccount !== undefined) ?
                                                            <ReferenceInput
                                                                label="Busque a su Cliente"
                                                                source="customerId"
                                                                reference="customers"
                                                                sort={{ field: 'name', order: 'ASC' }}
                                                                fullWidth
                                                                resettable
                                                            >
                                                                <AutocompleteInput
                                                                    optionText="name"
                                                                    validate={requiredValidate}
                                                                />
                                                            </ReferenceInput>
                                                            :
                                                            (formData.primaryAccount !== undefined) &&
                                                            <SelectInput
                                                                label="Cuenta Secundaria"
                                                                source="secondaryAccount"
                                                                choices={
                                                                    formData.primaryAccount
                                                                        ? toChoices(secondaryAccountsData[formData.primaryAccount])
                                                                        : []
                                                                }
                                                                validate={requiredValidate}
                                                                fullWidth
                                                                resettable
                                                                {...rest}
                                                            />

                                                    }
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={loading}
                                                    >
                                                        {loading && (<CircularProgress size={25} thickness={2} />)}

                                                        {'Buscar Cuenta'}
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                {state &&
                                    <AccountDatagrid value={value} />
                                }
                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>
    );
};

export default Account;
