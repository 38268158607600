import * as React from 'react';
import { useState } from 'react';
import { 
    useLogin,
    useNotify,
    Notification,
    PasswordInput
} from 'react-admin';

import { 
    useLocation 
} from 'react-router-dom';

import { 
    Field,
    withTypes
} from 'react-final-form';

//material-ui
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import LockIcon from '@material-ui/icons/Lock';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

//ESTILOS CSS LOGIN
import loginStyle from "./loginStyle";
//TEMA
import Theme from './Theme';

const useStyles = makeStyles(loginStyle);

const { Form } = withTypes();

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const Login = () => {
    const [loading, setLoading] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const classes = useStyles();
    const location = useLocation();

    const handleSubmit = (inputAuth) => {        
        setLoading(true);
        inputAuth = {
            username: inputAuth.username.toLowerCase(),
            password: inputAuth.password
        }         
        login(inputAuth, location.state ? location.Pathname : '/')
        .catch(
            (error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            }
        );
     };

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = 'Debe ingresar un nombre de usuario.';
        }
        if (!values.password) {
            errors.password = 'Debe ingresar una contraseña.';
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate >
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar >
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        name="username"
                                        label={'Usuario'}
                                        component={renderInput}
                                        disabled={loading}
                                        autoComplete="current-password"
                                        autoFocus
                                        variant="standard"
                                    />
                                </div>
                                <div className={classes.input}>
                                    <PasswordInput 
                                        label={'Contraseña'}
                                        source="password"
                                        name="password"
                                        component={renderInput}
                                        disabled={loading}       
                                        autoComplete="current-password"     
                                        variant="standard" 
                                        fullWidth                   
                                    />
                                </div>
                            </div>
                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (<CircularProgress size={25} thickness={2}/>)}

                                    {'Iniciar Sesión'}
                                </Button>
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

const LoginWithTheme = (props) => (
    <ThemeProvider theme={Theme}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
