import * as React from 'react';
import {
    TextInput,
    NumberInput,
    CheckboxGroupInput,
    BooleanInput,
} from 'react-admin';
import {
    validateDecription,
    requiredValidateHour,
    requiredValidateMinutes,
    requiredValidate,
} from '../validators/validators';
import {
    toUpperCase,
} from '../utils/functions';
import { Box, CardContent } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import daysData from '../components/data/daysData';

const ShiftForm = () => {

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={3} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Turno" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                autoFocus
                                label="Nombre del Turno"
                                source="description"
                                parse={toUpperCase}
                                validate={validateDecription}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <SectionTitle label="Horario de Entrada" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Inicio"
                                source="startHour"
                                max="23"
                                min="0"
                                validate={requiredValidateHour}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            Hrs.
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label=""
                                source="startMinutes"
                                max="59"
                                min="0"
                                validate={requiredValidateMinutes}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            Min.
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        </Box>
                        <Box flex={8} ml={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                    </Box>

                    <SectionTitle label="Horario de Salida" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Fin"
                                source="endHour"
                                max="23"
                                min="0"
                                validate={requiredValidateHour}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            Hrs.
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label=""
                                source="endMinutes"
                                max="59"
                                min="0"
                                validate={requiredValidateMinutes}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            Min.
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        </Box>
                        <Box flex={8} ml={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <CheckboxGroupInput
                                label="Días de la semana"
                                source="daysOfWeek"
                                validate={requiredValidate}
                                choices={daysData}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                    <SectionTitle label="Opciones Extras" />
                    <div>
                        <BooleanInput
                            label="Turno Primario"
                            source="primaryShift"
                        />
                        <BooleanInput
                            label="Hora Extra"
                            source="extraHours"
                        />
                        <BooleanInput
                            label="Sereno"
                            source="isWorkshopKeeper"
                        />
                    </div>
                </Box>
            </Box>
        </CardContent>
    );
};

export default ShiftForm;
