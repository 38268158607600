import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormatter, voucherFormat } from '../utils/functions';

const useStyles = makeStyles({
    container: { minWidth: '35em', marginLeft: '1em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
});

const CustomerFormAccountLines = ({ record }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.container} elevation={2}>
            {record.invoices === null ?
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'Cuenta Secundaria'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {record.secondaryAccount}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                :
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {'Factura Nro.'}
                            </TableCell>
                            <TableCell>
                                {'Fecha'}
                            </TableCell>
                            <TableCell className={classes.rightAlignedCell}>
                                {'Importe'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record.invoices.map((item, i) => (
                            <TableRow key={i}>
                                <TableCell>
                                    Factura-{voucherFormat(item.id)}
                                </TableCell>
                                <TableCell>
                                    FALTA LA FECHA
                                </TableCell>
                                <TableCell className={classes.rightAlignedCell}>
                                    {currencyFormatter(item.total)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </Paper>
    );
};

export default CustomerFormAccountLines;
