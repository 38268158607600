import * as React from 'react';
import { List, usePermissions } from 'react-admin';
import ConsumableListDesktop from './ConsumableListDesktop';
import { getRolesOnlySupervisorExceptAdmin, getRolesWorkshopOnly, getRolesOnlyStorekeeperExceptAdmin } from '../appRolesComponents';
import InputFilter from '../components/InputFilter';

const ConsumableList = (props) => {
    const { permissions } = usePermissions();

    const isWorkshopOnly = getRolesWorkshopOnly(permissions) || getRolesOnlySupervisorExceptAdmin(permissions) || getRolesOnlyStorekeeperExceptAdmin(permissions);

    if (!isWorkshopOnly) return null;

    return (
        <List
            {...props}
            filters={<InputFilter />}
            perPage={10}
            bulkActionButtons={false}
        >
            <ConsumableListDesktop />
        </List>
    );
};

export default ConsumableList;
