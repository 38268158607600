import * as React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

import QrReaderEmployees from './qr/QrReaderEmployee';
import Clock from 'react-live-clock';
import ReactFitText from 'react-fittext';

const styles = () => ({
    root: {
        margin: '-2px',
        width: '97%',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
});
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const useStyles = makeStyles(styles);

const TrackingAutomatic = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.flexColumn}>
                <Card className={classes.root}>
                    <ReactFitText compressor={5}>
                        <h1>
                            Reloj de Fichaje automático:
                            <Clock className={classes.root}
                                format=" YYYY-MM-DD - HH:mm:ss "
                                interval={1000}
                                ticking={true}
                                timezone={'America/Argentina/Buenos_Aires'}
                            />
                        </h1>
                    </ReactFitText>
                </Card>
                <VerticalSpacer />
                <Card className={classes.root}>
                    <QrReaderEmployees />
                </Card>
            </div>
        </div>
    );
};

export default TrackingAutomatic;