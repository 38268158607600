import * as React from 'react';
import {
    useListContext,
    TopToolbar,
    CreateButton,
    sanitizeListRestProps,
} from 'react-admin';

const ActionsTopToolbar = (props) => {
    const {
        className,
        ...rest
    } = props;

    const {
        basePath,
    } = useListContext();

    const onButtonClick = () => {
        if (localStorage.getItem("invoiceCart") !== null && basePath === '/invoices') {
            localStorage.removeItem("invoiceCart");
        }
        if (localStorage.getItem("savedDrawing") !== null && basePath === '/inspections') {
            localStorage.removeItem("savedDrawing");
        }
        if (localStorage.getItem("purchaseCart") !== null && basePath === '/purchases') {
            localStorage.removeItem("purchaseCart");
        }
        if (localStorage.getItem("methods") !== null && (basePath === '/receipts' || basePath === '/payments')) {
            localStorage.removeItem("methods");
        }
        if (localStorage.getItem("invoicesReceipts") !== null && basePath === '/receipts') {
            localStorage.removeItem("invoicesReceipts");
        }
        if (localStorage.getItem("purchasesPayments") !== null && basePath === '/payments') {
            localStorage.removeItem("purchasesPayments");
        }
        if (localStorage.getItem("purchasesInvoiceCart") !== null && basePath === '/purchases-invoices') {
            localStorage.removeItem("purchasesInvoiceCart");
        }
    }

    const label = basePath === '/tools' ? 'Retirar Herramienta' : 'Crear';

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {
                (basePath !== '/reports-rrhh') &&
                    (basePath !== '/orders') &&
                    (basePath !== '/warehouse') &&
                    (basePath !== '/cash') &&
                    (basePath !== '/checks') &&
                    (basePath !== '/accounts') &&
                    (basePath !== '/absences') &&
                    (basePath !== '/extra-hour') ?

                    <CreateButton
                        variant="contained"
                        basePath={basePath}
                        onClick={onButtonClick}
                        label={label}
                    />

                    :

                    ''
            }
        </TopToolbar>
    );
};

export default ActionsTopToolbar;