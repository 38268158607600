import * as React from 'react';
import {
    useEffect
} from 'react';
import {
    Edit,
    useNotify,
    useRefresh,
    useRedirect,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    useEditController
} from 'react-admin';
import InvoiceForm from './InvoiceForm';
import InvoiceFormTax from './InvoiceFormTax';
import EditActionsInvoice from './EditActionsInvoice';
import InvoiceEditToolbar from './InvoiceEditToolbar';
import { Card, CardHeader, useMediaQuery } from '@material-ui/core';
import ListItemsMobile from './ListItemsMobile';
import ListItemsDesktop from './ListItemsDesktop';

const InvoiceEdit = (props) => {
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const controllerProps = useEditController(props);

    useEffect(() => {
        if (controllerProps.record !== undefined) {
            localStorage.setItem("invoiceCart", JSON.stringify(controllerProps.record.operationInfo));
        }
    }, [controllerProps.record]);

    const onSuccess = () => {
        notify('resources.employees.notification.approved_success', 'info', {}, false);
        redirect(`/invoices`);
        refresh();
    };

    const onFailure = (error) => {
        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
    };

    const Title = ({ record }) => {
        return <span>{record ? `#${record.id}` : ''}</span>;
    };

    const validate = (values) => {
        const errors = {};
        const invoiceCart = JSON.parse(localStorage.getItem("invoiceCart"));
        if (invoiceCart === null || invoiceCart === undefined || (Object.keys(invoiceCart).length === 0)) {
            errors.total = ['Agregue un item'];
        }
        if (values.alicuota > 100) {
            errors.alicuota = 'La cantidad es incorrecta para la alicuota';
        }
        return errors;
    };

    return (
        <Edit
            {...props}
            undoable={false}
            title={<Title />}
            actions={<EditActionsInvoice />}
            onSuccess={onSuccess}
            onFailure={onFailure}
        >
            <TabbedForm
                // warnWhenUnsavedChanges
                toolbar={<InvoiceEditToolbar />}
                redirect={redirect}
                validate={validate}
            >

                <FormTab label="Cabecera" >
                    <InvoiceForm />
                </FormTab>

                <FormTab
                    label="Detalle"
                    path="details"
                >
                    <FormDataConsumer>
                        {({ formData }) =>
                            isXsmall ?
                                <ListItemsMobile formData={formData} />
                                :
                                <ListItemsDesktop formData={formData} />
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab
                    label="Otros Impuestos"
                    path="tax"
                >
                    <FormDataConsumer>
                        {({ formData }) => (formData.customerId !== undefined && formData.invoiceType !== undefined) ?
                            <InvoiceFormTax {...props} />
                            :
                            <Card key={0}>
                                <CardHeader
                                    title={
                                        <div>
                                            Por favor, selecciona un cliente y el tipo de comprobante y luego podrás cargar los conceptos facturados.
                                        </div>
                                    }
                                />
                            </Card>
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default InvoiceEdit;
