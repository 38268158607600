import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useGetMany } from 'react-admin';
import jsPDF from 'jspdf';

const BulkPDFButton = ({ selectedIds = [], quantities }) => {
    const [loading, setLoading] = useState(false);
    const [totalLabels, setTotalLabels] = useState(0);

    const { data: selectedRecords } = useGetMany('items', selectedIds);


    useEffect(() => {
        // Calcular el total de etiquetas dinámicamente según los ítems seleccionados y cantidades
        var total = (selectedRecords || []).reduce(function (sum, record) {
            return sum + (parseInt(quantities[record.id]) || 1);
          }, 0);          
        setTotalLabels(total);
    }, [selectedRecords, quantities]);

    const handleGeneratePDF = async () => {
        if (!selectedRecords) return;

        setLoading(true);

        setTimeout(() => {
            const pdf = new jsPDF('p', 'mm', 'a4');

            const etiquetasPorFila = 3;
            const etiquetasPorColumna = 8;
            const etiquetaAncho = 60;
            const etiquetaAlto = 30;
            const margenIzquierdo = 9;
            const margenSuperior = 11;
            const espacioX = 5;
            const espacioY = 5;

            let x = margenIzquierdo;
            let y = margenSuperior;
            let etiquetasEnPagina = 0;
            // let numeroEtiqueta = 1;
            const totalEtiquetas = selectedRecords.reduce(
                (sum, record) => sum + (parseInt(quantities[record.id]) || 1),
                0
            );
            let numeroPagina = 1;
            let totalPaginas = Math.ceil(totalEtiquetas / (etiquetasPorFila * etiquetasPorColumna));

            selectedRecords.forEach(record => {
                const quantity = parseInt(quantities[record.id]) || 1;
                for (let i = 0; i < quantity; i++) {
                    const barcodeCanvas = document.createElement('canvas');
                    window.JsBarcode(barcodeCanvas, record.code, {
                        format: "CODE128",
                        width: 1.5,
                        height: 20,
                        displayValue: false
                    });
                    const barcodeImage = barcodeCanvas.toDataURL('image/png');

                    // Dibujar rectángulo delimitador de la etiqueta
                    //pdf.rect(x, y, etiquetaAncho, etiquetaAlto);

                    // Agregar numero de codigo
                    pdf.setFontSize(5);
                    pdf.text(record.code, x + 5, y + 4.5, { maxWidth: etiquetaAncho - 10 });

                    // Agregar código de barras
                    pdf.addImage(barcodeImage, 'PNG', x + 5, y + 5, etiquetaAncho - 10, 20);

                    // Agregar descripción
                    pdf.setFontSize(5);
                    pdf.text(record.name, x + 5, y + 24, { maxWidth: etiquetaAncho - 10 });

                    // Agregar número de etiqueta
                    // pdf.setFontSize(6);
                    // pdf.text(`Etiqueta ${numeroEtiqueta} de ${totalEtiquetas}`, x + 5, y + 33);

                    etiquetasEnPagina++;
                    // numeroEtiqueta++;

                    // Calcular nueva posición
                    if (etiquetasEnPagina % etiquetasPorFila === 0) {
                        x = margenIzquierdo;
                        y += etiquetaAlto + espacioY;
                    } else {
                        x += etiquetaAncho + espacioX;
                    }

                    // Si llegamos al límite de etiquetas por página, agregamos nueva página
                    if (etiquetasEnPagina === etiquetasPorFila * etiquetasPorColumna) {
                        pdf.text(`Página ${numeroPagina} de ${totalPaginas}`, 100, 285);
                        pdf.addPage();
                        x = margenIzquierdo;
                        y = margenSuperior;
                        etiquetasEnPagina = 0;
                        numeroPagina++;
                    }
                }
            });

            // Agregar el número de página en la última hoja si no se completó antes
            pdf.text(`Página ${numeroPagina} de ${totalPaginas}`, 100, 285);
            pdf.save('etiquetas_codigos_barras.pdf');

            setLoading(false);
        }, 100);
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleGeneratePDF}
            disabled={loading || selectedIds.length === 0}
        >
            {loading ? 'Generando...' : `Generar Códigos de Barras (${totalLabels} etiquetas)`}
        </Button>
    );
};

export default BulkPDFButton;
