import {
    fetchUtils,
} from "react-admin";
import customDataProvider from "./customDataProvider";

const superDataProvider = (type, resource, params, httpClient = fetchUtils.fetchJson) => {
    if (resource === 'employees' || resource === 'imageProfile' || resource === 'absences' || resource === 'shifts' || resource === 'document' || resource === 'holidays' || resource === 'extra-hour') {
        return customDataProvider('https://api.marasa.com.ar/rrhh-api/api', httpClient)(type, resource, params);
    }

    if (resource === 'tracking' || resource === 'timeTracking') {
        return customDataProvider('https://api.marasa.com.ar/tracking-api/api', httpClient)(type, resource, params);
    }

    if (resource === 'reports-rrhh') {
        return customDataProvider('https://api.marasa.com.ar/reports-api/api', httpClient)(type, resource, params);
    }

    if (resource === 'vehicles' || resource === 'brands' || resource === 'customers' || resource === 'busLines' || resource === 'inspections' || resource === 'orders' || resource === 'orders/document' || resource === 'items' || resource === 'categories' || resource === 'tools' || resource === 'busTemplate' || resource === 'warehouse' || resource === 'baskets' || resource === 'consumables') {//TODO DEJARLO HASTA Q SE HAGA EL BACKEND
        return customDataProvider('https://api.marasa.com.ar/workshop-api/api', httpClient)(type, resource, params);
    }

    //http://localhost:9096/api/warehouse?_start=0&_end=10&_sort=id&_order=ASC&range=5&range=9&sort=ASC
    
    if (resource === 'invoices' || resource === 'suppliers' || resource === 'receipts' || resource === 'payments' || resource === 'purchases' || resource === 'purchases-invoices' || resource === 'checks' || resource === 'cash' || resource === 'accounts') {
        return customDataProvider('https://api.marasa.com.ar/finance-api/api', httpClient)(type, resource, params);
    }

    return customDataProvider('https://api.marasa.com.ar', httpClient)(type, resource, params);
};

export default superDataProvider;
