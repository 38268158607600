import * as React from 'react';
import {
    EditButton,
    useTranslate,
    NumberField,
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import SupplierField from '../components/SupplierField';
import DateFieldFormatted from '../components/DateFieldFormatted';
import NumberFieldWithIVA from '../components/NumberFieldWithIVA';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

const PurchaseListMobile = ({ ids, data, basePath }) => {
    const classes = useStyles();
    const translate = useTranslate();

    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h2>{`OC-${data[id].id}`}</h2>
                                <EditButton
                                    resource="purchases"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        subheader={
                            <div>
                                {`Proveedor: `}
                                <SupplierField id={data[id].supplierId} />
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                            {'Fecha Inicio'}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="date"
                                showTime={false}
                            />
                            
                        </div>
                        <div>
                            {'Fecha Pactada'}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="agreedDate"
                                showTime={false}
                            />
                        </div>
                        <div>
                            {'Fecha Entrega'}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="deliveryDate"
                                showTime={false}
                            />
                        </div>
                        <div>
                            {translate('resources.orders.fields.total')}
                            :&nbsp;
                            <NumberField
                                addlabel={false}
                                record={data[id]}
                                source="total"
                                locales="es-AR"
                                options={{ style: 'currency', currency: 'ARS' }}
                                cellClassName={classes.total}
                            />                        
                        </div>
                        <div>
                        {'Total (IVA)'}
                            :&nbsp;
                            <NumberFieldWithIVA
                                addlabel={false}
                                record={data[id]}
                                source="total"
                                locales="es-AR"
                                options={{ style: 'currency', currency: 'ARS' }}
                                cellClassName={classes.total}
                            />    
                        </div>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default PurchaseListMobile;
